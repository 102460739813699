// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__describeList--9q8Hf {
  display: flex;
  flex-wrap: wrap;
}
.s-module__describeList--9q8Hf .s-module__describe--e3vy1 {
  margin: 0 4px 4px 0;
  padding: 2px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--gray-800);
  cursor: pointer;
  border-radius: 60px;
  border: transparent 1px dashed;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(120.97deg, #C8E6C9 0.03%, #B3E5FC 100.03%) border-box;
}
.s-module__describeList--9q8Hf .s-module__describe--e3vy1.s-module__selected--vlNLm {
  background: linear-gradient(#c8e6c9, #b3e5fc) padding-box, linear-gradient(270deg, #02FF74 -10.14%, #0BCA88 12.21%, #000AF0 109.45%) border-box;
}
`, "",{"version":3,"sources":["webpack://./src/components/ApplicationFormComponents/Practice/component/DescribeList/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;AACJ;AAHA;EAKQ,mBAAA;EACA,iBAAA;EAIA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,mBAAA;EACA,8BAAA;EACA,0HAAA;AAFR;AAIQ;EACI,+IAAA;AAFZ","sourcesContent":[".describeList {\n    display: flex;\n    flex-wrap: wrap;\n\n    .describe {\n        margin: 0 4px 4px 0;\n        padding: 2px 12px;\n        // background: var(--gray-100);\n        // border: 1px dashed #9CA3AF;\n        // border-radius: 4px;\n        font-weight: 500;\n        font-size: 14px;\n        line-height: 20px;\n        text-align: center;\n        color: var(--gray-800);\n        cursor: pointer;\n        border-radius: 60px;\n        border: transparent 1px dashed;\n        background: linear-gradient(#fff, #fff) padding-box, linear-gradient(120.97deg, #C8E6C9 0.03%, #B3E5FC 100.03%) border-box;\n\n        &.selected {\n            background: linear-gradient(rgb(200, 230, 201), rgb(179, 229, 252)) padding-box, linear-gradient(270deg, #02FF74 -10.14%, #0BCA88 12.21%, #000AF0 109.45%) border-box;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"describeList": `s-module__describeList--9q8Hf`,
	"describe": `s-module__describe--e3vy1`,
	"selected": `s-module__selected--vlNLm`
};
export default ___CSS_LOADER_EXPORT___;
