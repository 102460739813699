import React from 'react';
import AccessDeniedImg from 'assets/accessManagement/securityBg.svg';
import s from './s.module.less';

type Props = {
    status: 'default' | 'configing' | 'selected'
}

const ConfigStatus = (props: Props) => {
    const { status } = props;
    return (
        <div className={s.wrap}>
            {
                status === 'default' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <circle cx="10.2969" cy="10.9146" r="4" fill="#D1D5DB" />
                    </svg>
                )
            }
            {
                status === 'configing' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <circle cx="10.2969" cy="10.9146" r="8" fill="#A7F3D0" />
                        <circle cx="10.2969" cy="10.9146" r="4" fill="#00816B" />
                    </svg>
                )
            }
            {
                status === 'selected' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.2969 18.9141C14.7152 18.9141 18.2969 15.3323 18.2969 10.9141C18.2969 6.49578 14.7152 2.91406 10.2969 2.91406C5.8786 2.91406 2.29688 6.49578 2.29688 10.9141C2.29688 15.3323 5.8786 18.9141 10.2969 18.9141ZM14.004 9.62117C14.3945 9.23065 14.3945 8.59748 14.004 8.20696C13.6135 7.81643 12.9803 7.81643 12.5898 8.20696L9.29688 11.4998L8.00398 10.207C7.61346 9.81643 6.98029 9.81643 6.58977 10.207C6.19924 10.5975 6.19924 11.2306 6.58977 11.6212L8.58977 13.6212C8.98029 14.0117 9.61346 14.0117 10.004 13.6212L14.004 9.62117Z" fill="#00816B" />
                    </svg>
                )
            }
        </div>
    );
};

export default ConfigStatus;
