import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
import CardItem from './components/CardItem';
import Modal from 'antd/es/modal/Modal';
import { ICCCDashboardItem, ICCCItem } from 'types/ccc';
import { CCCTypeList } from 'constants/ccc';
import { offerCCC, pauseCCC, resumeCCC } from 'api/ccc';
import { message, Tooltip } from 'antd';
import CCCStore from 'store/CCC';

interface IProps {
    cccList?: ICCCItem[];
    cccDashboardData?: ICCCDashboardItem[];
}

const CareCommitmentCards = ({
    cccList,
    cccDashboardData,
}: IProps) => {
    const hasOfferedNoneCCC = useMemo(() => cccList?.every((ccc) => !ccc.offerStatus), [cccList]);
    const [showPauseConfirm, setShowPauseConfirm] = React.useState<boolean>(false);
    const [selectedCCCItem, setSelectedCCCItem] = React.useState<ICCCItem | null>(null);
    const [,setCCCStore] = CCCStore.useStore();
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleConfirmPause = useCallback(async () => {
        if (!selectedCCCItem) return;

        setLoading(true);
        try {
            const { skuCode } = selectedCCCItem;
            const res = await pauseCCC([skuCode]);

            if (res && !res.error) {
                setSelectedCCCItem(null);
                setCCCStore('forceRefetchCCCList', {});
                setShowPauseConfirm(false);
                message.success('You’ve successfully paused offering Care Commitment Card.');
            } else {
                message.error(res.error);
            }
        } catch (error) {
            console.log('error', error);
        }
        setLoading(false);
    }, [selectedCCCItem]);

    const handleOffer = useCallback(async (item: ICCCItem, plan?: string) => {
        if (!item) return;

        try {
            const { skuCode } = item;

            const res = await offerCCC([skuCode]);
            if (res && !res.error) {
                setCCCStore('forceRefetchCCCList', {});
                message.success(`🎉 Congratulations! You’ve successfully offered ${plan || ''} Care Commitment Card.`);
            } else {
                message.error(res.error);
            }
        } catch (error) {
            console.error('error', error);
        }
    }, []);

    const handleResume = useCallback(async (item: ICCCItem) => {
        if (!item) return;

        try {
            const { skuCode } = item;

            const res = await resumeCCC([skuCode]);
            if (res && !res.error) {
                setCCCStore('forceRefetchCCCList', {});
            } else {
                message.error(res.error);
            }
        } catch (error) {
            console.error('error', error);
        }
    }, []);

    const currentSelectedCCCType = useMemo(() => {
        const type = CCCTypeList.find((e) => e.skuCode === selectedCCCItem?.skuCode)?.type;

        return type ? `the ${type}` : '';
    }, [selectedCCCItem]);

    return (
        <div className={s.wrap}>
            <Modal
                open={showPauseConfirm}
                title={`Pause offering ${currentSelectedCCCType} Care Commitment Card?`}
                okButtonProps={{
                    danger: true,
                }}
                okText="Pause"
                onCancel={() => {
                    setSelectedCCCItem(null);
                    setShowPauseConfirm(false);
                }}
                onOk={handleConfirmPause}
                confirmLoading={loading}
            >
                <p className={s.pauseConfirmContent}>Pausing offering this card will remove it from your Provider Profile page, making it unavailable to patients. You can easily resume it anytime to continue offering this benefit. Are you sure you want to proceed?</p>
            </Modal>
            <div className={s.header}>
                <div className={s.title}>Care Commitment Cards</div>
                <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '12px' }} color="#FFFFFF" title={<div className={s.tipText}>The Care Commitment Card (CCC) is a patient loyalty program designed to increase your patient retention, visit frequency, and overall practice revenue on Klarity. CCC can be used to purchased all of your services, including initial, follow-up, prescriptions services, but not medications. A patient who purchase your CCC can only use the CCC value for your services, not other providers.</div>}>
                    <div className={s.tip} />
                </Tooltip>
            </div>
            <div className={s.list}>
                {
                    cccList?.map((ccc: ICCCItem) => {
                        const { skuCode, offerStatus, price, value } = ccc;
                        const plan = CCCTypeList.find((item) => item.skuCode === skuCode)?.type;
                        const cccDashboardDataItem = cccDashboardData?.find((item) => item.skuCode === skuCode);
                        return (
                            <CardItem
                                price={price}
                                value={value}
                                plan={plan}
                                status={offerStatus}
                                cccDashboardDataItem={cccDashboardDataItem}
                                hasOfferedNoneCCC={hasOfferedNoneCCC}
                                onPause={() => {
                                    setShowPauseConfirm(true);
                                    setSelectedCCCItem(ccc);
                                }}
                                onOffer={() => {
                                    handleOffer(ccc, plan);
                                }}
                                onResume={() => {
                                    handleResume(ccc);
                                }}
                            />
                        );
                    })
                }
            </div>
        </div>
    );
};

export default CareCommitmentCards;
