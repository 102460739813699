import React, { useCallback, useMemo, useRef } from 'react';
import s from './s.module.less';
import { showBlackFriday2024EnhancementModal } from 'utils/globalLayerControl';
import { type } from 'constants/ccc';
import { ICCCDashboardItem } from 'types/ccc';
import { Tooltip } from 'antd';

interface IProps {
    price?: number;
    value?: number;
    status?: any;
    plan?: any;
    cccDashboardDataItem?: ICCCDashboardItem;
    onPause?: () => void;
    onOffer?: () => void;
    onResume?: () => void;
    hasOfferedNoneCCC?: boolean;
}

const CardItem = ({
    price,
    value,
    status,
    plan,
    onPause,
    onOffer,
    hasOfferedNoneCCC,
    onResume,
    cccDashboardDataItem,
}: IProps) => {
    const tipContent = useRef({
        [type.basic]: 'By offering a Basic CCC, you agree to provide your patients the service value of $125 for $100. The discount comes out from your pay-out.',
        [type.essential]: 'By offering an Essential CCC, you agree to provide your patients the service value of $400 for $300. The discount comes out from your pay-out.',
        [type.plus]: 'By offering a Plus CCC, you agree to provide your patients the service value of $800 for $600. The discount comes out from your pay-out.',
    });
    const handleOffer = useCallback(() => {
        if (hasOfferedNoneCCC) {
            showBlackFriday2024EnhancementModal();
        } else {
            onOffer?.();
        }
    }, [hasOfferedNoneCCC]);

    const handlePauseClick = useCallback(() => {
        onPause?.();
    }, []);

    const wrapCls = useMemo(() => {
        let secnCls = s.notOffered;

        if (status === 'D') {
            secnCls = s.resume;
        } else if (status === 'A') {
            secnCls = '';
        }

        return `${s.wrap} ${secnCls}`;
    }, [status]);

    const bottomContentRender = useMemo(() => {
        if (status === 'D') {
            return <div className={s.actionBtn} onClick={onResume}>Resume now</div>;
        }

        if (status === 'A') {
            let statsCls = s.minus;

            const rate = cccDashboardDataItem?.salesVolumeRate || 0;
            if (rate > 0) {
                statsCls = s.up;
            } else if (rate < 0) {
                statsCls = s.down;
            }
            return (
                <>
                    <div className={s.nums}>
                        <div className={s.current}>{cccDashboardDataItem?.salesVolume || 0}</div>
                        <div className={s.basicLine}>from {cccDashboardDataItem?.preSalesVolume || 0}</div>
                    </div>
                    <div className={`${s.stats} ${statsCls}`}>{rate}%</div>
                </>
            );
        }

        return <div className={s.actionBtn} onClick={handleOffer}>Offer now</div>;
    }, [status, handleOffer, cccDashboardDataItem]);

    const cardImageRender = useMemo(() => {
        if (plan === type.plus) {
            return <div className={`${s.image} ${s.plus}`} />;
        }

        if (plan === type.essential) {
            return <div className={`${s.image} ${s.essential}`} />;
        }

        return <div className={`${s.image} ${s.basic}`} />;
    }, [plan]);

    const tipIcon = useMemo(() => {
        if (status !== 'A') {
            const message = tipContent.current[plan];
            return (
                <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '12px' }} color="#FFFFFF" title={<div className={s.tipText}>{message}</div>}>
                    <div className={s.tip} />
                </Tooltip>
            );
        }
    }, [status, plan]);

    return (
        <div className={wrapCls}>
            {tipIcon}
            { !status && <div className={s.notOfferedLabel}>Not offered</div> }
            <div className={s.top}>
                {cardImageRender}
                <div className={s.info}>
                    <div className={s.nameBox}>
                        <div className={s.name}>{plan}</div>
                        { status === 'A' && <div className={s.status} onClick={handlePauseClick}>Pause offering</div> }
                    </div>
                    <div className={s.priceBox}>
                        <div className={s.price}>${price || 0} for ${value || 0} value</div>
                    </div>
                </div>
            </div>
            <div className={s.bottom}>
                {bottomContentRender}
            </div>
        </div>
    );
};

export default CardItem;
