import React from 'react';
import s from './s.module.less';

type IProps = {
    status?: string;
};

const EhrActiveStatus: React.FC<IProps> = (props) => {
    const { status } = props;
    return (
        <div className={s.descItem}>
            <span>Status:</span>
            <span className={`${s[status || '']} `}>
                {status}
            </span>
        </div>
    );
};

export default EhrActiveStatus;
