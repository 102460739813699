import { TFeedbackAiResultRes, TFeedbackMessageRes, TFetchAiCtaResultRes, TProviderTableRes, TStreamingChatRes } from 'types/ai';
import { Request } from '../request';

export const getAiEditorConfig = () => Request.get({ url: '/schedule-server/ai/config/editor' });

export const fetchAiCtaResult = (params: TFetchAiCtaResultRes) =>
    Request.post(
        { url: '/schedule-server/ai/editor/rewrite', params, config: { timeout: 120 * 1000 } },
    );

export const feedbackAiResult = (params: TFeedbackAiResultRes) =>
    Request.post(
        { url: '/schedule-server/ai/editor/feedback', disableErrorNote: true, params },
    );

export const streamingChat = (params: TStreamingChatRes) =>
    Request.streamPost(
        { url: 'ai-server/v1/streaming-chat-messages', disableErrorNote: true, params },
    );

export const getProviderTable = (params: TProviderTableRes) =>
    Request.post(
        { url: 'ai-server/v1/workflows/run', disableErrorNote: true, params },
    );

export const getSuggested = (messageId: string, user: string) =>
    Request.get(
        { url: `ai-server/v1/messages/${messageId}/suggested`, disableErrorNote: true, params: { user } },
    );

export const getConversations = (user: string, limit: number, lastId?: string) => {
    const data: any = {
        user,
        limit,
    };
    if (lastId) {
        data.last_id = lastId;
    }
    return Request.get(
        {
            url: 'ai-server/v1/conversations',
            disableErrorNote: true,
            params: data,
        },
    );
};
export const getMessages = (user: string, conversationId: string, limit: number, firstId?: string) => {
    const data: any = {
        user,
        limit,
        conversation_id: conversationId,
    };
    if (firstId) {
        data.first_id = firstId;
    }
    return Request.get(
        {
            url: 'ai-server/v1/messages',
            disableErrorNote: true,
            params: data,
        },
    );
};

export const feedbackMessage = (messageId: string, params: TFeedbackMessageRes) =>
    Request.post(
        {
            url:
                `ai-server/v1/messages/${messageId}/feedbacks`,
            disableErrorNote: true,
            params,
        },
    );

export const getProviderDetail = (providerIdList: string[]) => {
    return Request.post(
        {
            url:
                'admin-server/provider/search/list/detail',
            disableErrorNote: true,
            params: {
                sortBy: 'recommended',
                providerIdList,
            },
        },
    );
};

