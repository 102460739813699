import { Request } from '../request';
import { IproviderAvailabilityList, IproviderOverrideCreate, IproviderOverrideList, IproviderBillingCreate, IproviderBillingList } from 'types/appointmentSetting';
import { Service } from 'types/common';

export const providerAvailabilityList = (id: number) => Request.get<IproviderAvailabilityList[]>({ url: `/schedule-server/provider/availability/listByProviderId/${id}` });
export const providerAvailabilitySave = (data: IproviderAvailabilityList, id:number) =>
    Request.post({ url: `/schedule-server/provider/availability/saveByProviderId/${id}`, params: data });

export const providerOverrideList = (id: number) => Request.get<IproviderOverrideList[]>({ url: `/schedule-server/provider/override/listByProviderId/?providerId=${id}` });
export const providerOverrideCreate = (data: IproviderOverrideCreate) =>
    Request.post({ url: '/schedule-server/provider/override/create', params: data });
export const providerOverrideDelete = (id: number) => Request.delete({ url: `/schedule-server/provider/override/del/${id}` });

export const providerServiceList = (id: number) => Request.get<Service[]>({ url: `/schedule-server/providerService/listByProviderId/?providerId=${id}` });

export const providerServiceCreate = (data: Service) => Request.post({ url: '/schedule-server/providerService/create', params: data });
export const providerServiceUpdate = (data: Service, id:number) =>
    Request.put<Service>({ url: `/schedule-server/providerService/update/${id}`, params: data });

export const providerServiceDelete = (id: number) => Request.delete({ url: `/schedule-server/providerService/del/${id}` });

export const providerBillingList = (id: number) => Request.get<IproviderBillingList[]>({ url: `/schedule-server/provider/bill/listByProviderId/?providerId=${id}` });
export const providerBillingCreate = (data: IproviderBillingCreate) =>
    Request.post({ url: '/schedule-server/provider/bill/save', params: data });
export const providerBillingDelete = (id: number) => Request.delete({ url: `/schedule-server/provider/bill/del/${id}` });
