import instance, { baseURL } from './index';
import { message } from 'antd';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { getUserToken } from 'utils/localstore';
import { ERROR_CODE } from 'constants/common';

const parseJSON = async (response: AxiosResponse): Promise<any> => {
    if (response.status >= 200 && response.status < 400) {
        return response.status === 204 ? {} : { data: response.data, error: undefined };
    }
    if (response.status >= 400 && response.status < 500) {
        const errorText = response.data?.message ?? response.data?.error;
        throw new Error(errorText ?? `${response.status} ${response.statusText}`);
    }

    if (response.status >= 500 && response.status < 600) {
        // exception in backend
        const errorText = response.data?.message ?? response.data?.error;
        throw new Error(errorText ?? `${response.status} ${response.statusText}`);
    }

    throw new Error(
        `${response.status} ${response.statusText}: Unexpected status code`,
    );
};

const handleErrorMessage = (err?: any) => {
    try {
        const msg:string = err?.message;
        if (msg && !msg.includes('unauthorized')) {
            message.error(err?.message);
        }
    } catch (e) {

    }
};

const handleErrorCode = (error:any):void => {
    if (error?.data?.code === ERROR_CODE.TOKEN_UN_VERIFY) {
        // if (isOpPortal()) {
        //     window.navigate(PATH.HOME);
        // } else {
        //     window.navigate(PATH.SIGNUP);
        // }

        // window.location.reload();
    }
};

type RequestParam<T> = {
    url: string,
    params?: T,
    config?:AxiosRequestConfig,
    disableErrorNote?:boolean,
    disableToken?: boolean,
};

const getHeader = (disableToken?:boolean) => {
    if (getUserToken() && !disableToken) {
        return {
            token: getUserToken(),
            'Content-Type': 'application/json;charset=UTF-8;',
        };
    } else {
        return {
            'Content-Type': 'application/json;charset=UTF-8;',
        };
    }
};

export class Request {
    static get = async <T>(props:RequestParam<T>) => {
        const { url, config, params, disableErrorNote, disableToken } = props;
        try {
            const paramStr = params ? new URLSearchParams(params).toString() : '';
            const urlWithParam = paramStr ? `${url}?${paramStr}` : url;
            const response = await instance.get(urlWithParam, config ? { ...config, headers: { ...getHeader(disableToken), ...(config.headers || {}) } } : { headers: getHeader(disableToken) });
            return await parseJSON(response);
        } catch (err) {
            const error = err as any;
            handleErrorCode(error);
            const errorMsg = error?.message || error?.data?.message || 'Oops! Our system is on a coffee break. Please try again later.';
            !disableErrorNote && handleErrorMessage({ message: errorMsg });
            return { success: false, error: errorMsg, errorCode: error?.data?.code };
        }
    };

    static post = async <T>(props:RequestParam<T>) => {
        const { url, params, config, disableErrorNote, disableToken } = props;
        try {
            const response = await instance.post(url, params, config ? { ...config, headers: { ...getHeader(disableToken), ...(config.headers || {}) } } : { headers: getHeader(disableToken) });
            return await parseJSON(response);
        } catch (err) {
            const error = err as any;
            handleErrorCode(error);
            const errorMsg = error?.message || error?.data?.message || error?.data?.error || 'Oops! Our system is on a coffee break. Please try again later.';
            !disableErrorNote && handleErrorMessage({ message: errorMsg });
            return { success: false, error: errorMsg, errorCode: error?.data?.code };
        }
    };

    static put = async <T>(props:RequestParam<T>) => {
        const { url, params, config, disableErrorNote, disableToken } = props;
        try {
            const response = await instance.put(url, params, config ? { ...config, headers: { ...getHeader(disableToken), ...(config.headers || {}) } } : { headers: getHeader(disableToken) });
            //if server error will throw and stop here
            return await parseJSON(response);
        } catch (err) {
            const error = err as any;
            handleErrorCode(error);
            const errorMsg = error?.message || error?.data?.message || 'Oops! Our system is on a coffee break. Please try again later.';
            !disableErrorNote && handleErrorMessage({ message: errorMsg });
            return { success: false, error: errorMsg, errorCode: error?.data?.code };
        }
    };

    static delete = async <T>(props:RequestParam<T>) => {
        const { url, config, params, disableErrorNote, disableToken } = props;
        try {
            let urlWithParam = url;
            const requestConfig = config ? { ...config, headers: { ...getHeader(disableToken), ...(config.headers || {}) } } : { headers: getHeader(disableToken) };
            if (params instanceof Array) {
                requestConfig.data = params;
            } else {
                const paramStr = params ? new URLSearchParams(params).toString() : '';
                urlWithParam = paramStr ? `${url}?${paramStr}` : url;
            }

            const response = await instance.delete(urlWithParam, requestConfig);
            return await parseJSON(response);
        } catch (err) {
            const error = err as any;
            handleErrorCode(error);
            const errorMsg = error?.message || error?.data?.message || 'Oops! Our system is on a coffee break. Please try again later.';
            !disableErrorNote && handleErrorMessage({ message: errorMsg });
            return { success: false, error: errorMsg, errorCode: error?.data?.code };
        }
    };

    static streamPost = async <T>(props:RequestParam<T>) => {
        const { url, params, config, disableErrorNote, disableToken } = props;
        const header:any = { ...getHeader(disableToken), ...(config?.headers || {}) };

        return fetch(baseURL + url, {
            method: 'POST',
            headers: {
                ...header,
                'Content-Type': 'application/json',
                Accept: 'text/event-stream', // 接收服务器端的 streaming 数据
            },
            body: JSON.stringify(params),
        });
    };
}
