import React, { useCallback, useEffect, useState } from 'react';
import DaySlot from './components/DaySlot';
import capitalize from 'lodash/capitalize';

interface IWeekData {
    weekDay: string;
    hourFromTo: {
        fromHour: number;
        fromMinute: number;
        toHour: number;
        toMinute: number;
    }[];
}
interface IProps {
    showError?: boolean;
    value?: IWeekData[];
    onChange?: (v: any) => void;
}

const order = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const AppointmentTime: React.FC<IProps> = ({ value, onChange, showError }) => {
    // const [weeksData, setWeeksData] = useState<IWeekData[]>([
    //     { weekDay: 'sunday',
    //         hourFromTo: [
    //             { fromHour: 9, fromMinute: 0, toHour: 17, toMinute: 0 },
    //         ] },
    //     { weekDay: 'monday',
    //         hourFromTo: [
    //             { fromHour: 9, fromMinute: 0, toHour: 17, toMinute: 0 },
    //         ] },
    //     { weekDay: 'tuesday',
    //         hourFromTo: [
    //             { fromHour: 9, fromMinute: 0, toHour: 17, toMinute: 0 },
    //         ] },
    //     { weekDay: 'wednesday',
    //         hourFromTo: [
    //             { fromHour: 9, fromMinute: 0, toHour: 17, toMinute: 0 },
    //         ] },
    //     { weekDay: 'thursday',
    //         hourFromTo: [
    //             { fromHour: 9, fromMinute: 0, toHour: 17, toMinute: 0 },
    //         ] },
    //     { weekDay: 'friday',
    //         hourFromTo: [
    //             { fromHour: 9, fromMinute: 0, toHour: 17, toMinute: 0 },
    //         ] },
    //     { weekDay: 'saturday',
    //         hourFromTo: [
    //             { fromHour: 9, fromMinute: 0, toHour: 17, toMinute: 0 },
    //         ] },
    // ]);
    const [weeksData, setWeeksData] = useState<IWeekData[]>([
        { weekDay: 'sunday', hourFromTo: [] },
        { weekDay: 'monday', hourFromTo: [] },
        { weekDay: 'tuesday', hourFromTo: [] },
        { weekDay: 'wednesday', hourFromTo: [] },
        { weekDay: 'thursday', hourFromTo: [] },
        { weekDay: 'friday', hourFromTo: [] },
        { weekDay: 'saturday', hourFromTo: [] },
    ]);

    useEffect(() => {
        if (value) {
            const sortedData = value.sort((a, b) => {
                return order.indexOf(a.weekDay) - order.indexOf(b.weekDay);
            });
            setWeeksData(sortedData);
        }
    }, [value]);

    const handleChange = useCallback((v: IWeekData) => {
        const newWeeksData = weeksData.map((week) => {
            if (week.weekDay === v.weekDay) {
                return v;
            }
            return week;
        });
        setWeeksData(newWeeksData);

        onChange?.(newWeeksData);
    }, [onChange, weeksData]);

    return (
        <div>
            {
                weeksData.map((week) => {
                    const { weekDay, hourFromTo } = week;
                    return (
                        <div key={weekDay}>
                            <DaySlot showError={showError} time={hourFromTo} weekLabel={capitalize(weekDay)} weekValue={weekDay} onChange={handleChange} />
                        </div>
                    );
                })
            }
        </div>
    );
};

export default AppointmentTime;
