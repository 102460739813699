import { Form, Button, Input, Checkbox, InputNumber, Select, Switch, Tooltip, message } from 'antd';
import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle, useMemo } from 'react';
import commonS from 'styles/common.module.less';
import s from '../../s.module.less';
import { ProviderChannelService } from 'types/channelApplicationProvider';
import { ServicesFormFieldEnum } from 'pages/CreateUniProfilePage/types';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { PATIENT_TYPES } from 'constants/meta';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import ColorSelect from 'components/form/ColorSelect';
import DurationSelect from 'components/form/DurationSelect';
import { EMPTY_PLACEHOLDER } from 'constants/common';
import useServiceTypeData from 'hooks/useServiceTemplateData';
import { Service } from 'types/common';
import { ICommonFormData } from 'types/provider';

interface IProps {
    addressList: Array<{ value: number, label: string, isDefault: boolean }>;
    isOps?: boolean;
    showEditButtons?: boolean;
    formData: ICommonFormData;
    enable: boolean;
    onEditStatusChange: (v: boolean, formId: number) => void;
    onRemoveService: (id: number) => void;
    onUpdateService: (id: number, formValue: ProviderChannelService) => void;
}

const CommonForm = forwardRef(({
    addressList = [],
    isOps,
    showEditButtons = true,
    formData,
    enable,
    // onShowUpdateModal,
    onEditStatusChange,
    onRemoveService,
    onUpdateService,
}: IProps, ref) => {
    const [serviceTemplateData, serviceTemplateDataLoading] = useServiceTypeData(isOps);
    const [colorOptions] = useGetSettingData(EnumFields.COLOR);
    const [inpersonCheckStatus, setInpersonCheckStatus] = useState(false);
    const [telehealthCheckStatus, setTelehealthCheckStatus] = useState(false);
    const [enableSlidingScaleCheckStatus, setEnableSlidingScaleCheckStatus] = useState(false);
    const [commonFormInstance] = Form.useForm<ProviderChannelService>();
    const [currentServiceTemplateData, setCurrentServiceTemplateData] = useState<Service>();
    const [showInitialVisitError, setShowInitialVisitError] = useState(false);

    const {
        price,
        duration,
        personAddress,
        personCity,
        personState,
        personType,
        telehealthType,
        inpersonChecked,
        telehealthChecked,
        description,
        name,
        id,
        type,
        colorId,
        hasSetPrice,
        hasSetMeetingType,
        lowestPrice,
        highestPrice,
        enableSlidingScale,
        addressId,
    } = formData;

    useEffect(() => {
        if (currentServiceTemplateData && enable) {
            commonFormInstance.setFieldsValue({
                [ServicesFormFieldEnum.SERVICE_TYPE]: currentServiceTemplateData.type,
                [ServicesFormFieldEnum.PRICE]: currentServiceTemplateData.price,
                [ServicesFormFieldEnum.COLOR_ID]: currentServiceTemplateData.colorId,
                [ServicesFormFieldEnum.DESCRIPTION]: currentServiceTemplateData.description,
                [ServicesFormFieldEnum.DURATION]: currentServiceTemplateData.duration,
                [ServicesFormFieldEnum.NAME]: currentServiceTemplateData.name,
                [ServicesFormFieldEnum.ADDRESS]: currentServiceTemplateData.personAddress,
                [ServicesFormFieldEnum.CITY]: currentServiceTemplateData.personCity,
                [ServicesFormFieldEnum.STATE]: currentServiceTemplateData.personState,
                [ServicesFormFieldEnum.PERSON_TYPE]: currentServiceTemplateData.personType?.split(','),
                [ServicesFormFieldEnum.ZIP_CODE]: currentServiceTemplateData.personZip,
                [ServicesFormFieldEnum.SEND_FORM]: currentServiceTemplateData.sendForm,
                [ServicesFormFieldEnum.LOWEST_PRICE]: currentServiceTemplateData.enableSlidingScale ? currentServiceTemplateData.lowestPrice : undefined,
                [ServicesFormFieldEnum.HIGHEST_PRICE]: currentServiceTemplateData.enableSlidingScale ? currentServiceTemplateData.highestPrice : undefined,
                [ServicesFormFieldEnum.TELEHEALTH_TYPE]: currentServiceTemplateData.telehealthType?.split(','),
            });
            setEnableSlidingScaleCheckStatus(!!currentServiceTemplateData.enableSlidingScale);
        }
    }, [currentServiceTemplateData, enable, commonFormInstance]);

    useEffect(() => {
        if (formData) {
            if (!formData.enableSlidingScale) {
                formData.lowestPrice = undefined;
                formData.highestPrice = undefined;
            }
            commonFormInstance.setFieldsValue(formData);
            setEnableSlidingScaleCheckStatus(!!formData.enableSlidingScale);
        }
    }, [formData, commonFormInstance]);

    useEffect(() => {
        setInpersonCheckStatus(!!inpersonChecked);
        setTelehealthCheckStatus(telehealthChecked);
    }, [inpersonChecked, telehealthChecked]);

    const getEditStatus = useCallback(() => enable, [enable]);

    useImperativeHandle(ref, () => ({
        getEditStatus,
    }));

    const handleInpersonCheckChange = useCallback((e: CheckboxChangeEvent) => {
        setInpersonCheckStatus(e.target.checked);
    }, []);

    const handleTelehealthCheckChange = useCallback((e: CheckboxChangeEvent) => {
        setTelehealthCheckStatus(e.target.checked);
    }, []);

    const handleEnableSlidingScaleChange = (check: boolean) => {
        setEnableSlidingScaleCheckStatus(check);
    };

    const handleFormFinish = useCallback(async (formValue: ProviderChannelService) => {
        if (commonFormInstance.getFieldValue(ServicesFormFieldEnum.SERVICE_TYPE) === 'Initial Visit' && !inpersonCheckStatus && !telehealthCheckStatus) {
            setShowInitialVisitError(true);
        } else {
            if (!formValue) {
                message.error('Please fill in all required fields');
                return;
            }
            const lowestPriceVal = formValue[ServicesFormFieldEnum.LOWEST_PRICE] as number;
            const highestPriceVal = formValue[ServicesFormFieldEnum.HIGHEST_PRICE] as number;
            if (enableSlidingScaleCheckStatus && lowestPriceVal > highestPriceVal) {
                message.error('Highest price should be greater than lowest price');
            }
            formValue[ServicesFormFieldEnum.ENABLE_SLIDING_SCALE] = !!enableSlidingScaleCheckStatus;
            setShowInitialVisitError(false);
            onUpdateService(id!, formValue);
        }
    }, [commonFormInstance, id, inpersonCheckStatus, onUpdateService, telehealthCheckStatus, enableSlidingScaleCheckStatus]);

    const officeAddress = `${personAddress},${personCity},${personState}`;
    const formName = `commonForm_${id}`;

    const markColor = useMemo(() => colorOptions?.find((option) => Number(option.dictKey) === Number(colorId)), [colorId, colorOptions]);

    const renderNoMeetingtype = useMemo(() => {
        if (
            (!telehealthType || telehealthType?.length === 0) &&
            !(personType && personType.length > 0 && (addressId || addressId === 0))
        ) {
            return (
                <div className={s.box}>
                    <div className={s.wrongIcon} />
                    <div className={s.info}>
                        <div className={s.title}>No meeting needed</div>
                    </div>
                </div>

            );
        }

        return null;
    }, [personType, telehealthType]);

    const renderEditButtons = useMemo(() => {
        if (showEditButtons) {
            return !enable ? (
                <div key={`${id}_disable`} className={s.editButtons}>
                    <Button
                        size="small"
                        type="ghost"
                        className={s.removeButton}
                        onClick={() => {
                            onRemoveService(id);
                        }}
                    >
                        Remove
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            onEditStatusChange(true, id);
                        }}
                    >
                        Edit
                    </Button>
                </div>
            ) : (
                <div key={`${id}_enable`} className={s.editButtons}>
                    <Button
                        size="small"
                        onClick={() => {
                            onEditStatusChange(false, id);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        type="primary"
                        htmlType="submit"
                    >
                        Save changes
                    </Button>
                </div>
            );
        }
        return null;
    }, [enable, id, onEditStatusChange, onRemoveService, showEditButtons]);

    return (
        <Form
            form={commonFormInstance}
            name={formName}
            className={commonS.formStyle1}
            autoComplete="off"
            layout="vertical"
            onFinish={handleFormFinish}
            scrollToFirstError
        >
            <div className={s.wrapper} style={(hasSetPrice === false || hasSetMeetingType === false) ? { borderColor: 'red' } : {}}>
                <h4>
                    <div className={s.checkboxTitleBox}>
                        {
                            !enable && (
                                <div className={s.checkboxText}>
                                    { type && <div className={s.serviceTypeTag}>{type}</div> }
                                    <div className={s.titleBox}>
                                        <div className={s.colorMark} style={{ backgroundColor: markColor?.content }} />
                                        <div className={s.checkboxTitle}>{name}</div>
                                    </div>
                                    <div className={s.des}>{description || `Description ${EMPTY_PLACEHOLDER}`}</div>
                                </div>
                            )
                        }
                    </div>
                    {renderEditButtons}
                </h4>
                {
                    !enable ? (
                        <>
                            <div className={s.correctIcon} />
                            <div className={s.info}>
                                <div className={s.row}>
                                    <div
                                        className={s.displayItem}
                                        style={{ width: '30%', flex: 'none' }}
                                    >
                                        <div className={`${s.label} ${s.required}`}>Duration(minutes)</div>
                                        <div className={s.value}>{duration}</div>
                                    </div>
                                    <div
                                        className={s.displayItem}
                                    >
                                        <div className={`${s.label} ${s.required}`}>Price</div>
                                        <div className={s.value}>{!price && typeof price !== 'number' ? 'Price has not been set' : `$${parseInt(price, 10).toFixed(2)}`}</div>
                                    </div>
                                </div>
                                {
                                    !!enableSlidingScale &&
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                            style={{ width: '30%', flex: 'none' }}
                                        >
                                            <div className={s.label}>Enable sliding scale</div>
                                            <div className={s.value}>True</div>
                                        </div>
                                        <div
                                            className={s.displayItem}
                                            style={{ width: '30%', flex: 'none' }}
                                        >
                                            <div className={`${s.label} ${s.required}`}>Lowest price</div>
                                            <div className={s.value}>{(!lowestPrice || typeof lowestPrice !== 'number') ? 'Lowest price has not been set' : `$${lowestPrice!.toFixed(2)}`}</div>
                                        </div>
                                        <div
                                            className={s.displayItem}
                                            style={{ width: '30%', flex: 'none' }}
                                        >
                                            <div className={`${s.label} ${s.required}`}>Highest price</div>
                                            <div className={s.value}>{(!highestPrice || typeof highestPrice !== 'number') ? 'Highest price has not been set' : `$${highestPrice!.toFixed(2)}`}</div>
                                        </div>
                                    </div>
                                }
                                <div className={s.row}>
                                    <div
                                        className={s.displayItem}
                                    >
                                        <div className={`${s.label}`}>Meeting type</div>
                                        { renderNoMeetingtype }
                                        {
                                            telehealthCheckStatus &&
                                            <div className={s.box}>
                                                <div className={`${telehealthCheckStatus ? s.correctIcon : s.wrongIcon}`} />
                                                <div className={s.info}>
                                                    <div className={s.title}>Telehealth</div>
                                                    <div className={s.row}>
                                                        <div
                                                            className={s.displayItem}
                                                        >
                                                            <div className={`${s.label} ${s.required}`}>Patient type</div>
                                                            <div className={s.value}>{telehealthType?.join(',')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            inpersonCheckStatus &&
                                            <div className={s.box}>
                                                <div className={`${inpersonCheckStatus ? s.correctIcon : s.wrongIcon}`} />
                                                <div className={s.info}>
                                                    <div className={s.title}>In-person</div>
                                                    <div className={s.row}>
                                                        <div
                                                            className={s.displayItem}
                                                            style={{ width: '30%', flex: 'none' }}
                                                        >
                                                            <div className={`${s.label} ${s.required}`}>Office address</div>
                                                            <div className={s.value}>{officeAddress}</div>
                                                        </div>
                                                        <div className={s.displayItem}>
                                                            <div className={`${s.label} ${s.required}`}>Patient type</div>
                                                            <div className={s.value}>{personType?.join(',')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={`${s.row} ${s.mb12}`}>
                                <div
                                    style={{ flex: ' 0 0 350px' }}
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        label="Service Type"
                                        name={ServicesFormFieldEnum.SERVICE_TYPE}
                                        rules={[{ required: true, message: 'Service type is required' }]}
                                    >
                                        <Select
                                            style={{ width: '300px' }}
                                            disabled={false}
                                            loading={serviceTemplateDataLoading}
                                            onChange={(val) => {
                                                const target = serviceTemplateData.find((item:Service) => item.type === val);
                                                setCurrentServiceTemplateData(target);
                                            }}
                                        >
                                            {
                                                serviceTemplateData.map((service:Service) => {
                                                    return (
                                                        <Select.Option value={service.type} key={service.type}>
                                                            {service.type}
                                                        </Select.Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={`${s.row} ${s.mb12}`}>
                                <div
                                    style={{ flex: ' 0 0 350px' }}
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        label="Service name"
                                        name={ServicesFormFieldEnum.NAME}
                                        rules={[{ required: true, message: 'Service name is required' }]}
                                    >
                                        <Input style={{ width: '90%' }} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={`${s.row} ${s.mb12}`}>
                                <div
                                    // style={{ flex: '0 0 170px', height: '85px' }}
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        label="Description"
                                        name={ServicesFormFieldEnum.DESCRIPTION}
                                    >
                                        <Input.TextArea style={{ width: '90%' }} maxLength={500} rows={4} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={`${s.row} ${s.mb12}`}>
                                <div
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        label="Duration (minutes)"
                                        name={ServicesFormFieldEnum.DURATION}
                                        validateFirst
                                        rules={[
                                            { required: true, message: 'duration is a required' },
                                        ]}
                                    >
                                        <DurationSelect style={{ width: '150px' }} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >

                                    <Form.Item
                                        label="Price"
                                        name={ServicesFormFieldEnum.PRICE}
                                        rules={[{ required: true, message: 'Service price is required' }]}
                                    >
                                        <InputNumber precision={2} addonBefore="$" addonAfter="USD" style={{ width: '220px' }} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row} style={{ marginTop: '12px', marginBottom: '12px' }}>
                                <div
                                    className={s.displayItem}
                                >
                                    <div className={s.switchItem}>
                                        <Switch defaultChecked={false} checked={enableSlidingScaleCheckStatus} onChange={handleEnableSlidingScaleChange} />
                                        <span className={s.switchLabel}>Enable sliding scale</span>
                                        <Tooltip
                                            overlayClassName={s.tipCard}
                                            overlayInnerStyle={{ padding: '12px' }}
                                            color="#FFFFFF"
                                            title={
                                                <div className={s.tipText}>Sliding fee scales adjust costs by income, improving healthcare access. Proof of income and household size is required.</div>
                                            }
                                        >
                                            <div className={s.questionMarkIcon} />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            {
                                enableSlidingScaleCheckStatus && (
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                            style={{ width: '220px', flex: 'none', marginRight: 30 }}
                                        >

                                            <Form.Item
                                                label="Lowest price"
                                                name={ServicesFormFieldEnum.LOWEST_PRICE}
                                                rules={[{ required: true, message: 'Lowest price is required' }]}
                                            >
                                                <InputNumber precision={2} addonBefore="$" addonAfter="USD" style={{ width: '100%' }} />
                                            </Form.Item>
                                        </div>
                                        <div
                                            className={s.displayItem}
                                            style={{ width: '220px', flex: 'none' }}
                                        >

                                            <Form.Item
                                                label="Highest price"
                                                name={ServicesFormFieldEnum.HIGHEST_PRICE}
                                                rules={[{ required: true, message: 'Highest price is required' }]}
                                            >
                                                <InputNumber precision={2} addonBefore="$" addonAfter="USD" style={{ width: '100%' }} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                )
                            }
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={`${s.label}`}>Meeting type</div>
                                    {
                                        showInitialVisitError && <p className={s.errorText}>Initial Visit must have either Telehealth or In-person visit</p>
                                    }
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.checkbox}>
                                        <Checkbox checked={telehealthCheckStatus} onChange={handleTelehealthCheckChange}>Telehealth</Checkbox>
                                        <div className={s.des}>I can see patient via telehealth</div>
                                    </div>
                                    {
                                        telehealthCheckStatus && (
                                            <div className={s.checkboxDetail}>
                                                <div className={s.row}>
                                                    <div
                                                        className={s.displayItem}
                                                    >
                                                        <Form.Item
                                                            label="Select the type of patients you’re seeing for the telehealth visit"
                                                            name={ServicesFormFieldEnum.TELEHEALTH_TYPE}
                                                            validateFirst
                                                            rules={[
                                                                { required: true, message: 'Please select at least one patient type' },
                                                            ]}
                                                        >
                                                            {/* { patientTypeTelehealthError && <div className={s.errorText}>Please select at least one patient type</div>} */}
                                                            <Checkbox.Group
                                                                options={PATIENT_TYPES}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={`${s.row}`}>
                                <div
                                    className={s.displayItem}
                                >
                                    <div className={s.checkbox}>
                                        <Checkbox checked={inpersonCheckStatus} onChange={handleInpersonCheckChange}>In-person</Checkbox>
                                        <div className={s.des}>I can see patient in my local office</div>
                                    </div>
                                    {
                                        inpersonCheckStatus && (
                                            <div className={s.checkboxDetail}>
                                                <div className={s.title}>Enter your office address for the in-person visits.</div>
                                                <div className={s.row}>
                                                    <div
                                                        className={s.displayItem}
                                                    >
                                                        <Form.Item
                                                            label="Office address"
                                                            name={ServicesFormFieldEnum.ADDRESS_ID}
                                                            validateFirst
                                                            rules={[
                                                                { required: true, message: 'Office address is a required field' },
                                                            ]}
                                                        >
                                                            <Select options={addressList} />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className={s.row}>
                                                    <div
                                                        className={s.displayItem}
                                                    >
                                                        <Form.Item
                                                            label="Select the type of patients you’re seeing for the in-person visit"
                                                            name={ServicesFormFieldEnum.PERSON_TYPE}
                                                            validateFirst
                                                            rules={[
                                                                { required: true, message: 'Please select at least one patient type' },
                                                            ]}
                                                        >
                                                            {/* { patientTypeInPersonError && <div className={s.errorText}>Please select at least one patient type</div>} */}
                                                            <Checkbox.Group
                                                                options={PATIENT_TYPES}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={`${s.row}`}>
                                <div
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        label="Color code in your practice calendar"
                                        name={ServicesFormFieldEnum.COLOR_ID}
                                    >
                                        <ColorSelect useAdminApi={isOps} style={{ width: '150px' }} />
                                    </Form.Item>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </Form>
    );
});

export default CommonForm;
