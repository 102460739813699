// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--XsZ8K {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  padding: 36px;
}
.s-module__wrap--XsZ8K .s-module__content--IuBvF {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.s-module__wrap--XsZ8K .s-module__content--IuBvF img {
  margin-bottom: 36px;
  max-width: 80%;
  width: 300px;
}
.s-module__wrap--XsZ8K .s-module__content--IuBvF .s-module__title--dC5CK {
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 36px;
  line-height: 48px;
  color: var(--gray-900);
  text-align: center;
}
.s-module__wrap--XsZ8K .s-module__content--IuBvF .s-module__desc--YXAtv {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: var(--gray-500);
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/AccessDeniedTabView/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,aAAA;AACJ;AARA;EAUQ,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACR;AAdA;EAgBY,mBAAA;EACA,cAAA;EACA,YAAA;AACZ;AAnBA;EAsBY,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;AAAZ;AA3BA;EA8BY,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,sBAAA;EACA,kBAAA;AAAZ","sourcesContent":[".wrap {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    font-family: 'Inter';\n    font-style: normal;\n    padding: 36px;\n\n    .content {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n\n        img {\n            margin-bottom: 36px;\n            max-width: 80%;\n            width: 300px;\n        }\n    \n        .title {\n            margin-bottom: 20px;\n            font-weight: 800;\n            font-size: 36px;\n            line-height: 48px;\n            color: var(--gray-900);\n            text-align: center;\n        }\n        .desc {\n            font-weight: 400;\n            font-size: 20px;\n            line-height: 30px;\n            letter-spacing: 0.01em;\n            color: var(--gray-500);\n            text-align: center;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--XsZ8K`,
	"content": `s-module__content--IuBvF`,
	"title": `s-module__title--dC5CK`,
	"desc": `s-module__desc--YXAtv`
};
export default ___CSS_LOADER_EXPORT___;
