import React, { useCallback } from 'react';
import s from './s.module.less';
import { message, Modal } from 'antd';
import GlobalLayerStore from 'store/GlobalLayer';
import { closeBlackFriday2024LeaveCampaignModal } from 'utils/globalLayerControl';
import CampaignStore from 'store/Campaign';
import { leaveCampaign } from 'api/campaign';

const LeavCampaignModal = () => {
    const [getGlobalLayer] = GlobalLayerStore.useStore();
    const [getSelectedCampaign, setCampaignStore] = CampaignStore.useStore();
    const [loading, setLoading] = React.useState<boolean>(false);
    const selectedCampaign = getSelectedCampaign('selectedCampaign');

    const handleLeaveCampaign = useCallback(async () => {
        if (selectedCampaign) {
            setLoading(true);

            try {
                const res = await leaveCampaign(selectedCampaign.campaignId);

                if (res && !res.error) {
                    closeBlackFriday2024LeaveCampaignModal();
                    setCampaignStore('forceRefetchCampaignList', {});
                    setCampaignStore('selectedCampaign', null);
                } else {
                    message.error(res?.message || 'Failed to leave the campaign');
                }
            } catch (e) {
                console.error(e);
            }

            setLoading(false);
        }
    }, [selectedCampaign]);

    return (
        <Modal
            open={getGlobalLayer('showBlackFriday2024LeaveCampaignModal')}
            title="Leave the campaign?"
            destroyOnClose
            okButtonProps={{
                danger: true,
            }}
            okText="Leave"
            onCancel={() => closeBlackFriday2024LeaveCampaignModal()}
            confirmLoading={loading}
            onOk={handleLeaveCampaign}
        >
            <p className={s.leaveConfirmContent}>Are you sure you want to leave the Black Friday campaign? By opting out, your discounts will no longer be available to patients, and you may miss out on increased bookings during this limited-time event.</p>
        </Modal>
    );
};

export default LeavCampaignModal;
