import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { AdditionalPracticeFormFieldEnum, PracticeFormInterface } from 'pages/CreateUniProfilePage/types';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { Button, Form, Select } from 'antd';
import useGetSpecialtiesAndConditions from 'hooks/useGetSpecialtiesAndConditions';
import { ISpecialtiesOption, ISpecialtiesOptions } from 'types/applicationForm';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { EMPTY_PLACEHOLDER, SNAPSHOT_COLOR } from 'constants/common';
import { renderChangUI } from 'types/changeHighLight';

interface IProps {
    isOps?: boolean;
    formName: string;
    showEditButtons?: boolean;
    initFormValue: Partial<PracticeFormInterface>,
    enable: boolean;
    onEditStatusChange: (v: boolean, formName: string) => void;
    onUpdateService: (formName: string, formValue: {
        providerPractice: Partial<PracticeFormInterface>
    }) => void;
}

const Specialties = forwardRef(({
    isOps = false,
    initFormValue,
    formName,
    showEditButtons = true,
    enable,
    onUpdateService,
    onEditStatusChange,
}: IProps, ref) => {
    const [specialtiesOptions] = useGetSpecialtiesAndConditions({ isOps });
    const [conditionOptions, setConditionOptions] = useState<ISpecialtiesOptions>([]);
    const [specialtyForm] = Form.useForm<PracticeFormInterface>();

    const {
        specialtyList = [],
        conditionTreatedList = [],
    } = initFormValue || {};

    useEffect(() => {
        if (initFormValue) {
            specialtyForm.setFieldsValue(initFormValue);
        }
    }, [conditionTreatedList, initFormValue, specialtyForm, specialtyList]);

    const getFormValues = useCallback(async () => {
        let validRes = {};
        try {
            validRes = await specialtyForm.validateFields();
        } catch (e) {
            validRes = e;
            console.error(e);
        }

        return validRes;
    }, [specialtyForm]);

    useImperativeHandle(ref, () => ({
        getFromValues: getFormValues,
    }));

    const handleSetConditionOptions = useCallback((specialties: string[] = []) => {
        const conditionOpts = specialtiesOptions
            .filter((option) => {
                const { value } = option;
                return !!specialties?.includes(value);
            })
            .reduce((r:ISpecialtiesOptions, c: ISpecialtiesOption) => {
                const { children = [] } = c;

                return r.concat(children);
            }, []);

        setConditionOptions(uniqWith(conditionOpts, isEqual));
    }, [specialtiesOptions]);

    useEffect(() => {
        handleSetConditionOptions(initFormValue.specialtyList);
    }, [handleSetConditionOptions, initFormValue]);

    // 每次conditionOptions改变的时候，需要判断下目前表单中选中的condition 是否存在于conditionOptions
    // 如果不存在，那么删除掉
    // useEffect(() => {

    // }, []);

    const handleFormFieldChange = useCallback((changedFields) => {
        const fieldName = changedFields[0].name[0];

        if (fieldName === AdditionalPracticeFormFieldEnum.SPECIALTY_LIST) {
            const specialties = specialtyForm.getFieldValue(AdditionalPracticeFormFieldEnum.SPECIALTY_LIST);

            handleSetConditionOptions(specialties);
        }
    }, [handleSetConditionOptions, specialtyForm]);

    const handleFormFinish = useCallback(async () => {
        specialtyForm.validateFields().then((values: Partial<PracticeFormInterface>) => {
            if (typeof onUpdateService === 'function') {
                onUpdateService(formName, {
                    providerPractice: {
                        ...initFormValue,
                        ...values,
                    },
                });
            }
        }).catch((e) => {
            console.error(e);
        });
        // onUpdateService(formName, formValue);
        // setSubmitData(formValue);
    }, [formName, initFormValue, onUpdateService, specialtyForm]);

    const renderEditButtons = useMemo(() => {
        if (showEditButtons) {
            return !enable ? (
                <div key={`${formName}_disable`} className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            onEditStatusChange(true, formName);
                        }}
                    >
                        Edit
                    </Button>
                </div>
            ) : (
                <div key={`${formName}_enable`} className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            onEditStatusChange(false, formName);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        // size="small"
                        type="primary"
                        onClick={handleFormFinish}
                    >
                        Save changes
                    </Button>
                </div>
            );
        }
        return null;
    }, [enable, formName, onEditStatusChange, showEditButtons, handleFormFinish]);

    return (
        <div className={s.wrap}>
            <h4>
                Specialties & Conditions
                {renderEditButtons}
            </h4>
            <div className={s.content}>
                {
                    !enable ?
                        <>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <div className={`${s.label} ${s.required}`}>Specialties</div>
                                    <div className={s.value}>
                                        {
                                            specialtyList && specialtyList.length > 0 ?
                                                <ul>
                                                    {
                                                        specialtyList?.map((item, i) => <li key={i}>{item}</li>)
                                                    }
                                                </ul> : EMPTY_PLACEHOLDER
                                        }
                                    </div>
                                    {renderChangUI('specialtyList', initFormValue, !!isOps,
                                        (val:string[]) => { return (
                                            <ul>
                                                {
                                                    val?.map((item, i) => <li key={i}>{item}</li>)
                                                }
                                            </ul>
                                        );
                                        },
                                    )}
                                </div>
                            </div>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <div className={`${s.label} ${s.required}`}>Conditions treated</div>
                                    <div className={s.value}>
                                        {
                                            conditionTreatedList && conditionTreatedList.length > 0 ? conditionTreatedList?.map((item, i) => <div className={s.conditionTag} key={i}>{item}</div>) : EMPTY_PLACEHOLDER
                                        }
                                    </div>
                                    {renderChangUI('conditionTreatedList', initFormValue, !!isOps,
                                        (val:string[]) => { return (
                                            <div className={s.value}>
                                                {
                                                    val?.map((item, i) => <div className={s.conditionTag} key={i} style={{ color: SNAPSHOT_COLOR }}>{item}</div>)
                                                }
                                            </div>
                                        );
                                        },
                                    )}
                                </div>
                            </div>
                        </> :
                        <Form
                            form={specialtyForm}
                            name={formName}
                            className={commonS.formStyle1}
                            autoComplete="off"
                            layout="vertical"
                            onFieldsChange={handleFormFieldChange}
                            scrollToFirstError
                        >
                            <div className={s.row}>
                                <div
                                    id={`${AdditionalPracticeFormFieldEnum.SPECIALTY_LIST}`}
                                    style={{
                                        position: 'relative',
                                        top: '-160px',
                                    }}
                                />
                                <div
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        label="Specialties"
                                        name={AdditionalPracticeFormFieldEnum.SPECIALTY_LIST}
                                        validateFirst
                                        rules={[
                                            { required: true, message: 'Specialties is a required field' },
                                        ]}
                                    >
                                        <Select
                                            mode="tags"
                                            options={specialtiesOptions}
                                            allowClear
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div
                                    id={`${AdditionalPracticeFormFieldEnum.CONDITION_TREATED_LIST}`}
                                    style={{
                                        position: 'relative',
                                        top: '-160px',
                                    }}
                                />
                                <div
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        label="Condition treated"
                                        name={AdditionalPracticeFormFieldEnum.CONDITION_TREATED_LIST}
                                        validateFirst
                                        rules={[
                                            { required: true, message: 'Condition treated is a required field' },
                                        ]}
                                    >
                                        <Select
                                            options={conditionOptions}
                                            mode="tags"
                                            allowClear
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                }
            </div>
        </div>
    );
});

export default Specialties;
