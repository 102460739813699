import { Request } from '../request';

export const getCCCList = () => Request.get({ url: '/schedule-server/provider/ccc/list' });

export const offerCCC = (data: string[]) => Request.post({ url: '/schedule-server/provider/ccc/offer', params: data });

export const pauseCCC = (data: string[]) => Request.post({ url: '/schedule-server/provider/ccc/pause', params: data });

export const resumeCCC = (data: string[]) => Request.post({ url: '/schedule-server/provider/ccc/resume', params: data });

export const cccDashboard = (date = 'LAST_7_DAYS') => Request.get({ url: `schedule-server/provider/home/dashboard/${date}` });
