import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { Button, Form, Input } from 'antd';
import { AdditionFieldEnum, ProviderChannelAdditional } from 'types/channelApplicationProvider';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { EMPTY_PLACEHOLDER } from 'constants/common';

interface IProps {
    formName: string;
    showEditButtons?: boolean;
    initFormValue: Partial<ProviderChannelAdditional>,
    enable: boolean;
    onEditStatusChange: (v: boolean, formName: string) => void;
    onUpdateService: (formName: string, formValue: ProviderChannelAdditional) => void;
}

const Awards = forwardRef(({
    initFormValue,
    formName,
    showEditButtons = true,
    enable,
    onUpdateService,
    onEditStatusChange,
}: IProps, ref) => {
    const [awardsForm] = Form.useForm<ProviderChannelAdditional>();

    useEffect(() => {
        awardsForm.setFieldsValue(initFormValue);
    }, [initFormValue, awardsForm]);

    const getFormValues = useCallback(async () => {
        let validRes = {};
        try {
            const resp = await awardsForm.validateFields();

            validRes.awardAdditionalList = resp.awardAdditionalList ? resp.awardAdditionalList?.filter((item) => item.name?.replace(/\s*/g, '')) : [];
        } catch (e) {
            validRes = e;
            console.error(e);
        }

        return validRes;
    }, [awardsForm]);

    useImperativeHandle(ref, () => ({
        getFromValues: getFormValues,
    }));
    const handleFormFinish = useCallback(async () => {
        awardsForm.validateFields().then((values: ProviderChannelAdditional) => {
            if (typeof onUpdateService === 'function') {
                onUpdateService(formName, {
                    ...initFormValue,
                    awardAdditionalList: values.awardAdditionalList ? values.awardAdditionalList?.filter((item) => item.name?.replace(/\s*/g, '')) : [],
                });
            }
        }).catch((e) => {
            console.error(e);
        });
        // onUpdateService(formName, formValue);
        // setSubmitData(formValue);
    }, [formName, initFormValue, onUpdateService, awardsForm]);

    const reviewData = useMemo(() => (initFormValue?.awardAdditionalList ? initFormValue.awardAdditionalList?.filter((item) => item.name?.replace(/\s*/g, '')) : []), [initFormValue]);

    const renderEditButtons = useMemo(() => {
        if (showEditButtons) {
            return !enable ? (
                <div key={`${formName}_disable`} className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            onEditStatusChange(true, formName);
                        }}
                    >
                        Edit
                    </Button>
                </div>
            ) : (
                <div key={`${formName}_enable`} className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            onEditStatusChange(false, formName);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        // size="small"
                        type="primary"
                        onClick={handleFormFinish}
                    >
                        Save changes
                    </Button>
                </div>
            );
        }
        return null;
    }, [enable, formName, onEditStatusChange, showEditButtons, handleFormFinish]);

    const renderAdditionalReview = useMemo(() => {
        return (
            <div className={s.row}>
                <div
                    className={s.displayItem}
                >
                    <div className={s.value}>
                        {
                            reviewData.length > 0 ?
                                <ul>
                                    {
                                        reviewData.map((item, index) => <li key={index}>{item.name}</li>)
                                    }
                                </ul> :
                                EMPTY_PLACEHOLDER
                        }
                    </div>
                </div>
            </div>
        );
    }, [reviewData]);

    const renderAwardsAchievementsList = useMemo(() => {
        return (
            <Form
                form={awardsForm}
                name={formName}
                className={commonS.formStyle1}
                initialValues={initFormValue}
                autoComplete="off"
                layout="vertical"
                scrollToFirstError
            >
                <Form.List name="awardAdditionalList">
                    {
                        (fields, { add, remove }) => {
                            return (
                                <>
                                    {
                                        fields.map(({ key, name, ...restField }, index) => {
                                            const label = `Award/achievement ${index + 1}`;

                                            return (
                                                <div key={index} className={s.additionalBox}>
                                                    <div className={s.additionalForm}>
                                                        <div className={s.formBody}>
                                                            <div className={s.row}>
                                                                <div
                                                                    className={s.displayItem}
                                                                >
                                                                    <Form.Item
                                                                        {...restField}
                                                                        label={label}
                                                                        name={[name, AdditionFieldEnum.NAME]}
                                                                    >
                                                                        <Input />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={s.formFooter}>
                                                            <Button onClick={() => remove(index)}>Remove</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    <Form.Item>
                                        <div className={s.additionalTitle} onClick={() => add()}>+ Add another award or achievement</div>
                                    </Form.Item>
                                </>
                            );
                        }
                    }
                </Form.List>
            </Form>
        );
    }, [formName, initFormValue, awardsForm]);

    return (
        <div className={s.wrap}>
            <div className={`${s.module} ${s.moduleTitle}}`}>
                <h3>
                    <span>Awards or achievements</span>
                    <div className={s.buttonsWrap}>
                        {renderEditButtons}
                        {/* { enableReview && <Button className={s.showReviewModalButton} type="default" size="large" onClick={() => onShowReviewModal?.()}>Add reviews</Button>} */}
                    </div>
                </h3>
                {
                    !enable ? renderAdditionalReview : renderAwardsAchievementsList
                }
            </div>
        </div>
    );
});

export default Awards;
