import React from 'react';
import Logo from 'assets/common/Logo.svg';
import s from './s.module.less';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

type Props = {
    children: React.ReactNode;
    onNext?: () => void;
    onClose?: () => void;
};

const FullLayout = (props:Props) => {
    const { children, onNext, onClose } = props;
    return (
        <div className={s.wrap}>
            <div className={s.top}>
                <img src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_kiwihealth_by_klarity_380x96.png" />
                <CloseOutlined className={s.close} />
            </div>
            <div className={s.middle}>
                {children}
            </div>
            <div className={s.bottom}>
                <Button>Exit</Button>
                <Button type="primary">Next</Button>
            </div>
        </div>
    );
};

export default FullLayout;
