import React from 'react';
import BotLogo from 'assets/aiChat/botLogo.svg';
import AiChatStore from 'store/AiChatProvider';
import { CloseOutlined } from '@ant-design/icons';

import s from './s.module.less';

function ChatBoxHeader(): React.ReactElement {
    const [getAiChatData, setAiChatData] = AiChatStore.useStore();

    const closeChat = () => {
        setAiChatData('isShowChatBox', false);
    };

    return (
        <div className={s.wrapper}>
            <img width={42} height={42} src={BotLogo} alt="bot_logo" />
            <div className={s.userInfo}>
                <div className={s.name}>Ava</div>
                <div className={s.status}>online</div>
            </div>
            <CloseOutlined onClick={closeChat} style={{ color: '#0B30E0' }} />
        </div>
    );
}

export default ChatBoxHeader;
