import { Request } from '../request';
import { IGetProviderFormDetailInput, IProviderReviewSubmitInput, SelectProviderChannelPhotoInput, IServiceAddressUpdateInput } from 'types/applicationForm';

export const getProviderFormDetail = (data: IGetProviderFormDetailInput) => Request.post({ url: '/schedule-server/provider/profile/detail', params: data, disableErrorNote: true });

export const updateStepForm = <T>(data: T) => Request.put({ url: '/schedule-server/provider/profile/update', params: data, disableErrorNote: true });

export const submitPreview = (data:IProviderReviewSubmitInput) => Request.post({ url: '/schedule-server/provider/profile/submit', params: data, disableErrorNote: true });

export const selectProviderChannelPhoto = (data: SelectProviderChannelPhotoInput) => Request.put({ url: '/schedule-server/provider/profile/select/photo', params: data });

export const getSpecialties = () => Request.get({ url: '/schedule-server/provider/profile/practice/specialties' });

export const getSpecialtiesAndConditionList = () => Request.get({ url: '/schedule-server/provider/profile/practice/specialties' });

export const getRemovementPopupProviderPracticeAddressList = () => Request.get({ url: '/schedule-server/provider/profile/practice/address/list' });

export const getAllServiceByPracticeAddressId = (providerId: number, addressId: number) => Request.get({ url: `/schedule-server/providerService/list/${providerId}/${addressId}` });

export const updateProviderServiceAddress = (data: Array<IServiceAddressUpdateInput>) => Request.put({ url: '/schedule-server/providerService/update/batch', params: data });
