import React from 'react';
import s from './s.module.less';
import { Button, message, Modal } from 'antd';
import { Channel } from 'types/common';
import ChannelCard from './components/ChannelCard';
import commonS from 'styles/common.module.less';
import { submitChannels } from 'api/operation';
import { ISubmitRecommandChannelInput } from 'types/channel';
import { submitRecommandChannels } from 'api/channel';
import { getProvider, getUserId } from 'utils/localstore';
import { checkChannelIsLocked } from 'utils/common';

interface IProps {
    myPlanLevel?: number;
    channelList?: Channel[];
    channelListWithCategory?: Record<string, Channel[]>;
    providerId?: number;
    title?: string;
    defaultSelected?: boolean;
    maxSelectedAmount: number | null; //null means unlimited
    show: boolean;
    isOps?: boolean;
    onClose: () => void;
    showTopNav?: boolean;
    onSubmit: () => void;
    isKlarityUser?: boolean;
}

const ChannelSelectModal = ({
    myPlanLevel = -1,
    isOps,
    title,
    show,
    defaultSelected = true,
    maxSelectedAmount,
    providerId,
    showTopNav,
    channelListWithCategory,
    channelList,
    onClose,
    onSubmit,
    isKlarityUser,
}: IProps) => {
    const [selectedChannel, setSelectedChannel] = React.useState<number[]>([]);
    const bpProviderId = getUserId();
    const [submiting, setSubmiting] = React.useState(false);
    const max = (maxSelectedAmount === null || isKlarityUser) ? 999 : maxSelectedAmount;
    const showTips = selectedChannel.length === 0;
    const showDeselectAll = !!(channelList && selectedChannel && selectedChannel.length === channelList?.length) || selectedChannel.length === maxSelectedAmount;
    const hasCategory = !!channelListWithCategory;

    React.useEffect(() => {
        if (show) {
            if (defaultSelected) {
                if (!hasCategory) {
                    if (isKlarityUser) {
                        setSelectedChannel((channelList?.filter((channel) => !checkChannelIsLocked(channel, myPlanLevel, isOps)).map((channel) => channel.id) || []).slice(0, maxSelectedAmount || 999));
                    } else {
                        setSelectedChannel((channelList?.map((channel) => channel.id) || []).slice(0, maxSelectedAmount || 999));
                    }
                } else {
                    const ids: number[] = [];
                    Object.keys(channelListWithCategory).forEach((category) => {
                        const items = channelListWithCategory[category];
                        items.forEach((channel) => {
                            if (isKlarityUser && !checkChannelIsLocked(channel, myPlanLevel, isOps)) {
                                ids.push(channel.id);
                            } else if (!isKlarityUser) {
                                ids.push(channel.id);
                            }
                        });
                    });
                    setSelectedChannel(ids);
                }
            }
        } else {
            setSelectedChannel([]);
        }
    }, [defaultSelected, show, maxSelectedAmount, channelList, hasCategory, channelListWithCategory, isKlarityUser, myPlanLevel, isOps]);

    const handleSubmit = async () => {
        const submitData: ISubmitRecommandChannelInput = {
            channelIdList: selectedChannel,
            allowAuth: true,
        };

        //providerId: `${bpProviderId}`,
        if (selectedChannel.length === 0) {
            return;
        }
        setSubmiting(true);
        const fn = isOps ? submitChannels : submitRecommandChannels;
        if (isOps) {
            submitData.providerId = `${providerId}`;
        }
        const result = await fn(submitData);
        setSubmiting(false);
        if (!result.error) {
            message.success('Channels listing request for the provider successfully submitted.');
            onSubmit?.();
        }
    };

    return (
        <Modal
            title={title || 'List on channels'}
            className={commonS.modalFixHeightWrap}
            onCancel={() => {
                onClose?.();
            }}
            okText={isOps ? 'List' : 'Send'}
            width={1150}
            open={show}
            destroyOnClose
            onOk={handleSubmit}
            okButtonProps={{ disabled: selectedChannel.length === 0, loading: submiting }}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <div className={s.wrap}>
                {
                    showTopNav && (
                        <div className={s.topNav}>
                            {
                                maxSelectedAmount === null && <div>Unlimited standard channels remaining</div>
                            }
                            {
                                maxSelectedAmount !== null && isOps && <div>{maxSelectedAmount - selectedChannel.length} standard channels remaining</div>
                            }
                            {
                                maxSelectedAmount !== null && !isOps && <div>You have {maxSelectedAmount - selectedChannel.length} standard channel spot left in your plan.</div>
                            }
                            {
                                showDeselectAll && (<Button onClick={() => setSelectedChannel([])}>Deselect All</Button>)
                            }
                        </div>
                    )
                }
                <div className={showTips ? `${s.tips} ${s.tipsShow}` : s.tips}>
                    <div className={s.tipsIn}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L6.58579 8L5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071C5.68342 11.0976 6.31658 11.0976 6.70711 10.7071L8 9.41421L9.29289 10.7071C9.68342 11.0976 10.3166 11.0976 10.7071 10.7071C11.0976 10.3166 11.0976 9.68342 10.7071 9.29289L9.41421 8L10.7071 6.70711C11.0976 6.31658 11.0976 5.68342 10.7071 5.29289C10.3166 4.90237 9.68342 4.90237 9.29289 5.29289L8 6.58579L6.70711 5.29289Z" fill="#F87171" />
                            </svg>
                        </span>
                        <span>Please select at least one channel to proceed with listing.</span>
                    </div>
                </div>
                {
                    hasCategory && (
                        <div className={s.listWithCategory}>
                            {
                                Object.keys(channelListWithCategory!).map((category) => {
                                    const items = channelListWithCategory![category];
                                    const hasItem = items && items.length > 0;
                                    return (
                                        <div className={s.listItem} key={category}>
                                            {
                                                hasItem &&
                                                <>
                                                    <div className={s.label}>{category}</div>
                                                    <div className={s.list}>
                                                        {items?.map((channel) => {
                                                            const checked = selectedChannel.includes(channel.id);
                                                            let disable = true;
                                                            let isLocked = false;
                                                            if (isKlarityUser) {
                                                                disable = false;
                                                                isLocked = checkChannelIsLocked(channel, myPlanLevel, isOps);
                                                            } else {
                                                                disable = selectedChannel.length >= max && !checked;
                                                            }

                                                            return (
                                                                <div className={s.item}>
                                                                    <ChannelCard
                                                                        locked={isLocked}
                                                                        key={channel.id}
                                                                        item={channel}
                                                                        checked={checked}
                                                                        disable={disable}
                                                                        limitText={!isOps ? `You have reached limitation for stand channel listing. You can only choose ${max} channels` : undefined}
                                                                        onSelectChange={(value) => {
                                                                            if (value) {
                                                                                setSelectedChannel([...selectedChannel, channel.id]);
                                                                            } else {
                                                                                setSelectedChannel(selectedChannel.filter((id) => id !== channel.id));
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                }
                {
                    !hasCategory && (
                        <div className={s.list}>
                            {channelList?.map((channel) => {
                                const checked = selectedChannel.includes(channel.id);
                                let disable = true;
                                let isLocked = false;
                                if (isKlarityUser) {
                                    disable = false;
                                    isLocked = checkChannelIsLocked(channel, myPlanLevel, isOps) || channel.klarityPlanLevel === 0;
                                } else {
                                    disable = selectedChannel.length >= max && !checked;
                                }

                                return (
                                    <div className={s.item}>
                                        <ChannelCard
                                            locked={isLocked}
                                            key={channel.id}
                                            item={channel}
                                            checked={checked}
                                            disable={disable}
                                            limitText={!isOps ? `You have reached limitation for stand channel listing. You can only choose ${max} channels` : undefined}
                                            onSelectChange={(value) => {
                                                if (value) {
                                                    setSelectedChannel([...selectedChannel, channel.id]);
                                                } else {
                                                    setSelectedChannel(selectedChannel.filter((id) => id !== channel.id));
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>)
                }
            </div>
        </Modal>
    );
};

export default ChannelSelectModal;
