import { Request } from '../request';
import { ServerKlarityService } from 'types/common';

/* provider */
// export const getSpecialityTemplateRecommend = () =>
//     Request.post({ url: '/schedule-server/provider/create', params: data, disableErrorNote: true });

export const getSpecialityTemplateRecommend = () =>
    Request.get({ url: '/schedule-server/klarityServiceSpecialty/specialityTemplateRecommend' });

export const getSpecialityPrice = (specialty:string, state:string[]) =>
    Request.get(
        {
            url: '/schedule-server/provider/service/price/config',
            params: {
                specialty,
                state,
            },
        },
    );

export const checkProviderKlarityService = () =>
    Request.get(
        {
            url: '/schedule-server/provider/service/check',
        },
    );

export const saveProviderKlarityService = (providerKlarityService:ServerKlarityService[], disableIds:number[]) =>
    Request.post(
        {
            url: '/schedule-server/provider/klarity/service/save',
            params: {
                providerKlarityService,
                disableIds,
            },
        },
    );

export const checkServiceTypeName = (data:{ serviceSpecialtyId: number, customizedServiceName:string, serviceTypeId: number }) =>
    Request.post(
        {
            url: '/schedule-server/provider/klarity/service/customized/name/check',
            params: {
                ...data,
            },
        },
    );

export const getProviderKlarityServiceList = () =>
    Request.get(
        {
            url: '/schedule-server/provider/klarity/service/list',
        },
    );

export const getProviderPatientGroupAndLimitation = (id:number) =>
    Request.get(
        {
            url: `/schedule-server/provider/klarity/service/patientGroupAndLimitation/${id}`,
        },
    );

export const getSpecialityTemplateDetail = (id:number) =>
    Request.get(
        {
            url: '/schedule-server/klarityServiceSpecialty/specialityTemplateDetail',
            params: {
                id,
            },
        },
    );

export const getProviderSpecialtyDetail = (serviceSpecialtyId:number) =>
    Request.get(
        {
            url: '/schedule-server/provider/klarity/service/detail',
            params: {
                serviceSpecialtyId,
            },
        },
    );
/* end provider */
