// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/advertising/advertisingIcon.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/common/closeIcon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-module__loginDes--WUqSo {
  margin-bottom: 24px;
  font-size: 15px;
  color: var(--gray-900);
}
.app-module__loginBtn--nV1ii {
  display: inline-block;
  padding: 6px 12px;
  font-size: 15px;
  background-color: var(--primary-color);
  color: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 4px;
  cursor: pointer;
}
.app-module__loadingWrap--AcDHf {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 999;
}
.app-module__advertisingIcon--nFLJx {
  position: fixed;
  right: 26px;
  bottom: 65px;
  width: 84px;
  height: 84px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 99999;
}
.app-module__advertisingIcon--nFLJx .app-module__closeIcon--iSk2C {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat center center;
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/app.module.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,eAAA;EACA,sBAAA;AACJ;AACA;EACI,qBAAA;EACA,iBAAA;EACA,eAAA;EACA,sCAAA;EACA,mBAAA;EACA,iCAAA;EACA,kBAAA;EACA,eAAA;AACJ;AAEA;EACI,eAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,YAAA;AAAJ;AAGA;EACI,eAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,qBAAA;EACA,4BAAA;EACA,eAAA;EACA,cAAA;AADJ;AATA;EAaQ,WAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,2EAAA;EACA,qBAAA;EACA,4BAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,eAAA;AADR","sourcesContent":[".loginDes {\n    margin-bottom: 24px;\n    font-size: 15px;\n    color: var(--gray-900);\n}\n.loginBtn {\n    display: inline-block;\n    padding: 6px 12px;\n    font-size: 15px;\n    background-color: var(--primary-color);\n    color: var(--white);\n    border: 1px solid var(--gray-200);\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.loadingWrap{\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #fff;\n    z-index: 999;\n}\n\n.advertisingIcon {\n    position: fixed;\n    right: 26px;\n    bottom: 65px;\n    width: 84px;\n    height: 84px;\n    background-image: url('assets/advertising/advertisingIcon.png');\n    background-size: 100%;\n    background-repeat: no-repeat;\n    cursor: pointer;\n    z-index: 99999;\n\n    .closeIcon {\n        content: '';\n        display: block;\n        width: 14px;\n        height: 14px;\n        background: url('assets/common/closeIcon.svg') no-repeat center center;\n        background-size: 100%;\n        background-repeat: no-repeat;\n        position: absolute;\n        top: -10px;\n        right: -10px;\n        cursor: pointer;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginDes": `app-module__loginDes--WUqSo`,
	"loginBtn": `app-module__loginBtn--nV1ii`,
	"loadingWrap": `app-module__loadingWrap--AcDHf`,
	"advertisingIcon": `app-module__advertisingIcon--nFLJx`,
	"closeIcon": `app-module__closeIcon--iSk2C`
};
export default ___CSS_LOADER_EXPORT___;
