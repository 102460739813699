import { Request } from '../request';

type AuthCalendarPermissionProp = {
    code:string
};

type LoginProp = {
    credential: string
};

type FetchEvent = {
    start: string,
    end: string,
};

export const login = (credential:string) =>
    Request.get<LoginProp>({
        url: '/schedule-server/authentication',
        params: { credential },
        disableToken: true,
    });

export const opsLogin = (credential:string) =>
    Request.get<LoginProp>({
        url: '/admin-server/authentication',
        params: { credential },
        disableToken: true,
    });

export const authCalendarPermission = (code:string) =>
    Request.get<AuthCalendarPermissionProp>({
        url: '/schedule-server/authorization',
        params: { code },
    });

export const calendarEventFetch = (params:FetchEvent) =>
    Request.post({
        url: '/schedule-server/list',
        params,
    });

export type CreateEventType = {
    channelId?: string,
    colorId: string,
    description: string,
    duration?: string,
    email?: string[],
    end: string,
    location?: string,
    meetingLink?: string,
    patientName?: string,
    patientPhone?: string,
    providerId: number,
    recurrence?: string, // "null RRULE:FREQ=DAILY RRULE:FREQ=WEEKLY;BYDAY=FR RRULE:FREQ=MONTHLY;BYDAY=4FR RRULE:FREQ=MONTHLY RRULE:FREQ=YEARLY RRULE:FREQ=WEEKLY;BYDAY=FR,MO,TH,TU,WE",
    sendForm?: string,
    serviceId?: number,
    start: string,
    summary: string,
    timeZone: string, // "America/Thule America/New_York America/Chicago America/Phoenix America/Los_Angeles America/Anchorage America/Adak"
};

export const calendarCreateEvent = (params:CreateEventType) =>
    Request.post({
        url: '/schedule-server/create',
        params,
    });

export type UpdateEventType = {
    eventId: string,
    channelId?: string,
    colorId?: string,
    description: string,
    duration?: string,
    email?: string[],
    end: string,
    location?: string,
    meetingLink?: string,
    patientName?: string,
    patientPhone?: string,
    providerId?: number,
    recurrence?: string, // "null RRULE:FREQ=DAILY RRULE:FREQ=WEEKLY;BYDAY=FR RRULE:FREQ=MONTHLY;BYDAY=4FR RRULE:FREQ=MONTHLY RRULE:FREQ=YEARLY RRULE:FREQ=WEEKLY;BYDAY=FR,MO,TH,TU,WE",
    sendForm?: string,
    serviceId?: number,
    start: string,
    summary: string,
    timeZone: string, // "America/Thule America/New_York America/Chicago America/Phoenix America/Los_Angeles America/Anchorage America/Adak"
};

export const calendarUpdateEvent = (params:UpdateEventType) =>
    Request.put({
        url: `/schedule-server/update/${params.eventId}`,
        params,
    });

export type DeleteEventType = {
    eventId: string
};

export const calendarDeleteEvent = (params:DeleteEventType) =>
    Request.delete({
        url: '/schedule-server/del',
        params,
    });
