// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrapper--nmkCg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 92px;
  padding: 32px 16px;
  border-top: 3px solid #0B30E0;
  background: #F8FAFC;
  border-bottom: 1px solid #ECECEC;
}
.s-module__wrapper--nmkCg .s-module__userInfo--Hihj_ {
  flex: 1;
  margin: 0 8px;
}
.s-module__wrapper--nmkCg .s-module__userInfo--Hihj_ .s-module__name--ysli7 {
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  height: 22px;
}
.s-module__wrapper--nmkCg .s-module__userInfo--Hihj_ .s-module__status--u_tdO {
  font-size: 12px;
  line-height: 14px;
  height: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChatBot/components/ChatBoxHeader/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,6BAAA;EACA,mBAAA;EACA,gCAAA;AACF;AATA;EAWI,OAAA;EACA,aAAA;AACJ;AAbA;EAcM,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;AAEN;AAnBA;EAoBM,eAAA;EACA,iBAAA;EACA,YAAA;AAEN","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 92px;\n  padding: 32px 16px;\n  border-top: 3px solid #0B30E0;\n  background: #F8FAFC;\n  border-bottom: 1px solid #ECECEC;\n\n  .userInfo {\n    flex: 1;\n    margin: 0 8px;\n    .name {\n      font-weight: 500;\n      font-size: 24px;\n      line-height: 22px;\n      height: 22px;\n    }\n    .status {\n      font-size: 12px;\n      line-height: 14px;\n      height: 14px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `s-module__wrapper--nmkCg`,
	"userInfo": `s-module__userInfo--Hihj_`,
	"name": `s-module__name--ysli7`,
	"status": `s-module__status--u_tdO`
};
export default ___CSS_LOADER_EXPORT___;
