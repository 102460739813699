// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--evX4C {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  background: var(--blue-50, #EFF6FF);
  padding: 16px;
}
.s-module__wrap--evX4C .s-module__img--DUC8b {
  width: 20px;
  height: 20px;
}
.s-module__wrap--evX4C .s-module__title--VCbeo {
  color: #1E40AF;
  margin-left: 16px;
  font-weight: 500;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Alert/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mCAAA;EACA,aAAA;AACJ;AAPA;EAQQ,WAAA;EACA,YAAA;AAER;AAXA;EAYQ,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AAER","sourcesContent":[".wrap {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    border-radius: 6px;\n    background: var(--blue-50, #EFF6FF);\n    padding: 16px;\n    .img {\n        width: 20px;\n        height: 20px;\n    }\n    .title {\n        color: #1E40AF;\n        margin-left: 16px;\n        font-weight: 500;\n        font-size: 14px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--evX4C`,
	"img": `s-module__img--DUC8b`,
	"title": `s-module__title--VCbeo`
};
export default ___CSS_LOADER_EXPORT___;
