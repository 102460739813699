/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import s from './s.module.less';
// import { StepValue } from 'pages/CreateUniProfilePage/types';
// import ProviderStore from 'store/Provider';
import { NoticeType } from 'antd/es/message/interface';
import globalFormsEditStatusStore from 'store/ChannelApplicationProvider/FormsEditStatus';
import { ProviderLicenseFromDataWithoutState, ProviderProfile, ServiceExt } from 'types/provider';
import Landing from './components/Landing';
import ServiceModal from './components/ServiceModal';
import ServiceTable from './components/ServiceTable';
import { EContactType, KlarityService, KlarityServiceTypeInfo, ServerMeta, ELicenseType, TKlarityServiceServerTableItem, TKlarityServiceUiTableItem, ServerKlarityService, TKlarityProgramItem } from 'types/common';
import { EModalMode } from './types';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields, SettingEnum } from 'types/enumerationData';
import { FormLicense } from 'types/form';
import useKlarityServiceTemplate from 'hooks/useKlarityServiceTemplate';
import { getProviderSpecialtyDetail, getSpecialityTemplateDetail } from 'api/klarityService';
import { getOpsProviderSpecialtyDetail, getOpsSpecialityTemplateDetail } from 'api/operation';
import useProviderKlarityServiceList from 'hooks/useProviderKlarityServiceList';
import { Spin } from 'antd';
import { PracitceAddressForm } from 'types/practiceFront';
import ServiceLayer from './components/ServiceLayer';
import { setConfigingProgramId, setConfigProgramFromTable } from 'utils/sessionStorage';

interface IProps {
    licenses?: ProviderLicenseFromDataWithoutState;
    addressList?: PracitceAddressForm[];
    isReviewStep?: boolean;
    isOps?: boolean;
    // 运营端需要传当前操作的用户id
    providerId?: number;
    // showTypeCheckbox?: boolean;
    showEditButtons?: boolean;
    initFormValue?: Array<ServiceExt>;
    refetch: () => void;
    // onSubmit: (data: ISubmitChannelRelationshipData, ste: StepValue) => void;
    onShowMessage?: (type: NoticeType, content: string) => void;
    setDisallowNext?: (disallow: boolean) => void;
    setDisallowNextTips?: (tips: string) => void;
    showSyncButton?: boolean;
    onClickSync?: () => void;
}
const KlarityServices = ({
    licenses,
    isReviewStep,
    isOps,
    providerId,
    showEditButtons = true,
    initFormValue = [],
    refetch,
    onShowMessage,
    setDisallowNext,
    setDisallowNextTips,
    addressList,
    showSyncButton,
    onClickSync,
}: IProps) => {
    const [editStatus, setEditStatus] = useState(false);
    const [getGlobalEditStatus, setGloalEditStatus] = globalFormsEditStatusStore.useStore();
    const [allStates] = useGetSettingData(EnumFields.STATE);
    const [licenseStates, setLicenseStates] = useState<ServerMeta[]>([]);
    const [editingData, setEditingData] = useState<KlarityService[]>([]);

    const [modalMode, setModalMode] = useState<EModalMode>(EModalMode.SELECT_SERVICE);
    const [template, loadingTemplate, refetchTemplate] = useKlarityServiceTemplate(isOps, providerId);
    const [fetchingEdit, setFetchingEdit] = useState(false);
    const [editSpecialityValue, setEditSpecialityValue] = useState<ServerKlarityService[]>();

    const [isEdit, setIsEdit] = useState(false);

    const [tableData, tableDataLoading, refetchTableData] = useProviderKlarityServiceList(isOps, providerId);

    const loading = tableDataLoading || tableData === undefined;

    const [showSelectModal, setShowSelectModal] = useState(false);
    const [showSelectLayer, setShowSelectLayer] = useState(false);
    const showLanding = !isReviewStep && !isOps && tableData && tableData.length === 0 && !showSelectLayer;
    //todo
    //showLanding = false;
    const onClickReview = () => {
        if (isOps) {
            setShowSelectModal(true);
        } else {
            //setShowSelectModal(true);
            setShowSelectLayer(true);
        }
    };

    const practiceLicLength = licenses?.[ELicenseType.PRACTICE]?.length || 0;

    React.useEffect(() => {
        if (!licenses) {
            return;
        }
        //const deaLicense = licenses[ELicenseType.DEA];
        const practiceLicense = licenses[ELicenseType.PRACTICE];
        const stateKeys: string[] = [];
        // deaLicense?.forEach((license:FormLicense) => {
        //     if (!stateKeys.includes(license.state)) {
        //         stateKeys.push(license.state);
        //     }
        // });
        practiceLicense?.forEach((license: FormLicense) => {
            if (!stateKeys.includes(license.state)) {
                stateKeys.push(license.state);
            }
        });
        const _licenseStates = (allStates as ServerMeta[])?.filter((state: ServerMeta) => stateKeys.includes(state.dictKey));
        setLicenseStates(_licenseStates || []);
    }, [allStates, licenses, practiceLicLength]);

    React.useEffect(() => {
        if (showLanding || showSelectLayer) {
            setDisallowNext?.(true);
            setDisallowNextTips?.('Please set up your Klarity ServIce and Programs to proceed.');
        } else {
            setDisallowNext?.(false);
        }
    }, [showLanding, showSelectLayer]);

    React.useEffect(() => {
        return () => {
            setDisallowNext?.(false);
            setDisallowNextTips?.('');
        };
    }, []);

    if (loading) {
        return (
            <div className={s.module}>
                <div className={s.wrap}>
                    <Spin className={s.loading} />
                </div>
            </div>
        );
    }

    const getTableUseData = (serverData?: TKlarityServiceServerTableItem[]): TKlarityServiceUiTableItem[] => {
        const ret: TKlarityServiceUiTableItem[] = [];
        const allSpeIds: number[] = [];
        if (!allStates || allStates.length === 0) {
            return [];
        }
        serverData?.forEach((item: TKlarityServiceServerTableItem) => {
            if (!allSpeIds.includes(item.serviceSpecialtyId)) {
                allSpeIds.push(item.serviceSpecialtyId);
            }
        });
        allSpeIds.forEach((speId: number) => {
            const speData: TKlarityServiceServerTableItem[] = serverData?.filter((item: TKlarityServiceServerTableItem) => item.serviceSpecialtyId === speId) || [];
            speData.sort((a, b) => {
                return a.state && b.state ? a.state.localeCompare(b.state) : 0;
            });
            if (speData.length > 0) {
                const firstData = speData[0];
                const add: TKlarityServiceUiTableItem = {
                    serviceSpecialtyName: firstData.serviceSpecialtyName,
                    serviceSpecialtyId: firstData.serviceSpecialtyId,
                    serviceTypeInfo: [],
                };
                speData.forEach((item: TKlarityServiceServerTableItem, inx) => {
                    const state = (allStates as SettingEnum[])?.find((st: SettingEnum) => st.dictKey === item.state || st.content === item.state);
                    const contactType: EContactType[] = [];
                    if (item.inPerson) {
                        contactType.push(EContactType.IN_PERSON);
                    }
                    if (item.teleHealth) {
                        contactType.push(EContactType.TELE_HEALTH);
                    }
                    //is save spe
                    const addData: KlarityServiceTypeInfo = {
                        state: state as ServerMeta,
                        serviceId: item.serviceId,
                        serviceType: item.serviceType,
                        customizedServiceName: item.customizedServiceName || '',
                        earning: item.earning,
                        patientCost: item.patientCost,
                        contactType,
                        groupAndLimitationId: item.groupAndLimitationId,
                        qtyPatientGroup: item.qtyPatientGroup,
                        qtyLimitation: item.qtyLimitation,
                        accpet: undefined,
                        limitations: undefined,
                        rowId: inx,
                        allPatientGroup: item.allPatientGroup,
                        avaPriceInsight: item.avaPriceInsight,
                    };
                    add.serviceTypeInfo!.push(addData);
                });
                ret.push(add);
            }
        });
        return ret;
    };

    const fetchEditDetailAndShowModal = async (currentEditService: TKlarityServiceUiTableItem) => {
        setModalMode(EModalMode.CONFIG_SERVICE);
        if (!isOps && !isReviewStep) {
            setShowSelectLayer(true);
        } else {
            setShowSelectModal(true);
        }
        setFetchingEdit(true);
        setIsEdit(true);
        let templateDetailResult: any;
        if (isOps && providerId) {
            templateDetailResult = await getOpsSpecialityTemplateDetail(currentEditService.serviceSpecialtyId);
        } else {
            templateDetailResult = await getSpecialityTemplateDetail(currentEditService.serviceSpecialtyId);
        }
        if (!templateDetailResult.error) {
            const configItem = templateDetailResult?.data?.data;
            setEditingData([configItem]);
        }
        let detailDataResult: any;
        if (isOps && providerId) {
            detailDataResult = await getOpsProviderSpecialtyDetail(currentEditService.serviceSpecialtyId, providerId);
        } else {
            detailDataResult = await getProviderSpecialtyDetail(currentEditService.serviceSpecialtyId);
        }
        if (!detailDataResult.error) {
            setEditSpecialityValue(detailDataResult?.data?.data);
        }
        setFetchingEdit(false);
    };

    return (
        <div className={`${showSelectLayer ? '' : s.module}`}>
            <div className={`${showSelectLayer ? '' : s.wrap}`}>
                {
                    showLanding && <div className={s.landingWrap}><Landing onClickReview={onClickReview} /></div>
                }
                {
                    !showLanding && !showSelectLayer &&
                    <ServiceTable
                        onClickAdd={() => {
                            setIsEdit(false);
                            setModalMode(EModalMode.SELECT_SERVICE);
                            if (!isOps && !isReviewStep) {
                                setShowSelectLayer(true);
                            } else {
                                setShowSelectModal(true);
                            }
                        }}
                        onClickEdit={(currentEditService: TKlarityServiceUiTableItem) => {
                            fetchEditDetailAndShowModal(currentEditService);
                        }}
                        loading={tableDataLoading}
                        data={getTableUseData(tableData || [])}
                        showEditButtons={showEditButtons}
                        isOps={isOps}
                        providerId={providerId}
                        showSyncButton={showSyncButton}
                        onClickSync={onClickSync}
                        licenseStates={licenseStates}
                        onClickConfigProgram={(program: TKlarityProgramItem) => {
                            if (!isOps) {
                                setConfigProgramFromTable(true);
                                setConfigingProgramId(`${program.id}`);
                                setModalMode(EModalMode.CONFIG_PROGRAM_NOTE);
                                setShowSelectLayer(true);
                            }
                        }}
                    />
                }
                {
                    !showLanding && showSelectLayer &&
                    <ServiceLayer
                        isReviewStep={isReviewStep}
                        isEdit={isEdit}
                        fetchingEdit={fetchingEdit}
                        onCancel={(hasChange: boolean) => {
                            setEditingData([]);
                            setShowSelectModal(false);
                            setShowSelectLayer(false);
                            setEditingData([]);
                            setEditSpecialityValue(undefined);
                            setIsEdit(false);
                            refetchTemplate();
                            if (hasChange) {
                                setDisallowNext?.(false);
                                setDisallowNextTips?.('');
                                refetchTableData();
                            }
                        }}
                        onSelected={() => {

                        }}
                        recommendData={template?.recommend}
                        otherData={template?.other}
                        editData={editingData}
                        mode={modalMode}
                        allowStatesForService={licenseStates}
                        editingValue={editSpecialityValue}
                        showEditButtons={showEditButtons}
                        isOps={isOps}
                        providerId={providerId}
                        addressList={addressList}
                        onBack={() => {
                            setShowSelectLayer(false);
                        }}
                    />
                }
                <ServiceModal
                    isEdit={isEdit}
                    fetchingEdit={fetchingEdit}
                    show={showSelectModal}
                    onCancel={(hasChange: boolean) => {
                        setEditingData([]);
                        setShowSelectModal(false);
                        setEditingData([]);
                        setEditSpecialityValue(undefined);
                        setIsEdit(false);
                        refetchTemplate();
                        if (hasChange) {
                            setDisallowNext?.(false);
                            setDisallowNextTips?.('');
                            refetchTableData();
                        }
                    }}
                    onSelected={() => {

                    }}
                    recommendData={template?.recommend}
                    otherData={template?.other}
                    editData={editingData}
                    mode={modalMode}
                    allowStatesForService={licenseStates}
                    editingValue={editSpecialityValue}
                    showEditButtons={showEditButtons}
                    isOps={isOps}
                    providerId={providerId}
                    addressList={addressList}
                />
            </div>
        </div>
    );
};

export default KlarityServices;
