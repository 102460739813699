import { Button, DatePicker, Form, Input, Select, Tooltip } from 'antd';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { StepValue } from 'pages/CreateUniProfilePage/types';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import dayjs from 'dayjs';
import { EMPTY_PLACEHOLDER } from 'constants/common';
import { Meta } from 'types/common';

const OPTION_OTHER = 'Other';
interface IBiographicForm {
    ethnicity: string;
    birthDate: string;
    gender: string;
    religion: string;
    usedName: string;
}

interface IProps {
    initFormValue: IBiographicForm,
    editable?: boolean,
    showEditButtons: boolean,
    onSubmit: (value: any, step: StepValue) => void,
    onNext?: (value: any) => void,
    onEditableChange?: (enable: boolean, step: StepValue, callback: () => void) => void,
}

const Biographic = ({
    initFormValue = {},
    showEditButtons,
    editable,
    onSubmit,
    onNext,
    onEditableChange,
}: IProps) => {
    const [setting] = useSettings();
    const genderOptions = setting[EnumFields.GENDER];
    const ethnicityOptions = setting[EnumFields.ETHNICITY] || [];
    const religionOptions = setting[EnumFields.RELIGION] || [];
    const [enable, setEnable] = useState(!!editable);
    const [showOtherGenderInput, setShowOtherGenderInput] = useState(false);
    const [otherGender, setOtherGender] = useState('');
    const [biographicForm] = Form.useForm<IBiographicForm>();
    const handleEnableStatusChange = useCallback((status: boolean) => {
        if (typeof onEditableChange === 'function') {
            onEditableChange(status, StepValue.BIOGRAPHIC, () => {
                setEnable(status);
            });
        }
    }, [onEditableChange]);

    useEffect(() => {
        if (initFormValue) {
            const isOther = initFormValue.gender && genderOptions && (initFormValue.gender === OPTION_OTHER || !genderOptions.find((e: Meta) => e.value === initFormValue.gender));
            let defaultGender = initFormValue.gender;

            if (initFormValue.gender) {
                if (isOther) {
                    defaultGender = OPTION_OTHER;
                    setOtherGender(initFormValue.gender);
                    setShowOtherGenderInput(true);
                }
            } else {
                defaultGender = '';
            }
            biographicForm.setFieldsValue({
                ...initFormValue,
                gender: defaultGender,
            });
        }
    }, [initFormValue, biographicForm, genderOptions]);

    useEffect(() => {
        setEnable(!!editable);
    }, [editable]);

    const {
        ethnicity,
        birthDate,
        gender,
        religion,
        usedName,
    } = initFormValue;

    const handleGenderChange = useCallback((value: string) => {
        const isOther = value === OPTION_OTHER;

        setShowOtherGenderInput(isOther);
        if (isOther) {
            setOtherGender((genderOptions && !genderOptions.find((e: Meta) => e.value === initFormValue.gender)) ? initFormValue.gender : '');
        }
    }, [initFormValue, genderOptions]);

    const handleOtherGenderChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setOtherGender(e.target.value);
    }, []);

    const handleSubmit = useCallback(() => {
        biographicForm.validateFields().then((values) => {
            if (typeof onSubmit === 'function') {
                onSubmit({
                    biographic: {
                        ...values,
                        ethnicity: values.ethnicity || '',
                        religion: values.religion || '',
                        gender: values.gender === OPTION_OTHER ? otherGender : (values.gender || ''),
                        birthDate: values.birthDate ? dayjs(values.birthDate).format('YYYY-MM-DD') : '',
                    },
                }, StepValue.BIOGRAPHIC);
            }
        }).catch((e) => {
            console.error(e);
        });
    }, [biographicForm, onSubmit, otherGender]);

    const renderEditButtons = useMemo(() => {
        if (showEditButtons) {
            return !enable ? (
                <Button
                    onClick={() => handleEnableStatusChange(true)}
                >
                    Edit
                </Button>
            ) : (
                <div className={s.editButtons}>
                    <Button
                        onClick={() => handleEnableStatusChange(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                    >
                        Save changes
                    </Button>
                </div>
            );
        }

        return null;
    }, [enable, handleEnableStatusChange, handleSubmit, showEditButtons]);

    const handleFinish = useCallback((values) => {
        onNext?.({
            biographic: {
                ...values,
                ethnicity: values.ethnicity || '',
                religion: values.religion || '',
                gender: values.gender === OPTION_OTHER ? otherGender : (values.gender || ''),
                birthDate: values.birthDate ? dayjs(values.birthDate).format('YYYY-MM-DD') : '',
            },
        });
    }, [onNext, otherGender]);

    return (
        <div className={s.module}>
            <div className={s.wrap}>
                <div className={s.header}>
                    <h3>
                        <span>Biographic information</span>
                        { renderEditButtons }
                    </h3>
                    <span className={s.headerTip}>Providing the following information about your personal biography is entirely voluntary. Sharing these details in public may help build affinity and trust with specific groups of patients. However, please feel free to skip any information you don't wish to provide. Your comfort and privacy are our priority.</span>
                </div>
                { !enable ?
                    <>
                        <div className={s.row}>
                            <div
                                className={s.displayItem}
                            >
                                <div className={s.label}>Ethnicity</div>
                                <div className={s.value}>{ethnicity || EMPTY_PLACEHOLDER}</div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div
                                className={s.displayItem}
                            >
                                <div className={s.label}>Gender</div>
                                <div className={s.value}>{gender || EMPTY_PLACEHOLDER}</div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div
                                className={s.displayItem}
                            >
                                <div className={s.label}>Birth date</div>
                                <div className={s.value}>{birthDate ? dayjs(birthDate).format('YYYY-MM-DD') : EMPTY_PLACEHOLDER}</div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div
                                className={s.displayItem}
                            >
                                <div className={s.label}>Religion</div>
                                <div className={s.value}>{religion || EMPTY_PLACEHOLDER}</div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div
                                className={s.displayItem}
                            >
                                <div className={s.label}>Used name</div>
                                <div className={s.value}>{usedName || EMPTY_PLACEHOLDER}</div>
                            </div>
                        </div>
                    </> :
                    <Form
                        id={StepValue.BIOGRAPHIC}
                        form={biographicForm}
                        name={StepValue.BIOGRAPHIC}
                        className={`${commonS.formStyle1} ${s.formBody}`}
                        autoComplete="off"
                        layout="vertical"
                        onFinish={handleFinish}
                    >
                        <div className={s.module}>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        style={{ width: '390px' }}
                                        label="Ethnicity"
                                        name="ethnicity"
                                    >
                                        <Select options={ethnicityOptions} allowClear />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        style={{ width: '390px' }}
                                        label="Gender"
                                        name="gender"
                                    >
                                        <Select options={genderOptions || []} onChange={handleGenderChange} allowClear />
                                    </Form.Item>
                                    {
                                        showOtherGenderInput && <Input style={{ width: '390px', marginTop: '8px' }} value={otherGender} onChange={handleOtherGenderChange} />
                                    }
                                </div>
                            </div>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        style={{ width: '390px' }}
                                        label={
                                            <div className={s.formLabel}>Birth date
                                                <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '12px' }} color="#FFFFFF" title={<div className={s.tipText}>Your birth date will only be used to calculate your age. Your specific birth date will not be used in any public profile.</div>}>
                                                    <div className={s.questionMarkIcon} />
                                                </Tooltip>
                                            </div>
                                        }
                                        name="birthDate"
                                    >
                                        <DatePicker defaultPickerValue={dayjs('1980-01-01')} style={{ width: '100%' }} showToday={false} format="YYYY-MM-DD" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        style={{ width: '390px' }}
                                        label="Religion"
                                        name="religion"
                                    >
                                        <Select options={religionOptions} allowClear />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        style={{ width: '390px' }}
                                        label={
                                            <div className={s.formLabel}>Used name
                                                <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '12px' }} color="#FFFFFF" title={<div className={s.tipText}>This information helps us match your existing profile, if it’s under a different name.</div>}>
                                                    <div className={s.questionMarkIcon} />
                                                </Tooltip>
                                            </div>
                                        }
                                        name="usedName"
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </Form>
                }
            </div>
        </div>
    );
};

export default Biographic;
