// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/common/black_tip_icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--LJpr9 {
  margin-top: 16px;
  padding: 24px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}
.s-module__wrap--LJpr9 .s-module__header--eb4bE {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.s-module__wrap--LJpr9 .s-module__header--eb4bE .s-module__title--Fw8DM {
  color: var(--gray-900, #111827);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}
.s-module__wrap--LJpr9 .s-module__header--eb4bE .s-module__tip--YXjsp {
  width: 24px;
  height: 24px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  background-size: cover;
}
.s-module__wrap--LJpr9 .s-module__list--Z3j1N {
  display: flex;
  align-items: center;
  gap: 40px;
}
.s-module__pauseConfirmContent--D6rQe {
  color: var(--gray-900);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 175% */
}
.s-module__tipCard--O7IrR {
  max-width: 600px;
}
.s-module__tipText--aVBGZ {
  font-size: 14px;
  line-height: 22px;
  color: var(--gray-900);
}
`, "",{"version":3,"sources":["webpack://./src/components/CareCommitmentCards/s.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,+CAAA;AACJ;AANA;EAQQ,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACR;AAZA;EAcY,+BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACV,SAAS;AACX;AArBA;EAuBY,WAAA;EACA,YAAA;EACA,oEAAA;EACA,sBAAA;AACZ;AA3BA;EA+BQ,aAAA;EACA,mBAAA;EACA,SAAA;AADR;AAKA;EACI,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAHF,SAAS;AACX;AAKA;EACI,gBAAA;AAHJ;AAMA;EACI,eAAA;EACA,iBAAA;EACA,sBAAA;AAJJ","sourcesContent":[".wrap {\n    margin-top: 16px;\n    padding: 24px;\n    background-color: var(--white);\n    border-radius: 8px;\n    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);\n\n    .header {\n        margin-bottom: 10px;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n\n        .title {\n            color: var(--gray-900, #111827);\n            font-family: Inter;\n            font-size: 16px;\n            font-style: normal;\n            font-weight: 400;\n            line-height: 24px; /* 150% */\n        }\n\n        .tip {\n            width: 24px;\n            height: 24px;\n            background: url('assets/common/black_tip_icon.svg') no-repeat center;\n            background-size: cover;\n        }\n    }\n\n    .list {\n        display: flex;\n        align-items: center;\n        gap: 40px;\n    }\n}\n\n.pauseConfirmContent {\n    color: var(--gray-900);\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 28px; /* 175% */\n}\n\n.tipCard {\n    max-width: 600px;\n}\n\n.tipText {\n    font-size: 14px;\n    line-height: 22px;\n    color: var(--gray-900);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--LJpr9`,
	"header": `s-module__header--eb4bE`,
	"title": `s-module__title--Fw8DM`,
	"tip": `s-module__tip--YXjsp`,
	"list": `s-module__list--Z3j1N`,
	"pauseConfirmContent": `s-module__pauseConfirmContent--D6rQe`,
	"tipCard": `s-module__tipCard--O7IrR`,
	"tipText": `s-module__tipText--aVBGZ`
};
export default ___CSS_LOADER_EXPORT___;
