import React from 'react';
import s from './s.module.less';
import { Button, message } from 'antd';
import GlobalLayerStore from 'store/GlobalLayer';
import { Channel } from 'types/common';
import { getChannelPayLink } from 'api/subscription';
import { setPendingSubscriptionChannel } from 'utils/localstore';

const ChannelSubscription = () => {
    const [loading, setLoading] = React.useState(false);
    const [getGlobalLayer] = GlobalLayerStore.useStore();
    const channel:Channel | undefined = getGlobalLayer('pendingSubscriptionChannel');

    let period = 'month';
    if (channel?.billingCycle === 'Quarterly') {
        period = 'quarter';
    }
    if (channel?.billingCycle === 'Yearly') {
        period = 'year';
    }
    return (
        <div className={s.wrap}>
            <div className={s.logo}>
                <img
                    src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_kiwihealth_by_klarity_380x96.png"
                />
            </div>
            <div className={s.list}>Subscribe to Premium Channel</div>
            <div className={s.priceWrap}>
                <div className={s.price}>$ {channel?.kiwiPrice?.toFixed(2)}</div>
                <div className={s.priceLabel}>
                    <div>per</div>
                    <div>{period}</div>
                </div>
            </div>
            <div className={s.channelItem}>
                <div className={s.left}>
                    <img
                        src={channel?.logo}
                    />
                </div>
                <div className={s.right}>
                    <div className={s.nameAndPrice}>
                        <div className={s.name}>
                            {channel?.name}
                        </div>
                        <div className={s.price}>
                            ${channel?.kiwiPrice?.toFixed(2)}
                        </div>
                    </div>
                    <div className={s.time}>
                        Billed {channel?.billingCycle?.toLowerCase()}
                    </div>
                    <div className={s.total}>
                        <div className={s.label}>Total due today</div>
                        <div className={s.value}>{channel?.kiwiPrice?.toFixed(2)}</div>
                    </div>
                </div>
            </div>

            <div className={s.btnWrap}>
                <Button
                    onClick={async () => {
                        if (!channel?.subscriptionPriceId) {
                            message.error('Plan not set');
                            return;
                        }
                        setLoading(true);
                        const result = await getChannelPayLink(channel.id);
                        if (!result.error) {
                            const link = result.data.data;
                            if (link) {
                                setPendingSubscriptionChannel(channel);
                                window.open(link, '_self');
                            } else {
                                message.error('Pay link not found');
                            }
                        }

                        setLoading(false);
                    }}
                    block
                    size="large"
                    type="primary"
                    className={s.btn}
                    loading={loading}
                >
                    Confirm
                </Button>
                <div className={s.tips}>
                    By confirming, you authorize the payment using your saved info via Stripe, ensuring a secure transaction.
                </div>
                <div className={s.power}>
                    Powered by <b>Stripe</b>
                </div>
            </div>
        </div>
    );
};

export default ChannelSubscription;
