// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__channelSelectButton--txNqP {
  margin-top: 56px;
  background: var(--primary-color);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChannelStartButton/s.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EAEA,gCAAA;EACA,2CAAA;EACA,kBAAA;AAAJ","sourcesContent":[".channelSelectButton {\n    margin-top: 56px;\n    // width: 100%;\n    background: var(--primary-color);\n    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n    border-radius: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"channelSelectButton": `s-module__channelSelectButton--txNqP`
};
export default ___CSS_LOADER_EXPORT___;
