import React from 'react';
import dayjs from 'dayjs';
import { ChatController } from './chat-controller';
import {
    TextActionRequest,
} from './chat-types';
import { Message } from './Message';
import { LoadingMessage } from './LoadingMessage';
import { TextInput } from './TextInput';

import s from './s.module.less';

export function ChatBox({
    chatController,
}: React.PropsWithChildren<{
    chatController: ChatController;
}>): React.ReactElement {
    const chatCtl = chatController;
    const [messages, setMessages] = React.useState(chatCtl.getMessages());
    const [actReq, setActReq] = React.useState(chatCtl.getActionRequest());
    const isWaitingForAnswer = messages[messages.length - 1].self;
    const msgRef = React.useRef<HTMLDivElement>(null);
    const scroll = React.useCallback((): void => {
        if (msgRef.current) {
            msgRef.current.scrollTop = msgRef.current.scrollHeight;
        }
    }, [msgRef]);

    React.useEffect(() => {
        chatCtl.addOnMessagesChanged(() => {
            setMessages([...chatCtl.getMessages()]);
        });
        chatCtl.addOnActionChanged(() => {
            setActReq(chatCtl.getActionRequest());
        });
    }, []);

    React.useEffect(() => {
        scroll();
    }, [messages.length]);

    const unknownMsg = {
        type: 'text',
        content: 'Unknown message.',
        self: false,
    };

    let prevDate = dayjs(0);
    let prevTime = dayjs(0);

    return (
        <div className={s.chatboxWrapper}>
            <div
                className={s.content}
                ref={msgRef}
            >
                {messages.map((msg): React.ReactElement => {
                    let showDate = false;
                    let showTime = !!chatCtl.getOption().showDateTime;
                    if (!!chatCtl.getOption().showDateTime && !msg.deletedAt) {
                        const current = dayjs(
                            msg.updatedAt ? msg.updatedAt : msg.createdAt,
                        );

                        if (current.format('YYYYMMDD') !== prevDate.format('YYYYMMDD')) {
                            showDate = true;
                        }
                        prevDate = current;

                        if (current.diff(prevTime) < 60_000) {
                            showTime = false;
                        } else {
                            prevTime = current;
                        }
                    }
                    if (msg.type === 'text' || msg.type === 'jsx') {
                        return (
                            <Message
                                key={messages.indexOf(msg)}
                                id={`cu-msg-${messages.indexOf(msg) + 1}`}
                                message={msg}
                                showDate={showDate}
                                showTime={showTime}
                            />
                        );
                    }
                    return (
                        <Message
                            key={messages.indexOf(msg)}
                            id={`cu-msg-${messages.indexOf(msg) + 1}`}
                            message={unknownMsg}
                            showDate={showDate}
                            showTime={showTime}
                        />
                    );
                })}
                {
                    isWaitingForAnswer && <LoadingMessage />
                }
            </div>
            <div className={s.footer}>
                <TextInput
                    chatController={chatCtl}
                    actionRequest={actReq as TextActionRequest}
                    waitingAnswer={isWaitingForAnswer}
                />
            </div>
        </div>
    );
}
