import React from 'react';
import BotChatLogo from 'assets/aiChat/botChatLogo.svg';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Message, MessageContent } from './chat-types';

import s from './s.module.less';

export function Message({
    id,
    message,
    showDate,
    showTime,
}: {
    id: string;
    message: Message<MessageContent>;
    showDate: boolean;
    showTime: boolean;
}): React.ReactElement {
    if (message.deletedAt) {
        return <div id={id} />;
    }

    const dispDate = message.updatedAt ? message.updatedAt : message.createdAt;

    return (
        <div className={s.messageWrapper} id={id}>
            {showDate && (
                <div className={s.time}>
                    {dayjs(dispDate).format('MM/DD/YYYY HH:MM')}
                </div>
            )}
            <div className={cx({
                [s.messageRow]: true,
                [s.selfRow]: message.self,
                [s.botRow]: !message.self,
            })}
            >
                {!message.self && <img width={32} height={32} src={BotChatLogo} alt="bot_chat_logo" className={s.logo} />}
                <div className={cx({
                    [s.message]: true,
                    [s.selfBg]: message.self,
                    [s.botBg]: !message.self,
                })}
                >
                    {message.type === 'text' ? message.content : null}
                    {message.type === 'jsx' ? message.content : null}
                </div>
            </div>
        </div>
    );
}
