import { IProvderAgreementSignInput, ISalesLinkFinnalyCompleteInput, ICheckoutPaymentInput } from 'types/salesProvider';
import { Request } from '../request';

export const getSalesLinkDetail = (uuid: string) =>
    Request.get({ url: `/schedule-server/openapi/sales/contract/link/${uuid}` });

export const changeSalesLinkStatus = (uuid: string) =>
    Request.post({ url: `/schedule-server/openapi/sales/contract/link/click/${uuid}` });

export const checkoutNext = (uuid: string) =>
    Request.post({ url: `/schedule-server/openapi/sales/contract/link/checkout/next/${uuid}` });

export const checkoutPayment = (data: ICheckoutPaymentInput) =>
    Request.post({ url: '/schedule-server/openapi/sales/contract/link/checkout/next', params: data });

export const agreementSign = (data: IProvderAgreementSignInput) =>
    Request.post({ url: '/schedule-server/openapi/sales/contract/link/contract/signed', params: data });

export const salesScheduled = (uuid: string) =>
    Request.post({ url: `/schedule-server/openapi/sales/contract/link/onboarding/call/completed/${uuid}` });

export const salesLinkFinallyComplete = (data: ISalesLinkFinnalyCompleteInput) =>
    Request.post({ url: '/schedule-server/openapi/sales/contract/link/complete', params: data });

export const getStripePaymentStatus = (sessionId: string) =>
    Request.get({ url: '/schedule-server/openapi/sales/contract/link/checkout/session/status', params: { sessionId } });

// export const applyPromocode = (promocode: string) =>
//     Request.get({ url: `/schedule-server/openapi/sales/contract/link/promotion/${promocode}` });

export const applyPromocode = (linkUuid: string, promotionCode: string) =>
    Request.post({ url: '/schedule-server/openapi/sales/contract/link/promotion',
        params: {
            linkUuid,
            promotionCode,
        },
    });
