// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__launcher--dCdY8 .s-module__open--IRk28 {
  position: fixed;
  bottom: 94px;
  right: 120px;
}
.s-module__launcher--dCdY8 .s-module__close--kdnWK {
  position: fixed;
  bottom: 94px;
  right: 120px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChatBot/components/Launcher/s.module.less"],"names":[],"mappings":"AAAA;EAEI,eAAA;EACA,YAAA;EACA,YAAA;AAAJ;AAJA;EAQI,eAAA;EACA,YAAA;EACA,YAAA;AADJ","sourcesContent":[".launcher { \n  .open {\n    position: fixed;\n    bottom: 94px;\n    right: 120px;\n  }\n\n  .close {\n    position: fixed;\n    bottom: 94px;\n    right: 120px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"launcher": `s-module__launcher--dCdY8`,
	"open": `s-module__open--IRk28`,
	"close": `s-module__close--kdnWK`
};
export default ___CSS_LOADER_EXPORT___;
