// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/common/link.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__descItem--JzAVp {
  color: var(--gray-500, #6B7280);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  display: flex;
  align-items: center;
}
.s-module__Failed--NRDvo {
  display: flex;
  align-items: center;
  margin-left: 4px;
  color: #EF4444;
}
.s-module__Paided--FNTSs,
.s-module__Paid--ACjQA {
  margin-left: 4px;
}
.s-module__failLink--JXu_3 {
  width: 21px;
  height: 21px;
  margin-left: 4px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
  background-size: 100%;
  display: block;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/EhrPaymentStatus/s.module.less"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAa;EAAb,aAAA;EACA,mBAAA;AAEF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AAAF;AAGA;;EACE,gBAAA;AAAF;AAGA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,oEAAA;EACA,qBAAA;EACA,cAAA;EACA,eAAA;AADF","sourcesContent":[".descItem {\n  color: var(--gray-500, #6B7280);\n  text-align: center;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px; /* 142.857% */\n  display: flex;\n  align-items: center;\n}\n\n\n.Failed {\n  display: flex;\n  align-items: center;\n  margin-left: 4px;\n  color: #EF4444;\n}\n\n.Paided,.Paid {\n  margin-left: 4px;\n}\n\n.failLink {\n  width: 21px;\n  height: 21px;\n  margin-left: 4px;\n  background: url('assets/common/link.svg') center no-repeat;\n  background-size: 100%;\n  display: block;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descItem": `s-module__descItem--JzAVp`,
	"Failed": `s-module__Failed--NRDvo`,
	"Paided": `s-module__Paided--FNTSs`,
	"Paid": `s-module__Paid--ACjQA`,
	"failLink": `s-module__failLink--JXu_3`
};
export default ___CSS_LOADER_EXPORT___;
