import Airtable from 'airtable';
import type { TQuestion } from 'types/helloKlarity';
import type { TCarousel, TTeam } from 'types/kiwiHealth';

const CONFIG = {
    API: 'https://api.airtable.com',
    API_KEY: 'patYRIlJdCOfMn5RA.8bcfeef5ad3edc0d937e51cac2f7cde8b489f7d30c8640464d6743f5351246cf',
    BASE_ID: 'appD1rJRQik5OsYyE',
};

Airtable.configure({
    endpointUrl: CONFIG.API,
    apiKey: CONFIG.API_KEY,
});

const base = Airtable.base(CONFIG.BASE_ID);

export const getCarouselList = () => {
    return new Promise((res, rej) => {
        base('carousel_setting_table').select({
            maxRecords: 10,
            view: 'Grid view',
            sort: [],
        }).firstPage((err, records) => {
            if (err) {
                rej(err);
            }
            if (!records || records.length < 1) {
                rej();
            }
            res(records?.map((item) => {
                return {
                    index: item.id,
                    ...item.fields,
                };
            }));
        });
    });
};

export const kiwiCarouselUpdate = (params: Partial<TCarousel>) => {
    const { index } = params;
    if (!index) {
        return;
    }
    delete params.index;
    return base('carousel_setting_table').update(index, params);
};

export const kiwiCarouselCreate = (params: Partial<TCarousel>) => {
    return base('carousel_setting_table').create(params);
};

export const kiwiCarouselDelete = (index: string) => {
    return base('carousel_setting_table').destroy(index);
};

export const getTeamList = () => {
    return new Promise((res, rej) => {
        base('teams_setting_table').select({
            maxRecords: 100,
            view: 'Grid view',
            sort: [],
        }).firstPage((err, records) => {
            if (err) {
                rej(err);
            }
            if (!records || records.length < 1) {
                rej();
            }
            res(records?.map((item) => {
                return {
                    index: item.id,
                    ...item.fields,
                };
            }));
        });
    });
};

export const kiwiTeamUpdate = (params: Partial<TTeam>) => {
    const { index } = params;
    if (!index) {
        return;
    }
    delete params.index;
    return base('teams_setting_table').update(index, params);
};

export const kiwiTeamCreate = (params: Partial<TTeam>) => {
    return base('teams_setting_table').create(params);
};

export const kiwiTeamDelete = (index: string) => {
    return base('teams_setting_table').destroy(index);
};

export const getKlarityQaList = () => {
    return new Promise((res, rej) => {
        base('klarity_home_frequently_qa').select({
            maxRecords: 1000,
            view: 'Grid view',
            sort: [],
        }).firstPage((err, records) => {
            if (err) {
                rej(err);
            }
            if (!records || records.length < 1) {
                rej();
            }
            res(records?.map((item) => {
                return {
                    index: item.id,
                    ...item.fields,
                };
            }));
        });
    });
};

export const klarityQaUpdate = (params: Partial<TQuestion>) => {
    const { index } = params;
    if (!index) {
        return;
    }
    delete params.index;
    return base('klarity_home_frequently_qa').update(index, params);
};

export const klarityQaCreate = (params: Partial<TQuestion>) => {
    return base('klarity_home_frequently_qa').create(params);
};

export const klarityQaDelete = (index: string) => {
    return base('klarity_home_frequently_qa').destroy(index);
};
