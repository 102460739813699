import React, { useMemo } from 'react';
import s from './s.module.less';

interface IProps {
    status?: 'Pending' | 'Removed' | string ;
    statusText?: string;
}

const ChannelWithdrawStatusTag = ({
    status,
    statusText = '',
}: IProps) => {
    const statusClass = useMemo(() => {
        let cls = s.pending;
        switch (status?.toLowerCase()) {
            case 'removed':
                cls = s.removed;
                break;
            default: cls = s.pending;
        }

        return `${s.status} ${cls}`;
    }, [status]);
    return <div className={statusClass}>{statusText || status}</div>;
};

export default ChannelWithdrawStatusTag;
