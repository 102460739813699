import { Request } from '../request';

export const createReview = ({ rating, submitAt }:{
    rating: number;
    submitAt: string;
}) => Request.post({ url: '/schedule-server/provider/klarity/review/create',
    params:
    {
        rating,
        submitAt,
    },
});
