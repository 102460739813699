import { ProviderPractice } from 'types/provider';
import { Request } from '../request';
import { FormFields, IAboutMeInput, IBasicInfoInput, IEducationInput, IExperienceInput, IInsuranceInput, IMyPhilosophyInput, ISpecialtiesApproachesInput, PracitceAddressForm, ProvderAppointment } from 'types/practiceFront';

export const getPFStatusData = () => Request.get({ url: '/schedule-server/provider/practice/front/home' });

export const setPFTheme = (data: string) => Request.put({ url: `/schedule-server/provider/practice/front/theme/${data}` });

export const activePracticeFront = () => Request.put({ url: '/schedule-server/provider/practice/front/active' });

export const getPFFormData = () => Request.get({ url: '/schedule-server/provider/practice/front/detail' });

export const updateBasicInfo = (data: IBasicInfoInput) => Request.put({ url: '/schedule-server/provider/practice/front/basic/info/update', params: data });

export const updateInsuranceInfo = (data: IInsuranceInput) => Request.put({ url: '/schedule-server/provider/profile/insurance/update', params: data });

export const updateAboutMe = (data: IAboutMeInput) => Request.put({ url: '/schedule-server/provider/practice/front/aboutme/update', params: data });

export const updateMyPhilosophy = (data: IMyPhilosophyInput) => Request.put({ url: '/schedule-server/provider/practice/front/philosophy/update', params: data });

export const updateSpecialtiesAndApproch = (data: ISpecialtiesApproachesInput) => Request.put({ url: '/schedule-server/provider/practice/front/specialties/update', params: data });

export const updateExperience = (data: IExperienceInput) => Request.put({ url: '/schedule-server/provider/profile/experience/update', params: data });

export const addExperience = (data: IExperienceInput) => Request.post({ url: '/schedule-server/provider/profile/experience/add', params: data });

export const removeExperience = (id: number) => Request.delete({ url: `/schedule-server/provider/profile/experience/delete/${id}` });

export const updateEducation = (data: IEducationInput) => Request.put({ url: '/schedule-server/provider/profile/education/update', params: data });

export const addEducation = (data: IEducationInput) => Request.post({ url: '/schedule-server/provider/profile/education/add', params: data });

export const removeEducation = (id: number) => Request.delete({ url: `/schedule-server/provider/profile/education/delete/${id}` });

export const getFeatureList = () => Request.get({ url: '/schedule-server/provider/practice/front/feature/list' });

export const featureVote = (featureCode: string, voteType: number) => Request.put({ url: `/schedule-server/provider/practice/front/feature/vote/${featureCode}/${voteType}` });

export const launchPF = () => Request.post({ url: '/schedule-server/provider/practice/front/launch' });

export const activePF = () => Request.put({ url: '/schedule-server/provider/practice/front/active' });

export const deactivePF = () => Request.put({ url: '/schedule-server/provider/practice/front/inactive' });

export const addPractice = (data: ProviderPractice) => Request.post({ url: '/schedule-server/provider/profile/practice/add', params: data });

export const updatePractice = (data: Partial<ProviderPractice>) => Request.put({ url: '/schedule-server/provider/profile/practice/update', params: data });

export const addPracticeAddress = (data: PracitceAddressForm) => Request.post({ url: '/schedule-server/provider/profile/practice/address/add', params: data });

export const deletePracticeAddress = (id: number) => Request.delete({ url: `/schedule-server/provider/profile/practice/address/delete/${id}` });

export const updatePracticeAddress = (data: PracitceAddressForm) => Request.put({ url: '/schedule-server/provider/profile/practice/address/update', params: data });

export const updateAppointmentLink = (data: ProvderAppointment) => Request.put({ url: '/schedule-server/provider/practice/front/integration/url/update', params: data });

export const updateProviderReadNews = () => Request.put({ url: '/schedule-server/provider/practice/front/appointment/read' });

//json string
export const updateSEOcity = (seoCity: string) => Request.put({ url: '/schedule-server/provider/practice/front/seocity/update', params: { seoCity } });

export const updateBookingInfo = (data: { appointmentIntegrationUrl?: string, practicePhone?: string }) => Request.post({ url: '/schedule-server/provider/practice/front/appointment/booking/update', params: data });
