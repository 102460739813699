// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__module--xdoSd {
  padding: 0 24px;
  border-radius: 8px;
}
.s-module__module--xdoSd .s-module__loading--NdR_T {
  min-height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s-module__module--xdoSd .ant-form-item {
  margin-bottom: 0;
}
.s-module__module--xdoSd .ant-checkbox-disabled + span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-700);
}
.s-module__module--xdoSd .s-module__wrap--lkT2I {
  background: #fff;
  height: auto;
  border-radius: 8px;
  padding-bottom: 1px;
  padding: 6px 24px;
}
.s-module__landingWrap--ihrhV {
  margin-left: 24px;
  margin-right: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ApplicationFormComponents/KlarityServices/s.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;AACJ;AAHA;EAKQ,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACR;AAVA;EAcY,gBAAA;AADZ;AAbA;EAkBY,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AAFZ;AAnBA;EA0BQ,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;AAJR;AAQA;EACI,iBAAA;EACA,kBAAA;AANJ","sourcesContent":[".module {\n    padding: 0 24px;\n    border-radius: 8px;\n\n    .loading {\n        min-height: 60vh;\n        width: 100%;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n\n    :global {\n        .ant-form-item {\n            margin-bottom: 0;\n        }\n\n        .ant-checkbox-disabled+span {\n            font-weight: 500;\n            font-size: 14px;\n            line-height: 20px;\n            color: var(--gray-700);\n        }\n    }\n\n    .wrap {\n        background: #fff;\n        height: auto;\n        border-radius: 8px;\n        padding-bottom: 1px;\n        padding: 6px 24px;\n    }\n}\n\n.landingWrap {\n    margin-left: 24px;\n    margin-right: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"module": `s-module__module--xdoSd`,
	"loading": `s-module__loading--NdR_T`,
	"wrap": `s-module__wrap--lkT2I`,
	"landingWrap": `s-module__landingWrap--ihrhV`
};
export default ___CSS_LOADER_EXPORT___;
