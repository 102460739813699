import React, { useCallback, useEffect } from "react";
import s from "./s.module.less";
import { Checkbox, Col, Radio, Row, Select } from "antd";
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface IProps {
    options: { label: string, value: any }[];
    value?: string[];
    onChange?: (checkedValues: any[]) => void;
    unCheckAllText?: string;
}

const CheckboxNRadio = ({
    options,
    value,
    onChange,
    unCheckAllText = 'No meeting required'
}: IProps) => {
    const [val, setVal] = React.useState<any>();
    const [radioChecked, setRadioChecked] = React.useState<boolean|undefined>();

    useEffect(() => {
        setVal(value);
    }, [value]);

    useEffect(() => {
        if (val) {
            setRadioChecked(val.length > 0 ? false : true);
        }
    }, [val]);

    const handleChange = useCallback((checkedValues: CheckboxValueType[]) => {
        setVal(checkedValues);
        onChange?.(checkedValues)
    }, [onChange]);

    const handleRadioChange = useCallback((e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            setVal([]);
            onChange?.([])
        }
    }, [onChange]);

    return (
        <div className={s.wrap}>
            <Checkbox.Group style={{ width: '100%' }} onChange={handleChange} value={value}>
                <Row>
                    {
                        options.map((option, index) => (
                            <Col span={24} key={index}>
                                <Checkbox value={option.value}>{option.label}</Checkbox>
                            </Col>
                        ))
                    }
                </Row>
            </Checkbox.Group>
            {
                radioChecked !== undefined ?
                    <Radio checked={radioChecked} onChange={handleRadioChange}>{unCheckAllText}</Radio> :
                    <Radio onChange={handleRadioChange}>{unCheckAllText}</Radio>
            }

        </div>
    )
};

export default CheckboxNRadio;
