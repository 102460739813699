import React from 'react';
import { Button, Input, Popover, Spin } from 'antd';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import AvaIcon from 'assets/common/ava.png';
import s from './s.module.less';
import loadingIcon from 'assets/common/ailoading.svg';
import {
    CloseOutlined,
} from '@ant-design/icons';
import { LikeSVGIcon, LikedSVGIcon, UnlikeSVGIcon, UnlikedSVGIcon } from 'components/SocialIcons';
import { TCta, EAIEditorType, EConfigMoudleType, EConfigCtaType } from 'types/ai';
import useAiConfigModule from 'hooks/useAiConfigModule';
import type { TFetchAiCtaResultRes } from 'types/ai';
import { fetchAiCtaResult, feedbackAiResult } from 'api/ai';
import { opsFetchAiCtaResult } from 'api/operation';

type IBaseProps = {
    onFetchingUpdate?: (isFetching: boolean) => void;
    onChange?: (val: string) => void;
    value?: string; //if this val is undefind will ignore
    conversionId?: string;
    wrapClass?: string;
    resetFlag?: {}; //if !== undefined, will clear history
    type: EAIEditorType;
    size?: 'default' | 'small'
};

type IProps = TextAreaProps & IBaseProps & {
    isOps?: true;
    providerId?: number;
};
// not working..
//  | TextAreaProps & IBaseProps & {
//     isOps: undefined | false;
//     providerId: undefined
// };

type THistory = {
    title: string,
    content: string
};

type TAIRep = {
    messageId: string,
    conversionId: string,
    content: string,
};

const AiTextEditor = (props: IProps) => {
    const { onFetchingUpdate, onChange, conversionId, isOps, resetFlag, wrapClass, type, rows = 9, providerId, size, value, ...reset } = props;
    const [isFetching, setIsFetching] = React.useState(false);
    const [isLikeing, setIsLikeing] = React.useState(false);

    const [historyList, setHistoryList] = React.useState<THistory[]>([]);
    const [data, loading, refetch] = useAiConfigModule(!!isOps);
    const [openInx, setOpenInx] = React.useState<number>();
    const [val, setVal] = React.useState(value || '');
    const [currentConversionId, setCurrentConversionId] = React.useState('');
    const [lastAiResultId, setLastAiResultId] = React.useState<string>();
    const [showHistory, setShowHistory] = React.useState(false);

    const [likeStatus, setLikeStatus] = React.useState<boolean>();
    const [showVote, setShowVote] = React.useState(false);
    const timeoutRef = React.useRef<NodeJS.Timeout>();
    const pendingCtaIdTextMap = React.useRef(new Map());
    const [,forceUpdate] = React.useState({});
    const aiRequestFlag = React.useRef<'normal' | 'canceled'>('normal');
    const aiApiRequestString = React.useRef<string>();
    const hasHistory = !!(historyList && historyList.length > 0);
    const placeHolder = props.placeholder || `Begin by typing or pasting your text here. Once you have your content, explore Ava Editor's features to refine your message:
    - Adjust length: Adjust your text to be more concise or detailed.
    - Change tone: Modify the tone to suit your message – choose from Casual, Empathetic, Formal, or Friendly.
    - Tell Ava to...: Input specific instructions for custom edits, like 'make this more persuasive', 'translate to Spanish'.
    - Write Like XYZ: Emulate the writing style of renowned leaders or your favourite writers. Just select a name and watch the magic happen!

    Add your text, and then let Ava work its magic to elevate your writing!
`;

    const addToHistory = (item:THistory) => {
        const newHistoryList = [...historyList, item];
        setHistoryList(newHistoryList);
    };

    const sendSurveyFeedback = async (like:boolean) => {
        if (!lastAiResultId) {
            return;
        }
        const fn = feedbackAiResult;
        setIsLikeing(true);
        const result = await fn({
            messageId: lastAiResultId,
            like,
            type,
        });
        setIsLikeing(false);
    };

    const handleCancelRequest = () => {
        aiRequestFlag.current = 'canceled';
        setIsFetching(false);
    };

    const triggerAiAction = async (aiActionprops:{ id: number, promptVal?:string, ctaType:EConfigCtaType }) => {
        const { id, ctaType, promptVal } = aiActionprops;
        if (!val && ctaType !== EConfigCtaType.TEXT) {
            return;
        }
        const requestData:TFetchAiCtaResultRes = {
            ctaId: id,
            conversionId: currentConversionId,
            type,
            content: val,
        };

        if (isOps && providerId) {
            requestData.providerId = providerId;
        }

        const pendingSubmitValue = pendingCtaIdTextMap?.current?.get(id);
        if (ctaType === EConfigCtaType.TEXT) {
            requestData.prompt = pendingSubmitValue;
        }
        aiApiRequestString.current = JSON.stringify(requestData);
        setLikeStatus(undefined);
        setIsFetching(true);
        setShowVote(false);

        let fn = fetchAiCtaResult;
        if (isOps) {
            fn = opsFetchAiCtaResult;
        }

        const result = await fn(requestData);
        if (!result.error) {
            if (aiApiRequestString.current === JSON.stringify(requestData)) {
                const resultData:TAIRep = result.data?.data;
                setVal(resultData.content);
                setLastAiResultId(resultData.messageId);
                setCurrentConversionId(resultData.conversionId);
                if (!isOps) {
                    setShowVote(true);
                }
                addToHistory({
                    title: promptVal!,
                    content: resultData.content!,
                });
            }
        }
        setIsFetching(false);
    };

    React.useEffect(() => {
        onFetchingUpdate?.(isFetching);
    }, [isFetching]);

    React.useEffect(() => {
        onChange?.(val);
    }, [val]);

    React.useEffect(() => {
        if (resetFlag) {
            setHistoryList([]);
            aiRequestFlag.current = 'normal';
            aiApiRequestString.current = undefined;
            setShowVote(false);
            setLikeStatus(undefined);
            setLastAiResultId(undefined);
            setCurrentConversionId('');
            setOpenInx(undefined);
            pendingCtaIdTextMap.current = new Map();
        }
    }, [resetFlag]);

    React.useEffect(() => {
        if (value !== undefined) {
            setVal(value);
        }
    }, [value]);

    React.useEffect(() => {
        if (conversionId) {
            setCurrentConversionId(conversionId);
        }
    }, [conversionId]);

    React.useEffect(() => {
        return () => {
            if (timeoutRef?.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const HistoryContent = () => {
        return (
            <div className={s.historyWrapPop}>
                <div className={s.historyTitle}>Choose a version from the history</div>
                <div className={s.historyContent}>
                    {
                        historyList?.map((item:THistory, inx) => {
                            return (
                                <div className={s.historyItem} key={`${item.title}_${inx}`}>
                                    <div className={s.historyItemTitle}>{item.title}</div>
                                    <div className={s.historyItemContent}>{item.content}</div>
                                    <div className={s.historyItemBtn}>
                                        <Button
                                            onClick={() => {
                                                setVal(item.content);
                                                setShowHistory(false);
                                            }}
                                            className={s.btn}
                                            size="small"
                                        >Select
                                        </Button>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    };

    let voteClass = isLikeing ? `${s.voteInner} ${s.voteInnerLiking}` : s.voteInner;
    if (!showVote) {
        voteClass = `${s.voteInner} ${s.voteInnerHide}`;
    }

    const disableAction = !val;

    return (
        <div className={`${s.wrap} ${size === 'small' ? s.wrapSmall : ''} ${wrapClass || ''}`}>
            <Input.TextArea
                onChange={(e) => {
                    setVal(e.target.value);
                }}
                rows={rows}
                autoComplete="off"
                {...reset}
                value={val}
                disabled={reset.disabled || isFetching}
                // style={{ paddingBottom: showVote ? '38px' : '0' }}
                placeholder={placeHolder}
            />
            <div className={s.actionAndLikeWrap}>
                <div className={showVote ? s.voteWrap : `${s.voteWrap} ${s.voteWrapHide}`}>
                    <div className={voteClass}>
                        <div className={s.voteLeft}>
                            Is this edit helpful? Your feedback helps Ava improve!
                        </div>
                        <div className={s.voteRight}>
                            {
                                isLikeing && <Spin style={{ marginTop: '4px' }} spinning size="small" />
                            }
                            <div
                                onClick={() => {
                                    if (!showVote || isLikeing) {
                                        return;
                                    }
                                    if (likeStatus === undefined) {
                                    //only submit once
                                        setLikeStatus(true);
                                        sendSurveyFeedback(true);
                                        if (timeoutRef?.current) {
                                            clearTimeout(timeoutRef.current);
                                        }
                                        timeoutRef.current = setTimeout(() => {
                                            setShowVote(false);
                                        }, 1200);
                                    }
                                }}
                                className={s.imgWrap}
                            >
                                {likeStatus === true ?
                                    LikedSVGIcon :
                                    LikeSVGIcon
                                }
                            </div>
                            <div
                                onClick={() => {
                                    if (!showVote || isLikeing) {
                                        return;
                                    }
                                    if (likeStatus === undefined) {
                                    //only submit once
                                        setLikeStatus(false);
                                        sendSurveyFeedback(false);
                                        if (timeoutRef?.current) {
                                            clearTimeout(timeoutRef.current);
                                        }
                                        timeoutRef.current = setTimeout(() => {
                                            setShowVote(false);
                                        }, 1200);
                                    }
                                }}
                                className={s.imgWrap}
                            >
                                {likeStatus === false ?
                                    UnlikedSVGIcon
                                    :
                                    UnlikeSVGIcon
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.actionWrap}>

                    <div className={s.left}>
                        <img className={s.ava} src={AvaIcon} />
                        {
                            loading && <img className={s.loading} style={{ marginLeft: '16px' }} src={loadingIcon} />
                        }
                        {
                            isFetching &&
                            <div className={s.workingWrap}>
                                <img className={s.loading} src={loadingIcon} />
                                <span>Working on it</span>
                                <span
                                    onClick={handleCancelRequest}
                                    className={s.cancel}
                                >
                                    <CloseOutlined />
                                </span>
                            </div>
                        }
                        {
                            !isFetching && !loading &&
                            <div className={disableAction ? `${s.configWrap} ${s.configWrapDisabled}` : s.configWrap}>
                                {
                                    data?.map((item, inx) => {
                                        let hasImg = false;
                                        const isTextInput = item.type === EConfigMoudleType.TEXT;
                                        if (item?.ctas && item?.ctas?.length > 0) {
                                            hasImg = !!item?.ctas?.find((c) => !!c.imageUrl);
                                        }
                                        let submitText = 'Submit';
                                        let submitTextCtaId = -1; //should be only one cta child
                                        let submitTextCtaType = EConfigCtaType.TEXT; //should be only one cta child
                                        let submitTextPlaceHolder = '';
                                        if (isTextInput && item.ctas && item.ctas.length > 0) {
                                            submitText = item.ctas[0].name;
                                            submitTextCtaId = item.ctas[0].id;
                                            submitTextCtaType = item.ctas[0].type;
                                            submitTextPlaceHolder = item.ctas[0].placeholder || '';
                                        }
                                        const pendingSubmitValue = pendingCtaIdTextMap?.current?.get(submitTextCtaId);
                                        const canSubmit = !!pendingSubmitValue;
                                        const content = isTextInput ?
                                            (
                                                <div
                                                    className={s.configTextAction}
                                                    key={item.id}
                                                >
                                                    <div className={s.configTextTitle}>
                                                        {item.name}
                                                    </div>
                                                    <div className={s.configTextInputWrap}>
                                                        <Input.TextArea
                                                            rows={5}
                                                            placeholder={submitTextPlaceHolder}
                                                            value={pendingSubmitValue}
                                                            onChange={(e) => {
                                                                pendingCtaIdTextMap.current?.set(submitTextCtaId, e.target.value);
                                                                forceUpdate({});
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={s.configTextSubmit}>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenInx(undefined);
                                                                triggerAiAction({ id: submitTextCtaId, promptVal: pendingSubmitValue, ctaType: submitTextCtaType });
                                                                pendingCtaIdTextMap.current?.set(submitTextCtaId, '');
                                                            }}
                                                            disabled={!canSubmit}
                                                            type="primary"
                                                        >{submitText}
                                                        </Button>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            (
                                                <div
                                                    className={s.configAction}
                                                >
                                                    {item?.ctas?.map((i:TCta) => {
                                                        if (hasImg) {
                                                            return (
                                                                <Popover
                                                                    trigger="hover"
                                                                    title=""
                                                                    content={true ? null : <div className={s.hoverTips}>{i.aiPrompt}</div>}
                                                                    placement="right"
                                                                    rootClassName={s.pop}
                                                                    key={i.id}
                                                                >
                                                                    <div
                                                                        onClick={() => {
                                                                            setOpenInx(undefined);
                                                                            triggerAiAction({ id: i.id, promptVal: i.name, ctaType: i.type });
                                                                        }}
                                                                        className={s.configActionItem}
                                                                        style={{ minWidth: hasImg ? '300px' : '130px' }}
                                                                        key={i.id}
                                                                    >
                                                                        <img src={i.imageUrl} />
                                                                        <span className={s.ctaName}>{i.name}</span>
                                                                    </div>
                                                                </Popover>
                                                            );
                                                        }
                                                        return (
                                                            <div
                                                                onClick={() => {
                                                                    setOpenInx(undefined);
                                                                    triggerAiAction({ id: i.id, promptVal: i.name, ctaType: i.type });
                                                                }}
                                                                className={s.configActionItem}
                                                                style={{ minWidth: hasImg ? '300px' : '130px' }}
                                                                key={i.id}
                                                            >
                                                                <span className={s.ctaName}>{i.name}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );

                                        return (
                                            <div className={openInx === inx ? `${s.configItem} ${s.configItemActive}` : s.configItem}>
                                                <Popover
                                                    trigger="click"
                                                    arrow={false}
                                                    placement={inx === 0 ? 'bottomLeft' : 'bottomLeft'}
                                                    title=""
                                                    open={openInx === inx && !disableAction}
                                                    onOpenChange={(isOpen:boolean) => {
                                                        if (disableAction) {
                                                            return;
                                                        }
                                                        if (isOpen) {
                                                            setOpenInx(inx);
                                                        } else {
                                                            setOpenInx(undefined);
                                                        }
                                                    }}
                                                    content={content}
                                                    rootClassName={s.pop}
                                                >
                                                    <Popover
                                                        trigger="hover"
                                                        arrow={false}
                                                        title=""
                                                        content={<div className={s.hoverTips}>{item.description}</div>}
                                                        placement={inx === 0 ? 'bottomLeft' : 'bottomLeft'}
                                                        rootClassName={s.pop}
                                                    >
                                                        <div className={s.configItemInner}>
                                                            <img src={item.iconUrl} />
                                                            <span className={s.configName}>{item.name}</span>
                                                        </div>
                                                    </Popover>
                                                </Popover>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        }
                    </div>
                    <div className={s.right}>
                        {
                            !isFetching && hasHistory &&
                            <Popover
                                trigger="click"
                                open={showHistory}
                                onOpenChange={(v) => { setShowHistory(v); }}
                                arrow={false}
                                placement="topRight"
                                title=""
                                content={(<HistoryContent />)}
                            >
                                <div className={s.historyWrap}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <g clipPath="url(#clip0_3081_10987)">
                                            <path d="M8.66667 4C8.48986 4 8.32029 4.07024 8.19526 4.19526C8.07024 4.32029 8 4.48986 8 4.66667V8.33333C8 8.51014 8.07024 8.67971 8.19526 8.80474C8.32029 8.92976 8.48986 9 8.66667 9H12C12.1768 9 12.3464 8.92976 12.4714 8.80474C12.5964 8.67971 12.6667 8.51014 12.6667 8.33333C12.6667 8.15652 12.5964 7.98695 12.4714 7.86193C12.3464 7.7369 12.1768 7.66667 12 7.66667H9.47333C9.42913 7.66667 9.38674 7.64911 9.35548 7.61785C9.32423 7.5866 9.30667 7.5442 9.30667 7.5V4.66667C9.3068 4.49438 9.24024 4.32873 9.12092 4.20445C9.00161 4.08016 8.83882 4.00689 8.66667 4Z" fill="#6B7280" />
                                            <path d="M8.3801 0.839636C6.94581 0.895524 5.56088 1.37844 4.40282 2.2265C3.24476 3.07456 2.36632 4.24912 1.8801 5.59964C1.86679 5.6353 1.84167 5.66533 1.80892 5.68473C1.77618 5.70414 1.73777 5.71175 1.7001 5.7063L0.573435 5.53964C0.511419 5.53132 0.448308 5.53994 0.390796 5.56459C0.333284 5.58924 0.283515 5.62899 0.246768 5.67964C0.21439 5.73288 0.197266 5.79399 0.197266 5.8563C0.197266 5.91861 0.21439 5.97973 0.246768 6.03297L2.0001 9.1663C2.02785 9.2135 2.06658 9.2533 2.11301 9.28232C2.15944 9.31134 2.21218 9.32871 2.26677 9.33297C2.31741 9.33852 2.36865 9.33305 2.41698 9.31694C2.46531 9.30083 2.50958 9.27446 2.54677 9.23964L5.0601 6.73964C5.10412 6.69602 5.13512 6.641 5.1496 6.58075C5.16408 6.5205 5.16149 6.4574 5.1421 6.39855C5.12271 6.33969 5.0873 6.2874 5.03984 6.24756C4.99238 6.20771 4.93476 6.18188 4.87343 6.17297L3.72677 5.99964C3.70161 5.99495 3.6778 5.98474 3.65705 5.96976C3.6363 5.95477 3.61913 5.93538 3.60677 5.91297C3.59507 5.89029 3.58897 5.86515 3.58897 5.83964C3.58897 5.81412 3.59507 5.78898 3.60677 5.7663C4.00375 4.86709 4.63418 4.09053 5.43261 3.51724C6.23105 2.94394 7.16838 2.59481 8.14731 2.50608C9.12623 2.41736 10.1111 2.59228 10.9996 3.01268C11.8881 3.43309 12.6478 4.08365 13.2 4.89683C13.7522 5.71001 14.0766 6.65617 14.1397 7.63709C14.2027 8.618 14.0021 9.59791 13.5585 10.4751C13.115 11.3523 12.4447 12.0947 11.6174 12.6254C10.79 13.1561 9.83566 13.4556 8.85343 13.493C8.63242 13.5009 8.42362 13.5964 8.27297 13.7583C8.12231 13.9202 8.04214 14.1353 8.0501 14.3563C8.05806 14.5773 8.15349 14.7861 8.31539 14.9368C8.4773 15.0874 8.69242 15.1676 8.91343 15.1596C10.8133 15.0898 12.6075 14.2681 13.9015 12.8753C15.1955 11.4826 15.8833 9.6328 15.8134 7.73297C15.7436 5.83313 14.9219 4.03886 13.5291 2.74486C12.1364 1.45086 10.2866 0.763129 8.38677 0.832969L8.3801 0.839636Z" fill="currentColor" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3081_10987">
                                                <rect width="16" height="16" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span className={s.view}>View history</span>
                                </div>
                            </Popover>
                        }
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AiTextEditor;
