import React from 'react';

export function Desktop() {
    return (
        <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.6401 18.2676V2.94628C32.6401 2.78678 32.5823 2.64859 32.4667 2.53172C32.3511 2.41484 32.2145 2.35641 32.0567 2.35641H2.91322C2.75546 2.35641 2.61877 2.41484 2.50317 2.53172C2.38757 2.64859 2.32976 2.78678 2.32976 2.94628V18.2676C2.32976 18.4271 2.38757 18.5652 2.50317 18.6821C2.61877 18.799 2.75546 18.8574 2.91322 18.8574H32.0567C32.2145 18.8574 32.3511 18.799 32.4667 18.6821C32.5823 18.5652 32.6401 18.4271 32.6401 18.2676ZM34.972 2.94628V22.9824C34.972 23.793 34.6867 24.4867 34.1161 25.0635C33.5456 25.6403 32.8595 25.9287 32.0577 25.9287H22.1491C22.1491 26.3832 22.2463 26.8589 22.4408 27.356C22.6353 27.853 22.8294 28.2889 23.0232 28.6636C23.2171 29.0383 23.3143 29.3054 23.315 29.4649C23.315 29.7839 23.1997 30.0602 22.9692 30.294C22.7387 30.5278 22.4653 30.6443 22.1491 30.6436H12.8229C12.5073 30.6436 12.234 30.5271 12.0028 30.294C11.7716 30.0609 11.6563 29.7846 11.657 29.4649C11.657 29.293 11.7542 29.0228 11.9487 28.6543C12.1432 28.2858 12.3373 27.8561 12.5311 27.3652C12.725 26.8744 12.8222 26.3955 12.8229 25.9287H2.91424C2.1125 25.9287 1.42635 25.6403 0.855811 25.0635C0.28527 24.4867 0 23.793 0 22.9824V2.94628C0 2.13572 0.28527 1.44203 0.855811 0.865219C1.42635 0.288406 2.1125 0 2.91424 0H32.0577C32.8595 0 33.5456 0.288406 34.1161 0.865219C34.6867 1.44203 34.972 2.13572 34.972 2.94628Z" fill="currentColor" />
        </svg>

    );
}
