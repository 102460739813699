import React from 'react';
import Launcher from './components/Launcher';
import ChatBoxWrapper from './components/ChatBoxWrapper';

const ChatBot = ({ api }: { api?: string }) => {
    return (
        <div>
            <Launcher />
            <ChatBoxWrapper api={api} />
        </div>
    );
};

export default ChatBot;
