/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Modal, Button, Popover, Spin, message, Checkbox } from 'antd';
import s from './s.module.less';
import { KlarityService, ServerKlarityService, ServerMeta, TKlarityProgramItem, TKlarityProgramItemDetail } from 'types/common';
import { saveProviderKlarityService } from 'api/klarityService';
import { saveOpsProviderKlarityService } from 'api/operation';
import commonS from 'styles/common.module.less';
import SelectService from '../SelectService';
import ConfigService from '../ConfigService';
import ProgramConfigNote from '../ProgramConfigNote';
import SignProgram from '../SignProgram';
import { EModalMode, ServerData } from '../../types';
import { PracitceAddressForm } from 'types/practiceFront';
import { updateStepForm } from 'api/applicationForm';
import { getHasDisableViewServiceCheck, setHasDisableViewServiceCheck } from 'utils/localstore';
import UniprofileStepDataStore from 'store/UniprofileStepData';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { EServiceType } from 'types/common';
import { Provider } from 'types/provider';
import ProviderStore from 'store/Provider';
import { BOOK_PROGRAM_WEBINAR_LINK } from 'constants/common';
import { setClickSkipWebinar, getClickSkipWebinar, getConfigingProgramId, getConfigProgramFromTable, setConfigProgramFromTable, setConfigingProgramId } from 'utils/sessionStorage';
import useKlarityProgram from 'hooks/useKlarityProgram';
import { enrollKlarityProgram, getKlarityProgramDetail, TEnrollKlarityProgram } from 'api/common';

type IProps = {
    onCancel: (hasChange: boolean) => void;
    recommendData?: KlarityService[];
    otherData?: KlarityService[];
    onSelected: (data: KlarityService[]) => void;
    mode?: EModalMode;
    allowStatesForService?: ServerMeta[];
    editData?: KlarityService[]; //under config
    fetchingEdit?: boolean;
    editingValue?: ServerKlarityService[];
    isEdit?: boolean;
    isOps?: boolean;
    // 运营端需要传当前操作的用户id
    providerId?: number;
    // showTypeCheckbox?: boolean;
    showEditButtons?: boolean;
    addressList?: PracitceAddressForm[];
    onBack?: () => void;
    isReviewStep?: boolean;
};

const ServiceLayer = (props: IProps) => {
    const { recommendData, otherData, editData, isReviewStep, onBack, onCancel, onSelected, mode, allowStatesForService = [], addressList, fetchingEdit, editingValue, isEdit, isOps, providerId, showEditButtons } = props;
    const [currentStep, setCurrentStep] = React.useState<EModalMode>(mode || EModalMode.CONFIG_SERVICE);
    const [selectData, setSelectData] = React.useState<KlarityService[]>([]); //mockSelectedServiceData
    const [serverIdStateMap, setServerIdStateMap] = React.useState<Record<string, ServerMeta[]>>({});
    const [currentSettingServiceTypeInx, setCurrentSettingServiceTypeInx] = React.useState<number>(1);
    const [pendingSaveData, setPendingSaveData] = React.useState<ServerData>();
    const [hasSaveAny, setHasSaveAny] = React.useState<boolean>(false);
    const [hasNameError, setHasNameError] = React.useState<boolean>(false);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [showTipsModal, setShowTipsModal] = React.useState(false);
    const [hasCheckNotShow, setHasCheckNotShow] = React.useState(false);
    const [showWebinarModal, setShowWebinarModal] = React.useState(false);
    const [signChecked, setSignChecked] = React.useState(false);
    const [getProvider] = ProviderStore.useStore();
    const provider: Provider = getProvider('data') as Provider;
    const [onAgreeButtonClickFlag, setOnAgreeButtonClickFlag] = React.useState<{}>();
    const [allPrograms, loading, refetchProgram] = useKlarityProgram(isOps, providerId);
    const [configingProgram, setConfigingProgram] = React.useState<TKlarityProgramItemDetail>();
    const [fetchingProgram, setFetchingProgram] = React.useState<boolean>(false);
    const [enrolling, setEnrolling] = React.useState<boolean>(false);
    const [errorMedicationFlag, setErrorMedicationFlag] = React.useState<{}>();

    const [get, set] = UniprofileStepDataStore.useStore();
    const configSpeciality = get("speciality");
    const currentSpeciality = get("currentSpeciality");
    const specialityWithProgram = allPrograms.find((item) => item.specialtyName === currentSpeciality);

    const hasDisableViewServiceCheck = getHasDisableViewServiceCheck();


    const allowStateRemovedNotSupport = allowStatesForService?.filter((item) => {
        const notSupportStates = configingProgram?.excludeState?.split(',') || [];
        return notSupportStates.indexOf(item.dictKey) === -1;
    });

    const hasAnySupportState = allowStateRemovedNotSupport && allowStateRemovedNotSupport?.length > 0;

    React.useEffect(() => {
        const actionFunc = async () => {
            const configProgramId = getConfigingProgramId();
            if (configProgramId) {
                const detailResult = await getKlarityProgramDetail(parseInt(configProgramId));
                if (!detailResult.error) {
                    const detail = detailResult?.data?.data;
                    setConfigingProgram(detail);
                    setCurrentStep(EModalMode.CONFIG_PROGRAM_NOTE);
                    scrollToTop();
                }
            }
        }
        actionFunc();
    }, [])

    // console.log({
    //     pendingSaveData,
    // });

    //console.log(JSON.stringify(serverIdStateMap))

    //console.log(JSON.stringify(selectData))

    const selectDataLength = selectData?.length;
    const currentEditData = isEdit ? editData : selectData;
    const showSkip = currentSettingServiceTypeInx <= (selectDataLength || 0);

    React.useEffect(() => {
        //todo remove
        //set("currentSpeciality", "Infections");
        //setCurrentStep(EModalMode.CONFIG_PROGRAM_NOTE);
        //setConfigingProgram(mockConfigProgramDetail);
    }, [allPrograms]);

    React.useEffect(() => {
        if (mode) {
            setCurrentStep(mode);
        }
    }, [mode]);

    const handleSkip = () => {
        const nextInx = currentSettingServiceTypeInx + 1;
        const isEnd = nextInx > (currentEditData?.length || 0);
        if (isEnd) {
            onCancel?.(hasSaveAny);
            set("currentSpeciality", undefined);
            set("speciality", []);
            scrollToTop();
            return;
        }
        setCurrentSettingServiceTypeInx(nextInx);
        scrollToTop();
        //onCancel?.(hasSaveAny);
    };

    const scrollToTop = () => {
        if (isReviewStep) {
            const target = document.getElementById('layer-wrap');
            try {
                target?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            } catch (e) {
                console.error(e);
            }
            return;
        }
        try {
            // document.getElementsByClassName('ant-modal-body')[0].scrollTo({
            //     top: 0,
            //     behavior: 'smooth',
            // });
            document.getElementById('ks-form-content')?.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } catch (e) {
            console.error(e);
        }
    };

    const scrollIntoEmtpyMedication = () => {
        setTimeout(() => {
            try {
                const target = document.getElementsByClassName('emptyMedication');
                if (target && target.length > 0) {
                    target[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            } catch (e) {
                console.error(e);
            }
        }, 400);
    }

    const updateUniprofile = async () => {
        try {
            const submitData = {
                step: 'KLARITY_SERVICES',
            };

            const result = await updateStepForm(submitData);

            if (result?.error) {
                message.error(result.error);
            }
        } catch (e) {
            console.error(e);
        }
    };

    // const handleCancel = () => {
    //     () => {
    //         setCurrentStep(mode || EModalMode.SELECT_SERVICE);
    //         setSelectData([]);
    //         setCurrentSettingServiceTypeInx(1);
    //         setServerIdStateMap({});
    //         onCancel?.(hasSaveAny);
    //     }
    // }

    // const handleOpenCalendar = () => {
    //     setShowWebinarModal(false);
    //     try {
    //         const objString = new URLSearchParams({
    //             hide_gdpr_banner: '1',
    //             first_name: provider?.firstName,
    //             last_name: provider?.lastName
    //         }).toString();
    //         (window as any).Calendly.initPopupWidget({
    //             url: `${BOOK_APPOINTMENT_LINK}?${objString}`,
    //             prefill: {
    //                 email: provider?.email,
    //             },
    //         });

    //     } catch (err) {
    //         console.log(err)
    //     } finally {
    //         setShowWebinarModal(false);
    //     }
    // }
    const handleOpenCalendarInModal = () => {
        setShowWebinarModal(true);
        setTimeout(() => {
            const objString = new URLSearchParams({
                hide_gdpr_banner: '1',
                first_name: provider?.firstName,
                last_name: provider?.lastName
            }).toString();

            (window as any).Calendly.initInlineWidget({
                url: `${BOOK_PROGRAM_WEBINAR_LINK}?${objString}`,
                // url: `https://calendly.com/kunqian-fan/11?${objString}`,
                parentElement: document.getElementById('calendly'),
                prefill: {
                    email: provider?.email,
                },
            });
        }, 300);
    }

    const handleSaveService = async () => {
        if (!currentEditData) {
            return;
        }
        const nextInx = currentSettingServiceTypeInx + 1;
        const max = currentEditData.length + 1;
        const _emptyMedicationErrorIndex = [];
        const length = pendingSaveData?.providerKlarityService?.length || 0;

        for (let i = 0; i < length; i++) {
            const service = pendingSaveData?.providerKlarityService[i];
            const isMedication = service?.serviceType === EServiceType.MEDICATIONS;
            if (isMedication) {
                const medLength = service?.medications?.length || 0;
                if (medLength === 0) {
                    _emptyMedicationErrorIndex.push(i);
                }
            }
        }
        const hasEmptyMedication = _emptyMedicationErrorIndex.length > 0;
        if (hasEmptyMedication) {
            setErrorMedicationFlag(_emptyMedicationErrorIndex?.length > 0 ? {} : undefined);
            scrollIntoEmtpyMedication();
            message.error('Please select at least one medication for each medication service');
            return;
        }

        if (nextInx === max) {
            //finish
            setSaving(true);
            let result: any = {};
            if (isOps && providerId) {
                result = await saveOpsProviderKlarityService(providerId, pendingSaveData?.providerKlarityService || [], pendingSaveData?.disableIds || []);
            } else {
                await updateUniprofile();
                result = await saveProviderKlarityService(pendingSaveData?.providerKlarityService || [], pendingSaveData?.disableIds || []);
            }

            if (!result.error) {
                //success
                onCancel?.(true);
                set("currentSpeciality", undefined);
                set("speciality", []);
            }
            setSaving(false);
        } else {
            //do save,
            setSaving(true);
            let result: any = {};
            if (isOps && providerId) {
                result = await saveOpsProviderKlarityService(providerId, pendingSaveData?.providerKlarityService || [], pendingSaveData?.disableIds || []);
            } else {
                await updateUniprofile();
                result = await saveProviderKlarityService(pendingSaveData?.providerKlarityService || [], pendingSaveData?.disableIds || []);
            }
            if (!result.error) {
                //success
                const serviceIds = result?.data?.data;
                if (currentEditData && currentEditData[currentSettingServiceTypeInx - 1] && serviceIds) {
                    currentEditData[currentSettingServiceTypeInx - 1].savedValue = pendingSaveData?.providerKlarityService;
                    currentEditData[currentSettingServiceTypeInx - 1].savedValue?.forEach((item, inx) => {
                        if (serviceIds.length > inx) {
                            item.serviceId = serviceIds[inx];
                        }
                    });
                }
                setCurrentSettingServiceTypeInx(nextInx);
                const targetSpe = nextInx < currentEditData?.length ? currentEditData[nextInx]?.specialtyName : undefined;
                const withProgram = allPrograms.find((item) => item.specialtyName?.toLowerCase() === targetSpe?.toLowerCase());
                if (withProgram && !withProgram.enrolled) {
                    const detailResult = await getKlarityProgramDetail(withProgram.id);
                    if (!detailResult.error) {
                        const detail = detailResult?.data?.data;
                        console.log({
                            detail,
                        })
                        setConfigingProgram(detail);
                        set("currentSpeciality", targetSpe);
                        setCurrentStep(EModalMode.CONFIG_PROGRAM_NOTE);
                        scrollToTop();
                        setHasSaveAny(true);
                        setSaving(false);
                        return;
                    }
                }
                scrollToTop();
                setHasSaveAny(true);
            }
            setSaving(false);
        }
    };

    const handleNextForSelectService = async () => {
        const first = currentEditData && currentEditData.length > 0 ? currentEditData[0] : undefined;
        if (first) {
            const firstSpe = first?.specialtyName;
            const withProgram = allPrograms.find((item) => item.specialtyName?.toLowerCase() === firstSpe?.toLowerCase());

            if (withProgram && !withProgram.enrolled) {
                setFetchingProgram(true);
                const detailResult = await getKlarityProgramDetail(withProgram.id);
                if (!detailResult.error) {
                    const detail = detailResult?.data?.data;
                    setConfigingProgram(detail);
                    set("currentSpeciality", firstSpe);
                    setCurrentStep(EModalMode.CONFIG_PROGRAM_NOTE);
                    scrollToTop();
                    return;
                }
                setFetchingProgram(false);
            }
        }
        setCurrentStep(EModalMode.CONFIG_SERVICE);
        scrollToTop();
    }

    const handleSignProgram = async (submitData: TEnrollKlarityProgram) => {
        setEnrolling(true);
        const enrollResult = await enrollKlarityProgram(submitData);
        if (!enrollResult.error) {
            refetchProgram();
            if (getConfigProgramFromTable()) {
                setConfigProgramFromTable(false);
                setConfigingProgramId('');
                onBack?.();
                return;
            }
            setCurrentStep(EModalMode.CONFIG_SERVICE);
            scrollToTop();
        }
        setEnrolling(false);
    }

    return (
        <div className={s.wrap} id="layer-wrap">
            {currentStep === EModalMode.SELECT_SERVICE &&
                <SelectService
                    isLayer
                    onSelectedDataChange={
                        (data) => {
                            setSelectData(data);
                            onSelected?.(data);
                            set("speciality", data?.map((item) => item.specialtyName));
                        }}
                    otherData={otherData}
                    recommendData={recommendData}
                    onServerIdStateMapChange={setServerIdStateMap}
                    allowStates={allowStatesForService}
                />
            }
            {currentStep === EModalMode.CONFIG_SERVICE &&
                <ConfigService
                    isEdit={isEdit}
                    onHasNameErrorUpdate={setHasNameError}
                    onUpdate={setPendingSaveData}
                    serviceDatas={currentEditData}
                    errorMedicationFlag={errorMedicationFlag}
                    editingValue={editingValue}
                    serverIdStateMap={serverIdStateMap}
                    currentEditingStep={currentSettingServiceTypeInx}
                    isOps={isOps}
                    programList={allPrograms}
                    isLayer
                    providerId={providerId}
                    addressList={addressList}
                    allStatesFromService={allowStatesForService}
                    handleExploreProgram={() => {
                        setCurrentStep(EModalMode.CONFIG_PROGRAM_NOTE);
                        scrollToTop();
                    }}
                />
            }
            {
                currentStep === EModalMode.CONFIG_PROGRAM_NOTE &&
                <ProgramConfigNote
                    data={configingProgram}
                    isLayer
                />
            }
            {
                currentStep === EModalMode.SIGN_PROGRAM &&
                <SignProgram
                    onAgreeButtonClickFlag={onAgreeButtonClickFlag}
                    data={configingProgram}
                    allowStates={allowStatesForService}
                    onSuccessNext={handleSignProgram}
                />
            }
            <div className={`${s.footWrap} ${[EModalMode.CONFIG_PROGRAM_NOTE, EModalMode.CONFIG_PROGRAM, EModalMode.SIGN_PROGRAM, EModalMode.CONFIG_SERVICE].includes(currentStep) ? s.footWrapWhite : ''} ${isReviewStep ? s.footerInReview : ''}`}>
                {
                    currentStep === EModalMode.SELECT_SERVICE &&
                    <>
                        <Button
                            className={s.back}
                            onClick={() => {
                                onBack?.();
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            onClick={handleNextForSelectService}
                            type="primary"
                            className={s.next}
                            loading={fetchingProgram}
                            disabled={selectData.length === 0}
                        >
                            Next
                        </Button>
                    </>
                }
                {
                    currentStep === EModalMode.CONFIG_PROGRAM_NOTE &&
                    <>
                        {
                            getConfigProgramFromTable() &&
                            <Button
                                className={s.cancelFromTable}
                                onClick={() => {
                                    setConfigProgramFromTable(false);
                                    setConfigingProgramId('');
                                    onBack?.();
                                }}
                            >
                                Cancel
                            </Button>
                        }
                        {
                            !getConfigProgramFromTable() &&
                            <Button
                                className={s.skip}
                                onClick={() => {
                                    // setClickSkipWebinar(true);
                                    // handleOpenCalendarInModal();
                                    //handleOpenCalendar();
                                    setCurrentStep(EModalMode.CONFIG_SERVICE);
                                    scrollToTop();
                                }}
                            >
                                Skip
                            </Button>
                        }
                        <Button
                            className={s.back}
                            onClick={() => {
                                setClickSkipWebinar(true);
                                handleOpenCalendarInModal();
                                //handleOpenCalendar();
                                //setCurrentStep(EModalMode.CONFIG_SERVICE);
                                //scrollToTop();
                            }}
                        >
                            Learn more in our webinar
                        </Button>

                        <Button
                            onClick={() => {
                                setCurrentStep(EModalMode.SIGN_PROGRAM);
                                if (document.getElementById('ks-form-content')) {
                                    const offsetTop = document.getElementById('ks-form-content')!.scrollTop;
                                    if (offsetTop > 100) {
                                        setTimeout(() => {
                                            scrollToTop();
                                        }, 200)
                                    }
                                }
                            }}
                            type="primary"
                            className={s.next}
                        >
                            Enroll in the program
                        </Button>
                    </>
                }
                {
                    currentStep === EModalMode.SIGN_PROGRAM &&
                    <>
                        <Button
                            className={s.back}
                            onClick={() => {
                                setCurrentStep(EModalMode.CONFIG_PROGRAM_NOTE);
                                scrollToTop();
                            }}
                        >
                            Back
                        </Button>
                        {
                            !hasAnySupportState &&
                            <Button
                                onClick={() => {
                                    if (getConfigProgramFromTable()) {
                                        setConfigProgramFromTable(false);
                                        setConfigingProgramId('');
                                        onBack?.();
                                        return;
                                    }
                                    setCurrentStep(EModalMode.CONFIG_SERVICE);
                                    scrollToTop();
                                }}
                                className={s.next}>
                                Skip the program due to state restrictions
                            </Button>
                        }
                        {
                            hasAnySupportState &&
                            <Button
                                onClick={() => {
                                    setOnAgreeButtonClickFlag({});
                                }}
                                type="primary"
                                loading={enrolling}
                                className={s.next}
                            >
                                Agree to enroll
                            </Button>
                        }
                    </>
                }
                {/* {
                    currentStep === EModalMode.CONFIG_PROGRAM &&
                    <>
                        <Button
                            className={s.back}
                            onClick={() => {
                                setCurrentStep(EModalMode.CONFIG_SERVICE);
                                scrollToTop();
                            }}
                        >
                            Skip the program
                        </Button>
                        <Button
                            onClick={() => {
                                setCurrentStep(EModalMode.CONFIG_SERVICE);
                                scrollToTop();
                            }}
                            type="primary"
                            className={s.next}
                        >
                            Next
                        </Button>
                    </>
                } */}
                {
                    currentStep === EModalMode.CONFIG_SERVICE &&
                    <>
                        <div className={s.left}>
                        </div>
                        <div className={s.right}>
                            {
                                showSkip ?
                                    <Button
                                        style={{
                                            width: '49%'
                                        }}
                                        onClick={handleSkip}
                                    >
                                        Skip
                                    </Button> :
                                    <span></span>
                            }
                            {
                                !showSkip && isEdit &&
                                <Button
                                    style={{
                                        width: '49%'
                                    }}
                                    onClick={() => {
                                        onCancel?.(false);
                                    }}>
                                    Cancel
                                </Button>
                            }

                            <Button
                                style={{
                                    width: '49%'
                                }}
                                onClick={() => {
                                    if (!pendingSaveData?.allSeted && !hasDisableViewServiceCheck && !isEdit) {
                                        setShowTipsModal(true);
                                    } else {
                                        handleSaveService();
                                    }
                                }}
                                type="primary"
                                disabled={hasNameError}
                                loading={saving}
                            >
                                {
                                    hasNameError ?
                                        <Popover content={hasNameError ? <div className={s.toolWrap}>Please fix duplicate name error first</div> : null} trigger="hover">
                                            {currentSettingServiceTypeInx === currentEditData?.length ?
                                                'Save services' :
                                                'Save services'
                                            }
                                        </Popover>
                                        :
                                        <span>
                                            {currentSettingServiceTypeInx === currentEditData?.length ?
                                                'Save services' :
                                                'Save services'
                                            }
                                        </span>
                                }

                            </Button>
                        </div>
                    </>
                }
            </div>

            <Modal
                open={showTipsModal}
                footer={null}
                onCancel={() => {
                    setShowTipsModal(false);
                    setHasCheckNotShow(false);
                }}
            >
                <div>
                    <div className={s.confirmWrap}>
                        <div className={s.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M11.9995 9V11M11.9995 15H12.0095M5.07134 19H18.9277C20.4673 19 21.4296 17.3333 20.6598 16L13.7316 4C12.9618 2.66667 11.0373 2.66667 10.2675 4L3.33929 16C2.56949 17.3333 3.53174 19 5.07134 19Z" stroke="#D97706" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className={s.content}>
                            <h3>Proceed without adding all services?</h3>
                            <div className={s.des}>
                                Are you sure you want to proceed without adding all services? Providing more services gives users more options to choose from and helps you stay competitive.
                            </div>
                            <div className={s.check}>
                                <Checkbox
                                    checked={hasCheckNotShow}
                                    onChange={(e) => {
                                        setHasCheckNotShow(e.target.checked);
                                    }}
                                >
                                    Don’t show this again
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                    <div className={s.footer}>
                        <Button
                            onClick={() => {
                                setShowTipsModal(false);
                                setHasCheckNotShow(false);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                if (hasCheckNotShow) {
                                    setHasDisableViewServiceCheck('true');
                                }
                                setShowTipsModal(false);
                                handleSaveService();
                            }}
                            className={s.confirmBtn}
                        >
                            Proceed without adding services
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                okText="Set up single service sessions"
                onCancel={() => {
                    setShowWebinarModal(false);
                    // if (currentStep === EModalMode.CONFIG_PROGRAM_NOTE) {
                    //     setCurrentStep(EModalMode.CONFIG_SERVICE);
                    // }
                }}
                footer={null &&
                    <div className={s.sModalFooter}>
                        <div className={s.sFooterAction}>
                            <Button
                                onClick={() => {
                                    setShowWebinarModal(false);
                                    if (currentStep === EModalMode.CONFIG_PROGRAM_NOTE) {
                                        setCurrentStep(EModalMode.CONFIG_SERVICE);
                                    }
                                }}
                                type='primary'
                            >
                                Set up single service sessions
                            </Button>
                        </div>
                    </div>
                }
                width={1100}
                className={`${commonS.modalFixHeightWrap} ${s.modalWrap}`}
                open={showWebinarModal}
                title={"Discover more about the program in our next webinar"}
            >
                <div className={s.sModalWrap}>
                    <div className={s.content}>
                        <div className={s.calendlyWrapOut}>
                            <div className={s.des}>
                                Interested in learning more before deciding? Join our next webinar to discover how the Klarity Select Weight Loss Program can benefit your practice. Register now to secure your spot!
                            </div>
                            <div id="calendly" className={s.calendlyWrap}>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ServiceLayer;
