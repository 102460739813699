import React from 'react';
import AccessDeniedImg from 'assets/accessManagement/securityBg.svg';
import s from './s.module.less';

const AccessDeniedTabView = () => {
    return (
        <div className={s.wrap}>
            <div className={s.content}>
                <img src={AccessDeniedImg} />
                <div className={s.title}>Permission Denied</div>
                <div className={s.desc}>You do not have the permission to access this page, please contact the administrator for assistance.</div>
            </div>
        </div>
    );
};

export default AccessDeniedTabView;
