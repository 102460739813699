/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Modal, Button, Popover, Spin, message, Checkbox } from 'antd';
import s from './s.module.less';
import { KlarityService, ServerKlarityService, ServerMeta } from 'types/common';
import { saveProviderKlarityService } from 'api/klarityService';
import { saveOpsProviderKlarityService } from 'api/operation';
import commonS from 'styles/common.module.less';
import SelectService from '../SelectService';
import ConfigService from '../ConfigService';
import { EModalMode, ServerData } from '../../types';
import { PracitceAddressForm } from 'types/practiceFront';
import { updateStepForm } from 'api/applicationForm';
import { getHasDisableViewServiceCheck, setHasDisableViewServiceCheck } from 'utils/localstore';

type IProps = {
    show: boolean;
    onCancel: (hasChange: boolean) => void;
    recommendData?: KlarityService[];
    otherData?: KlarityService[];
    onSelected: (data: KlarityService[]) => void;
    mode?: EModalMode;
    allowStatesForService?: ServerMeta[];
    editData?: KlarityService[]; //under config
    fetchingEdit?: boolean;
    editingValue?: ServerKlarityService[];
    isEdit?: boolean;
    isOps?: boolean;
    // 运营端需要传当前操作的用户id
    providerId?: number;
    // showTypeCheckbox?: boolean;
    showEditButtons?: boolean;
    addressList?: PracitceAddressForm[];
};

const ServiceModal = (props: IProps) => {
    const { recommendData, otherData, editData, onCancel, show, onSelected, mode, allowStatesForService = [], addressList, fetchingEdit, editingValue, isEdit, isOps, providerId, showEditButtons } = props;
    const [currentStep, setCurrentStep] = React.useState<EModalMode>(mode || EModalMode.SELECT_SERVICE);
    const [selectData, setSelectData] = React.useState<KlarityService[]>([]);
    const [serverIdStateMap, setServerIdStateMap] = React.useState<Record<string, ServerMeta[]>>({});
    const [currentSettingServiceTypeInx, setCurrentSettingServiceTypeInx] = React.useState<number>(1);
    const [pendingSaveData, setPendingSaveData] = React.useState<ServerData>();
    const [hasSaveAny, setHasSaveAny] = React.useState<boolean>(false);
    const [hasNameError, setHasNameError] = React.useState<boolean>(false);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [showTipsModal, setShowTipsModal] = React.useState(false);
    const [hasCheckNotShow, setHasCheckNotShow] = React.useState(false);
    const hasDisableViewServiceCheck = getHasDisableViewServiceCheck();

    // console.log({
    //     pendingSaveData,
    // });

    const selectDataLength = selectData?.length;
    const currentEditData = isEdit ? editData : selectData;
    const showSkip = currentSettingServiceTypeInx < selectDataLength;

    React.useEffect(() => {
        if (mode) {
            setCurrentStep(mode);
        }
    }, [mode]);

    const handleSkip = () => {
        const nextInx = currentSettingServiceTypeInx + 1;
        setCurrentSettingServiceTypeInx(nextInx);
        scrollToTop();
        //onCancel?.(hasSaveAny);
    };

    const scrollToTop = () => {
        try {
            document.getElementsByClassName('ant-modal-body')[0].scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } catch (e) {
            console.error(e);
        }
    };

    const updateUniprofile = async () => {
        try {
            const submitData = {
                step: 'KLARITY_SERVICES',
            };

            const result = await updateStepForm(submitData);

            if (result?.error) {
                message.error(result.error);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleSave = async () => {
        if (!currentEditData) {
            return;
        }
        const nextInx = currentSettingServiceTypeInx + 1;
        const max = currentEditData.length + 1;
        if (nextInx === max) {
            //finish
            setSaving(true);
            let result: any;
            if (isOps && providerId) {
                result = await saveOpsProviderKlarityService(providerId, pendingSaveData?.providerKlarityService || [], pendingSaveData?.disableIds || []);
            } else {
                await updateUniprofile();
                result = await saveProviderKlarityService(pendingSaveData?.providerKlarityService || [], pendingSaveData?.disableIds || []);
            }

            if (!result.error) {
                //success
                onCancel?.(true);
            }
            setSaving(false);
        } else {
            //do save,
            setSaving(true);
            let result: any;
            if (isOps && providerId) {
                result = await saveOpsProviderKlarityService(providerId, pendingSaveData?.providerKlarityService || [], pendingSaveData?.disableIds || []);
            } else {
                await updateUniprofile();
                result = await saveProviderKlarityService(pendingSaveData?.providerKlarityService || [], pendingSaveData?.disableIds || []);
            }
            if (!result.error) {
                //success
                const serviceIds = result.data.data;
                if (currentEditData && currentEditData[currentSettingServiceTypeInx - 1] && serviceIds) {
                    currentEditData[currentSettingServiceTypeInx - 1].savedValue = pendingSaveData?.providerKlarityService;
                    currentEditData[currentSettingServiceTypeInx - 1].savedValue?.forEach((item, inx) => {
                        if (serviceIds.length > inx) {
                            item.serviceId = serviceIds[inx];
                        }
                    });
                }
                setCurrentSettingServiceTypeInx(nextInx);
                scrollToTop();
                setHasSaveAny(true);
            }
            setSaving(false);
        }
    };

    return (
        <>
            <Modal
                open={show}
                width="1224px"
                style={{
                    maxWidth: '86%',
                }}
                destroyOnClose
                onCancel={() => {
                    setCurrentStep(mode || EModalMode.SELECT_SERVICE);
                    setSelectData([]);
                    setCurrentSettingServiceTypeInx(1);
                    setServerIdStateMap({});
                    onCancel?.(hasSaveAny);
                }}
                cancelButtonProps={{
                    style: {
                        display: 'none',
                    },
                }}
                className={`${commonS.modalFixHeightWrap} ${commonS.modalFixSetedHeightWrap}`}
                footer={
                    <div className={s.footWrap}>
                        {
                            currentStep === EModalMode.SELECT_SERVICE &&
                            <>
                                <span />
                                <Button
                                    onClick={() => {
                                        setCurrentStep(EModalMode.CONFIG_SERVICE);
                                        scrollToTop();
                                    }}
                                    type="primary"
                                    disabled={selectData.length === 0}
                                >
                                    Select practice templates
                                </Button>
                            </>
                        }
                        {
                            currentStep === EModalMode.CONFIG_SERVICE &&
                            <>
                                <div className={s.left}>
                                    {/* {
                                    currentSettingServiceTypeInx > 1 && currentStep === EModalMode.CONFIG_SERVICE &&
                                    <Button
                                        onClick={() => {
                                            const pre = currentSettingServiceTypeInx - 1;
                                            setCurrentSettingServiceTypeInx(pre);
                                        }}
                                    >
                                        Back
                                    </Button>
                                } */}
                                </div>
                                <div className={s.right}>
                                    {
                                        showSkip &&
                                        <span
                                            onClick={handleSkip}
                                            className={s.skip}
                                        >
                                            Skip setup of this specialty
                                        </span>
                                    }
                                    <Button
                                        onClick={() => {
                                            if (!pendingSaveData?.allSeted && !hasDisableViewServiceCheck && !isEdit) {
                                                setShowTipsModal(true);
                                            } else {
                                                handleSave();
                                            }
                                        }}
                                        type="primary"
                                        disabled={hasNameError}
                                        loading={saving}
                                    >
                                        {
                                            hasNameError ?
                                                <Popover content={hasNameError ? <div className={s.toolWrap}>Please fix duplicate name error first</div> : null} trigger="hover">
                                                    {currentSettingServiceTypeInx === currentEditData?.length ?
                                                        'Save and finish' :
                                                        'Save and continue'
                                                    }
                                                </Popover>
                                                :
                                                <span>
                                                    {currentSettingServiceTypeInx === currentEditData?.length ?
                                                        'Save and finish' :
                                                        'Save and continue'
                                                    }
                                                </span>
                                        }

                                    </Button>
                                </div>
                            </>
                        }
                    </div>
                }
            >
                {currentStep === EModalMode.SELECT_SERVICE &&
                    <SelectService
                        onSelectedDataChange={
                            (data) => {
                                setSelectData(data);
                                onSelected?.(data);
                            }}
                        otherData={otherData}
                        recommendData={recommendData}
                        onServerIdStateMapChange={setServerIdStateMap}
                        allowStates={allowStatesForService}
                    />
                }
                {currentStep === EModalMode.CONFIG_SERVICE &&
                    <ConfigService
                        isEdit={isEdit}
                        onHasNameErrorUpdate={setHasNameError}
                        onUpdate={setPendingSaveData}
                        serviceDatas={currentEditData}
                        editingValue={editingValue}
                        serverIdStateMap={serverIdStateMap}
                        currentEditingStep={currentSettingServiceTypeInx}
                        isOps={isOps}
                        providerId={providerId}
                        addressList={addressList}
                        allStatesFromService={allowStatesForService}
                    />
                }
            </Modal>

            <Modal
                open={showTipsModal}
                footer={null}
                onCancel={() => {
                    setShowTipsModal(false);
                    setHasCheckNotShow(false);
                }}
            >
                <div>
                    <div className={s.confirmWrap}>
                        <div className={s.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M11.9995 9V11M11.9995 15H12.0095M5.07134 19H18.9277C20.4673 19 21.4296 17.3333 20.6598 16L13.7316 4C12.9618 2.66667 11.0373 2.66667 10.2675 4L3.33929 16C2.56949 17.3333 3.53174 19 5.07134 19Z" stroke="#D97706" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className={s.content}>
                            <h3>Proceed without adding all services?</h3>
                            <div className={s.des}>
                                Are you sure you want to proceed without adding all services? Providing more services gives users more options to choose from and helps you stay competitive.
                            </div>
                            <div className={s.check}>
                                <Checkbox
                                    checked={hasCheckNotShow}
                                    onChange={(e) => {
                                        setHasCheckNotShow(e.target.checked);
                                    }}
                                >
                                    Don’t show this again
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                    <div className={s.footer}>
                        <Button
                            onClick={() => {
                                setShowTipsModal(false);
                                setHasCheckNotShow(false);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                if (hasCheckNotShow) {
                                    setHasDisableViewServiceCheck('true');
                                }
                                setShowTipsModal(false);
                                handleSave();
                            }}
                            className={s.confirmBtn}
                        >
                            Proceed without adding services
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ServiceModal;
