import { Request } from '../request';
import { IproviderCreate, ProviderBasicInfo, IUpdatePasswordFormData, IProvderAgreementSignInput } from 'types/provider';
import { IntakeqForm, License, Physician, Service } from 'types/common';
import { IAddProfile } from 'types/reputation';
import { TSubscriptionType } from 'types/subscription';

export const providerCreate = (data: IproviderCreate) =>
    Request.post({ url: '/schedule-server/provider/create', params: data, disableErrorNote: true });

export const providerSendVerification = (email: string) => Request.post({ url: '/schedule-server/provider/getByEmail', params: { email } });
export const providerSendResume = (email: string) => Request.post({ url: '/schedule-server/provider/resume/', params: { email }, disableErrorNote: true });

export const providerFetchByEmail = (email: string) =>
    Request.post({ url: '/schedule-server/provider/getByEmail', params: { email } });

export const providerFetch = (id: number) =>
    Request.get({ url: `/schedule-server/provider/get/${id}` });

export const providerBasicInfoUpdate = (data: Partial<ProviderBasicInfo>, id:number) =>
    Request.put<Partial<ProviderBasicInfo>>({ url: `/schedule-server/provider/updateBasicInfo/${id}`, params: data, disableErrorNote: true });

//use state, type & secondType as key
export const providerLicenseUpdate = (data: Partial<License>, id:number) =>
    Request.put<Partial<License>>({ url: `/schedule-server/license/updateByProviderId/${id}`, params: data, disableErrorNote: true });

export const providerPhysicianUpdate = (data: Partial<Physician>, id:number) =>
    Request.put<Partial<Physician>>({ url: `/schedule-server/physician/saveByProviderId/${id}`, params: data, disableErrorNote: true });

export const providerLicenseFetch = (id: number) =>
    Request.get({ url: `/schedule-server/license/listByProviderId/${id}` });

export const providerPhysicianFetch = (id: number) =>
    Request.get({ url: `/schedule-server/physician/getByProviderId/${id}` });

export const getIntakeqForm = () =>
    Request.get<IntakeqForm[]>({
        url: '/schedule-server/intakeq/form/template/list',
        disableErrorNote: true,
    });

export const providerChannelServiceFetch = () =>
    Request.get({ url: '/schedule-server/provider/channel/service/list' });

export const surveyChannelUsing = (channelIds: number[]) =>
    Request.post({ url: '/schedule-server/provider/channel/listed/save', params: channelIds });

export const surveyCareMost = (careList: string[]) =>
    Request.post({ url: '/schedule-server/provider/channel/care/save', params: careList });

export const surveyComplete = () =>
    Request.put({ url: '/schedule-server/provider/channel/guide/complete' });

export const getChannelHome = () =>
    Request.get({ url: '/schedule-server/provider/channel/home' });

export const providerListedChannelFetch = (providerId:number) =>
    Request.get({ url: `/schedule-server/openapi/channel/list/${providerId}` });

export const providerBaseServiceList = (providerId: number) => Request.get<Service[]>({ url: `/schedule-server/providerService/listByProviderId/?providerId=${providerId}` });

export const providerChannelServiceList = (providerId: number, channelId: number) => Request.get<Service[]>({ url: `/schedule-server/openapi/channel/service/list/${providerId}/${channelId}` });

export const providerServiceTemplateList = () => Request.get<Service[]>({ url: '/schedule-server/serviceTemplate/list' });

export const getHomeInfo = () =>
    Request.get({ url: '/schedule-server/provider/home' });

export const getHomeConfiglList = () => Request.get({ url: '/schedule-server/homeConfig/list' });

export const updateProviderPassword = (data:IUpdatePasswordFormData) => Request.post({ url: '/schedule-server/provider/saveEmailPassword', params: data });

export const getProviderEmailNotify = () =>
    Request.get({ url: '/schedule-server/provider/getEmailNotify' });

//"jim.a@gmail.com,perter.b@gmal.com"
export const updateProviderEmailNotif = (emailNotify:string) => Request.post({ url: '/schedule-server/provider/updateEmailNotify', params: { emailNotify } });

export const providerSeoCityUpdate = (data: string[], state:string) =>
    Request.put({ url: '/schedule-server/provider/seo/city/update', params: { seoCity: JSON.stringify(data), state } });

export const providerSeoCountryUpdate = (data: string[], state:string) =>
    Request.put({ url: '/schedule-server/provider/seo/country/update', params: { seoCountry: JSON.stringify(data), state } });

export const providerSeoLandmarkUpdate = (data: string[], state:string) =>
    Request.put({ url: '/schedule-server/provider/seo/landmark/update', params: { seoLandmark: JSON.stringify(data), state } });

export const providerSeoZipUpdate = (data: string[], state:string) =>
    Request.put({ url: '/schedule-server/provider/seo/zip/update', params: { seoZip: JSON.stringify(data), state } });

export const providerRemoveSeo = () =>
    Request.delete({ url: '/schedule-server/provider/app/seo/remove' });

export const providerAddSeo = () =>
    Request.post({ url: '/schedule-server/provider/app/seo/add' });

export const getLocalSeoInfoByZipCode = (zipCode: string) =>
    Request.get({ url: `/schedule-server/provider/seo/google/${zipCode}` });

export const getSuggestCityByInput = (input: string) =>
    Request.get({ url: `/schedule-server/provider/seo/google/city/${input}` });

export const replyFeedback = (feedbackId: number, item:{ subject: string, message: string }) =>
    Request.post({ url: `/schedule-server/provider/reply/${feedbackId}`, params: item });

export const reputationSendSurvey = (patientId: number[]) =>
    Request.post({ url: '/schedule-server/reputation/manage/sendSurvey', params: { patientId } });

export const reputationAddProfile = (id: number, data: IAddProfile) => Request.put({ url: `/schedule-server/provider/update/${id}`, params: data });

export const getProviderAgreementInfo = (plan: string) => Request.get({ url: `/schedule-server/payPlan/agreement?type=${plan}` });

export const signProviderAgreement = (data: IProvderAgreementSignInput) => Request.post({ url: '/schedule-server/provider/contract/signed', params: data });

export const saveNoPrescribeLicense = () => Request.put({ url: '/schedule-server/license/dea/not/prescribe' });
