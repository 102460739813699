// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__textarea--jQ3so {
  height: 162px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ApplicationFormComponents/Practice/component/AutoLineBreakTextArea/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ","sourcesContent":[".textarea {\n    height: 162px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `s-module__textarea--jQ3so`
};
export default ___CSS_LOADER_EXPORT___;
