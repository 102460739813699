import { Request } from '../request';

export const getPayPlanList = () =>
    Request.get({ url: '/schedule-server/payPlan/list' });

export const getPayLink = (planId: number, jumpMetaData?: Record<string, string>, promotionCode?: string) => {
    const data: any = { planId };
    if (promotionCode) {
        data.promotionCode = promotionCode;
    }
    if (jumpMetaData) {
        data.jumpMetaData = jumpMetaData;
    }
    return Request.post({ url: '/schedule-server/payPlan/pay', params: data });
}


export const getProviderPlan = () =>
    Request.get({ url: '/schedule-server/payPlan/getProviderPlan' });

export const getProviderPaidPlanDetails = () =>
    Request.get({ url: '/schedule-server/provider/stat' });

export const getChannelPayLink = (channelId: number) =>
    Request.post({ url: '/schedule-server/payPlan/payChannel', params: { channelId } });

export const getBillingPortalLink = () =>
    Request.get({ url: '/schedule-server/payPlan/billPortal' });

export const getProviderAgreement = () => Request.get({ url: '/schedule-server/payPlan/contract' });

export const checkCoupon = (promotionCode: string) => Request.get({ url: '/schedule-server/payment/getCheckByPromotionCode', params: { promotionCode } });