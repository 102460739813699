import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Select } from 'antd';
import { ETimeTag, ITime, ITimePair } from 'types/appointmentTime';
import { BaseOptionType } from 'antd/es/select';
import { transTimeStringToNumber, getTheClosestTimeObj } from 'utils/common';

interface TimeSlotProps {
    showError?: boolean;
    index: number;
    times: ITimePair[];
    time: ITimePair;
    timeOptions?: BaseOptionType[];
    onSelect: (v: ITime, index: number) => void;
    onDelete: (i: number) => void;
}

const TimeSlot: React.FC<TimeSlotProps> = ({
    showError,
    index,
    times,
    time,
    timeOptions,
    onDelete,
    onSelect,
}: TimeSlotProps) => {
    const [endTimeOptions, setEndTimeOptions] = useState<BaseOptionType[]>();

    useEffect(() => {
        const startTime = time.start;

        let options = timeOptions;

        if (startTime) {
            // 将早于start的项置为disable
            options = timeOptions?.map((option) => {
                const startTimeNumber = transTimeStringToNumber(startTime);
                const optionTimeNumber = transTimeStringToNumber(option.value);

                return {
                    ...option,
                    // disabled: option.disabled || startTimeNumber >= optionTimeNumber,
                    disabled: startTimeNumber >= optionTimeNumber,
                };
            });

            // 获取离start最近且晚于start的已存在时间段，用于限制end的可选时间，避免出现时间线重叠
            const closestTimeObj = getTheClosestTimeObj(startTime, times);

            if (closestTimeObj) {
                options = options?.map((option) => {
                    const startTimeNumber = transTimeStringToNumber(closestTimeObj.start);
                    const optionTimeNumber = transTimeStringToNumber(option.value);

                    return {
                        ...option,
                        disabled: option.disabled || startTimeNumber < optionTimeNumber,
                    };
                });
            }

            setEndTimeOptions(options);
        }
    }, [time, timeOptions, times]);

    const handleTimeSelect = useCallback((e: string, tag: ETimeTag) => {
        const timeItem = {
            value: e,
            tag,
        };

        onSelect(timeItem, index);
    }, [index, onSelect]);

    return (
        <div className={s.item}>
            <div className={s.slot}>
                <Select status={(showError && !time.start) ? 'error' : undefined} allowClear className={s.timeSelect} placeholder="Start Time" options={timeOptions} value={time.start} onChange={(v: string) => handleTimeSelect(v, ETimeTag.START)} />
                <span className={s.connect}>to</span>
                <Select status={(showError && !time.end) ? 'error' : undefined} allowClear className={s.timeSelect} disabled={!time.start} placeholder="End Time" options={endTimeOptions} value={time.end} onChange={(v: string) => handleTimeSelect(v, ETimeTag.END)} />
            </div>
            <div className={s.delete} onClick={() => onDelete(index)} />
        </div>
    );
};

export default TimeSlot;
