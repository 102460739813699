import React, { useMemo } from 'react';
import s from './s.module.less';
import { IEFaxInfo } from 'types/ehr';

interface IProps {
    data: IEFaxInfo;
}

const EFax = ({
    data,
}: IProps) => {
    const { eFax, eFaxOption, kiwiEFax } = data;

    const eFaxNumber = useMemo(() => {
        let num;
        if (eFaxOption === '1') {
            num = kiwiEFax;
        } else if (eFaxOption === '2' || eFaxOption === '3') {
            num = eFax;
        }

        return num || '-';
    }, [eFax, eFaxOption, kiwiEFax]);

    const otherInfo = useMemo(() => {
        const source = eFaxOption === '1' ? 'Kiwi EHR' : 'Provider';
        if (eFaxOption === '1') {
            return {
                source,
                transfer: null,
            };
        }
        if (eFaxOption === '2') {
            return {
                source,
                transfer: 'Yes',
            };
        }
        if (eFaxOption === '3') {
            return {
                source,
                transfer: 'No',
            };
        }
    }, [eFaxOption]);
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>e-Fax</div>
            </div>
            <div className={s.body}>
                <div className={s.listItem}>
                    <div className={s.row}>
                        <div className={s.item}>
                            <div className={s.label}>e-Fax source</div>
                            <div className={s.value}>{otherInfo?.source}</div>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.item}>
                            <div className={s.label}>e-Fax number</div>
                            <div className={s.value}>{eFaxNumber}</div>
                        </div>
                    </div>
                    {
                        otherInfo?.transfer && (
                            <div className={s.row}>
                                <div className={s.item}>
                                    <div className={s.label}>Transfer to Kiwi EHR</div>
                                    <div className={s.value}>{otherInfo?.transfer}</div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default EFax;
