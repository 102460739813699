import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Form, Button, Input, Switch, Modal, message } from 'antd';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { AdditionFieldEnum, ProviderChannelAdditional } from 'types/channelApplicationProvider';
import { StepValue } from 'pages/CreateUniProfilePage/types';
// import usePayoutOptions from './hooks/usePayoutOptions';
import { useNavigate } from 'react-router-dom';
import { EMPTY_PLACEHOLDER } from 'constants/common';
// import Star from 'components/Star';
import Membership from './components/Membership';
import Awards from './components/Awards';
import Publications from './components/Publications';
import globalFormsEditStatusStore from 'store/ChannelApplicationProvider/FormsEditStatus';

const formNames = {
    MEMBERSHIP: 'membership',
    AWRADS: 'AWRADS',
    PUBLICATIONS: 'Publications',
};

interface IProps {
    // providerId?: number,
    // reviews?: Array<IReviews>,
    // enableReview?: boolean;
    allStartEditing?: boolean;
    initFormValue: ProviderChannelAdditional;
    editable?: boolean;
    showEditButtons: boolean;
    onSubmit: (value: ProviderChannelAdditional, step: StepValue) => void;
    // onNext?: (value: ProviderChannelAdditional) => void;
    // onEditableChange?: (enable: boolean, step: StepValue, callback: () => void) => void,
    refetch?: () => void;
    // onShowReviewModal?: () => void
}

const AdditionalInformation = forwardRef(({
    // providerId,
    // reviews,
    // enableReview = false,
    allStartEditing = false,
    initFormValue,
    // editable = false,
    showEditButtons = false,
    onSubmit,
    // onNext,
    // onEditableChange,
    refetch,
    // onShowReviewModal,
}: IProps, ref) => {
    const navigate = useNavigate();
    const [additionalInformationForm] = Form.useForm<ProviderChannelAdditional>();
    const [showLeaveModal, setShowLeaveModal] = useState(false);
    const [formEditStatus, setFormEditStatus] = useState<Record<string, boolean>>({
        [formNames.MEMBERSHIP]: allStartEditing,
        [formNames.AWRADS]: allStartEditing,
        [formNames.PUBLICATIONS]: allStartEditing,
    });
    // const payoutOptions = usePayoutOptions(providerId);
    const [getGlobalEditStatus, setGloalEditStatus] = globalFormsEditStatusStore.useStore();
    const globalFormsEditStatus = getGlobalEditStatus('globalEditStatus');

    const awardsRef = useRef();
    const memberRef = useRef();
    const publicationsRef = useRef();

    useEffect(() => {
        if (initFormValue) {
            additionalInformationForm.setFieldsValue(initFormValue);
        }
    }, [initFormValue, additionalInformationForm]);

    const handleEditStatusChange = useCallback((v: boolean, formName: string) => {
        if (!globalFormsEditStatus || !v) {
            formEditStatus[formName] = v;

            setFormEditStatus({ ...formEditStatus });
            setGloalEditStatus('globalEditStatus', v);
        } else {
            message.error('Please save your changes before proceeding');
        }
    }, [formEditStatus, globalFormsEditStatus]);

    const handleSubmitRequest = useCallback(async (formName: string, formValue) => {
        const submitRes = await onSubmit(formValue, StepValue.ADDITIONAL_INFORMATION);

        if (!submitRes?.error) {
            if (formName) {
                handleEditStatusChange(false, formName);
            } else {
                setGloalEditStatus('globalEditStatus', false);
            }
            refetch?.();
        } else {
            message.error('save failed!');
            console.error(submitRes);
        }

        return submitRes;
    }, [handleEditStatusChange, onSubmit, refetch]);

    const handleSubmitAllForms = useCallback(async () => {
        const awardsFormData = await awardsRef.current?.getFromValues();
        const memberFormData = await memberRef.current?.getFromValues();
        const publicationsFormData = await publicationsRef.current?.getFromValues();

        let submitRes = {
            error: true,
        };

        if (awardsFormData && memberFormData && publicationsFormData) {
            if (
                (awardsFormData && !awardsFormData.errorFields) &&
                (memberFormData && !memberFormData.errorFields) &&
                (publicationsFormData && !publicationsFormData.errorFields)
            ) {
                const recognitonData = {
                    ...awardsFormData,
                    ...memberFormData,
                    ...publicationsFormData,
                };

                submitRes = await handleSubmitRequest('', recognitonData);
            }
        }

        return submitRes;
    }, [handleSubmitRequest]);

    useImperativeHandle(ref, () => ({
        submitForm: handleSubmitAllForms,
    }));

    const handleHideLeaveModal = useCallback(() => setShowLeaveModal(false), []);

    const handleLeave = useCallback(() => {
        navigate('/dashboard/appointment-setting');
    }, [navigate]);

    return (
        <div className={s.module}>
            <Modal
                title="Leave the page?"
                centered
                open={showLeaveModal}
                okText="Leave the page"
                onOk={(handleLeave)}
                onCancel={handleHideLeaveModal}
                width={543}
            >
                <p className={s.modalContent}>You have made changes that have not been saved. Are you sure you want to leave the page now? You will lose all the progress on this page.</p>
            </Modal>
            <div className={s.wrap}>
                <Membership
                    showEditButtons={showEditButtons}
                    ref={memberRef}
                    formName={formNames.MEMBERSHIP}
                    initFormValue={initFormValue}
                    enable={formEditStatus[formNames.MEMBERSHIP]}
                    onEditStatusChange={handleEditStatusChange}
                    onUpdateService={handleSubmitRequest}
                />
                <Awards
                    showEditButtons={showEditButtons}
                    ref={awardsRef}
                    formName={formNames.AWRADS}
                    initFormValue={initFormValue}
                    enable={formEditStatus[formNames.AWRADS]}
                    onEditStatusChange={handleEditStatusChange}
                    onUpdateService={handleSubmitRequest}
                />
                <Publications
                    showEditButtons={showEditButtons}
                    ref={publicationsRef}
                    formName={formNames.PUBLICATIONS}
                    initFormValue={initFormValue}
                    enable={formEditStatus[formNames.PUBLICATIONS]}
                    onEditStatusChange={handleEditStatusChange}
                    onUpdateService={handleSubmitRequest}
                />
            </div>
        </div>
    );
});

export default AdditionalInformation;
