import { IKlarityProgramSaveInput, IKlarityProgramPackageInput, IAddNUpdateKlarityServiceSessionInput, IAddNUpdateKlarityServiceMedicationInput } from 'types/programs';
import { Request } from '../request';

export const getKlarityProgamesList = () => Request.get({ url: '/admin-server/klarity/program/list' });

export const getKlarityProgramDetail = (programId: number) => Request.get({ url: `/admin-server/klarity/program/getByIdInfo?id=${programId}` });

export const getKlarityProgramPackageDetail = (programId: number) => Request.get({ url: `/admin-server/klarity/program/package/getById?id=${programId}` });

export const saveNUpdatePackage = (data: IKlarityProgramPackageInput) => Request.post({ url: '/admin-server/klarity/program/package/saveOrUpdate', params: data });

export const saveNUpdateProgram = (data: IKlarityProgramSaveInput) => {
    if (data.id) {
        return Request.post({ url: '/admin-server/klarity/program/update', params: data });
    }

    return Request.post({ url: '/admin-server/klarity/program/add', params: data })
};

export const activeProgramStatus = (programId: number) => Request.put({ url: `/admin-server/klarity/program/action?id=${programId}` });

export const getSessionList = (programId: number) => Request.get({ url: `/admin-server/klarity/program/service/session/list/${programId}` });

export const getMedicationList = () => Request.get({ url: `/admin-server/klarity/medication/list` });

export const addKlarityServiceSession = (data: IAddNUpdateKlarityServiceSessionInput) => Request.post({ url: '/admin-server/klarity/program/service/session/add', params: data });

export const updateKlarityServiceSession = (data: IAddNUpdateKlarityServiceSessionInput) => Request.post({ url: '/admin-server/klarity/program/service/session/update', params: data });

export const addNUpdateKlarityMedication = (data: IAddNUpdateKlarityServiceMedicationInput) => Request.post({ url: '/admin-server/klarity/medication/saveOrUpdate', params: data });

export const getAllTags = () => Request.get({ url: '/admin-server/klarity/program/tag/listAll' });

export const removePackageById = (packageId: number) => Request.put({ url: `/admin-server/klarity/program/package/remove?id=${packageId}` });

export const getProgramsByProviderId = (providerId: number) => Request.get({ url: `/admin-server/provider/program/enroll/list/${providerId}` });

export const updateProgramDisplayStatus = (programId: number, providerId: number, status: "ACTIVE"|"INACTIVE") => Request.put({ url: `/admin-server/provider/program/display/${providerId}/${programId}/${status}` });

export const getKlarityMedicationList = () => Request.get({ url: '/admin-server/klarity/medication/listAll' });

export const getKlaritySessionList = () => Request.get({ url: '/admin-server/klarity/program/service/session/listAll' });
