import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import { ChannelPayPlanTypeEnum, klarityPlanLevel } from 'types/channel';
import { Channel } from 'types/common';
import { Button, Popover } from 'antd';
import { openKlaritySubscriptionModal } from 'utils/globalLayerControl';
import ChannelStatus from 'components/ChannelStatus';

interface IProps {
    item: Channel,
    checked: boolean,
    disable: boolean,
    onSelectChange: (value: boolean) => void,
    showTag?: boolean,
    limitText?: string,
    locked?: boolean, //用于klarity plan channel
    showStatus?: boolean,
    listStatus?: string,
}

const ChannelCard = ({
    locked,
    showTag,
    item,
    checked,
    disable,
    onSelectChange,
    limitText,
    showStatus,
    listStatus,
}: IProps) => {
    const {
        payPlanType,
        name = 'Google',
        logo = 'https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/a5363e29-0a52-45ac-a4a1-6aa02d551367.png',
        intro = 'A mental health platform that connects directly to Kiwi Health. It is the easiest platform to bring in a large volume of new patients with a strong care coordination and patient support team.',
    } = item;
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(!!checked);
    }, [checked]);

    const handleCheck = () => {
        if (!disable && !locked) {
            const value = !isChecked;

            setIsChecked?.(value);
            onSelectChange?.(value);
        }
    };

    let tagRender = {
        cls: s.free,
        text: 'Free to start',
    };
    if (payPlanType === ChannelPayPlanTypeEnum.PAID) {
        tagRender = {
            cls: s.paid,
            text: 'In paid plan',
        };
    } else if (payPlanType === ChannelPayPlanTypeEnum.PREMIUM) {
        tagRender = {
            cls: s.premium,
            text: 'In premium',
        };
    }
    return (
        <div className={`${s.card} ${disable && s.disable} ${isChecked && s.checked}`} onClick={handleCheck}>
            <div className={s.topBox}>
                <div className={s.header}>
                    <div className={s.nameBox}>
                        <img className={s.logo} src={logo} />
                        <div className={s.channelName}>{name}</div>
                    </div>
                    {
                        disable ?
                            <Popover content={<div style={{ maxWidth: '300px' }}>{limitText || 'Channel limit reached'}</div>} trigger="hover"><div className={s.disableIcon} /></Popover> :
                            (!locked && <div className={`${s.checkbox} ${isChecked ? s.checked : ''}`} />)
                    }
                </div>
                <div className={s.content}>{intro}</div>
            </div>
            { !disable && locked && (
                <Popover
                    placement="bottomLeft"
                    content={
                        <div style={{ maxWidth: '350px', padding: '26px 32px' }}>
                            <div className={s.popoverTitle}>Upgrade to unlock the channel</div>
                            <p className={s.popoverContent}>This channel is only available to Klarity Plus Members.</p>
                            <Button type="primary" onClick={() => openKlaritySubscriptionModal()}>Upgrade to unlock</Button>
                        </div>
                    }
                >
                    <div className={s.lockedMask}>
                        <div className={s.lockIcon} />
                    </div>
                    <div className={s.popoverEl} />
                </Popover>
            )}
            <div className={s.bottomBox}>
                {
                    showStatus && !locked && (
                        <div className={s.detail}>
                            <span className={s.label}>Status: </span>
                            <ChannelStatus showDot status={listStatus} />
                        </div>
                    )
                }
                {showTag && tagRender && <div className={`${s.tag} ${tagRender?.cls}`}>{tagRender?.text}</div> }
                {
                    locked && (
                        <div className={s.priceBox}>
                            <div className={`${s.originalPrice} ${locked ? '' : s.cancelation}`}>Price: $30/month</div>
                            <div className={s.price}>Free in { locked ? `Klarity ${klarityPlanLevel[item.klarityPlanLevel - 1]} plan` : 'your plan' }!</div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default ChannelCard;
