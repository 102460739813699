import React from 'react';
import { Button, ButtonProps, message } from 'antd';
import { getBillingPortalLink } from 'api/subscription';
import { ExportOutlined } from '@ant-design/icons';

type IProps = ButtonProps & {
    text?: string;
};

const BillingPortalButton = (props: IProps) => {
    const { text = 'Billing and invoice details', ...reset } = props;
    const [loading, setLoading] = React.useState(false);
    return (
        <Button
            type="primary"
            {...reset}
            loading={loading}
            onClick={async () => {
                setLoading(true);
                const result = await getBillingPortalLink();
                if (!result.error) {
                    const link = result.data.data;
                    if (link) {
                        window.open(link, '_blank');
                    } else {
                        message.error('Customer billing portal is for paid user only.');
                    }
                }

                setLoading(false);
            }}
        >
            {text}
            <ExportOutlined />
        </Button>
    );
};

export default BillingPortalButton;
