import { Request } from '../request';

export const getStripeSession = (linkUuid: string, planId: number) =>
    Request.post({ url: '/schedule-server/openapi/sales/contract/link/checkout/session/generate',
        params: {
            linkUuid,
            planId,
        } });

export const getStripePaymentInfo = () => Request.get({ url: '/schedule-server/provider/getStripePaymentInfo' });
