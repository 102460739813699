// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__block--HbAf7 {
  margin-bottom: 24px;
}
.s-module__modalContent--q1H4c {
  padding: 32px 24px;
  font-family: 'Inter';
  font-style: normal;
}
.s-module__modalContent--q1H4c .s-module__modalContentText--x8jh7 {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--gray-900);
}
.s-module__modalContent--q1H4c .s-module__modalContentExp--JWl9e {
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: var(--gray-500);
}
.s-module__modalContent--q1H4c .s-module__modalContentExp--JWl9e a {
  color: var(--primary-color);
  cursor: pointer;
}
.s-module__modalContent--q1H4c .s-module__editButtons--pWGoe {
  display: flex;
}
.s-module__modalContent--q1H4c .s-module__editButtons--pWGoe button:first-child {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ApplicationFormComponents/Preview/s.module.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAEA;EACI,kBAAA;EAEA,oBAAA;EACA,kBAAA;AADJ;AAHA;EAOQ,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AADR;AAVA;EAeQ,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AAFR;AAjBA;EAsBY,2BAAA;EACA,eAAA;AAFZ;AArBA;EA4BQ,aAAA;AAJR;AAOY;EACI,iBAAA;AALhB","sourcesContent":[".block {\n    margin-bottom: 24px;\n}\n\n.modalContent {\n    padding: 32px 24px;\n\n    font-family: 'Inter';\n    font-style: normal;\n\n    .modalContentText {\n        margin-bottom: 16px;\n        font-weight: 400;\n        font-size: 16px;\n        line-height: 28px;\n        color: var(--gray-900);\n    }\n\n    .modalContentExp {\n        margin-top: 16px;\n        font-weight: 500;\n        font-size: 14px;\n        line-height: 28px;\n        color: var(--gray-500);\n\n        a {\n            color: var(--primary-color);\n            cursor: pointer;\n        }\n    }\n\n    .editButtons {\n        display: flex;\n\n        button {\n            &:first-child {\n                margin-right: 8px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `s-module__block--HbAf7`,
	"modalContent": `s-module__modalContent--q1H4c`,
	"modalContentText": `s-module__modalContentText--x8jh7`,
	"modalContentExp": `s-module__modalContentExp--JWl9e`,
	"editButtons": `s-module__editButtons--pWGoe`
};
export default ___CSS_LOADER_EXPORT___;
