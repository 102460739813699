import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Button, Input, Checkbox, DatePicker, Select, message } from 'antd';
import { EStep, StepValue } from 'pages/CreateUniProfilePage/types';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { ProviderChannelEducation, EducationFieldEnum, IProfileFormSubmit } from 'types/channelApplicationProvider';
import PhotoList from 'components/PhotoList';
import dayjs from 'dayjs';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import ExperiencePlaceholder from 'assets/common/experiencePlaceholder.svg';
import { EMPTY_PLACEHOLDER } from 'constants/common';

// interface IFormData {
//     providerEducationList: Array<ProviderChannelEducation>
// }

type IFormData = Pick<IProfileFormSubmit, 'providerEducationList'>;
interface IProps {
    initFormValue: Array<ProviderChannelEducation>,
    editable?: boolean,
    showEditButtons: boolean,
    onSubmit: (value: IFormData, step: StepValue) => void,
    onNext?: (value: IFormData) => void,
    onEditableChange?: (enable: boolean, step: StepValue, callback: () => void) => void,
}

const Education = ({
    initFormValue,
    editable = false,
    showEditButtons = false,
    onSubmit,
    onNext,
    onEditableChange,
}: IProps) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [degreeOptions] = useGetSettingData(EnumFields.DEGREE);
    const [enable, setEnable] = useState(!!editable);
    const [educationForm] = Form.useForm<IFormData>();
    const [update, forceUpdate] = useState({});

    useEffect(() => {
        if (initFormValue) {
            educationForm.setFieldsValue({
                providerEducationList: initFormValue,
            });
        }
    }, [initFormValue, educationForm]);

    useEffect(() => {
        setEnable(editable);
    }, [editable]);

    const handleEnableStatusChange = useCallback((status: boolean) => {
        if (typeof onEditableChange === 'function') {
            onEditableChange(status, StepValue.EDUCATION, () => {
                setEnable(status);
            });
        }
    }, [onEditableChange]);

    const formatDate = useCallback((day: string) => dayjs(day).format('YYYY-MM'), []);

    const handleSubmit = useCallback(() => {
        educationForm.validateFields().then((values) => {
            if (typeof onSubmit === 'function') {
                onSubmit(values, StepValue.EDUCATION);
            }
        }).catch((e) => {
            console.error(e);
        });
    }, [educationForm, onSubmit]);

    const checkAddable = useCallback((add: (defaultValue?: any, insertIndex?: number | undefined) => void) => {
        const data = educationForm.getFieldsValue();
        const noEmptyEducation = !data.providerEducationList || data.providerEducationList.every((item) => !!item && Object.values(item).some((v) => !!v));

        if (noEmptyEducation) {
            add();
        } else {
            messageApi.error('There\'s already an empty education form !');
        }
    }, [educationForm, messageApi]);

    const renderEditButtons = useMemo(() => {
        if (showEditButtons) {
            return !enable ? (
                <Button
                    onClick={() => handleEnableStatusChange(true)}
                >
                    Edit
                </Button>
            ) : (
                <div className={s.editButtons}>
                    <Button
                        onClick={() => handleEnableStatusChange(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                    >
                        Save changes
                    </Button>
                </div>
            );
        }

        return null;
    }, [enable, handleEnableStatusChange, handleSubmit, showEditButtons]);

    const renderReviewEducationList = useMemo(() => {
        if (!initFormValue || initFormValue.length === 0) {
            return <div style={{ marginTop: 16, marginBottom: 16 }}>{EMPTY_PLACEHOLDER}</div>;
        }
        return initFormValue?.map((item, index) => {
            const {
                schoolName,
                major,
                degree,
                startDate,
                endDate,
                specialties,
                schoolLogo,
                currently,
                // certificates,
                // schoolLogo,
                // currently,
            } = item;
            return (
                <div key={index} className={s.row}>
                    <div className={s.education}>
                        <div className={s.logoBox}>
                            <div className={s.logo} style={{ backgroundImage: schoolLogo ? `url(${schoolLogo})` : `url(${ExperiencePlaceholder})` }} />
                        </div>
                        <div className={s.intro}>
                            <div className={s.name}>{schoolName}</div>
                            <div className={s.title}>{degree} of {major}{specialties ? ` - ${specialties}` : ''}</div>
                            {
                                startDate &&
                                <div className={s.timeloc}>
                                    {
                                        currently ?
                                            <>
                                                <span>{formatDate(startDate)}</span>
                                                <span>-</span>
                                                <span>Present</span>
                                            </>
                                            :
                                            <>
                                                <span>{formatDate(startDate)}</span>
                                                <span>-</span>
                                                <span>{formatDate(endDate)}</span>
                                            </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        });
    }, [formatDate, initFormValue]);

    const renderEducationList = useMemo(() => {
        return (
            <Form.List name="providerEducationList">
                {
                    (fields, { add, remove }) => {
                        const additionalButtonText = fields.length === 0 ? '+ Add education' : '+ Add another education';
                        return (
                            <>
                                {
                                    fields.map(({ key, name, ...restField }, index) => {
                                        const currentEducation = educationForm.getFieldValue(['providerEducationList', name, EducationFieldEnum.IS_PURSUING_DEGREE]);
                                        return (
                                            <div key={key} className={s.additionalBox}>
                                                <div
                                                    id={`Education_${index + 1}`}
                                                    style={{
                                                        position: 'relative',
                                                        top: '-120px',
                                                    }}
                                                />
                                                <div className={s.labelBox}>
                                                    <div className={`${s.label} ${s.required} ${s.third}`}>{`Education ${index + 1}`}</div>
                                                    <Button onClick={() => remove(index)}>Remove</Button>
                                                </div>
                                                <div className={s.additionalForm}>
                                                    <div className={s.formBody}>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="School name"
                                                                    name={[name, EducationFieldEnum.SCHOOL_NAME]}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: 'School name is a required field' },
                                                                    ]}
                                                                >
                                                                    <Input type="text" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Major"
                                                                    name={[name, EducationFieldEnum.MAJOR]}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: 'Major is a required field' },
                                                                    ]}
                                                                >
                                                                    <Input type="text" placeholder="Ex. Nurse practitioner" />
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Degree"
                                                                    name={[name, EducationFieldEnum.DEGREE]}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: 'Degree is a required field' },
                                                                    ]}
                                                                >
                                                                    <Select fieldNames={{ label: 'content', value: 'dictKey' }} options={degreeOptions} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Specialties"
                                                                    name={[name, EducationFieldEnum.SPECIALTIES]}
                                                                    // validateFirst
                                                                    // rules={[
                                                                    //     { required: true, message: 'Specialties is a required field' },
                                                                    // ]}
                                                                >
                                                                    <Input type="text" placeholder="Ex. Family practice; use “,” to separate if there are multiple specialties" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Certificates"
                                                                    name={[name, EducationFieldEnum.CERTIFICATES]}
                                                                >
                                                                    <Input type="text" placeholder="Ex. ANP-BC; use “,” to separate if there are multiple certificates" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, EducationFieldEnum.IS_PURSUING_DEGREE]}
                                                                    valuePropName="checked"
                                                                >
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                educationForm.setFieldValue(['providerEducationList', name, EducationFieldEnum.END_DATE], undefined);
                                                                                educationForm.validateFields();
                                                                            }
                                                                            forceUpdate({});
                                                                        }}
                                                                    >I am currently pursuing this degree
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                                style={{
                                                                    maxWidth: '50%',
                                                                }}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Start Date"
                                                                    name={[name, EducationFieldEnum.START_DATE]}
                                                                    style={{ height: '85px' }}
                                                                    rules={[
                                                                        { required: true, message: 'Start Date is a required field' },
                                                                        {
                                                                            validator: (rules, value) => {
                                                                                const endDate = educationForm.getFieldValue('providerEducationList')?.[index]?.endDate;
                                                                                // educationForm.validateFields([['providerEducationList', index, 'endDate']]);

                                                                                if (value && !endDate && !currentEducation) {
                                                                                    return Promise.reject(
                                                                                        new Error('End date is required'),
                                                                                    );
                                                                                }

                                                                                if (endDate && endDate.isBefore(value)) {
                                                                                    return Promise.reject(
                                                                                        new Error('start date cannot be later than the end date'),
                                                                                    );
                                                                                }
                                                                                return Promise.resolve(true);
                                                                            },
                                                                        },
                                                                    ]}
                                                                >
                                                                    <DatePicker
                                                                        className={s.datepicker}
                                                                        picker="month"
                                                                        onChange={() => {
                                                                            educationForm.validateFields();
                                                                            forceUpdate({});
                                                                        }}
                                                                        disabledDate={(current) => {
                                                                            const endDate = educationForm.getFieldValue('providerEducationList')?.[index]?.endDate;
                                                                            if (!endDate || currentEducation) {
                                                                                return false;
                                                                            }
                                                                            return current.valueOf() > endDate.valueOf();
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            {
                                                                !currentEducation &&
                                                                <div
                                                                    className={s.displayItem}
                                                                >
                                                                    <Form.Item
                                                                        {...restField}
                                                                        label="End Date"
                                                                        name={[name, 'endDate']}
                                                                        style={{ height: '85px' }}
                                                                        rules={[
                                                                            { required: true, message: 'End Date is a required field' },
                                                                            {
                                                                                validator: (rules, value) => {
                                                                                    const startDate = educationForm.getFieldValue('providerEducationList')?.[index]?.[EducationFieldEnum.START_DATE];
                                                                                    // educationForm.validateFields([['providerEducationList', index, 'startDate']]);

                                                                                    if (value && !startDate) {
                                                                                        return Promise.reject(
                                                                                            new Error('Start date is required'),
                                                                                        );
                                                                                    }

                                                                                    if (startDate && value && value.isBefore(startDate)) {
                                                                                        return Promise.reject(
                                                                                            new Error('end date cannot be earlier than the start date'),
                                                                                        );
                                                                                    }

                                                                                    return Promise.resolve(true);
                                                                                },
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <DatePicker
                                                                            className={s.datepicker}
                                                                            picker="month"
                                                                            onChange={() => {
                                                                                educationForm.validateFields();
                                                                                forceUpdate({});
                                                                            }}
                                                                            disabledDate={(current) => {
                                                                                const startDate = educationForm.getFieldValue('providerEducationList')?.[index]?.[EducationFieldEnum.START_DATE];
                                                                                if (!startDate) {
                                                                                    return false;
                                                                                }
                                                                                return current.valueOf() < startDate.valueOf();
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="School logo"
                                                                    name={[name, EducationFieldEnum.LOGO]}
                                                                    valuePropName="data"
                                                                >
                                                                    <PhotoList min={1} enable responseType="string" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={s.formFooter}>
                                                        <Button onClick={() => remove(index)}>Remove</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        // )
                                        );
                                    })
                                }
                                <Form.Item>
                                    <div className={s.additionalTitle} onClick={() => checkAddable(add)}>{additionalButtonText}</div>
                                </Form.Item>
                            </>
                        );
                    }
                }
            </Form.List>
        );
    }, [update, checkAddable, degreeOptions, educationForm]);

    return (
        <div className={s.module}>
            {contextHolder}
            <div className={s.wrap}>
                <Form
                    id={StepValue.EDUCATION}
                    form={educationForm}
                    name={StepValue.EDUCATION}
                    className={commonS.formStyle1}
                    initialValues={{
                        providerEducationList: initFormValue,
                    }}
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onNext}
                    scrollToFirstError
                >
                    <div className={s.module}>
                        <h3>
                            <span>Education</span>
                            {renderEditButtons}
                        </h3>
                        {
                            !enable ? renderReviewEducationList : renderEducationList
                        }
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default Education;
