import { Button, Checkbox, Col, Form, Input, Radio, RadioChangeEvent, Row, Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StepValue } from 'pages/CreateUniProfilePage/types';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import { EMPTY_PLACEHOLDER, INSURANCE_TYPE_CASH, INSURANCE_TYPE_CASH_AND_INSURANCE, INSURANCE_TYPE_INSURANCE, PAY_TYPE_TEXT, SNAPSHOT_COLOR } from 'constants/common';
import { renderChangUI } from 'types/changeHighLight';

interface IInsurance {
    supportPayType: string;
    insuranceList: Array<string>;
    otherInsuranceList: string;
}

interface IProps {
    isOps?: boolean,
    initFormValue: IInsurance,
    editable?: boolean,
    showEditButtons: boolean,
    onSubmit: (value: any, step: StepValue) => void,
    onNext?: (value: any) => void,
    onEditableChange?: (enable: boolean, step: StepValue, callback: () => void) => void,
}

const Insurance = ({
    initFormValue,
    showEditButtons,
    editable,
    onSubmit,
    onNext,
    onEditableChange,
    isOps,
}: IProps) => {
    const [insuranceOptions] = useGetSettingData(EnumFields.INSURANCE);
    const [showInsuranceList, setShowInsuranceList] = useState(false);
    const [enable, setEnable] = useState(!!editable);
    const [insuranceForm] = Form.useForm<IInsurance>();
    const handleEnableStatusChange = useCallback((status: boolean) => {
        if (typeof onEditableChange === 'function') {
            onEditableChange(status, StepValue.INSURANCE, () => {
                setEnable(status);
            });
        }
    }, [onEditableChange]);

    useEffect(() => {
        if (initFormValue) {
            insuranceForm.setFieldsValue({
                ...initFormValue,
                supportPayType: initFormValue.supportPayType || INSURANCE_TYPE_CASH,
            });

            setShowInsuranceList(initFormValue.supportPayType !== INSURANCE_TYPE_CASH);
        }
    }, [initFormValue, insuranceForm]);

    useEffect(() => {
        setEnable(!!editable);
    }, [editable]);

    const {
        insuranceList,
        otherInsuranceList,
    } = initFormValue;

    const handleRadioChange = useCallback((e: RadioChangeEvent) => {
        setShowInsuranceList(e.target.value !== INSURANCE_TYPE_CASH);
    }, []);

    const handleSubmit = useCallback(() => {
        insuranceForm.validateFields().then((values) => {
            let data = {
            };

            if (values.supportPayType === INSURANCE_TYPE_CASH) {
                data = {
                    ...values,
                    insuranceList: [],
                    otherInsuranceList: [''],
                };
            } else {
                data = {
                    ...values,
                    otherInsuranceList: values.otherInsuranceList ? values.otherInsuranceList.split(',') : [],
                };
            }
            if (typeof onSubmit === 'function') {
                onSubmit(data, StepValue.INSURANCE);
            }
        }).catch((e) => {
            console.error(e);
        });
    }, [insuranceForm, onSubmit]);

    const renderEditButtons = useMemo(() => {
        if (showEditButtons) {
            return !enable ? (
                <Button
                    onClick={() => handleEnableStatusChange(true)}
                >
                    Edit
                </Button>
            ) : (
                <div className={s.editButtons}>
                    <Button
                        onClick={() => handleEnableStatusChange(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                    >
                        Save changes
                    </Button>
                </div>
            );
        }

        return null;
    }, [enable, handleEnableStatusChange, handleSubmit, showEditButtons]);

    const handleFinish = useCallback((e) => {
        let data = {};

        if (e.supportPayType === INSURANCE_TYPE_CASH) {
            data = {
                ...e,
                insuranceList: [],
                otherInsuranceList: [],
            };
        } else {
            data = {
                ...e,
                otherInsuranceList: e.otherInsuranceList ? e.otherInsuranceList.split(',') : [],
            };
        }
        onNext?.(data);
    }, [onNext]);

    return (
        <div className={s.module}>
            <div className={s.wrap}>
                <Form
                    id={StepValue.INSURANCE}
                    form={insuranceForm}
                    name={StepValue.INSURANCE}
                    className={`${commonS.formStyle1} ${s.formBody}`}
                    autoComplete="off"
                    layout="vertical"
                    onFinish={handleFinish}
                >
                    <h3>
                        <span>Insurance</span>
                        {renderEditButtons}
                    </h3>
                    {!enable ?
                        <>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <div className={s.label}>Type</div>
                                    <div className={s.value}>{PAY_TYPE_TEXT[initFormValue.supportPayType]}</div>
                                    {renderChangUI('supportPayType', initFormValue, !!isOps,
                                        (val: string) => {
                                            return (
                                                <div>{PAY_TYPE_TEXT[val]}</div>
                                            );
                                        },
                                    )}
                                </div>
                            </div>
                            {
                                initFormValue.supportPayType !== INSURANCE_TYPE_CASH &&
                                <>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={s.label}>In-network Insurance company</div>
                                            <div className={s.value}>
                                                {
                                                    insuranceList && insuranceList.length > 0 ?
                                                        <ul>
                                                            {
                                                                insuranceList?.map((item, i) => <li key={i}>{item}</li>)
                                                            }
                                                        </ul> :
                                                        EMPTY_PLACEHOLDER
                                                }
                                            </div>
                                            {renderChangUI('insuranceList', initFormValue, !!isOps,
                                                (val: string[]) => {
                                                    return (
                                                        <div className={s.value}>
                                                            {
                                                                val && val.length > 0 &&
                                                                <ul>
                                                                    {
                                                                        val?.map((item, i) => <li key={i} style={{ color: SNAPSHOT_COLOR }}>{item}</li>)
                                                                    }
                                                                </ul>
                                                            }
                                                        </div>
                                                    );
                                                },
                                            )}
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={s.label}>Other In-Network Insurance Companies</div>
                                            <div className={s.value}>
                                                {otherInsuranceList || EMPTY_PLACEHOLDER}
                                            </div>
                                            {renderChangUI('otherInsuranceList', initFormValue, !!isOps)}
                                        </div>
                                    </div>
                                </>
                            }
                        </> :
                        <>
                            <div className={s.module}>
                                <div className={s.row}>
                                    <div
                                        className={s.displayItem}
                                    >
                                        <Form.Item
                                            // label="Select all insurance companies you are currently in-network "
                                            name="supportPayType"
                                        >
                                            <Radio.Group onChange={handleRadioChange}>
                                                <Space direction="vertical">
                                                    <Radio value={INSURANCE_TYPE_CASH}>I only support cash pay.</Radio>
                                                    <Radio value={INSURANCE_TYPE_CASH_AND_INSURANCE}>I support both cash pay and insurance.</Radio>
                                                    <Radio value={INSURANCE_TYPE_INSURANCE}>I only accept insurance.</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            {
                                showInsuranceList && (
                                    <>
                                        <div className={s.module}>
                                            <h4>
                                                <span>Select all insurance companies you are currently in-network </span>
                                            </h4>
                                            <div className={s.row}>
                                                <div
                                                    className={s.displayItem}
                                                >
                                                    <Form.Item
                                                        // label="Select all insurance companies you are currently in-network "
                                                        name="insuranceList"
                                                    >
                                                        <Checkbox.Group style={{ width: '100%' }}>
                                                            <Row>
                                                                {
                                                                    insuranceOptions?.map((insurance) => {
                                                                        const { id, dictKey } = insurance;
                                                                        return (
                                                                            <Col key={id} span={12}>
                                                                                <Checkbox value={dictKey}>{dictKey}</Checkbox>
                                                                            </Col>
                                                                        );
                                                                    })
                                                                }
                                                            </Row>
                                                        </Checkbox.Group>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={s.module}>
                                            <h4>
                                                <span>Other insurance companies not listed above</span>
                                            </h4>

                                            <div className={s.row}>
                                                <div
                                                    className={s.displayItem}
                                                >
                                                    <Form.Item
                                                        label="Company Name"
                                                        name="otherInsuranceList"
                                                    >
                                                        <Input placeholder="Add company names and separate by comma " />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    }
                </Form>
            </div>
        </div>
    );
};

export default Insurance;
