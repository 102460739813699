import React, { } from 'react';
import AiChatStore from 'store/AiChatProvider';
import ChatLauncher from 'assets/aiChat/chatLauncher.svg';
import CloseBtn from 'assets/aiChat/closeBtn.svg';

import s from './s.module.less';

function Launcher() {
    const [getAiChatData, setAiChatData] = AiChatStore.useStore();
    const isShowChatBox = getAiChatData('isShowChatBox');

    const toggleChat = () => {
        setAiChatData('isShowChatBox', !isShowChatBox);
    };

    return (
        <div className={s.launcher}>
            {!isShowChatBox && <img width={60} height={60} className={s.open} src={ChatLauncher} alt="chat" onClick={toggleChat} />}
            {isShowChatBox && <img width={60} height={60} className={s.close} src={CloseBtn} alt="chat" onClick={toggleChat} />}
        </div>
    );
}

export default Launcher;
