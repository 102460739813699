import { Request } from '../request';
import { IDisputeSubmitInput, ISurveyEmailInput, ISurveyFormInput, TKlarityReviewReplyInput } from 'types/survey';
import { IGetFeedbackListInput, IFeedbackReplyInput, IGetPatientsListInput, IGetKlarityReviewListInput } from 'types/reputation';

export const getSurveyEmailTemplate = () => Request.get({ url: '/schedule-server/reputation/manage/surveyEmail' });

export const saveSurveyEmail = (data: ISurveyEmailInput) => Request.post({ url: '/schedule-server/reputation/manage/surveyEmail', params: data });

export const getSurveyForm = () => Request.get({ url: '/schedule-server/reputation/manage/surveyForm' });

export const saveSurveyForm = (data: ISurveyFormInput) => Request.post({ url: '/schedule-server/reputation/manage/surveyForm', params: data });

export const getReviewTrigger = () => Request.get({ url: '/schedule-server/reputation/manage/ReviewTrigger' });

export const saveReviewTrigger = (data: { minimalSurveyScore: number }) => Request.post({ url: '/schedule-server/reputation/manage/ReviewTrigger', params: data });

export const getReviewChannelList = () => Request.get({ url: '/schedule-server/provider/channel/review/list' });

export const getSurveyHistory = (patientId: number) => Request.get({ url: `/schedule-server/reputation/manage/patient/survey/history/${patientId}` });

export const getReputationOverviewData = (cycle: string) => Request.get({ url: `/schedule-server/reputation/manage/overview/${cycle}` });

export const getReputationPatientOverviewData = () => Request.get({ url: '/schedule-server/reputation/manage/patient/survey/stat' });

export const getReputationFeedbackOverviewData = () => Request.get({ url: '/schedule-server/reputation/manage/feedback/stat' });

export const getReputationFeedbackList = (data: IGetFeedbackListInput) => Request.post({ url: '/schedule-server/reputation/manage/feedback/list', params: data });

export const postReputationFeedbackReply = (data: IFeedbackReplyInput) => Request.post({ url: '/schedule-server/reputation/manage/feedback/reply', params: data });

export const getReputationPatientsList = (data: IGetPatientsListInput) => Request.get({ url: '/schedule-server/reputation/manage/patient/list', params: data });

export const getReputationStatus = () => Request.get({ url: '/schedule-server/reputation/manage/checkReputation' });

export const getReputationProspectCount = (type: string = 'Patient') => Request.get({ url: `/schedule-server/prospect/count/${type}` });

export const deleteReputationByEmail = (email: string) => Request.post({ url: '/schedule-server/prospect/patient/deleteByEmail', params: { email } });

export const getKlarityReviewOverviewData = (cycle: string) => Request.get({ url: `/schedule-server/provider/klarity/review/summary/${cycle}` });

export const getKlarityReviewList = (data: IGetKlarityReviewListInput) => Request.get({ url: '/schedule-server/provider/klarity/review/list', params: data });

export const addKlarityReviewReply = (data: TKlarityReviewReplyInput) => Request.post({ url: '/schedule-server/provider/klarity/review/comment/reply', params: data, disableErrorNote: true });

export const getKlarityReviewReply = (commentId: number) => Request.get({ url: `/schedule-server/provider/klarity/review/comments/${commentId}` });

export const getKlarityScoreInfoData = () => Request.get({ url: '/schedule-server/provider/klarity/review/summary/info' });

export const submitDisupteReview = (data: IDisputeSubmitInput) => Request.post({ url: '/schedule-server/provider/klarity/review/dispute/submit', params: data });

export const getDisputeReviewDetail = (id: number) => Request.get({ url: `schedule-server/provider/klarity/review/dispute/detail/${id}` });
