import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { Button, Form, Input } from 'antd';
import { AdditionFieldEnum, AddtionalList, ProviderChannelAdditional } from 'types/channelApplicationProvider';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { EMPTY_PLACEHOLDER } from 'constants/common';

interface IProps {
    formName: string;
    showEditButtons?: boolean;
    initFormValue: Partial<ProviderChannelAdditional>,
    enable: boolean;
    onEditStatusChange: (v: boolean, formName: string) => void;
    onUpdateService: (formName: string, formValue: ProviderChannelAdditional) => void;
}

const Publications = forwardRef(({
    initFormValue,
    formName,
    showEditButtons = true,
    enable,
    onUpdateService,
    onEditStatusChange,
}: IProps, ref) => {
    const [publicationsForm] = Form.useForm<ProviderChannelAdditional>();

    useEffect(() => {
        publicationsForm.setFieldsValue(initFormValue);
    }, [initFormValue, publicationsForm]);

    const handleFormFinish = useCallback(async () => {
        publicationsForm.validateFields().then((values: ProviderChannelAdditional) => {
            if (typeof onUpdateService === 'function') {
                onUpdateService(formName, {
                    ...initFormValue,
                    publicationAdditionalList: values.publicationAdditionalList ? values.publicationAdditionalList?.filter((item) => item.name?.replace(/\s*/g, '')) : [],
                });
            }
        }).catch((e) => {
            console.error(e);
        });
        // onUpdateService(formName, formValue);
        // setSubmitData(formValue);
    }, [formName, initFormValue, onUpdateService, publicationsForm]);

    const getFormValues = useCallback(async () => {
        let validRes = {};
        try {
            const resp = await publicationsForm.validateFields();

            validRes.publicationAdditionalList = resp.publicationAdditionalList ? resp.publicationAdditionalList?.filter((item) => item.name?.replace(/\s*/g, '')) : [];
        } catch (e) {
            validRes = e;
            console.error(e);
        }

        return validRes;
    }, [publicationsForm]);

    useImperativeHandle(ref, () => ({
        getFromValues: getFormValues,
    }));

    const reviewData = useMemo(() => (initFormValue?.publicationAdditionalList ? initFormValue.publicationAdditionalList?.filter((item) => item.name?.replace(/\s*/g, '')) : []), [initFormValue]);

    const renderEditButtons = useMemo(() => {
        if (showEditButtons) {
            return !enable ? (
                <div key={`${formName}_disable`} className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            onEditStatusChange(true, formName);
                        }}
                    >
                        Edit
                    </Button>
                </div>
            ) : (
                <div key={`${formName}_enable`} className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            onEditStatusChange(false, formName);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        // size="small"
                        type="primary"
                        onClick={handleFormFinish}
                    >
                        Save changes
                    </Button>
                </div>
            );
        }
        return null;
    }, [enable, formName, onEditStatusChange, showEditButtons, handleFormFinish]);

    const renderAdditionalReview = useMemo(() => {
        return (
            <div className={s.row}>
                <div
                    className={s.displayItem}
                >
                    {/* <div className={s.label}>Publications</div> */}
                    <div className={s.value}>
                        {
                            reviewData.length > 0 ?
                                <ul>
                                    {
                                        reviewData.map((item, index) => {
                                            const { name, website, authors } = item;
                                            return (
                                                <li key={index}>
                                                    <div>{authors}</div>
                                                    <div>{name}</div>
                                                    <a href={website}>{website}</a>
                                                </li>
                                            );
                                        })
                                    }
                                </ul> :
                                EMPTY_PLACEHOLDER
                        }
                    </div>
                </div>
            </div>
        );
    }, [reviewData]);

    const renderPublicationResourceList = useMemo(() => {
        return (
            <Form
                form={publicationsForm}
                name={formName}
                className={commonS.formStyle1}
                initialValues={initFormValue}
                autoComplete="off"
                layout="vertical"
                scrollToFirstError
            >
                <Form.List name="publicationAdditionalList">
                    {
                        (fields, { add, remove }) => {
                            return (
                                <>
                                    {
                                        fields.map(({ key, name, ...restField }, index) => {
                                            const title = `Publications ${index + 1}`;

                                            return (
                                                <div key={index} className={s.additionalBox}>
                                                    <div className={s.labelBox}>
                                                        <div className={`${s.label} ${s.third}`}>{title}</div>
                                                        <Button onClick={() => remove(index)}>Remove</Button>
                                                    </div>
                                                    <div className={s.additionalForm}>
                                                        <div className={s.formBody}>
                                                            <div className={s.row}>
                                                                <div
                                                                    className={s.displayItem}
                                                                >
                                                                    <Form.Item
                                                                        {...restField}
                                                                        label="Article/publication name"
                                                                        name={[name, AdditionFieldEnum.NAME]}
                                                                    >
                                                                        <Input />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className={s.row}>
                                                                <div
                                                                    className={s.displayItem}
                                                                >
                                                                    <Form.Item
                                                                        {...restField}
                                                                        label="Authors"
                                                                        name={[name, AdditionFieldEnum.AUTHORS]}
                                                                    >
                                                                        <Input />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className={s.row}>
                                                                <div
                                                                    className={s.displayItem}
                                                                >
                                                                    <Form.Item
                                                                        {...restField}
                                                                        label="Website"
                                                                        name={[name, AdditionFieldEnum.WEBSITE]}
                                                                    >
                                                                        <Input />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={s.formFooter}>
                                                            <Button onClick={() => remove(index)}>Remove</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    <Form.Item>
                                        <div className={s.additionalTitle} onClick={() => add()}>+ Add another publication</div>
                                    </Form.Item>
                                </>
                            );
                        }
                    }
                </Form.List>
            </Form>
        );
    }, [formName, initFormValue, publicationsForm]);

    return (
        <div className={s.wrap}>
            <div className={`${s.module} ${s.moduleTitle}}`}>
                <h3>
                    <span>Publications</span>
                    <div className={s.buttonsWrap}>
                        {renderEditButtons}
                        {/* { enableReview && <Button className={s.showReviewModalButton} type="default" size="large" onClick={() => onShowReviewModal?.()}>Add reviews</Button>} */}
                    </div>
                </h3>
                {
                    !enable ? renderAdditionalReview : renderPublicationResourceList
                }
            </div>
        </div>
    );
});

export default Publications;
