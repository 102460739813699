import React from 'react';
import BotChatLogo from 'assets/aiChat/botChatLogo.svg';
import cx from 'classnames';

import s from './s.module.less';

export function LoadingMessage({ }): React.ReactElement {
    return (
        <div className={cx([s.messageWrapper, s.loadingMessage])}>
            <div className={cx({
                [s.messageRow]: true,
                [s.botRow]: true,
            })}
            >
                <img width={32} height={32} src={BotChatLogo} alt="bot_chat_logo" className={s.logo} />
                <div className={cx({
                    [s.message]: true,
                    [s.botBg]: true,
                })}
                >
                    <div className={s.spinner}>
                        <div className={s.bounce1} />
                        <div className={s.bounce2} />
                        <div className={s.bounce3} />
                    </div>
                </div>
            </div>
        </div>
    );
}
