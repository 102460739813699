// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__top--qqTtZ {
  padding: 24px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
}
.s-module__top--qqTtZ img {
  height: 30px;
}
.s-module__top--qqTtZ .s-module__close--En6Ye {
  cursor: pointer;
}
.s-module__bottom--U__dc {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #E5E7EB;
}
.s-module__middle--vn6E6 {
  padding-top: 78px;
  padding-bottom: 70px;
}
`, "",{"version":3,"sources":["webpack://./src/components/FullLayout/s.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EAIA,eAAA;EACA,MAAA;EACA,WAAA;AAFF;AATA;EAOI,YAAA;AAKJ;AAZA;EAaI,eAAA;AAEJ;AAEA;EACE,eAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,6BAAA;AAAF;AAGA;EACE,iBAAA;EACA,oBAAA;AADF","sourcesContent":[".top{\n  padding: 24px 32px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  img {\n    height: 30px;\n  }\n  position: fixed;\n  top: 0;\n  width: 100%;\n  .close{\n    cursor: pointer;\n  }\n}\n\n.bottom{\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  height: 70px;\n  padding: 0 32px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  border-top: 1px solid #E5E7EB;\n}\n\n.middle {\n  padding-top: 78px;\n  padding-bottom: 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top": `s-module__top--qqTtZ`,
	"close": `s-module__close--En6Ye`,
	"bottom": `s-module__bottom--U__dc`,
	"middle": `s-module__middle--vn6E6`
};
export default ___CSS_LOADER_EXPORT___;
