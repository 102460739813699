import { Request } from '../request';
import { Setting } from 'types/api';
import { EnumFields } from 'types/enumerationData';
import type { ServerMeta, TMedication } from 'types/common';

export const uploadFile = (data: FormData) =>
    Request.post<FormData>(
        {
            url: '/oms-server/uploadFile',
            params: data,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        },
    );

export const uploadFileByType = (data: FormData) =>
    Request.post<FormData>(
        {
            url: '/oms-server/uploadFileByType',
            params: data,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        },
    );

export const getSettings = () =>
    Request.get<Setting[]>({
        url: '/admin-server/setting/list',
        disableErrorNote: true,
    });

export const getCalendarColors = () =>
    Request.get<Setting[]>({
        url: '/schedule-server/openapi/admin/listSettingByType?type=color',
        disableErrorNote: true,
    });

export const getAdminCalendarColors = () =>
    Request.get<Setting[]>({
        url: '/admin-server/setting/listByType?type=color',
        disableErrorNote: true,
    });

export const getListSetting = () => Request.get({
    url: '/schedule-server/openapi/admin/listSetting',
    disableErrorNote: true,
});

export const getAdminListSetting = () => Request.get({
    url: '/admin-server/setting/list',
    disableErrorNote: true,
});

export const getListSettingByType = (type: EnumFields) => Request.get({
    url: '/schedule-server/openapi/admin/listSettingByType',
    params: { type },
    disableErrorNote: true,
});

export const getConfigFeaturedItems = () =>
    Request.get<ServerMeta[]>({
        url: '/schedule-server/configFeaturedItems/getAll',
        disableErrorNote: true,
    });

export const uploadProviderFile = (data: FormData) =>
    Request.post<FormData>(
        {
            url: '/oms-server/uploadPrivateFile',
            params: data,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        },
    );

export const getKlarityProgramList = () => Request.get({ url: `/schedule-server/provider/program/all/list` });

export const getKlarityProgramDetail = (id: number) => Request.get({ url: `/schedule-server/klarity/program/getByIdInfo`, params: { id } });

export type TEnrollKlarityProgram = {
    programId: number,
    states: string,
    contractSignName: string,
    contractSignDate: string,
    contractSignAddress: string,
}

export const enrollKlarityProgram = (data: TEnrollKlarityProgram) => Request.post({ url: '/schedule-server/provider/program/enroll', params: data });

export const updateKlarityProgramState = (programId: number, states: string) => Request.post({ url: '/schedule-server/provider/program/state/update', params: { programId, states } });

export const disContinueProgram = (pid: number) => Request.put({ url: '/schedule-server/provider/program/disContinue/' + pid });

export const getProviderMedicationList = () => Request.get({ url: `/schedule-server/provider/medication/list` });

export const updateProviderMedicationList = (data: TMedication[]) => Request.post({ url: `/schedule-server/provider/medication/saveOrUpdate/batch`, params: data });
