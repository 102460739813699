// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__status--uXAtW {
  padding: 2px 10px;
  display: inline-block;
  background: #DBDBDB;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--gray-800);
  white-space: nowrap;
}
.s-module__status--uXAtW.s-module__approved--Nu4Z1 {
  background-color: var(--green-100);
  color: var(--green-800);
}
.s-module__status--uXAtW.s-module__followup--FT0q_ {
  background-color: var(--yellow-100);
  color: var(--yellow-800);
}
.s-module__status--uXAtW.s-module__rejected--SR842 {
  background-color: var(--red-100);
  color: var(--red-800);
}
.s-module__status--uXAtW.s-module__pending--BUFsR {
  background-color: var(--red-100);
  color: var(--red-800);
}
.s-module__status--uXAtW.s-module__golive--m5aMv {
  background-color: var(--blue-100);
  color: var(--blue-800);
}
`, "",{"version":3,"sources":["webpack://./src/components/ChannelAuditStatusTag/s.module.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,qBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACI,kCAAA;EACA,uBAAA;AACR;AAEI;EACI,mCAAA;EACA,wBAAA;AAAR;AAKI;EACI,gCAAA;EACA,qBAAA;AAHR;AAMI;EACI,gCAAA;EACA,qBAAA;AAJR;AAOI;EACI,iCAAA;EACA,sBAAA;AALR","sourcesContent":[".status {\n    padding: 2px 10px;\n    display: inline-block;\n    background: #DBDBDB;\n    border-radius: 10px;\n    font-weight: 500;\n    font-size: 12px;\n    line-height: 16px;\n    text-align: center;\n    color: var(--gray-800);\n    white-space: nowrap;\n\n    &.approved {\n        background-color: var(--green-100);\n        color: var(--green-800);\n    }\n\n    &.followup {\n        background-color: var(--yellow-100);\n        color: var(--yellow-800);\n        // background-color: var(--purple-100);\n        // color: var(--purple-800);\n    }\n\n    &.rejected {\n        background-color: var(--red-100);\n        color: var(--red-800);\n    }\n\n    &.pending {\n        background-color: var(--red-100);\n        color: var(--red-800);\n    }\n\n    &.golive {\n        background-color: var(--blue-100);\n        color: var(--blue-800);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `s-module__status--uXAtW`,
	"approved": `s-module__approved--Nu4Z1`,
	"followup": `s-module__followup--FT0q_`,
	"rejected": `s-module__rejected--SR842`,
	"pending": `s-module__pending--BUFsR`,
	"golive": `s-module__golive--m5aMv`
};
export default ___CSS_LOADER_EXPORT___;
