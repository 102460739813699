import React, { useMemo } from 'react';
import s from './s.module.less';
import { EDISPUTE_STATUS } from 'types/common';

interface IProps {
    status?: EDISPUTE_STATUS;
}

const DisputeStatus = ({
    status,
}: IProps) => {
    const cls = useMemo(() => {
        switch (status) {
            case EDISPUTE_STATUS.INVALID:
                return s.invalid;
            case EDISPUTE_STATUS.VALID:
                return s.valid;
            default:
                return '';
        }
    }, [status]);

    const text = useMemo(() => {
        switch (status) {
            case EDISPUTE_STATUS.INVALID:
                return 'Dispute invalid';
            case EDISPUTE_STATUS.VALID:
                return 'Dispute valid';
            case EDISPUTE_STATUS.INVESTIGATION:
                return 'Under investigation';
            default:
                return '';
        }
    }, [status]);
    return (
        <div className={`${s.status} ${cls}`}>{text}</div>
    );
};

export default DisputeStatus;
