import React from 'react';
import Icon from './icon.svg';
import s from './s.module.less';

const PatientTableEmpty = () => {
    return (
        <div className={s.wrap}>
            <div className={s.title}>
                No patient yet
            </div>
            <div className={s.des}>
                You can add patients manually
            </div>
            <div className={s.img}>
                <img src={Icon} />
            </div>
        </div>
    );
};

export default PatientTableEmpty;
