// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__leaveConfirmContent--hqG6z {
  color: var(--gray-900);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 175% */
}
`, "",{"version":3,"sources":["webpack://./src/components/GlobalLayer/CampainModals/blackfriday2024/LeavCampaignModal/s.module.less"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACF,SAAS;AACX","sourcesContent":[".leaveConfirmContent {\n    color: var(--gray-900);\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 28px; /* 175% */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leaveConfirmContent": `s-module__leaveConfirmContent--hqG6z`
};
export default ___CSS_LOADER_EXPORT___;
