import React, { useCallback, useState } from 'react';
import s from './s.module.less';
import CommonTable from 'components/CommonTable';
import dayjs from 'dayjs';
import StatusTag from './StatusTag';
import { ProviderPractice, ProviderProfile } from 'types/provider';
import { ELAB, IAddon, IAddonPhysician } from 'types/ehr';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import { getW9PreviewLink, changeAddonStatus, getEhrPwdProviderId } from 'api/operation';
import { Button, message } from 'antd';
import PaymentStatusTag from 'components/PaymentStatusTag';
import copy from 'copy-to-clipboard';
import { getStateDisplayName } from 'utils/common';
import useGetSettingData from 'hooks/useGetSettingData';

const EMPTY_PLACEHOLDER = 'Not set yet';
interface IProps {
    addonPhysicianList: IAddonPhysician[];
    addOns?: IAddon[];
    providerId?: string;
    data: ProviderPractice;
    biographic: {
        ethnicity: string;
        birthDate: string;
        gender: string;
        religion: string;
        usedName: string;
    };
    provider: Partial<ProviderProfile>;
    doesSpot?: string;
    doesSpotName?: string;
    hasEhrAccountPwd?: boolean;
    refetch?: (id?: string) => void;
}

const EHRAccountNAddons = ({
    addonPhysicianList,
    addOns,
    providerId,
    data,
    biographic,
    provider,
    doesSpot,
    doesSpotName,
    hasEhrAccountPwd,
    refetch,
}: IProps) => {
    const [setting] = useSettings();
    const taxIdTypeOptions = setting[EnumFields.TAX_ID_TYPE] || [];
    const [stateData] = useGetSettingData(EnumFields.STATE);
    const [w9Loading, setW9Loading] = useState(false);
    const [currentRowId, setCurrentRowId] = useState<number>(-1);
    const [statusChangeLoading, setStatusChangeLoading] = useState(false);
    const { practiceEmail } = data;
    const [ehrPwd, setEhrPwd] = useState('');

    const getW9Link = useCallback(async (id: string) => {
        if (id) {
            setW9Loading(true);

            try {
                const res = await getW9PreviewLink(id);

                if (res && !res.error) {
                    window.open(res.data.data, '_blank');
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }

            setW9Loading(false);
        }
    }, []);

    const handleChangeStatus = useCallback(async (addonId?: number, pid?: string) => {
        if (addonId && pid) {
            setCurrentRowId(addonId);
            setStatusChangeLoading(true);

            try {
                const res = await changeAddonStatus(addonId, pid);

                if (res && !res.error) {
                    refetch?.(providerId);
                    setCurrentRowId(-1);
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }

            setStatusChangeLoading(false);
        }
    }, [refetch, providerId]);

    const DEANumberColumns = [
        {
            title: 'STATE',
            dataIndex: 'physicianState',
            className: s.tableColumn,
            width: '120px',
            fixed: 'left',
            render: (state: string) => getStateDisplayName(state, stateData),
        },
        {
            title: 'NUMBER',
            dataIndex: 'deaNumber',
            className: s.tableColumn,
            width: '120px',
            fixed: 'left',
            render: (deaNumber: string) => deaNumber || '-',
        },
    ];

    const columns = [
        {
            title: 'NAME',
            dataIndex: 'productName',
            className: s.tableColumn,
            width: '250px',
            fixed: 'left',
        },
        {
            title: 'QUANTITY',
            dataIndex: 'productQty',
            width: '150px',
            className: s.tableColumn,
        },
        {
            title: 'PURCHASE DATE',
            dataIndex: 'paidAt',
            width: '180px',
            className: s.tableColumn,
            render: (date?: string) => {
                return date ? dayjs(date).format('MM/DD/YYYY') : '-';
            },
        },
        {
            title: 'PAYMENT',
            dataIndex: 'paymentStatus',
            width: '350px',
            className: s.tableColumn,
            render: (status: string) => status && <PaymentStatusTag status={status} />,
        },
        {
            title: 'STATUS',
            dataIndex: 'activeStatus',
            width: '280px',
            className: s.tableColumn,
            render: (status: string) => status && <StatusTag status={status} />,
        },
        {
            title: 'ACTION',
            width: '150px',
            className: s.tableColumn,
            fixed: 'right',
            render: (item: IAddon) => {
                return item.activeStatus.toString().toLowerCase() !== 'active' ? <Button type="link" loading={currentRowId === item.id && statusChangeLoading} className={s.tableActionLink} onClick={() => handleChangeStatus(item.id, providerId)}>Set as active</Button> : '-';
            },
        },
    ];

    const handleCopyPwd = useCallback(async () => {
        if (ehrPwd) {
            copy(ehrPwd || '', { format: 'text/plain' });
            message.success('Copied to the clipboard');
        } else {
            const result = await getEhrPwdProviderId(Number(providerId));
            if (!result.error) {
                const pwd = result.data.data;
                setEhrPwd(pwd);
                copy(pwd || '', { format: 'text/plain' });
                message.success('Copied to the clipboard');
            }
        }
    }, [ehrPwd, providerId]);
    return (
        <div className={s.wrap}>
            <div className={s.body}>
                <div className={s.row}>
                    <div className={s.item}>
                        <div className={s.label}>Email address</div>
                        <div className={s.valueBox}>
                            <div className={s.value}>{practiceEmail}</div>
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.item}>
                        <div className={s.label}>Initial password</div>
                        <div className={s.valueBox}>
                            {
                                hasEhrAccountPwd ? (
                                    <>
                                        <div className={s.value}>*************</div>
                                        <div className={s.copyIcon} onClick={handleCopyPwd} />
                                    </>
                                ) : EMPTY_PLACEHOLDER
                            }
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.item}>
                        <div className={s.label}>Date of birth</div>
                        <div className={s.valueBox}>
                            <div className={s.value}>{biographic?.birthDate ? dayjs(biographic?.birthDate).format('MM-DD-YYYY') : EMPTY_PLACEHOLDER}</div>
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.item}>
                        <div className={s.label}>NPI number</div>
                        <div className={s.valueBox}>
                            <div className={s.value}>{provider?.npi || EMPTY_PLACEHOLDER}</div>
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.item}>
                        <div className={s.label}>DEA number</div>
                        <div className={s.valueBox}>
                            <div className={s.valueBox}>
                                <CommonTable
                                    bordered
                                    rowKey="id"
                                    columns={DEANumberColumns}
                                    data={addonPhysicianList || []}
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.item}>
                        <div className={s.label}>DoseSpot</div>
                        <div className={s.valueBox}>
                            <div className={s.value}>{doesSpot === 'N' ? 'Never used before' : doesSpotName}</div>
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.item}>
                        <div className={s.label}>Lab</div>
                        <div className={s.valueBox}>
                            <div className={s.value}>{ELAB[data.setUpLapOption]}</div>
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.item} style={{ width: '300px' }}>
                        <div className={s.label}>Practice Tax ID type</div>
                        <div className={s.valueBox}>
                            <div className={s.value}>{taxIdTypeOptions.find((e) => e.value === data.taxIdType)?.label}</div>
                        </div>
                    </div>
                    <div className={s.item} style={{ width: '200px' }}>
                        <div className={s.label}>Practice Tax ID</div>
                        <div className={s.valueBox}>
                            <div className={s.value}>{data.taxIdType !== 'NONE' ? data.taxId : 'None'}</div>
                        </div>
                    </div>
                    <div className={s.item} style={{ width: '200px' }}>
                        <div className={s.label}>W-9 form</div>
                        <div className={s.valueBox}>
                            <Button type="link" className={`${s.value} ${s.link}`} onClick={() => getW9Link(providerId!)} loading={w9Loading}>W-9 form</Button>
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.item}>
                        <div className={s.label}>Add-ons</div>
                        <div className={s.valueBox}>
                            <CommonTable
                                bordered
                                rowKey="id"
                                columns={columns}
                                data={addOns || []}
                                pagination={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EHRAccountNAddons;
