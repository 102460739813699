import React from 'react';
import { Form, FormInstance, Input } from 'antd';
import s from './s.module.less';
// import FormValidationHelper from 'utils/validation';
import commonS from 'styles/common.module.less';
import RemoveIcon from './Trash.svg';
import MyChannelSelect from 'components/form/MyChannelSelect';
import { FormProspects } from '../../types';
import RegExps from 'constants/regexps';

type Props = {
    form: FormInstance<FormProspects>
};

const FirstStep = (props:Props) => {
    const { form } = props;
    const initData = [{ channelId: -1 }];
    return (
        <div className={s.wrap}>
            <h2>Add patients </h2>
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <Form.List name="list" initialValue={initData}>
                    {(fields, { add, remove }) => {
                        const { length } = fields;
                        return (
                            <div>
                                {fields.map((field) => {
                                    return (
                                        <Form.Item
                                            key={field.key}
                                            noStyle
                                        >
                                            <div className={s.item}>
                                                <div className={s.no}>
                                                    {field.name + 1}
                                                </div>
                                                <div className={s.content}>
                                                    <div className={s.row}>
                                                        <div
                                                            className={s.displayItem}
                                                            style={{ marginRight: '12px', width: '300px' }}
                                                        >
                                                            <Form.Item
                                                                label="First name"
                                                                name={[field.name, 'firstName']}
                                                                validateFirst
                                                                rules={[
                                                                    { required: true, message: 'First name is a required field' },
                                                                    { max: 50, message: 'Exceeded maximum character length of 50' },
                                                                ]}
                                                            >
                                                                <Input type="text" />
                                                            </Form.Item>
                                                        </div>
                                                        <div
                                                            className={s.displayItem}
                                                            style={{ marginRight: '12px', width: '300px' }}
                                                        >
                                                            <Form.Item
                                                                label="Last name"
                                                                name={[field.name, 'lastName']}
                                                                validateFirst
                                                                rules={[
                                                                    { required: true, message: 'Last name is a required field' },
                                                                    { max: 50, message: 'Exceeded maximum character length of 50' },
                                                                ]}
                                                            >
                                                                <Input type="text" />
                                                            </Form.Item>
                                                        </div>
                                                        <div
                                                            className={s.displayItem}
                                                            style={{ marginRight: '12px', width: '360px' }}
                                                        >
                                                            <Form.Item
                                                                label="Email"
                                                                name={[field.name, 'email']}
                                                                validateFirst
                                                                rules={[
                                                                    { required: true, message: 'Email is a required field' },
                                                                    { type: 'email', message: 'Email is invalid' },
                                                                ]}
                                                            >
                                                                <Input type="text" />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className={s.row}>
                                                        <div
                                                            className={s.displayItem}
                                                            style={{ marginRight: '12px', width: '300px' }}
                                                        >
                                                            <Form.Item
                                                                label="Phone number"
                                                                name={[field.name, 'tel']}
                                                                validateFirst
                                                            >
                                                                <Input maxLength={30} />
                                                            </Form.Item>
                                                        </div>
                                                        <div
                                                            className={s.displayItem}
                                                        >
                                                            <Form.Item
                                                                style={{ width: '300px' }}
                                                                label="Select a channel:"
                                                                name={[field.name, 'channelId']}
                                                                validateFirst
                                                                rules={[
                                                                    { required: true, message: 'Channel is required' },
                                                                ]}
                                                            >
                                                                <MyChannelSelect value={-1} showOthers />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>

                                                {length !== 1 &&
                                                    <div className={s.removeWrap}>
                                                        <div className={s.remove}>
                                                            <img
                                                                onClick={() => {
                                                                    remove(field.name);
                                                                }}
                                                                src={RemoveIcon}
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </Form.Item>
                                    );
                                })}
                                <div
                                    onClick={() => {
                                        add({
                                            channelId: -1,
                                        });
                                    }}
                                    className={s.add}
                                >
                                    + Add a patient
                                </div>
                            </div>
                        );
                    }}
                </Form.List>
            </Form>
        </div>
    );
};

export default FirstStep;
