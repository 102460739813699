import React, { useCallback, useEffect } from "react";
import s from './s.module.less';
import { Button, Form, message, Modal } from "antd";
import commonS from 'styles/common.module.less';
import { renderChangUI } from 'types/changeHighLight';
import { EMPTY_PLACEHOLDER, SNAPSHOT_COLOR } from 'constants/common';
import UploadModal from "./UploadModal";
import { License, ELicenseType } from "types/common";
import { providerLicenseUpdate } from 'api/provider';
import { opLicenseUpdate } from 'api/operation';
import useDeleteLicense from 'hooks/useDeleteLicense';

type IProps = {
    inited?:boolean,
    initValue?: any[],
    state?:string,
    providerId?:number,
    onChange?: (value:any)=>void
    viewOnly?:boolean,
    onNullState?: ()=>void,
    isOperation?:boolean,
    onEditableChange?: (editing: boolean) => void,
    refetch?: () => void,
};

const AdditionalLicensesNCertificates = ({
    initValue,
    providerId,
    inited,
    viewOnly,
    onEditableChange,
    isOperation,
    refetch,
}: IProps) => {
    const [submiting, setSubmiting] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState('');
    const [showModal, setShowModal] = React.useState(false);
    const [autonomyValue, setAutonomyValue] = React.useState<License>();
    const [otherValues, setOtherValues] = React.useState<License>();
    const [currentUploading, setCurrentUploading] = React.useState<ELicenseType>();
    const [currentRemoveId, setCurrentRemoveId] = React.useState<number>();

    useEffect(() => {
        if (initValue && initValue.length > 0) {
            setAutonomyValue(initValue.find((v:License) => v.type === ELicenseType.AUTONOMY));
            setOtherValues(
                initValue.find((v:License) => {
                    return v.type === ELicenseType.OTHER;
                })
         );
        }
    }, [initValue])

    //enable and show modal is little function cover..

    const [enable, setEnable] = React.useState(!!inited);

    const [, deleteLicense, removeLoading] = useDeleteLicense({});
    
    const handleShowModal = useCallback((v: ELicenseType) => {
        setCurrentUploading(v)
        setModalTitle(v === ELicenseType.AUTONOMY ? 'Add certificate of autonomy' : 'Add other certificates');
        setShowModal(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);
    const onSubmit = useCallback(async (url: string) => {
        if (currentUploading) {
            try {
                setSubmiting(true);
                const licenseData:Partial<License> = {
                    type: currentUploading,
                    url: url,
                    id: currentUploading === ELicenseType.AUTONOMY ? autonomyValue?.id : otherValues?.id,
                };
                const fn = isOperation ? opLicenseUpdate : providerLicenseUpdate;
                // eslint-disable-next-line no-await-in-loop
                const result = await fn(licenseData, providerId!);
                if (!result.error) {
                    setShowModal(false);
                    // if (currentUploading === ELicenseType.AUTONOMY) {
                    //     setAutonomyValue(autonomyValue ? {
                    //         ...autonomyValue,
                    //         url,
                    //     }: { url});
                    // } else if (currentUploading === ELicenseType.OTHER) {
                    //     setOtherValues(
                    //         otherValues ? {
                    //             ...otherValues,
                    //             url,
                    //         }: { url}
                    //     );
                    // }
                    refetch?.();
                }
            } catch (e:any) {
                if (e.errorFields) {
                    //form error
                    return;
                }
                const msg = e?.toString?.() || 'data error';
                message.error(msg);
            } finally {
                setSubmiting(false);
            }
        }
    }, [currentUploading, isOperation, otherValues, providerId, refetch]);

    const handleOk = useCallback((v: string) => {
        onSubmit(v)
    }, [onSubmit]);
    return (
        <div className={s.wrap}>
            <UploadModal
                title={modalTitle}
                submiting={submiting}
                onCancel={handleCloseModal}
                open={showModal}
                onOk={handleOk}
                value=''
            />
            {/* <h3>
                <div className={s.left}>
                    <span>Additional licences and certificates </span>
                </div>
                {
                    !viewOnly && !enable &&
                    <Button
                        size="small"
                        onClick={() => {
                            onEditableChange?.(true);
                            setEnable(true);
                        }}
                    >
                        Edit
                    </Button>
                }
                {
                    enable && !viewOnly &&
                    <div className={s.btnWrap}>
                        <Button
                            type="primary"
                            size="small"
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                        <Button
                            size="small"
                            onClick={() => {
                                onEditableChange?.(false);
                                setEnable(false);
                            }}
                            style={{ marginLeft: 16 }}
                        >
                            Cancel
                        </Button>
                    </div>
                }
            </h3> */}
            {
                (
                    <div className={s.editWrap}>
                        <div className={s.editItem}>
                            <div className={s.editHeader}>
                                <div className={s.addIcon} onClick={() => handleShowModal(ELicenseType.AUTONOMY)} />
                                <div className={s.title}>Add certificate of autonomy</div>
                            </div>
                            {
                                autonomyValue?.url && (
                                    <div className={s.itemList}>
                                        {
                                            <div className={s.item}>
                                                <div className={s.text}>{autonomyValue?.url}</div>
                                                <Button className={s.removeButton} loading={currentRemoveId === autonomyValue?.id && removeLoading} ghost onClick={() => {
                                                    setCurrentRemoveId(autonomyValue?.id);
                                                    deleteLicense([autonomyValue?.id!], () => refetch?.());
                                                }}>remove</Button>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        </div>
                        <div className={s.editItem}>
                            <div className={s.editHeader}>
                                <div className={s.addIcon} onClick={() => handleShowModal(ELicenseType.OTHER)} />
                                <div className={s.title}>Other certificates</div>
                            </div>
                            {
                                otherValues?.url && (
                                    <div className={s.itemList}>
                                    {
                                        <div className={s.item}>
                                            <div className={s.text}>{otherValues?.url}</div>
                                            <Button className={s.removeButton} loading={currentRemoveId === otherValues?.id && removeLoading} type="ghost" onClick={() => {
                                                    setCurrentRemoveId(otherValues?.id);
                                                    deleteLicense([otherValues?.id!], () => refetch?.());
                                                }}>remove</Button>
                                        </div>
                                    }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default AdditionalLicensesNCertificates
