import { Request } from '../request';
import type { TServiceSetting } from 'types/helloKlarity';

export const getKlarityServiceList = (
    pageId: number | string,
) => Request.get({
    url: '/admin-server/klarity/page/service/getByPageId/',
    params: {
        pageId,
    },
});

export const getKlarityServiceDetail = (
    id: number,
) => Request.get({ url: `/admin-server/klarity/page/service/getById/${id}` });

export const klarityServiceUpdate = (data: TServiceSetting) => Request.post({ url: '/admin-server/klarity/page/service/saveOrUpdate', params: data });

export const exchangePriority = (data: {
    fromSpecialty: string;
    toSpecialty: string;
}) => Request.put({ url: '/admin-server/klarity/condition/specialty/priority/exchange', params: data });

export const conditionSaveOrUpdateBatch = (data: TServiceSetting[]) => Request.post({ url: '/admin-server/klarity/condition/saveOrUpdate/batch', params: data });
