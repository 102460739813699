import { Request } from '../request';
import type { IAssessmentEmail, IAssessmentInput, TAssessmentItem, TEngagementQuestion, TResult } from 'types/operation';

export const getAssessmentList = (data: IAssessmentInput) => Request.post({ url: '/admin-server/assessment/pageList', params: data });
export const addOrUpdateAssessment = (data: TAssessmentItem) => Request.post({ url: '/admin-server/assessment/addOrUpdateAssessment', params: data });
export const duplicateAssessment = (data: { assessmentName?: string; id: string | number; conditionKeyTags?: string }) => Request.post({
    url: '/admin-server/assessment/duplicate',
    params: data,
});
export const updateQuestionsFrom = (data: { id: string | number; questionsFormLink: string }) => Request.post({ url: '/admin-server/assessment/updateQuestionsFrom', params: data });
export const getByAssessmentId = (data: { assessmentId: string | number; }) => Request.get({
    url: '/admin-server/assessmentEngagementTips/getByAssessmentId',
    params: data,
});
export const engagementTipsAddOrUpdate = (data: TEngagementQuestion) => Request.post({ url: '/admin-server/assessmentEngagementTips/addOrUpdate', params: data });
// sort
export const engagementTipsAddOrUpdateBatch = (data: TEngagementQuestion[]) => Request.post({ url: '/admin-server/assessmentEngagementTips/addOrUpdateBatch', params: data });
export const deleteEngagementTips = (data: { id: string | number }) => Request.delete({ url: `/admin-server/assessmentEngagementTips/deleteById?id=${data.id}`, params: {} });
export const getCountByGoogleFormId = (data: { questionsFormLinkId: string | number }) => Request.get({ url: '/admin-server/assessmentEngagementTips/getCountByGoogleFormId', params: data });
export const getQuestionsByAssessmentUuid = (data: {
    assessmentUuid: string
}) => Request.get({
    url: '/admin-server/assessment/getQuestionsByAssessmentUuid',
    params: data,
});

export const assessmentResultLevelAddOrUpdate = (data: TResult) => Request.post({ url: '/admin-server/assessmentResultLevel/addOrUpdate', params: data });
export const assessmentResultLevelAddOrUpdateBatch = (data: {
    promptManagement: string;
    assessmentId: string | number;
    reqs: TResult[]
}) => Request.post({ url: '/admin-server/assessmentResultLevel/addOrUpdateBatch', params: data });
export const getAiGenerateData = (data: {
    prompt: string;
    assessmentId: string | number;
}) => Request.get({
    url: '/admin-server/assessmentResultLevel/getAiGenerateData',
    params: data,
});
export const getAssessmentResultLevel = (data: { assessmentId: string | number; }) => Request.get({
    url: '/admin-server/assessmentResultLevel/getByAssessmentId',
    params: data,
});

export const assessmentEmailAddOrUpdate = (data: IAssessmentEmail) => Request.post({ url: '/admin-server/assessmentEmail/addOrUpdate', params: data });

export const assessmentEmailAddOrUpdateBatch = (data: {
    promptManagement: string;
    assessmentId: string | number;
    reqs: IAssessmentEmail[]
}) => Request.post({ url: '/admin-server/assessmentEmail/addOrUpdateBatch', params: data });

export const getEmailListByType = (emailType: string) => Request.get({
    url: '/admin-server/email/listByType',
    params: { emailType },
});

export const getAssessmentEmailById = (assessmentId: number) => Request.get({
    url: '/admin-server/assessmentEmail/getByAssessmentId',
    params: { assessmentId },
});

export const updateAssementEmailById = (data) => Request.post({ url: '/admin-server/assessment/updateById', params: data });
export const changeAssessmentStatus = (data: {
    status: string;
    id: number | string;
}) => Request.post({
    url: '/admin-server/assessment/activation',
    params: data,
});
