import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Switch } from 'antd';
import remove from 'lodash/remove';
import TimeSlot from '../TimeSlot';
import { ETimeTag, ITime, ITimePair } from 'types/appointmentTime';
import update from 'lodash/update';
import { isTimeOverlap, transTimeStringToNumber } from 'utils/common';
import { BaseOptionType } from 'antd/es/select';

type TValue = {
    weekDay: string;
    hourFromTo: {
        fromHour: number | string;
        fromMinute: number | string;
        toHour: number | string;
        toMinute: number | string;
    }[]
};

interface TimeSlotProps {
    showError?: boolean;
    weekLabel: string;
    weekValue: string;
    time: {
        fromHour: number | string;
        fromMinute: number | string;
        toHour: number | string;
        toMinute: number | string;
    }[];
    onChange?: (v: TValue) => void;
}

// 1. 所有时间段，都不允许重叠
// 2. end 必须晚于 start
// 3. 时间A的end只能晚于「距离A最近的时间段的start」。如下图，A的end 时间不得晚于「离得最近且晚于Astart的时间C的start」, 否则将会产生交集
//  ------------------------------------------------------------------------------------
//     |---B----|                 |----C---|       |-----D-----| |-------E----|
//               |---------A------|

const generateTimeArray = () => {
    const gap = 15;
    const times = [];
    const appendZero = (num: number) => (num < 10 ? `0${num}` : num);

    for (let hour = 0; hour < 24; hour++) {
        for (let minutes = 0; minutes < 60; minutes += gap) {
            const period = hour < 12 ? 'AM' : 'PM';
            let displayHour = hour % 12;
            displayHour = displayHour === 0 ? 12 : displayHour;

            const labelHour = displayHour === 12 && period === 'AM' ? '00' : appendZero(displayHour);
            const label = `${labelHour}:${appendZero(minutes)} ${period}`;
            const value = `${appendZero(hour)}:${appendZero(minutes)}`;

            times.push({ label, value });
        }
    }

    return times;
};

const timeArray = generateTimeArray();

const DaySlot: React.FC<TimeSlotProps> = ({ showError, time, weekLabel, weekValue, onChange }) => {
    const [avail, setAvail] = useState(false);
    const [timesOptions, setTimesOptions] = useState<BaseOptionType[]>();
    const [times, setTimes] = useState<ITimePair[]>([]);

    const disableOverlappingTimes = useCallback((existTimes: ITimePair[], allTimesOptions: BaseOptionType[]) => {
        const starts = new Set(existTimes.map((period) => period.start));

        return allTimesOptions.map((e) => {
            const isStart = starts.has(e.value);
            const timePeriod = { start: e.value, end: e.value };

            const isOverlapping = existTimes.some((period) => isTimeOverlap(period, timePeriod));

            if (isStart || isOverlapping) {
                return { ...e, disabled: true };
            }

            return e;
        });
    }, []);

    //fill zero
    const fillZero = useCallback((num: number | string) => {
        return Number(num) < 10 ? `0${num}` : num;
    }, []);

    useEffect(() => {
        const initTimes = time.map((e) => {
            const t = {
                start: '',
                end: '',
            };

            if (
                (typeof e.fromHour === 'number' || e.fromHour) &&
                (typeof e.fromMinute === 'number' || e.fromMinute)
            ) {
                t.start = `${fillZero(e.fromHour)}:${fillZero(e.fromMinute)}`;
            }

            if (
                (typeof e.toHour === 'number' || e.toHour) &&
                (typeof e.toMinute === 'number' || e.toMinute)
            ) {
                t.end = `${fillZero(e.toHour)}:${fillZero(e.toMinute)}`;
            }

            return t;
        });

        setTimes(initTimes);
        setAvail(time.length > 0);
    }, [fillZero, time]);

    useEffect(() => {
        const disabledByExistTimes = disableOverlappingTimes(times, timeArray);

        setTimesOptions(disabledByExistTimes);
    }, [disableOverlappingTimes, times]);

    const handleChange = useCallback((changeValue:ITimePair[]) => {
        onChange?.({
            weekDay: weekValue,
            hourFromTo: changeValue.map((e) => {
                return {
                    fromHour: e.start?.split(':')[0] ? parseInt(e.start.split(':')[0], 10) : '',
                    fromMinute: e.start?.split(':')[1] ? parseInt(e.start.split(':')[1], 10) : '',
                    toHour: e.end?.split(':')[0] ? parseInt(e.end.split(':')[0], 10) : '',
                    toMinute: e.end?.split(':')[1] ? parseInt(e.end.split(':')[1], 10) : '',
                };
            }),
        });
    }, [onChange, weekValue]);

    const handleAdd = useCallback((newData: {
        start: string;
        end: string;
    }) => {
        const newTime = newData || {
            start: '',
            end: '',
        };
        const newTimes = [...times, newTime];
        setTimes(newTimes);
        handleChange(newTimes);
    }, [handleChange, times]);

    const handleAvailabelChange = useCallback((e: boolean) => {
        setAvail(e);
        if (!e) {
            setTimes([]);
            handleChange([]);
        } else if (times.length === 0) {
            handleAdd({
                start: '09:00',
                end: '17:00',
            });
        }
    }, [handleAdd, handleChange, times]);

    const handleDelete = useCallback((index: number) => {
        remove(times, (e, i) => i === index);
        const newTimes = [...times];
        setTimes(newTimes);
        handleChange(newTimes);
    }, [handleChange, times]);

    const handleTimeSelect = useCallback((v: ITime, index: number) => {
        update(times, index, (item: ITimePair) => {
            // 如果end选好了，但是又将start改成了晚于end，那么将end置空，重新选
            if (v.tag === ETimeTag.START && item.end) {
                const startTimeNumber = transTimeStringToNumber(v.value);
                const endTimeNumber = transTimeStringToNumber(item.end);

                if (startTimeNumber > endTimeNumber || !v.value) {
                    return {
                        ...item,
                        [v.tag]: v.value,
                        [ETimeTag.END]: '',
                    };
                }
            }

            return {
                ...item,
                [v.tag]: v.value,
            };
        });

        const newTimes = [...times];

        setTimes(newTimes);
        handleChange(newTimes);
    }, [handleChange, times]);

    return (
        <div className={s.wrap}>
            <Switch className={s.availableSwitch} checked={avail} onChange={handleAvailabelChange} />
            <div className={s.dayLabel}>{weekLabel}</div>
            <div className={s.times}>
                <div className={s.timeList}>
                    {
                        times.map((e, i) => {
                            return (
                                <TimeSlot
                                    showError={showError}
                                    index={i}
                                    key={i}
                                    times={times}
                                    timeOptions={timesOptions}
                                    time={e}
                                    onSelect={handleTimeSelect}
                                    onDelete={handleDelete}
                                />
                            );
                        })
                    }
                </div>
                { avail && <div className={s.add} onClick={handleAdd} /> }
            </div>
        </div>
    );
};

export default DaySlot;
