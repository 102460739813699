// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--TOSDa {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
}
.s-module__wrap--TOSDa .s-module__content--ZcsGs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 768px;
}
.s-module__wrap--TOSDa .s-module__content--ZcsGs img {
  margin-bottom: 36px;
  width: 30vw;
  max-width: 300px;
}
.s-module__wrap--TOSDa .s-module__content--ZcsGs .s-module__title--_VN3J {
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  color: var(--gray-900);
  text-align: center;
}
.s-module__wrap--TOSDa .s-module__content--ZcsGs .s-module__desc--BbyNu {
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  letter-spacing: 0.01em;
  color: var(--gray-500);
  text-align: center;
}
.s-module__new--Vbu1_ {
  padding: 1px 4px;
  background-color: var(--blue-50);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #2B82EE;
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/components/GlobalLayer/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;AACJ;AAPA;EASQ,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACR;AAdA;EAgBY,mBAAA;EACA,WAAA;EACA,gBAAA;AACZ;AAnBA;EAsBY,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;AAAZ;AA3BA;EA+BY,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,sBAAA;EACA,kBAAA;AADZ;AAOA;EACI,gBAAA;EACA,gCAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,qBAAA;AALJ","sourcesContent":[".wrap {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    font-family: 'Inter';\n    font-style: normal;\n\n    .content {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        width: 768px;\n\n        img {\n            margin-bottom: 36px;\n            width: 30vw;\n            max-width: 300px;\n        }\n\n        .title {\n            margin-bottom: 20px;\n            font-weight: 800;\n            font-size: 44px;\n            line-height: 60px;\n            color: var(--gray-900);\n            text-align: center;\n        }\n\n        .desc {\n            font-weight: 400;\n            font-size: 24px;\n            line-height: 37px;\n            letter-spacing: 0.01em;\n            color: var(--gray-500);\n            text-align: center;\n        }\n    }\n\n}\n\n.new {\n    padding: 1px 4px;\n    background-color: var(--blue-50);\n    font-family: Inter;\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 18px;\n    text-align: left;\n    color: #2B82EE;\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--TOSDa`,
	"content": `s-module__content--ZcsGs`,
	"title": `s-module__title--_VN3J`,
	"desc": `s-module__desc--BbyNu`,
	"new": `s-module__new--Vbu1_`
};
export default ___CSS_LOADER_EXPORT___;
