import React, { useState } from 'react';
import s from './s.module.less';
import { Tooltip, message, Spin } from 'antd';
import { getBillingPortalLink } from 'api/subscription';

type IProps = {
    status?: string;
};

const EhrPaymentStatus: React.FC<IProps> = (props) => {
    const { status } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const goToStripe = async () => {
        setIsLoading(true);
        try {
            const result = await getBillingPortalLink();
            if (!result.error) {
                const link = result.data.data;
                if (link) {
                    window.open(link, '_blank');
                } else {
                    message.error('Customer billing portal is for paid user only.');
                }
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };
    return (
        <Spin spinning={isLoading}>
            <div className={s.descItem}>
                <span>Payment: </span>
                <span className={`${s[status || '']}`}>
                    {status}
                    {status === 'Failed' && (
                        <Tooltip
                            color="#fff"
                            overlayInnerStyle={{
                                color: '#333',
                                padding: 16,
                            }}
                            title="To avoid service interruption, please update your payment information immediately. "
                        >
                            <span className={s.failLink} onClick={() => goToStripe()} />
                        </Tooltip>
                    )}
                </span>
            </div>
        </Spin>
    );
};

export default EhrPaymentStatus;
