import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Checkbox, Col, Row, Space } from 'antd';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface IProps {
    value?: string[];
    options: DefaultOptionType[];
    showCheckAll?: boolean;
    checkAllText?: string;
    onChange?: (v: CheckboxValueType[]) => void;
}

const CheckGroupColumn = ({
    value,
    options,
    showCheckAll,
    checkAllText = 'All of the above',
    onChange,
}: IProps) => {
    const [val, setVal] = useState<string[]>([]);
    const [ops, setOps] = useState<DefaultOptionType[]>([]);

    useEffect(() => {
        if (value) {
            if (value.length === options.length) {
                setVal(['all', ...value]);
            } else {
                setVal([...value]);
            }
        }
    }, [value]);

    useEffect(() => {
        if (showCheckAll) {
            setOps([...options, { value: 'all', label: checkAllText }]);    
        }
        
    }, [options]);

    const handleCheckall = useCallback((e: CheckboxChangeEvent) => {
        let v: string[] = [];
        if (e.target.checked) {
            v = ops.map((option) => option.value?.toString() as string)
        }

        setVal(v);
        onChange?.(v.filter((e) => e !== 'all'));
    }, [ops]);


    const handleOnChange = useCallback((v: CheckboxChangeEvent, oldValue: any[]) => {
        let newValue:any[] = [];
        if (oldValue.includes(v.target.value as string)) {
            newValue = oldValue.filter((e) => e !== v.target.value);
        } else {
            newValue = [...oldValue, v.target.value as string];
        }

        setVal(newValue);
        onChange?.(newValue.filter((e) => e !== 'all'));
    }, [onChange]);

    const handleSingleCheckboxChange = useCallback((e: CheckboxChangeEvent) => {
        if (e.target.value === 'all') {
            handleCheckall(e);
        } else {
            handleOnChange(e, val)
        }
    }, [handleCheckall, val]);

    return (
        <div className={s.wrap}>
            <Space direction="vertical">
                <Checkbox.Group value={val}>
                    <Row>
                        {
                            ops?.map((e: DefaultOptionType, i: number) => {
                                const { value: optionValue, label } = e;

                                return (
                                    <Col key={i} span={12}>
                                        <Checkbox value={optionValue?.toString()} onChange={handleSingleCheckboxChange}>{label}</Checkbox>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Checkbox.Group>
                {/* {
                    showCheckAll && (
                        <Checkbox
                            checked={checkAll}
                            onChange={handleCheckall}
                        >{checkAllText}
                        </Checkbox>
                    )
                } */}
            </Space>
        </div>
    );
};

export default CheckGroupColumn;
