import React from 'react';
import s from './s.module.less';
import { Button } from 'antd';
import CommonStore from 'store/Common';
import commonS from 'styles/common.module.less';

type Props = {
    successCallbackPath?: string,
    type?:'primary',
    onClick?:()=>void,
    text?:string,
};

export default function AuthGoogleCalendar(props: Props) {
    const { successCallbackPath, type, onClick, text } = props;
    const [, setCommon] = CommonStore.useStore();

    const url = `${window.location.protocol}//${window.location.host}`;
    const authLink = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcloud-platform%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.modify%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&client_id=189053781471-tsm9j66gn95h7t3r9cm9erhfcbs5c2gb.apps.googleusercontent.com&redirect_uri=${encodeURIComponent(url)}%2Fredirect`;
    return (
        <div className={s.wrap}>
            <Button
                block
                type={type || 'default'}
                className={`${s.btn} ${!type ? commonS.lightBtn : ''}`}
                onClick={() => {
                    if (successCallbackPath) {
                        setCommon('authGoogleCalendarCallbackPath', successCallbackPath);
                    }
                    if (onClick) {
                        onClick?.();
                    }
                    window.open(authLink, '_self');
                }}
            >
                { text || 'Grant Access'}
            </Button>
        </div>
    );
}
