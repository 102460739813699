import React from 'react';
import { Button, Radio } from 'antd';
import s from './s.module.less';
// import useMyListedChannels from 'hooks/useMyListedChannels';
import { Prospect } from 'types/common';
import { SecondStepDataType } from '../../types';
import { ColumnsType } from 'antd/es/table';
import { SearchOutlined, PhoneOutlined,
} from '@ant-design/icons';
import CommonTable from 'components/CommonTable';

type Props = {
    onUpdate: (data?: Partial<Prospect>[]) => void;
    data?:SecondStepDataType;
};

const SecondStep = (props:Props) => {
    const { data, onUpdate } = props;
    const needAction = data?.pendingSelectData && data?.pendingSelectData?.length > 0;
    const pendingSelectData = data?.pendingSelectData || [];
    const [pendingSelect, setPendingSelect] = React.useState<number[]>([]);

    //pendingAddToServerData mean not and conflict data
    const [pendingAddToServerData, setPendingAddToServerData] = React.useState<Partial<Prospect>[]>([]);

    React.useEffect(() => {
        setPendingAddToServerData([...(data?.pendingAddData || [])]);
        if (data?.pendingSelectData) {
            setPendingSelect(data?.pendingSelectData?.map(() => 1));
        }
    }, [data]);

    React.useEffect(() => {
        const selectData: Partial<Prospect>[] = [];
        if (pendingSelect.length === pendingSelectData?.length) {
            for (let i = 0; i < pendingSelectData.length; i++) {
                if (pendingSelectData[i].length > pendingSelect[i]) {
                    selectData.push(pendingSelectData[i][pendingSelect[i]]);
                } else {
                    selectData.push(pendingSelectData[i][pendingSelect[i]]);
                }
            }
        }
        onUpdate?.([...selectData, ...pendingAddToServerData]);
    }, [pendingSelect, pendingSelectData, pendingAddToServerData]);

    const columns: ColumnsType<Partial<Prospect>> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            width: '30%',
            filterIcon: <SearchOutlined />,
            render: (name:string, item:Partial<Prospect>) => {
                return (
                    <div className={s.nameWrap}>
                        <span>{item?.firstName} {item?.lastName}</span>
                    </div>
                );
            },
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            render: (name:string, item:Partial<Prospect>) => {
                return (
                    <div className={s.emailWrap}>
                        <div className={s.email}>{item.email}</div>
                        <div className={s.phoneWithIcon}>
                            <PhoneOutlined />
                            <p className={s.val}>{item.tel || 'No phone number provided'}</p>
                        </div>
                    </div>
                );
            },
        },
        {
            title: '',
            width: '150px',
            render: (item) => {
                return (
                    <div>
                        <Button
                            className={s.link}
                            onClick={() => {
                                const newData = [...pendingAddToServerData];
                                const index = newData.findIndex((i) => i.email === item.email);
                                if (index > -1) {
                                    newData.splice(index, 1);
                                    setPendingAddToServerData(newData);
                                }
                            }}
                            type="text"
                        >
                            Remove
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div className={s.wrap}>
            {
                needAction && (
                    <>
                        <div className={s.tips}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.25706 3.09858C9.02167 1.73928 10.9788 1.73928 11.7434 3.09858L17.3237 13.0191C18.0736 14.3523 17.1102 15.9996 15.5805 15.9996H4.4199C2.89025 15.9996 1.92682 14.3523 2.67675 13.0191L8.25706 3.09858ZM11.0001 12.9998C11.0001 13.552 10.5524 13.9998 10.0001 13.9998C9.44784 13.9998 9.00012 13.552 9.00012 12.9998C9.00012 12.4475 9.44784 11.9998 10.0001 11.9998C10.5524 11.9998 11.0001 12.4475 11.0001 12.9998ZM10.0001 4.99976C9.44784 4.99976 9.00012 5.44747 9.00012 5.99976V8.99976C9.00012 9.55204 9.44784 9.99976 10.0001 9.99976C10.5524 9.99976 11.0001 9.55204 11.0001 8.99976V5.99976C11.0001 5.44747 10.5524 4.99976 10.0001 4.99976Z" fill="#FBBF24" />
                            </svg>
                            {pendingSelectData.length} patients cannot be added due to duplication with existing patient records
                        </div>
                        <h3>Patient information conflict detected</h3>
                        <div className={s.des}>The following patients have the same email address but different name or phone number. </div>
                        <div className={s.des}>Please review and choose which record to keep for each. </div>
                        <div className={s.forSelect}>
                            {
                                pendingSelectData?.map((items, inx) => {
                                    return (
                                        <div className={s.selectItem} key={items[0].email}>
                                            <Radio.Group
                                                onChange={(e) => {
                                                    const newData = [...pendingSelect];
                                                    newData[inx] = e.target.value;
                                                    setPendingSelect(newData);
                                                }}
                                                value={pendingSelect[inx]}
                                            >
                                                <div className={s.selectItemIn}>
                                                    {
                                                        items.map((item, index) => {
                                                            const val = index;
                                                            return (
                                                                <Radio value={val} key={index}>
                                                                    {item.email} - {item.firstName} {item.lastName} - {item.tel} {item.channel ? `- ${item.channel}` : 'Others'}
                                                                </Radio>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </Radio.Group>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </>
                )
            }
            <h3>Review patients added</h3>
            <div className={s.tableWrap}>
                <CommonTable
                    bordered
                    rowKey="email"
                    columns={columns}
                    data={pendingAddToServerData || []}
                />
            </div>
        </div>
    );
};

export default SecondStep;
