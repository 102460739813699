import { Button, Form, Modal, message } from 'antd';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import FormItem from './FormItem';
import NumberSelector from 'components/NumberSelector';
import { EBookingLinkModalMode, ISpecialtyNServiceTypeResponse } from 'types/helloKlarity';
import { IEditBookingLink, IFormData } from 'pages/operation/CustomizeHelloKlarityPPP/type';
import commonS from 'styles/common.module.less';
import { getInfoFromLinkName } from 'utils/helloklarity';
import capitalize from 'lodash/capitalize';

type TSelectOption = {
    content: string;
    dictKey: string;
};

type TLink = TSelectOption & {
    directBookingLink?: string
};

interface IProps {
    providerId: number;
    specialtyNServiceTypeData?: ISpecialtyNServiceTypeResponse;
    editingData?: IEditBookingLink;
    linkOptions: { content: string, dictKey: string, directBookingLink?: string }[];
    mode?: EBookingLinkModalMode;
    open?: boolean;
    onSave?: (mode: EBookingLinkModalMode, value: IEditBookingLink | IEditBookingLink[]) => void;
    hideModal: () => void;
    onCopyLink?: (value?: IEditBookingLink) => void;
    onRemove?: (value: IEditBookingLink) => void;
}

const BookingLinkModal = ({
    providerId,
    specialtyNServiceTypeData,
    editingData,
    linkOptions,
    open,
    mode,
    onSave,
    hideModal,
    onCopyLink,
    onRemove,
}: IProps) => {
    const [formMode, setFormMode] = useState<EBookingLinkModalMode>();
    const [formInstance] = Form.useForm<IFormData | IEditBookingLink>();
    const [links, setLinks] = useState<TLink[]>([]);
    const [serviceTypeOptions, setServiceTypeOptions] = useState<TSelectOption[]>([]);
    const [specialtyOptions, setSpecialtyOptions] = useState<TSelectOption[]>([]);
    const [allConditionsList, setAllConditionsList] = useState<Record<string, TSelectOption[]>>();
    const [formListErrors, setFormListErrors] = useState([]);

    useEffect(() => {
        setFormMode(mode);

        if (mode === EBookingLinkModalMode.LINK_EDIT && editingData) {
            const { specialty, bookingLinkName, directBookingLink } = editingData;
            formInstance.setFieldsValue({
                ...editingData,
                specialty,
                bookingLinkName: bookingLinkName || directBookingLink,
            });
        }
    }, [editingData, formInstance, mode]);

    useEffect(() => {
        if (specialtyNServiceTypeData) {
            const { serviceSpecialtyList = [], serviceTypeList = [], specialtyCondition } = specialtyNServiceTypeData;

            setServiceTypeOptions(serviceTypeList.map((item: string) => ({ content: item, dictKey: item })));
            setSpecialtyOptions(serviceSpecialtyList.map((item: string) => ({ content: item, dictKey: item })));

            if (specialtyCondition) {
                setAllConditionsList(
                    Object.entries(specialtyCondition)
                        .reduce((r: Record<string, TSelectOption[]>, [k, v]: [k: string, string[]]) => {
                            r[k] = v.map((e) => ({ content: e, dictKey: e }));

                            return r;
                        }, {}),
                );
            }
        }
    }, [specialtyNServiceTypeData]);

    const getInfoFromLink = useCallback((linkName: string) => {
        const info = getInfoFromLinkName(linkName, specialtyNServiceTypeData);

        return info;
    }, [specialtyNServiceTypeData]);

    const handleLinkSelectorChange = useCallback((e: string[]) => {
        const items = linkOptions.filter((option) => e.includes(option.dictKey));

        setLinks(items);
    }, [linkOptions]);

    const handleNext = useCallback(() => {
        if (links && links.length > 0) {
            const formData = links.map((link: TLink) => {
                const info = getInfoFromLink(link.dictKey) || {};

                return {
                    ...info,
                    directBookingLink: link.directBookingLink,
                };
            });

            setFormMode(EBookingLinkModalMode.LINK_ADD);

            formInstance.setFieldsValue({
                bookingList: formData,
            });
        }
    }, [formInstance, getInfoFromLink, links]);

    const handleAdd = useCallback((add: (defaultValue?: any) => void) => {
        add();
    }, []);

    const handleSaveEdit = useCallback(() => {
        formInstance.validateFields()
            .then((formData) => {
                onSave?.(mode, formData);
            })
            .catch((errors) => {
                if (errors?.errorFields?.length > 0) {
                    setFormListErrors(errors?.errorFields);
                    message.error(errors.errorFields[0].errors?.[0]);
                }
            });
    }, [formInstance, mode, onSave]);

    const checkItemInFormListIsError = useCallback((index: number) => {
        const checkRes = formListErrors.find((error) => {
            return error?.name?.[1] === index;
        });

        return !!checkRes;
    }, [formListErrors]);

    const modalContentRender = useMemo(() => {
        if (formMode === EBookingLinkModalMode.LINK_SELECT) {
            return (
                <div className={s.selectLinkName}>
                    <div className={s.lable}>Booking link</div>
                    <NumberSelector
                        placeholder="Select booking links"
                        fieldNames={{
                            label: 'content',
                            value: 'dictKey',
                        }}
                        onChange={handleLinkSelectorChange}
                        options={linkOptions}
                    />
                </div>
            );
        } else {
            return (
                <div className={s.form}>
                    <Form
                        form={formInstance}
                        id="bookingListForm"
                        layout="vertical"
                    >
                        {
                            formMode === EBookingLinkModalMode.LINK_EDIT ?
                                <FormItem
                                    watchField="specialty"
                                    watchFieldAllConditionsField="allCondition"
                                    linkOptions={linkOptions}
                                    serviceTypeOptions={serviceTypeOptions}
                                    specialtyOptions={specialtyOptions}
                                    allConditionsList={allConditionsList}
                                    bookingLinkNameField="bookingLinkName"
                                    directBookingLinkField="directBookingLink"
                                    stateField="state"
                                    specialtyField="specialty"
                                    serviceTypeField="serviceType"
                                    conditionsField="conditions"
                                    allConditionsField="allCondition"
                                    idField="id"
                                    uuidField="uuid"
                                    fullPrefillField=""
                                    getInfoFromLinkName={getInfoFromLink}
                                    mode={formMode}
                                    onRemove={() => {
                                        if (editingData) {
                                            onRemove?.(editingData);
                                        }
                                    }}
                                    onCopyLink={() => onCopyLink?.(editingData)}
                                /> :
                                <Form.List name="bookingList">
                                    {
                                        (fields, { add, remove }) => {
                                            return (
                                                <>
                                                    {
                                                        fields.map(({ key, name }, index) => {
                                                            const isError = checkItemInFormListIsError(name);
                                                            return (
                                                                <FormItem
                                                                    isError={isError}
                                                                    index={name}
                                                                    watchField="bookingList"
                                                                    watchFieldAllConditionsField="bookingList"
                                                                    linkOptions={linkOptions}
                                                                    serviceTypeOptions={serviceTypeOptions}
                                                                    specialtyOptions={specialtyOptions}
                                                                    allConditionsList={allConditionsList}
                                                                    key={key}
                                                                    bookingLinkNameField={[name, 'bookingLinkName']}
                                                                    directBookingLinkField={[name, 'directBookingLink']}
                                                                    stateField={[name, 'state']}
                                                                    specialtyField={[name, 'specialty']}
                                                                    serviceTypeField={[name, 'serviceType']}
                                                                    idField={[name, 'id']}
                                                                    uuidField={[name, 'uuid']}
                                                                    conditionsField={[name, 'conditions']}
                                                                    allConditionsField={[name, 'allCondition']}
                                                                    fullPrefillField={['bookingList', name]}
                                                                    onRemove={() => remove(index)}
                                                                    getInfoFromLinkName={getInfoFromLink}
                                                                    onCopyLink={() => onCopyLink?.(formInstance.getFieldsValue(true)?.bookingList?.[name])}
                                                                />
                                                            );
                                                        })
                                                    }
                                                    <a className={s.add} onClick={() => handleAdd(add)}>Add another link</a>
                                                </>
                                            );
                                        }
                                    }
                                </Form.List>
                        }
                    </Form>
                </div>
            );
        }
    }, [formMode, handleLinkSelectorChange, linkOptions, formInstance, serviceTypeOptions, specialtyOptions, allConditionsList, getInfoFromLink, editingData, onRemove, onCopyLink, handleAdd, checkItemInFormListIsError]);

    const modalFooterRender = useMemo(() => {
        if (formMode === EBookingLinkModalMode.LINK_SELECT) {
            return (
                <div className={s.footer}>
                    <Button onClick={hideModal}>Cancel</Button>
                    <Button type="primary" onClick={handleNext}>Next</Button>
                </div>
            );
        }

        if (formMode === EBookingLinkModalMode.LINK_EDIT) {
            return (
                <div className={s.footer}>
                    <Button onClick={hideModal}>Cancel</Button>
                    <Button type="primary" onClick={handleSaveEdit}>Save Changes</Button>
                </div>
            );
        }

        if (formMode === EBookingLinkModalMode.LINK_ADD) {
            return (
                <div className={s.footer}>
                    <Button onClick={hideModal}>Cancel</Button>
                    <Button type="primary" onClick={handleSaveEdit}>Add</Button>
                </div>
            );
        }
    }, [formMode, hideModal, handleNext, handleSaveEdit]);

    return (
        <Modal
            title={mode === EBookingLinkModalMode.LINK_EDIT ? 'Edit direct booking links' : 'Add direct booking links'}
            className={commonS.modalFixHeightWrap}
            maskClosable={false}
            open={open}
            onCancel={hideModal}
            zIndex={1001}
            footer={modalFooterRender}
        >
            { modalContentRender }
        </Modal>
    );
};

export default BookingLinkModal;
