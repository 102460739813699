import dayjs from 'dayjs';

export enum FormFieldEnum {
    CHANNEL = 'channel',
    SERVICE_ID = 'serviceId',
    MEETING_LINK = 'meetingLink',
    LOCATION = 'location',
    TIME = 'time',
    PATIENT_NAME = 'patientName',
    PATIENT_EMAIL = 'patientEmail',
    PATIENT_PHONE = 'patientPhone',
    SEND_FORM = 'sendForm',
    CHANNEL_ID = 'channelId',
}

export interface FormInterface {
    [FormFieldEnum.CHANNEL]: string;
    [FormFieldEnum.SERVICE_ID]: number | string;
    [FormFieldEnum.CHANNEL_ID]: number | string;
    [FormFieldEnum.MEETING_LINK]: string;
    [FormFieldEnum.LOCATION]: string;
    [FormFieldEnum.TIME]: dayjs.Dayjs[];
    [FormFieldEnum.PATIENT_NAME]: string;
    [FormFieldEnum.PATIENT_EMAIL]: string;
    [FormFieldEnum.PATIENT_PHONE]: string;
    [FormFieldEnum.SEND_FORM]: string;
}
