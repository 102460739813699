
import { Button, message, Modal, Spin, Upload } from 'antd';
import React, { useCallback, useEffect } from 'react';
import s from './s.module.less';
import type { UploadProps } from 'antd';
import { uploadFile } from 'api/common';
import commonS from 'styles/common.module.less';

interface IProps {
    title: string,
    submiting?: boolean,
    open?: boolean,
    value?: string,
    onOk?: (v: string) => void,
    onCancel?: () => void,
}

const UploadModal = ({
    title,
    submiting,
    open,
    onOk,
    onCancel,
    value,
}: IProps) => {
    const [uploadloading, setUploadLoading] = React.useState(false);
    const [license, setLicense] = React.useState<string>();

    useEffect(() => {
        if (!open) {
            setLicense(undefined);
            setUploadLoading(false)
        }
    }, [open])

    const hideModal = useCallback(() => {
        onCancel?.();
    }, [onCancel]);
    
    const handleAdd = useCallback(() => {
        if (license) {
            onOk?.(license);
        }
    }, [license, onOk]);

    const props: UploadProps = {
        name: 'file',
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        headers: {
            authorization: 'authorization-text',
        },
        showUploadList: false,
        multiple: false,
        accept: '.png,.jpg,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                const isLt10M = file.size / 1024 / 1024 <= 10;
                if (!isLt10M) {
                    message.error('File must smaller than 10MB!');
                    reject(false);
                } else {
                    resolve(true);
                }
            });
        },
        customRequest: async (data) => {
            setUploadLoading(true);
            try {
                const formData = new FormData();
                formData.append('file', data.file);
                const res = await uploadFile(formData);
                if (!res.error) {
                    setLicense(res.data?.data)
                }
            } catch (e) {
                console.error(e);
            } finally {
                setUploadLoading(false);
            }
        },
    };
    return (
        <Modal
            title={title}
            className={commonS.modalFixHeightWrap}
            width={951}
            open={open}
            onCancel={hideModal}
            footer={(
                <div className={s.modalFooter}>
                    <Button onClick={hideModal}>Cancel</Button>
                    <Button loading={submiting || uploadloading} type="primary" onClick={handleAdd}>Add</Button>
                </div>
            )}
        >
            <div className={s.modalLabel}>Certificate copy</div>
            <div className={s.uploaderWrap}>
            {
                !license ? (
                    <Spin spinning={uploadloading}>
                        <div className={s.upload}>
                            <Upload.Dragger {...props}>
                                <div className={s.uploadBox}>
                                    <div className={s.uploadIcon} />
                                    <p><span>Upload a file </span>or drag and drop</p>
                                    <div className={s.desc}>PNG, JPG, PDF up to 10MB</div>
                                </div>
                            </Upload.Dragger>
                        </div>
                    </Spin>
                ) : (
                    <img className={s.previewImage} src={license} alt="license" />
                )
            }
        </div>
        </Modal>
    );
};

export default UploadModal;
