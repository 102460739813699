// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrapper--UtBGI {
  position: fixed;
  width: 40vw;
  height: 400px;
  background: #fff;
  bottom: 183px;
  right: 120px;
}
.s-module__wrapper--UtBGI .s-module__content--aEuxc {
  height: 300px;
}
@media only screen and (max-width: 768px) {
  .s-module__wrapper--UtBGI {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #fff;
    bottom: 0;
    right: 0;
  }
  .s-module__wrapper--UtBGI .s-module__content--aEuxc {
    height: calc(100vh - 100px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ChatBot/components/ChatBoxWrapper/s.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;EACA,YAAA;AACF;AAPA;EAQI,aAAA;AAEJ;AAEA;EACE;IACE,eAAA;IACA,YAAA;IACA,aAAA;IACA,gBAAA;IACA,SAAA;IACA,QAAA;EAAF;EANA;IAQI,2BAAA;EACJ;AACF","sourcesContent":[".wrapper {\n  position: fixed;\n  width: 40vw;\n  height: 400px;\n  background: #fff;\n  bottom: 183px;\n  right: 120px;\n  .content {\n    height: 300px;\n  }\n}\n\n@media only screen and (max-width: 768px) {\n  .wrapper {\n    position: fixed;\n    width: 100vw;\n    height: 100vh;\n    background: #fff;\n    bottom: 0;\n    right: 0;\n    .content {\n      height: calc(100vh - 100px);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `s-module__wrapper--UtBGI`,
	"content": `s-module__content--aEuxc`
};
export default ___CSS_LOADER_EXPORT___;
