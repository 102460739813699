import React from 'react';
import cx from 'classnames';
import AiChatStore from 'store/AiChatProvider';
import { getBotAnswer } from 'api/aiChat';
import { getUserId } from 'utils/localstore';
import { ChatBox, ChatController } from '../ChatBox';
import ChatBoxHeader from '../ChatBoxHeader';
import GrowIn from '../GrowIn';
import { BotAnswer } from 'types/aiChat';

import s from './s.module.less';

const chatCtl = new ChatController({
    option: { showDateTime: true },
    defaultMessages: [{
        type: 'text',
        content: '👋 Hello, I’m PF Virtual assistant! Great to meet you.',
        self: false,
    }],
});

function ChatBoxWrapper({ api }: { api?: string }): React.ReactElement {
    const [getAiChatData] = AiChatStore.useStore();
    const isShowChatBox = getAiChatData('isShowChatBox');
    const uid = `${getUserId() ?? '2'}`;

    const waitAndSendInput = async () => {
        const inputMsg = await chatCtl.setActionRequest({
            type: 'text',
        });
        const res = await getBotAnswer({ text: inputMsg.value, userid: uid, url: api });
        await chatCtl.addMessage({
            type: 'text',
            content: (res?.data?.msgs ?? []).map((item: BotAnswer) => item.text).join('\n') || res?.error || 'error',
            self: false,
        });
        await waitAndSendInput();
    };

    React.useEffect(() => {
        waitAndSendInput();
    }, []);

    return (
        <GrowIn inProp={isShowChatBox}>
            <div className={cx([s.wrapper, 'shadow-md'])}>
                <ChatBoxHeader />
                <div className={s.content}>
                    <ChatBox chatController={chatCtl} />
                </div>
            </div>
        </GrowIn>
    );
}

export default ChatBoxWrapper;
