import { Request } from '../request';

export const getBotAnswer = ({ text, userid, url }:{ text: string, userid: string, url?: string }) =>
    Request.post({
        url: url ?? 'https://onwish.co/brewery/chat/',
        params: {
            userid,
            text,
            chatbot_id: 'klarity-front-desk',
        },
        disableToken: true,
    });

export const getRandomUser = () =>
    Request.get({
        url: 'https://randomuser.me/api/',
    });
