/* eslint-disable react/no-unescaped-entities */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Form, Button, Input, Modal, Select, Checkbox, InputNumber, Tooltip, message } from 'antd';
import { ProfileFormFieldEnum, StepValue } from 'pages/CreateUniProfilePage/types';
import { ISubmitProvider, Provider } from 'types/channelApplicationProvider';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import PhotoList from 'components/PhotoList';
import { FOREIGN_LANGUAGE } from 'constants/meta';
import copy from 'copy-to-clipboard';
import SwiperCore, {
    Navigation,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { removeHtmlTag } from 'utils/common';
import { IntroTextLength, EMPTY_PLACEHOLDER, SNAPSHOT_COLOR } from 'constants/common';
import PhotoEditorModal from 'components/PhotoEditorModal';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import JSZipUtils from 'jszip-utils';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import DefaultProfileAvatar from 'assets/common/defaultProviderAvatar.svg';
import TitleTip from 'assets/channel/titleTip.jpg';
import CredentialTip from 'assets/channel/credentialTip.jpg';
import HeadlineTip from 'assets/channel/headlineTIp.jpg';
import ProfileTip from 'assets/practiceFront/PF_profile_tip.png';
import AiTextEditor from 'components/AI/AiTextEditor';
import { InternalNamePath } from 'antd/es/form/interface';
import { EAIEditorType } from 'types/ai';
import { renderChangUI } from 'types/changeHighLight';
import { ProviderProfile } from 'types/provider';
import { getEhrPwdProviderId } from 'api/operation';
import HeadlinesEditor from 'components/HeadlinesEditor';
import HeadlineTipImage1 from 'assets/uniprofile/headlinetip1.jpg';
import HeadlineTipImage2 from 'assets/uniprofile/headlinetip2.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import VideoUploader from 'components/PhotoUploader';
import VideoPreview from 'components/VideoPreview';
import VideoList from 'components/VideoList';
import { VIDEO_UPLOAD_DEMO } from 'constants/common';

SwiperCore.use([Navigation]);

type ConcatPhoto = {
    originIndex: number;
    index: number;
    photo: string;
};
interface IProps {
    conversationId?: string;
    isAva?: boolean;
    isOps?: boolean;
    providerId?: number;
    // channelId?: number;
    initFormValue?: Partial<ProviderProfile>,
    editable?: boolean,
    showEditButtons: boolean,
    onSubmit: (value: Partial<ISubmitProvider>, step: StepValue) => void,
    onSubmitFailed?: (errorInfo: {
        values: Partial<Provider>,
        errorFields: {
            name: InternalNamePath;
            errors: string[];
        }[],
        outOfDate: boolean
    }) => void;
    onNext?: (value: Partial<Provider>) => void,
    onEditableChange?: (enable: boolean, step: StepValue, callback: () => void) => void,
    // refetch?: () => void,
}

const Profile = forwardRef(({
    conversationId,
    isAva,
    isOps,
    // channelId = -1,
    initFormValue,
    editable = false,
    showEditButtons = false,
    onSubmit,
    onNext,
    onEditableChange,
    onSubmitFailed,
    providerId,
    // refetch,
}: IProps, ref) => {
    const [credentialTypes] = useGetSettingData(EnumFields.CREDENTIAL_TYPE);
    const mustBeReplacedText = useRef('<strong>has [#] years of experience as a [Title] at [Hospital/clinic name] in [Practice settings]</strong>');
    const templateText = useRef(`* Delete all gray text and replace it with your own experience <br>[Your name] ${mustBeReplacedText.current}. [Talk about your key achievements, highlights, or associations]. [Talk about your treatment philosophy, how you treat patients, and what you believe in].`);
    const [enable, setEnable] = useState(!!editable);
    const [showAboutMeTemplateModal, setShowAboutMeTemplateModal] = useState(false);
    const [disableNIP, setDisableNIP] = useState(false);
    const [currentSelectedPhoto, setCurrentSelectedPhoto] = useState<ConcatPhoto | undefined>();
    const [profileForm] = Form.useForm<Partial<Provider>>();
    const [ehrPwd, setEhrPwd] = React.useState('');

    const videoUrl = VIDEO_UPLOAD_DEMO;

    useEffect(() => {
        if (initFormValue) {
            profileForm.setFieldsValue({
                ...initFormValue,
            });
            if (initFormValue?.credential && initFormValue.credential.length > 0 && !initFormValue.npi) {
                setDisableNIP(true);
            }
        }
    }, [initFormValue, profileForm]);

    useEffect(() => {
        setEnable(editable);
    }, [editable]);

    const {
        id,
        firstName,
        lastName,
        tel,
        email,
        npi,
        title,
        intro,
        primaryLanguage,
        foreignLanguage,
        photoList: photoListData = [],
        additionalPhotoList = [],
        videoList = [],
        photo = '',
        credential,
        headLine,
        middleName,
        npiNotRequired,
        yearExp,
        gmail,
        hasEhrAccountPwd,
        headLineList
    } = initFormValue || {};

    useEffect(() => {
        setDisableNIP(!!npiNotRequired);
    }, [npiNotRequired]);

    const allPhotos = useMemo(() => {
        const photoData = photoListData?.map((p, index) => ({
            originIndex: index,
            photo: p,
        })) || [];
        const additionalPhtoData = additionalPhotoList?.map((p, index) => ({
            originIndex: index,
            photo: p,
        })) || [];

        return photoData.concat(additionalPhtoData).map((p, index) => ({
            ...p,
            index,
        }));
    }, [additionalPhotoList, photoListData]);

    useEffect(() => {
        const index = allPhotos.findIndex((p) => p.photo === photo);

        if (index > -1) {
            setCurrentSelectedPhoto(allPhotos[index]);
        }
    }, [allPhotos, photo]);

    const hideTemplateModal = useCallback(() => setShowAboutMeTemplateModal(false), []);
    const showTemplateModal = useCallback(() => setShowAboutMeTemplateModal(true), []);

    const handleEnableStatusChange = useCallback((status: boolean) => {
        if (typeof onEditableChange === 'function') {
            onEditableChange(status, StepValue.PROFILE, () => {
                setEnable(status);
            });
        }
    }, [onEditableChange]);

    const handleUseTemplate = useCallback(() => {
        profileForm.setFieldsValue({ intro: templateText.current });
        hideTemplateModal();
    }, [hideTemplateModal, profileForm]);

    const handleSubmit = useCallback(() => {
        profileForm.validateFields().then((values) => {
            if (typeof onSubmit === 'function') {
                onSubmit({
                    ...values,
                    credential: values?.credential?.join(','),
                }, StepValue.PROFILE);
            }
        }).catch((e) => {
            console.error(334, e);
        });
    }, [onSubmit, profileForm]);

    const handleDisableNIP = useCallback((e: CheckboxChangeEvent) => {
        profileForm.validateFields();
        profileForm.setFieldValue(ProfileFormFieldEnum.NPI_NUMBER, '');
        setDisableNIP(e.target.checked);
    }, [profileForm]);

    const loadImage = (url: string) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'anonymous'; // 设置为 "anonymous" 启用跨域支持

            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = url;
        });
    };

    const downloadImageAndAddToZip = async (zip: JSZip, url: string, filename: string) => {
        try {
            const img: any = await loadImage(url);

            // 创建一个 canvas 元素，将图片绘制到 canvas 上
            const canvas = document.createElement('canvas');
            canvas.width = img?.width;
            canvas.height = img?.height;
            const ctx = canvas.getContext('2d')!;
            ctx.drawImage(img, 0, 0);

            // 将 canvas 数据添加到 zip 文件
            const imageDataUrl = canvas.toDataURL('image/png');
            const binaryData = atob(imageDataUrl.split(',')[1]);
            const dataArray = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
                dataArray[i] = binaryData.charCodeAt(i);
            }

            zip.file(filename, dataArray, { binary: true });
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };

    const _handleDownloadPhoto = async (urls: string[], zipFileName: string) => {
        const zip = new JSZip();
        const zipFilename = `${zipFileName}.zip`;
        let count = 0;
        try {
            urls.forEach(async (url, index) => {
                const filename = `file-${index}.jpg`;
                await downloadImageAndAddToZip(zip, url, filename);
                count++;
                if (count === urls.length) {
                    const zipFile = await zip.generateAsync({ type: 'blob' });
                    saveAs(zipFile, zipFilename);
                }
            });
        } catch (ex) {
            console.error(ex);
        }
    };

    const handleDownloadPhoto = useCallback((urls: string[], zipFileName: string) => {
        const zip = new JSZip();
        const zipFilename = `${zipFileName}.zip`;
        let count = 0;
        if (urls.length === 1) {
            window.open(urls[0], '_blank');
            return;
        }
        try {
            urls.forEach((url, index) => {
                const filename = `file-${index}.jpg`;
                // loading a file and add it in a zip file
                JSZipUtils.getBinaryContent(url, async (err, data) => {
                    if (err) {
                        throw err; // or handle the error
                    }
                    zip.file(filename, data, { binary: true });
                    count++;
                    if (count === urls.length) {
                        const zipFile = await zip.generateAsync({ type: 'blob' });
                        saveAs(zipFile, zipFilename);
                    }
                });
            });
        } catch (e) {
            if (urls && urls.length > 0) {
                window.open(urls[0], '_blank');
            }
            console.error(e);
        }
    }, []);

    useImperativeHandle(ref, () => ({
        submit: handleSubmit,
    }));

    const renderEditButtons = useMemo(() => {
        if (showEditButtons) {
            return !enable ? (
                <Button
                    onClick={() => handleEnableStatusChange(true)}
                >
                    Edit
                </Button>
            ) : (
                <div className={s.editButtons}>
                    <Button
                        onClick={() => handleEnableStatusChange(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                    >
                        Save changes
                    </Button>
                </div>
            );
        }

        return null;
    }, [enable, handleEnableStatusChange, handleSubmit, showEditButtons]);

    const realSelectedIndex = useMemo(() => {
        const photoListLength = photoListData?.length;

        if (currentSelectedPhoto) {
            if (currentSelectedPhoto.index + 1 > photoListLength) {
                return {
                    photoListIndex: -1,
                    additionalListIndex: currentSelectedPhoto?.originIndex,
                };
            }

            return {
                photoListIndex: currentSelectedPhoto?.originIndex,
                additionalListIndex: -1,
            };
        }

        return {
            photoListIndex: -1,
            additionalListIndex: -1,
        };
    }, [currentSelectedPhoto, photoListData]);

    const introWithBr = intro?.replace(/\n/g, '<br />');

    const handleCopyPwd = async () => {
        if (ehrPwd) {
            copy(ehrPwd || '', { format: 'text/plain' });
            message.success('Copied to the clipboard');
        } else {
            const result = await getEhrPwdProviderId(providerId!);
            if (!result.error) {
                const pwd = result.data.data;
                setEhrPwd(pwd);
                copy(pwd || '', { format: 'text/plain' });
                message.success('Copied to the clipboard');
            }
        }
    };

    const defaultCover = initFormValue?.photo || initFormValue?.photoList?.[0] || DefaultProfileAvatar;

    return (
        <div className={s.module}>
            <Modal
                title="About me template"
                centered
                open={showAboutMeTemplateModal}
                okText="Use this template"
                onOk={handleUseTemplate}
                onCancel={hideTemplateModal}
                width={543}
            >
                <p>Jeremy has 20 years of nursing experience as a Registered Nurse at New York University Langone Health and Mount Sinai Health System in a variety of settings. He is a member of the American Psychiatric Nurses Association and The Tallahassee Area Council of Advanced Practice Nurses. He is committed to providing quality, compassionate, and comprehensive mental health services to adolescents and adults. </p>
            </Modal>
            <div className={s.wrap}>
                <Form
                    id={StepValue.PROFILE}
                    form={profileForm}
                    name={StepValue.PROFILE}
                    className={commonS.formStyle1}
                    // initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onNext}
                    onFinishFailed={onSubmitFailed}
                    scrollToFirstError
                >
                    <div className={s.module}>
                        <h3>
                            <span>Profile</span>
                            <div className={s.buttonsWrap}>
                                {renderEditButtons}
                            </div>
                        </h3>
                        {
                            !enable ? (
                                <>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={s.label}>Title</div>
                                            <div className={s.value}>{title || EMPTY_PLACEHOLDER}</div>
                                            {renderChangUI('title', initFormValue, !!isOps)}
                                        </div>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={`${s.label} ${s.required}`}>First name</div>
                                            <div className={s.value}>{firstName}</div>
                                            {renderChangUI('firstName', initFormValue, !!isOps)}
                                        </div>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={s.label}>Middle name</div>
                                            <div className={s.value}>{middleName || EMPTY_PLACEHOLDER}</div>
                                            {renderChangUI('middleName', initFormValue, !!isOps)}
                                        </div>
                                        <div className={s.displayItem}>
                                            <div className={`${s.label} ${s.required}`}>Last name</div>
                                            <div className={s.value}>{lastName}</div>
                                            {renderChangUI('lastName', initFormValue, !!isOps)}
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={`${s.label}`}>Kiwi account</div>
                                            <div className={s.value}>{gmail || 'Not created yet'}</div>
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={`${s.label} ${s.required}`}>Years of experience in healthcare</div>
                                            <div className={s.value}>{yearExp}</div>
                                            {renderChangUI('yearExp', initFormValue, !!isOps)}
                                        </div>
                                    </div>
                                    {
                                        !!hasEhrAccountPwd &&
                                        <div className={s.row}>
                                            <div
                                                className={s.displayItem}
                                            >
                                                <div className={`${s.label}`}>EHR initial password</div>
                                                <div className={`${s.value} ${s.pswCopy}`}>
                                                    <span style={{ paddingTop: '8px' }}>********</span>
                                                    <Button
                                                        onClick={handleCopyPwd}
                                                        size="small"
                                                        className={s.btn}
                                                        type="text"
                                                    >Copy
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={`${s.label} ${s.required}`}>Credentials</div>
                                            <div className={s.value}>{credential?.map((e) => e).join(',')}</div>
                                            {renderChangUI('credential', initFormValue, !!isOps, (val: string[]) => { return val.join(','); })}
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={`${s.label} ${s.required}`}>Headline</div>
                                            <div className={s.value}>{headLine}</div>
                                            {renderChangUI('headLine', initFormValue, !!isOps)}
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={`${s.label} ${s.required}`}>Personal phone number</div>
                                            <div className={s.value}>{tel}</div>
                                            {renderChangUI('tel', initFormValue, !!isOps)}
                                        </div>
                                        <div className={s.displayItem}>
                                            <div className={`${s.label} ${s.required}`}>Email</div>
                                            <div className={s.value}>{email}</div>
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={`${s.label} ${s.required}`}>About me</div>
                                            <div
                                                className={s.value}
                                                dangerouslySetInnerHTML={{
                                                    __html: introWithBr || '',
                                                }}
                                            />
                                            {renderChangUI('intro', initFormValue, !!isOps, (val: string) => { return val?.replace(/\n/g, '<br />'); })}
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div className={s.displayItem}>
                                            <div className={`${s.label} ${s.required}`}>NPI number</div>
                                            <div className={s.value}>{npiNotRequired && !npi ? 'My practice doesn’t required an NPI' : npi}</div>
                                            {renderChangUI('npi', initFormValue, !!isOps)}
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={`${s.label} ${s.required}`}>Profile photo</div>
                                            <img className={s.profileAvatar} src={photo || DefaultProfileAvatar} />
                                            {renderChangUI('photo', initFormValue, !!isOps, (val: string) => { return <img className={s.profileAvatar} style={{ border: `2px solid ${SNAPSHOT_COLOR}`, marginTop: '12px' }} src={val} />; })}
                                            {isOps && <div className={s.downloadPhotos} onClick={() => handleDownloadPhoto([photo], 'ProfilePhoto')}>Download</div>}
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={`${s.label}`}>Additional photos</div>
                                            {additionalPhotoList?.length > 0 ? <PhotoList selectedPhotoIndex={realSelectedIndex.additionalListIndex} enable={enable} min={3} data={additionalPhotoList} /> : EMPTY_PLACEHOLDER}
                                            {additionalPhotoList?.length > 0 && isOps && <div className={s.downloadPhotos} onClick={() => handleDownloadPhoto(additionalPhotoList, 'AddtionalPhotos')}>Download</div>}
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={`${s.label}`}>Videos</div>
                                            <div className={s.subLabel}>
                                                <p>Build trust with patients by adding a short video to your UniProfile. Let them see your face and hear your voice to create rapport early on.</p>
                                                <p>Not sure where to start? We've got you covered! Watch the example video for ideas by clicking <a href={videoUrl} target="_blank">the video link</a>.</p>
                                            </div>
                                            <VideoList
                                                isOps={isOps}
                                                providerId={providerId}
                                                defaultCover={defaultCover}
                                                max={3}
                                                value={videoList}
                                                enable={false} />
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={`${s.label} ${s.required}`}>Main language</div>
                                            <div className={s.value}>{primaryLanguage}</div>
                                            {renderChangUI('primaryLanguage', initFormValue, !!isOps)}
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <div className={s.label}>Additional languages/dialects</div>
                                            <div className={s.value}>{foreignLanguage?.join(',') || EMPTY_PLACEHOLDER}</div>
                                            {renderChangUI('foreignLanguage', initFormValue, !!isOps, (val: string[]) => { return val.join(','); })}
                                        </div>
                                    </div>
                                </>
                            )
                                : (
                                    <>
                                        <div className={s.row}>
                                            <div
                                                id={ProfileFormFieldEnum.TITLE}
                                                style={{
                                                    position: 'relative',
                                                    top: '-120px',
                                                }}
                                            />
                                            <div
                                                className={s.displayItem}
                                                style={{ marginRight: '24px' }}
                                            >
                                                <Form.Item
                                                    label={
                                                        <div className={s.formLabel}>Title
                                                            <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '24px' }} color="#FFFFFF" title={<div className={s.tipText}>Title will appear before the name. <img src={TitleTip} /></div>}>
                                                                <div className={s.questionMarkIcon} />
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    name={ProfileFormFieldEnum.TITLE}
                                                    // validateFirst
                                                    rules={[
                                                        { max: 10, message: 'Exceeded maximum character length of 10' },
                                                    ]}
                                                >
                                                    <Input type="text" placeholder="Ex. Dr., Sr., Hon., Mr., Mrs., Ms." />
                                                </Form.Item>
                                            </div>
                                            <div
                                                className={s.displayItem}
                                                style={{ marginRight: '24px' }}
                                            >
                                                <div
                                                    id={ProfileFormFieldEnum.FIRST_NAME}
                                                    style={{
                                                        position: 'relative',
                                                        top: '-120px',
                                                    }}
                                                />
                                                <Form.Item
                                                    label="First name"
                                                    name={ProfileFormFieldEnum.FIRST_NAME}
                                                    validateFirst
                                                    rules={[
                                                        { required: true, message: 'First name is a required field' },
                                                        { max: 50, message: 'Exceeded maximum character length of 50' },
                                                    ]}
                                                >
                                                    <Input type="text" />
                                                </Form.Item>
                                            </div>
                                            <div
                                                className={s.displayItem}
                                                style={{ marginRight: '24px' }}
                                            >
                                                <Form.Item
                                                    label="Middle name"
                                                    name={ProfileFormFieldEnum.MIDDLE_NAME}
                                                    rules={[
                                                        { max: 50, message: 'Exceeded maximum character length of 50' },
                                                    ]}
                                                >
                                                    <Input type="text" />
                                                </Form.Item>
                                            </div>
                                            <div
                                                className={s.displayItem}
                                                style={{ marginRight: '24px' }}
                                            >
                                                <div
                                                    id={ProfileFormFieldEnum.LAST_NAME}
                                                    style={{
                                                        position: 'relative',
                                                        top: '-120px',
                                                    }}
                                                />
                                                <Form.Item
                                                    label="Last name"
                                                    name={ProfileFormFieldEnum.LAST_NAME}
                                                    validateFirst
                                                    rules={[
                                                        { required: true, message: 'Last name is a required field' },
                                                        { max: 50, message: 'Exceeded maximum character length of 50' },
                                                    ]}
                                                >
                                                    <Input type="text" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        {
                                            isOps && (
                                                <div className={s.row}>
                                                    <div
                                                        className={s.displayItem}
                                                        style={{ marginRight: '24px' }}
                                                    >
                                                        <Form.Item
                                                            style={{ width: '360px' }}
                                                            label="Kiwi account"
                                                            name={ProfileFormFieldEnum.GMAIL}
                                                        // rules={[{ required: true, message: 'Years of experience is required' }]}
                                                        >
                                                            <Input disabled />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className={s.row}>
                                            <div
                                                id={ProfileFormFieldEnum.YEARS_OF_EXPERIENCE}
                                                style={{
                                                    position: 'relative',
                                                    top: '-120px',
                                                }}
                                            />
                                            <div
                                                className={s.displayItem}
                                                style={{ marginRight: '24px' }}
                                            >
                                                <Form.Item
                                                    style={{ width: '360px' }}
                                                    label="Years of experience in healthcare"
                                                    name={ProfileFormFieldEnum.YEARS_OF_EXPERIENCE}
                                                    rules={[{ required: true, message: 'Years of experience is required' }]}
                                                >
                                                    <InputNumber min={0} controls={false} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                id={ProfileFormFieldEnum.CREDENTIAL}
                                                style={{
                                                    position: 'relative',
                                                    top: '-120px',
                                                }}
                                            />
                                            <div
                                                className={s.displayItem}
                                            >
                                                <Form.Item
                                                    label={
                                                        <div className={s.formLabel}>Credentials
                                                            <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '24px' }} color="#FFFFFF" title={<div className={s.tipText}>Credentials will appear right next to your name.<img src={CredentialTip} /></div>}>
                                                                <div className={s.questionMarkIcon} />
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    name={ProfileFormFieldEnum.CREDENTIAL}
                                                    validateFirst
                                                    rules={[
                                                        { required: true, message: 'Credentials is a required field' },
                                                    ]}
                                                >
                                                    <Select placeholder="search and select your credentials" mode="multiple" fieldNames={{ label: 'content', value: 'dictKey' }} options={credentialTypes} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                id={ProfileFormFieldEnum.HEADLINE}
                                                style={{
                                                    position: 'relative',
                                                    top: '-120px',
                                                }}
                                            />
                                            <div
                                                className={s.displayItem}
                                            >
                                                <Form.Item
                                                    label={
                                                        <div className={s.formLabel}>Headline
                                                            <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '24px' }} color="#FFFFFF" title={
                                                                <>
                                                                    <div className={s.tipText}>Provide a punchy headline about yourself to stand out. Your headline will appear on all channels' profiles, including your Practice Front and Klarity profile page.</div>
                                                                    <Swiper navigation={true}>
                                                                        <SwiperSlide key={0} className={s.leftWrap}>
                                                                            <div style={{ width: '344px', margin: '0 auto' }}>
                                                                                <img src={HeadlineTipImage1} />
                                                                            </div>
                                                                        </SwiperSlide>
                                                                        <SwiperSlide key={1} className={s.leftWrap}>
                                                                            <div style={{ width: '344px', margin: '0 auto' }}>
                                                                                <img src={HeadlineTipImage2} />
                                                                            </div>
                                                                        </SwiperSlide>
                                                                    </Swiper>
                                                                </>
                                                            }>
                                                                <div className={s.questionMarkIcon} />
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    name={ProfileFormFieldEnum.HEADLINE}
                                                    validateFirst
                                                    rules={[
                                                        { required: true, message: 'Headline is a required field' },
                                                        { max: 220, message: 'Exceeded maximum character length of 220' },
                                                    ]}
                                                >
                                                    {/* <Input type="text" /> */}
                                                    <HeadlinesEditor options={headLineList} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                id={ProfileFormFieldEnum.TEL}
                                                style={{
                                                    position: 'relative',
                                                    top: '-120px',
                                                }}
                                            />
                                            <div
                                                className={s.displayItem}
                                                style={{ marginRight: '12px' }}
                                            >
                                                <Form.Item
                                                    label={
                                                        <div className={s.formLabel}>Personal phone number
                                                            <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '24px' }} color="#FFFFFF" title={<div className={s.tipText2}>Only used for Kiwi Ops to support you; will not display to the public</div>}>
                                                                <div className={s.questionMarkIcon} />
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    name={ProfileFormFieldEnum.TEL}
                                                    validateFirst
                                                    rules={[
                                                        { required: true, message: 'Personal phone number is a required field' },
                                                    ]}
                                                >
                                                    <InputNumber controls={false} />
                                                </Form.Item>
                                            </div>
                                            <div
                                                className={s.displayItem}
                                                style={{ marginLeft: '12px' }}
                                            >
                                                <Form.Item
                                                    label="Email"
                                                    name={ProfileFormFieldEnum.EMAIL}
                                                // validateFirst
                                                // rules={[
                                                //     { required: true, message: 'Email is a required field' },
                                                // ]}
                                                >
                                                    <Input type="text" disabled />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                id={ProfileFormFieldEnum.INTRO}
                                                style={{
                                                    position: 'relative',
                                                    top: '-120px',
                                                }}
                                            />
                                            <div
                                                className={s.displayItem}
                                            >
                                                <div className={s.formItemLabel}>About me <span className={s.required} /></div>
                                                <div className={s.subLabel}>What would you like to share with your patients about yourself?</div>
                                                {/* <div className={`${commonS.tips} ${s.tips}`}>
                                                    <img src={SparklesIcon} />
                                                    <p>Patients want to read about the providers' professional experiences before committing to who they feel is the right choice for their needs. <span onClick={showTemplateModal}>See template</span></p>
                                                </div> */}
                                                <Form.Item
                                                    // label="About me"
                                                    name={ProfileFormFieldEnum.INTRO}
                                                    validateFirst
                                                    rules={[
                                                        { required: true, message: 'About me is a required field' },
                                                        {
                                                            validator: (rule, value) => {
                                                                if (removeHtmlTag(value).length > IntroTextLength) {
                                                                    return Promise.reject(`Maximum ${IntroTextLength} characters`);
                                                                } else {
                                                                    return Promise.resolve();
                                                                }
                                                            },
                                                        },
                                                        {
                                                            validator: (rule, value) => {
                                                                if (value.indexOf('<strong>') > -1) {
                                                                    return Promise.reject('Please remove all gray placeholder text');
                                                                } else {
                                                                    return Promise.resolve();
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    {/* <RichText className={s.textarea} maxLength={IntroTextLength} placeholder="Ex. Years of experience, work experience and title, affiliated associations, treatment philosophy" /> */}
                                                    <AiTextEditor isOps={isOps || undefined} providerId={providerId} type={EAIEditorType.ABOUTME} conversionId={conversationId} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                className={s.displayItem}
                                            >
                                                <Form.Item
                                                    label="NPI number"
                                                    name={ProfileFormFieldEnum.NPI_NUMBER}
                                                    style={{ width: '312px' }}
                                                    validateFirst
                                                    rules={[
                                                        { required: !disableNIP, message: 'NPI number is a required field' },
                                                    ]}
                                                >
                                                    <Input disabled={disableNIP} type="text" />
                                                </Form.Item>
                                                <Form.Item
                                                    name={ProfileFormFieldEnum.NPI_NOT_REQUIRED}
                                                    style={{ marginTop: '-22px' }}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox checked={disableNIP} onChange={handleDisableNIP}>My practice doesn't require an NPI</Checkbox>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                id={ProfileFormFieldEnum.PHOTO}
                                                style={{
                                                    position: 'relative',
                                                    top: '-120px',
                                                }}
                                            />
                                            <div
                                                className={s.displayItem}
                                            >
                                                <div className={s.formItemLabel}>
                                                    Profile photo <span className={s.required} />
                                                    <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '12px' }} color="#FFFFFF" title={<div className={s.tipTextWrap}>Photos that include a background typically achieve the best possible appearance and visibility across various channels. <img src={ProfileTip} /></div>}>
                                                        <div className={s.questionMarkIcon} />
                                                    </Tooltip>
                                                </div>
                                                <div className={s.subLabel}>Upload a professional photo of yourself with a preferred background. A top-notch profile photo is likely to generate 3x more clicks. You can crop and rotate the image as needed.</div>
                                                <Form.Item
                                                    // label="Profile photo"
                                                    name={ProfileFormFieldEnum.PHOTO}
                                                    rules={[
                                                        { required: true, message: 'Profile photo is a required field' },
                                                    ]}
                                                >
                                                    <PhotoEditorModal
                                                        isOps={isOps}
                                                        providerId={Number(id)}
                                                        borderRadius
                                                        title="Edit photo"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                className={s.displayItem}
                                            >
                                                <div className={s.formItemLabel}>Additional photos</div>
                                                <div className={s.subLabel}>Upload some photos of you, your office or staff members to allow patients know more about your practice</div>
                                                <Form.Item
                                                    // label="Additional photos"
                                                    name={ProfileFormFieldEnum.ADDITIONAL_PHOTO}
                                                    valuePropName="data"
                                                >
                                                    <PhotoList enable={enable} min={3} data={additionalPhotoList} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                className={s.displayItem}
                                            >
                                                <div className={s.formItemLabel}>Videos</div>
                                                <div className={s.subLabel}>
                                                    <p>Build trust with patients by adding a short video to your UniProfile. Let them see your face and hear your voice to create rapport early on.</p>
                                                    <p>Not sure where to start? We've got you covered! Watch the example video for ideas by clicking <a href={videoUrl} target="_blank">the video link</a>.</p>
                                                </div>
                                                <Form.Item
                                                    // label="Additional photos"
                                                    name={ProfileFormFieldEnum.VIDEO_LIST}
                                                >
                                                    <VideoList
                                                        isOps={isOps}
                                                        providerId={providerId}
                                                        defaultCover={defaultCover}
                                                        enable={enable} max={3} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                id={ProfileFormFieldEnum.PRIMARY_LANGUAGE}
                                                style={{
                                                    position: 'relative',
                                                    top: '-120px',
                                                }}
                                            />
                                            <div
                                                className={s.displayItem}
                                            >
                                                <Form.Item
                                                    label="Main language"
                                                    name={ProfileFormFieldEnum.PRIMARY_LANGUAGE}
                                                    style={{ width: '312px' }}
                                                    validateFirst
                                                    rules={[
                                                        { required: true, message: 'Main language is a required field' },
                                                    ]}
                                                >
                                                    <Input type="text" disabled />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                className={s.displayItem}
                                            >
                                                <Form.Item
                                                    label="Additional languages/dialects"
                                                    name={ProfileFormFieldEnum.FOREIGN_LANGUAGE}
                                                >
                                                    <Select
                                                        mode="tags"
                                                        placeholder="Enter the language here"
                                                        style={{ width: '100%' }}
                                                        options={FOREIGN_LANGUAGE}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </>
                                )
                        }
                    </div>
                </Form>
            </div>
        </div>
    );
});

export default Profile;
