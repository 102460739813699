import { Request } from '../request';
import { getGoogleToken } from 'utils/localstore';
import { Event } from 'types/calendar';

type FetchEvent = {
    maxResults: number,
    orderBy: 'startTime', //must put orderBy and singleEvents
    singleEvents: boolean,
    timeMin: string,
    timeMax: string,
};

export const calendarEventFetch = (params:FetchEvent) =>
    Request.get({ url: 'https://www.googleapis.com/calendar/v3/calendars/primary/events',
        params,
        config: { headers: {
            Authorization: `Bearer ${getGoogleToken()}`,
        },
        },
    });

export const calendarEventCreate = (params:Partial<Event>) =>
    Request.post({ url: 'https://www.googleapis.com/calendar/v3/calendars/primary/events',
        params,
        config: { headers: {
            Authorization: `Bearer ${getGoogleToken()}`,
        },
        },
    });

export const calendarColorFetch = () =>
    Request.get({ url: 'https://www.googleapis.com/calendar/v3/colors',
        config: { headers: {
            Authorization: `Bearer ${getGoogleToken()}`,
        },
        },
    });

export const profileEventFetch = () =>
    Request.get({ url: 'https://www.googleapis.com/oauth2/v1/userinfo?alt=json',
        config: { headers: {
            Authorization: `Bearer ${getGoogleToken()}`,
        },
        },
    });
