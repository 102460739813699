import { Request } from "../request";
import { GetOpsDetailInput } from "types/operation";

export type TRegisterWithPwd = {
  email: string;
  inform: 0 | 1;
  password: string;
  signUpFrom?: string;
};

export const registerWithPwd = (data: TRegisterWithPwd) =>
  Request.post({
    url: "/schedule-server/public/provider/registerWithPwd",
    params: data,
    disableErrorNote: true,
  });

type TLoginWithPwd = {
  email: string;
  password: string;
};

export const loginWithPwd = (data: TLoginWithPwd) =>
  Request.post({
    url: "/schedule-server/public/provider/loginWithPwd",
    params: data,
    disableErrorNote: true,
  });

type TSendForget = {
  email: string;
};

export const sendForget = (data: TSendForget) =>
  Request.post({
    url: "/schedule-server/public/provider/sendForget",
    params: data,
    disableErrorNote: true,
  });

export type TRegisterWithoutPwd = {
  credential: string;
  inform: 0 | 1;
  signUpFrom?: string;
};

export const registerWithoutPwd = (data: TRegisterWithoutPwd) =>
  Request.post({
    url: "/schedule-server/public/provider/registerWithoutPwd",
    params: data,
    disableErrorNote: true,
  });

type TLoginWithoutPwd = {
  credential: string;
};

export const loginWithoutPwd = (data: TLoginWithoutPwd) =>
  Request.post({
    url: "/schedule-server/public/provider/loginWithoutPwd",
    params: data,
  });

type TSavePassword = {
  code: number;
  email: string;
  password: string;
};

export const savePassword = (data: TSavePassword) =>
  Request.post({
    url: "/schedule-server/public/provider/savePassword",
    params: data,
    disableErrorNote: true,
  });

type ICheckEmail = {
  email?: string;
  code?: string;
};

export const checkMail = (data: ICheckEmail) =>
  Request.post({
    url: "/schedule-server/public/provider/checkMail",
    params: { code: data?.code ? parseInt(data.code) : '', email: data.email },
  });

export const sendVerify = (email: string, source?: string, sourceId?: string) =>
  Request.post({
    url: "/schedule-server/public/provider/verify",
    params: { email: email, source, sourceId },
  });
