import React from 'react';
import Logo from 'assets/common/Logo.svg';
import s from './s.module.less';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

type Props = {
    children: React.ReactNode;
    footerButton: React.ReactNode;
    addHeader?: boolean;
    contentId?: string;
    wrapClass?: string;
    headerClass?: string;
    contentClass?: string;
    footerClass?: string;
    headerRightButtons?: React.ReactNode
};

const FullScreenPopLayout = (props: Props) => {
    const { children, footerButton, addHeader, contentId, wrapClass, headerClass, contentClass, footerClass, headerRightButtons } = props;

    return (
        <div className={`${s.wrap} ${wrapClass || ''}`}>
            {
                addHeader &&
                <div className={`${s.header} ${headerClass || ''}`}>
                    <div className={s.logo}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="38" viewBox="0 0 30 38" fill="none">
                            <path d="M0.56655 8.32718C1.32188 6.98692 6.13053 7.35927 6.68041 8.32718C7.23029 9.29508 5.7271 17.1544 9.22213 19.7246C9.58608 19.9922 9.93202 20.2089 10.2715 20.3853C10.7438 20.1867 11.2362 19.9645 11.7638 19.7246C16.962 17.3612 16.2581 10.0872 21.6559 8.32718C24.7645 7.31358 29.4868 7.28477 29.968 8.32718C30.4492 9.36959 25.418 17.3544 20.282 19.7246C18.0303 20.7637 16.6123 21.3468 14.1682 21.2144C12.6052 21.1298 11.4794 21.0129 10.2715 20.3853C8.33313 21.2001 6.73218 21.6157 4.41347 21.2144C2.83815 20.9418 1.32202 20.9165 0.56655 19.7246C-0.18892 18.5327 -0.18878 9.66744 0.56655 8.32718Z" fill="url(#paint0_linear_2161_10535)" />
                            <path d="M0.56655 36.5587C1.32188 37.9408 6.13053 37.5568 6.68041 36.5587C7.23029 35.5605 5.7271 27.4556 9.22213 24.8051C9.58608 24.5291 9.93202 24.3056 10.2715 24.1238C10.7438 24.3285 11.2362 24.5577 11.7638 24.8051C16.962 27.2423 16.2581 34.7436 21.6559 36.5587C24.7645 37.604 29.4869 37.6337 29.968 36.5587C30.4492 35.4837 25.418 27.2493 20.282 24.8051C18.0303 23.7335 16.6123 23.1322 14.1682 23.2687C12.6052 23.356 11.4794 23.4765 10.2715 24.1238C8.33313 23.2835 6.73218 22.8549 4.41347 23.2687C2.83815 23.5498 1.32202 23.576 0.56655 24.8051C-0.18892 26.0343 -0.188779 35.1765 0.56655 36.5587Z" fill="url(#paint1_linear_2161_10535)" />
                            <path d="M16.6892 6.00651C16.2796 8.32937 13.8787 10.6526 12.222 10.9068C10.7739 10.6602 8.90349 7.02875 9.31307 4.7059C9.72266 2.38304 11.7059 0.791152 13.7428 1.15031C15.7796 1.50946 17.0988 3.68366 16.6892 6.00651Z" fill="url(#paint2_linear_2161_10535)" />
                            <defs>
                                <linearGradient id="paint0_linear_2161_10535" x1="33.0429" y1="7.45587" x2="-2.83393" y2="7.45587" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#02FF74" />
                                    <stop offset="0.359375" stopColor="#0BCA88" />
                                    <stop offset="1" stopColor="#000AF0" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_2161_10535" x1="33.0429" y1="37.4572" x2="-2.83393" y2="37.4572" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#02FF74" />
                                    <stop offset="0.359375" stopColor="#0BCA88" />
                                    <stop offset="1" stopColor="#000AF0" />
                                </linearGradient>
                                <linearGradient id="paint2_linear_2161_10535" x1="18.179" y1="1.93241" x2="9.35793" y2="0.37701" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#02FF74" />
                                    <stop offset="0.359375" stopColor="#0BCA88" />
                                    <stop offset="1" stopColor="#000AF0" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <div className={s.right}>
                            <div className={s.logoTitle}>Kiwi Health</div>
                            <div className={s.logoDes}>by Klarity</div>
                        </div>
                    </div>
                    {headerRightButtons}
                </div>
            }
            <div className={`${s.content} ${contentClass || ''}`} id={contentId || ''}>
                <div>
                    {children}
                </div>
            </div>
            <div className={`${s.footer} ${footerClass || ''}`}>
                {footerButton}
            </div>
        </div>
    );
};

export default FullScreenPopLayout;
