import React from "react";
import s from './s.module.less';

const EHRAvaRecommandLabel = () => {
    return (
        <div className={s.avaRecommand}>Recommended by <span className={s.avaLogo}>Ava</span></div>
    )
}

export default EHRAvaRecommandLabel;
