import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import cx from 'classnames';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';

import s from './s.module.less';
import { TFaqInfo } from 'types/operation';

const { Panel } = Collapse;

const FrequencyQuestionSecion = ({
    data = [],
    title = '',
    openAll = false,
    largerFontsize = false,
}: {
    data?: TFaqInfo[];
    title?: string;
    openAll?: boolean;
    largerFontsize?: boolean;
}) => {
    const [openedKey, setOpenedKey] = React.useState<string[]>([]);

    const onChange = (key: string | string[]) => {
        setOpenedKey(key as string[]);
    };

    React.useEffect(() => {
        if (openAll && data) {
            setOpenedKey(data.map((item) => item.question!));
        }
    }, [openAll, data]);

    if (data.length < 1) {
        return null;
    }

    return (
        <div className={`${s.qsWrap} ${largerFontsize ? s.qsWrapLargerFontsize : ''}`}>
            {
                title &&
                <h2 className={s.header}>
                    {title}
                </h2>
            }

            <div className={s.cards}>
                <Collapse activeKey={openedKey} rootClassName={s.collapse} bordered={false} defaultActiveKey={['1']} onChange={onChange}>
                    {data.map((item) => {
                        const fold = !openedKey.includes(item.question!);
                        return (
                            <Panel
                                header={
                                    <div
                                        className={cx(s.question, !fold && s.active)}
                                    >
                                        <span
                                            className={s.title}
                                            dangerouslySetInnerHTML={{ __html: item.question }}
                                        />
                                        <DownOutlined
                                            className={s.icon}
                                        />
                                    </div>
                                }
                                key={item.question!}
                                showArrow={false}
                            >
                                <div
                                    className={s.answer}
                                    dangerouslySetInnerHTML={{
                                        __html: item.answer || '',
                                    }}
                                />
                            </Panel>
                        );
                    })}
                </Collapse>
            </div>
        </div>
    );
};

export default FrequencyQuestionSecion;
