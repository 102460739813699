import { Request } from '../request';
import { IGetAnalysisProgressInput, IGetResumeInfo } from 'types/uniprofileResume';

export const uploadUniprofile = (data: FormData) =>
    Request.post<FormData>(
        {
            url: '/schedule-server/provider/profile/resume/upload',
            params: data,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        },
    );

export const getResumeAnalysisProcess = (data: IGetAnalysisProgressInput) => Request.post({ url: '/schedule-server/provider/profile/resume/progress', params: data });
export const getResumeInfo = (data: IGetResumeInfo) => Request.post({ url: '/schedule-server/provider/profile/resume/info', params: data });
