import { Button } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY, CHANNEL_DETAIL } from 'constants/common';
import { useNavigate } from 'react-router-dom';
import s from './s.module.less';
import { PATH } from 'constants/path';
// import { EStep, StepValue } from 'pages/CreateUniProfilePage/types';
import { STEPS } from 'pages/CreateUniProfilePage/data';
import { Email } from 'constants/contact';
import useGetUniprofileEntry from 'hooks/useGetUniprofileEntry';

interface IProps {
    ghost?: boolean;
    width?: string;
    listStatus: string;
    id: number;
    currentStep: string;
    hasSubmit: boolean;
    // onWithdraw: (channelId: number, step: string) => void;
    loading?: boolean;
    profileWebsite?: string;
    handleListToChannel?:() => void;
}

const ChannelStartButton = ({
    hasSubmit,
    currentStep,
    ghost = false,
    width = 'auto',
    listStatus = '',
    id,
    loading,
    profileWebsite,
    handleListToChannel,
}: IProps) => {
    const navigate = useNavigate();
    const [uniprofileEntryPath] = useGetUniprofileEntry();

    const handleListMe = useCallback(async (e: { stopPropagation: () => void; }) => {
        e.stopPropagation();

        const stepIndex = STEPS.findIndex((step: string) => step.toLowerCase() === currentStep.toLowerCase());

        try {
            let stepNumber = stepIndex > -1 ? stepIndex : 0;
            let navigateRoute = '';

            if (listStatus === 'not-listed') {
                // 如果当前用户未完成Uniprofile，则先让他去完成。
                if (!hasSubmit) {
                    stepNumber = 0;
                    navigateRoute = `${uniprofileEntryPath}?channelId=${id}&from=${CHANNEL_DETAIL}`;
                } else {
                    handleListToChannel?.();
                }
            } else if (listStatus === 'rejected') {
                stepNumber = STEPS.length - 1;

                navigateRoute = `${uniprofileEntryPath}?channelId=${id}&from=${CHANNEL_DETAIL}`;
            } else if (listStatus === 'listed') {
                if (profileWebsite) {
                    const url = profileWebsite?.indexOf('http') === 0 ? profileWebsite : `https://${profileWebsite}`;

                    window.open(url, '_blank');
                }
            } else if (listStatus === 'under-review') {
                navigateRoute = '';
                window.open(`mailto:${Email?.support}`);
            }

            if (navigateRoute) {
                sessionStorage.setItem(CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY, stepNumber.toString());
                navigate(navigateRoute);
            }
        } catch (err) {
            console.error(err);
        }
    }, [currentStep, listStatus, hasSubmit, uniprofileEntryPath, id, handleListToChannel, profileWebsite, navigate]);

    // 如果当前用户未完成Uniprofile，则先提示他去完成。
    const buttonText = useMemo(() => {
        let text = 'Please complete your uniprofile';

        if (!hasSubmit) {
            return text;
        } else {
            switch (listStatus) {
                case 'not-listed':
                    text = 'List me on this channel';
                    break;
                case 'listed':
                    text = 'Access My Channel Profile';
                    break;
                case 'under-review':
                    text = 'Follow up with Support team';
                    break;
                case 'rejected':
                    text = 'Edit My UniProfile';
                    break;
                // case 'approved':
                //     text = 'Edit application';
                //     break;
                default:
                    text = 'Edit My UniProfile';
            }

            return text;
        }
    }, [listStatus, hasSubmit]);
    return (
        <>
            {
                loading ?
                    null
                    :
                    <>
                        { listStatus === 'rejected' && <Button style={{ width, marginRight: '8px' }} className={s.channelSelectButton} type="primary" ghost onClick={() => handleListToChannel?.()}>List me on this channel again</Button> }
                        { buttonText && <Button style={{ width }} className={s.channelSelectButton} type="primary" ghost={ghost} onClick={handleListMe}>{buttonText}</Button> }
                    </>
            }
        </>
    );
};

export default ChannelStartButton;
