import { Request } from '../request';
import {
    GetOpsDetailInput,
} from 'types/operation';

export const providerFetch = (id: number) =>
    Request.get({ url: `/admin-server/provider/get/${id}` });

export const getChannelAuditDetail = (data: GetOpsDetailInput) => Request.post({ url: '/admin-server/provider/channel/audit/detail', params: data });

export const getProviderListedChannel = () => Request.get({ url: '/admin-server/channel/live/list' });

export const getAllServiceByPracticeAddressId = (providerId: number, addressId: number) => Request.get({ url: `/schedule-server/providerService/list/${providerId}/${addressId}` });

export const getProviderPlan = (provderId: number) => Request.get({ url: `/schedule-server/openapi/getProviderPlan/${provderId}` });
