import React from 'react';
import { Form, Space, Radio, DatePicker, Input, InputNumber, Modal, message, Checkbox } from 'antd';
import { EProspectType, Prospect } from 'types/common';
import { createProspect, updateProspect } from 'api/prospect';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import FileUpload from 'components/form/FileUpload';
import StateSelect from 'components/form/StateSelect';
import { formatDate } from 'utils/common';
import { FormProspect } from './types';
import GenderSelect from 'components/form/GenderSelect';
import InsuranceSelect from 'components/form/InsuranceSelect';
import dayjs from 'dayjs';
// import useMyListedChannels from 'hooks/useMyListedChannels';
import MyChannelSelect from 'components/form/MyChannelSelect';

const InputTextArea = Input.TextArea;

type Props = {
    show:boolean,
    item?:Prospect,
    onCannel?: (needRefresh?:boolean)=>void,
    onSuccess?: ()=>void
};

enum EBillingType {
    SELF_PAY = 'Self-pay',
    INSURANCE = 'Insurance',
}

const BillingTypeOption = [
    {
        label: EBillingType.SELF_PAY,
        value: EBillingType.SELF_PAY,
    },
    {
        label: EBillingType.INSURANCE,
        value: EBillingType.INSURANCE,
    },
];

const TEXT_MESSAGE = 'Text message';
const PHONE_CALL = 'Phone call';
const EMAIL = 'Email';

const AddAndUpdateProspects = (props: Props) => {
    const { show, item, onCannel, onSuccess } = props;
    const [submiting, setSubmiting] = React.useState(false);
    const [form] = Form.useForm<FormProspect>();
    const [isOthers, setIsOthers] = React.useState(false);
    const isEdit = !!item?.id;
    const [currentBillingType, setCurrentBillingType] = React.useState<EBillingType>();
    const [preferMethod, setPreferMethod] = React.useState<string[]>([TEXT_MESSAGE, PHONE_CALL, EMAIL]);

    const options = [
        { label: TEXT_MESSAGE, value: TEXT_MESSAGE, key: TEXT_MESSAGE },
        { label: PHONE_CALL, value: PHONE_CALL, key: PHONE_CALL },
        { label: EMAIL, value: EMAIL, key: EMAIL },
    ];
    // const [myChannels] = useMyListedChannels();
    const onSubmit = async () => {
        try {
            const createTime = formatDate(dayjs());

            const formValues = await form.validateFields();
            if (isOthers) {
                formValues.carrier = formValues.others;
                delete formValues.others;
            }
            if (formValues.channelId === 'others') {
                formValues.channelId = null;
            }
            if (!formValues.tel && !formValues.email) {
                message.error('Please enter email or phone number');
                return;
            }
            const data:Partial<Prospect> = {
                ...formValues,
                preferMethod: formValues.preferMethod!.join(','),
                birthday: formatDate(formValues.birthday),
                type: item?.type || EProspectType.PROSPECT,
            };

            setSubmiting(true);
            if (isEdit) {
                data.id = item.id;
                const result = await updateProspect(item.id, data);
                if (result.error) {
                    message.error(result.error);
                } else {
                    message.success('Prospect info has updated');
                    if (onSuccess) {
                        onSuccess();
                    }
                }
            } else {
                data.createTime = createTime;
                const result = await createProspect(data);
                if (result.error) {
                    //message.error(result.error);

                } else {
                    message.success('Prospect has added');
                    if (onSuccess) {
                        onSuccess();
                    }
                }
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            //const msg = e?.toString?.() || 'data error';
            //message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (item) {
            form.setFieldsValue({
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email,
                //@ts-ignore
                birthday: item.birthday ? dayjs(item.birthday) : undefined,
                channelId: item.channelId === null ? 'others' : item.channelId,
                tel: item.tel,
                address: item.address,
                city: item.city,
                state: item.state,
                zip: item.zip,
                gender: item.gender,
                billing: item.billing,
                carrier: item.carrier,
                plan: item.plan,
                notes: item.notes,
                idBack: item.idBack,
                idFront: item.idFront,
                //@ts-ignore
                preferMethod: item.preferMethod?.split(',') || [],
            });
            if (item.billing) {
                setCurrentBillingType(item.billing as any);
            }
        }
    }, [item]);

    return (
        <Modal
            title={isEdit ? 'Edit the prospect' : 'Add a prospect'}
            closable
            className={commonS.modalFixHeightWrap}
            width="700px"
            onCancel={() => {
                onCannel?.();
            }}
            okText="Save"
            open={show}
            onOk={onSubmit}
            destroyOnClose
            okButtonProps={{ loading: submiting }}
        >
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true, preferMethod }}
                autoComplete="off"
                layout="vertical"
                scrollToFirstError
            >
                <div className={s.modalWrap}>
                    <h3 style={{ border: 'none', paddingTop: 0 }}>Required information</h3>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="First name"
                                name={['firstName']}
                                rules={[
                                    { required: true, message: 'First name is required' },
                                ]}
                            >
                                <Input maxLength={25} placeholder="" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Last name"
                                name={['lastName']}
                                rules={[
                                    { required: true, message: 'Last name is required' },
                                ]}
                            >
                                <Input max={25} placeholder="" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Email"
                                name={['email']}
                                validateFirst
                                rules={[
                                    { type: 'email', message: 'Email is invalid' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Select a channel:"
                                name={['channelId']}
                                validateFirst
                                rules={[
                                    { required: true, message: 'Channel is required' },
                                ]}
                            >
                                <MyChannelSelect showOthers />
                            </Form.Item>
                        </div>
                    </div>
                    <h3 className={s.h3Btm}>Contact information</h3>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Phone number"
                                name={['tel']}
                                validateFirst
                                rules={[
                                    { required: false, message: 'Phone number is required' },
                                ]}
                            >
                                <Input maxLength={30} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Address"
                                name="address"
                                rules={[{ required: false, message: 'Address is required' }]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '120px' }}
                                label="City"
                                name="city"
                                rules={[{ required: false, message: 'City is required' }]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '100px' }}
                                label="State"
                                name="state"
                                rules={[{ required: false, message: 'State is required' }]}
                            >
                                <StateSelect />
                            </Form.Item>
                        </div>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '80px' }}
                                label="ZIP code"
                                name="zip"
                                rules={[{ required: false, message: 'ZIP code is required' }]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div
                            className={s.rowItem}
                            style={{ width: '100%' }}
                        >
                            <Form.Item
                                label="Preferred methods of contact"
                                name={['preferMethod']}
                            >
                                <Checkbox.Group
                                    options={options.map((op) => { return { ...op, disabled: preferMethod.includes(op.value) && preferMethod.length === 1 }; })}
                                    onChange={(e) => {
                                        setPreferMethod(e as string[]);
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <h3>Personal Information</h3>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Birthday"
                                name={['birthday']}
                                rules={[
                                    { required: false, message: 'Birthday is required' },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Gender"
                                name={['gender']}
                                rules={[
                                    { required: false, message: 'Gender is required' },
                                ]}
                            >
                                <GenderSelect />
                            </Form.Item>
                        </div>
                    </div>
                    <h3>ID</h3>
                    <div className={s.subTitle}>Upload photos of your ID</div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '240px' }}
                                label=""
                                name={['idFront']}
                                rules={[
                                    { required: false, message: 'Id photo is required' },
                                ]}
                            >
                                <FileUpload />
                            </Form.Item>
                        </div>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '240px' }}
                                label=""
                                name={['idBack']}
                                rules={[
                                    { required: false, message: 'Id photo is required' },
                                ]}
                            >
                                <FileUpload />
                            </Form.Item>
                        </div>
                    </div>
                    <h3>Billing</h3>
                    <div className={s.rowItem}>
                        <Form.Item
                            style={{ width: '360px' }}
                            label=""
                            name={['billing']}
                            rules={[
                                { required: false, message: 'BillingType is required' },
                            ]}
                        >
                            <Radio.Group onChange={(e) => {
                                setCurrentBillingType(e.target.value);
                            }}
                            >
                                <Space direction="vertical">
                                    {
                                        BillingTypeOption.map((typeItem) => {
                                            return (
                                                <Radio key={typeItem.value} value={typeItem.value}>{typeItem.label}</Radio>
                                            );
                                        })
                                    }
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    {
                        currentBillingType === EBillingType.INSURANCE &&
                        <div className={s.subModule}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '360px' }}
                                    label="Carrier"
                                    name={['carrier']}
                                    validateFirst
                                    rules={[
                                        { required: true, message: 'Carrier is required' },
                                    ]}
                                >
                                    <InsuranceSelect
                                        showOthers
                                        onChange={(val) => {
                                            if (val === 'others') {
                                                setIsOthers(true);
                                            } else {
                                                setIsOthers(false);
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            {
                                isOthers &&
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '360px' }}
                                        label=""
                                        name={['others']}
                                        validateFirst
                                        rules={[
                                            { required: true, message: 'Please input carrier' },
                                        ]}
                                    >
                                        <Input placeholder="Other carrier" />
                                    </Form.Item>
                                </div>
                            }
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '100%' }}
                                    label="Plan"
                                    name={['plan']}
                                    validateFirst
                                    rules={[
                                        { required: false, message: 'Carrier number is required' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                        </div>
                    }
                    {
                        !isEdit &&
                        <>
                            <h3 className={s.h3Btm}>Notes</h3>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '80%' }}
                                    label=""
                                    name={['notes']}
                                    validateFirst
                                    rules={[
                                        { required: false, message: 'Carrier number is required' },
                                    ]}
                                >
                                    <InputTextArea
                                        rows={5}
                                    />
                                </Form.Item>
                            </div>
                        </>
                    }
                </div>
            </Form>
        </Modal>
    );
};

export default AddAndUpdateProspects;
