import { Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { EStep, StepValue } from 'pages/CreateUniProfilePage/types';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import ContinuingMedicalEducationCertificates from 'components/License/ContinuingMedicalEducationCertificates';
import DeaLicense from 'components/License/DeaLicense';
import ControlledSubstanceCertification from 'components/License/ControlledSubstanceCertification';
import PracticeLicense from 'components/License/PracticeLicense';
import CollaboratingPhysician from 'components/License/CollaboratingPhysician';
import { getUserId } from 'utils/localstore';
import { ELicenseType } from 'types/common';
import { ProviderLicenseFromDataWithoutState } from 'types/provider';
import { NoticeType } from 'antd/es/message/interface';
import { FormLicense, FormPhysician } from 'types/form';
import MalpracticeInsuranceCertificate from 'components/License/MalpracticeInsuranceCertificate';
import AdditionalLicensesNCertificates from 'components/ApplicationFormComponents/AdditionalLicensesNCertificates';

interface IProviderLicenseAndPhysician {
    license: ProviderLicenseFromDataWithoutState,
    physician: FormPhysician[],
    malpracticeInsuranceCertificate: FormLicense,
}

interface IProps {
    currentStep?: number;
    isKlarityUser?: boolean;
    initLicenseFormValue?: ProviderLicenseFromDataWithoutState,
    initPhysicianFormValue?: FormPhysician[],
    initMalpracticeInsuranceCertificateValue?: FormLicense,
    editable?: boolean,
    onSubmit: (value: any, step: StepValue) => void,
    onNext?: (value: any) => void,
    onShowMessage?: (type: NoticeType, content: string) => void;
    onEditableChange?: (enable: boolean, step: StepValue, callback: () => void) => void,
    isOps?: boolean;
    providerId?: number;
    viewOnly?: boolean;
    refetch?: () => void;
    deaLicensePrescribeStatus?: string;
}

const LicenseCom = ({
    deaLicensePrescribeStatus,
    currentStep,
    isKlarityUser,
    initLicenseFormValue,
    initPhysicianFormValue,
    initMalpracticeInsuranceCertificateValue,
    onEditableChange,
    onSubmit,
    onNext,
    onShowMessage,
    isOps,
    providerId,
    viewOnly,
    refetch,
}: IProps) => {
    const [, forceUpdate] = useState({});
    const [isEditing1, setIsEditing1] = useState(false);
    const [isEditing2, setIsEditing2] = useState(false);
    const [isEditing3, setIsEditing3] = useState(false);
    const [isEditing4, setIsEditing4] = useState(false);
    const [licenseAndPhysicianForm] = Form.useForm<IProviderLicenseAndPhysician>();
    const deaLicenseCount = initLicenseFormValue?.[ELicenseType.DEA]?.length || 0;
    const practiceLicenseCount = initLicenseFormValue?.[ELicenseType.PRACTICE]?.length || 0;

    useEffect(() => {
        if (isKlarityUser && currentStep === EStep.LICENSE) {
            if (!initMalpracticeInsuranceCertificateValue) {
                setIsEditing2(true);
            }

            // 必须是null，且dea数量为0，否则要么2个选项都选过，要么就是老数据填写过dea
            if (typeof deaLicensePrescribeStatus !== 'string' && !deaLicensePrescribeStatus && deaLicenseCount === 0) {
                setIsEditing3(true);
            }

            if (!initPhysicianFormValue || initPhysicianFormValue.length === 0) {
                setIsEditing4(true);
            }
        }
    }, [currentStep, isKlarityUser, deaLicensePrescribeStatus])
    
    useEffect(() => {
        if (initLicenseFormValue || initPhysicianFormValue || initMalpracticeInsuranceCertificateValue) {
            licenseAndPhysicianForm.setFieldsValue({
                license: initLicenseFormValue,
                physician: initPhysicianFormValue,
                malpracticeInsuranceCertificate: initMalpracticeInsuranceCertificateValue,
            });
            forceUpdate({});
        }
    }, [initLicenseFormValue, initPhysicianFormValue, deaLicenseCount, practiceLicenseCount, licenseAndPhysicianForm]);

    const handleSubmit = useCallback(() => {
        licenseAndPhysicianForm.validateFields().then((values) => {
            if (typeof onSubmit === 'function') {
                onSubmit({
                    ...values,
                }, StepValue.LICENSE);
            }
        }).catch((e) => {
            console.error(e);
        });
    }, [licenseAndPhysicianForm, onSubmit]);
    const handleFinish = () => {
        if (isEditing1 || isEditing2 || isEditing3 || isEditing4) {
            onShowMessage?.('error', 'Please save your changes before proceeding!');
            return;
        }
        onNext?.({

        });
        // setVerifiedCount(0);
        // setDoVerify({});
    };

    React.useEffect(() => {
        onEditableChange?.(isEditing1 || isEditing2 || isEditing3 || isEditing4, StepValue.LICENSE, () => { });
    }, [isEditing1, isEditing2, isEditing3, isEditing4]);

    // React.useEffect(() => {
    //     if (verifiedCount === 1) {
    //         onNext?.({

    //         });
    //     }
    // }, [verifiedCount]);

    return (
        <div className={s.wrap}>
            <Form
                id={StepValue.LICENSE}
                form={licenseAndPhysicianForm}
                name={StepValue.LICENSE}
                className={`${commonS.formStyle1} ${s.formBody}`}
                autoComplete="off"
                layout="vertical"
                onFinish={handleFinish}
            >
                <>
                    <div className={s.module} style={{paddingLeft: '24px', paddingRight: '24px', backgroundColor: '#ffffff'}} id="state">
                        <div id="licenseNumber" />
                        <div id="expireDate" />
                        <div id="type" />
                        <div id="secondType" />
                        <div id="url" />
                        <PracticeLicense
                            initValue={{ licenses: licenseAndPhysicianForm.getFieldValue(['license', ELicenseType.PRACTICE]) }}
                            inited={!!(licenseAndPhysicianForm.getFieldValue(['license', ELicenseType.PRACTICE])?.length > 0)}
                            providerId={isOps ? providerId : getUserId()}
                            onNullState={() => { }}
                            onEditableChange={(editing: boolean) => {
                                //shoule have issue if open two and close
                                setIsEditing1(editing);
                            }}
                            isOperation={isOps}
                            viewOnly={viewOnly}
                            refetch={refetch}
                        // doVerifyFlag={doVerify}
                        // onVerified={onVerified}
                        />
                    </div>
                    <div className={s.module}>
                        {/* <h3 style={{ marginBottom: 36 }}>
                            <span>Additional licenses, certificates, and qualifications</span>
                        </h3> */}
                        <div className={s.editItem}>
                            <Form.Item
                                style={{ width: '100%', marginBottom: 0 }}
                                label=""
                                name={['malpracticeInsuranceCertificate']}
                            >
                                <MalpracticeInsuranceCertificate
                                    onEditableChange={(editing: boolean) => {
                                        //shoule have issue if open two and close
                                        setIsEditing4(editing);
                                    }}
                                    isEditing={isEditing4}
                                    isKlarityUser={isKlarityUser}
                                    initValue={licenseAndPhysicianForm.getFieldValue('malpracticeInsuranceCertificate')}
                                    inited={!!(licenseAndPhysicianForm.getFieldValue('malpracticeInsuranceCertificate'))}
                                    providerId={isOps ? providerId : getUserId()}
                                    onNullState={() => { }}
                                    isOperation={isOps}
                                    viewOnly={viewOnly}
                                />
                            </Form.Item>
                        </div>
                        <div className={s.editItem}>
                            <Form.Item
                                style={{ width: '100%', marginBottom: 0 }}
                                label=""
                                name={['license', ELicenseType.DEA]}
                            >
                                <DeaLicense
                                    deaLicensePrescribeStatus={deaLicensePrescribeStatus}
                                    initValue={{
                                        licenses: initLicenseFormValue?.[ELicenseType.DEA] || [{}],
                                        needLicense: !deaLicensePrescribeStatus || initLicenseFormValue?.[ELicenseType.DEA] && initLicenseFormValue?.[ELicenseType.DEA]?.length > 0 ? '1' : deaLicensePrescribeStatus,
                                    }}
                                    inited={!!(licenseAndPhysicianForm.getFieldValue(['license', ELicenseType.DEA])?.length > 0)}
                                    providerId={isOps ? providerId : getUserId()}
                                    onNullState={() => { }}
                                    isEditing={isEditing3}
                                    onEditableChange={(editing: boolean) => {
                                        //shoule have issue if open two and close
                                        setIsEditing3(editing);
                                    }}
                                    isOperation={isOps}
                                    viewOnly={viewOnly}
                                    refetch={refetch}
                                />
                            </Form.Item>
                        </div>
                        <div className={s.editItem}>
                            <Form.Item
                                style={{ width: '100%', marginBottom: 0 }}
                                label=""
                                name={['physician']}
                            >
                                <CollaboratingPhysician
                                    initValue={licenseAndPhysicianForm.getFieldValue('physician')}
                                    inited={!!(licenseAndPhysicianForm.getFieldValue('physician')?.length > 0)}
                                    providerId={isOps ? providerId : getUserId()}
                                    onNullState={() => { }}
                                    onEditableChange={(editing: boolean) => {
                                        //shoule have issue if open two and close
                                        setIsEditing2(editing);
                                    }}
                                    isEditing={isEditing2}
                                    isOperation={isOps}
                                    viewOnly={viewOnly}
                                    refetch={refetch}
                                />
                            </Form.Item>
                        </div>
                        {/* <div className={s.editItem}>
                            <Form.Item
                                style={{ width: '100%', marginBottom: 0 }}
                                label=""
                                name={['license', ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION]}
                            >
                                <ControlledSubstanceCertification
                                    initValue={{ licenses: licenseAndPhysicianForm.getFieldValue(['license', ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION]) }}
                                    inited={!!(licenseAndPhysicianForm.getFieldValue(['license', ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION])?.length > 0)}
                                    providerId={isOps ? providerId : getUserId()}
                                    onNullState={() => { }}
                                    isOperation={isOps}
                                    viewOnly={viewOnly}
                                />
                            </Form.Item>
                        </div>
                        <div className={s.editItem}>
                            <Form.Item
                                style={{ width: '100%', marginBottom: 0 }}
                                label=""
                                name={['license', ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]}
                            >
                                <ContinuingMedicalEducationCertificates
                                    initValue={{ licenses: licenseAndPhysicianForm.getFieldValue(['license', ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]) }}
                                    inited={!!(licenseAndPhysicianForm.getFieldValue(['license', ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES])?.length > 0)}
                                    providerId={isOps ? providerId : getUserId()}
                                    onNullState={() => { }}
                                    isOperation={isOps}
                                    viewOnly={viewOnly}
                                />
                            </Form.Item>
                        </div> */}
                        <div className={s.editItem}>
                            <div className={s.secHeader}>
                                <h3>Additional licences and certificates </h3>
                            </div>
                            <div className={s.secEditItem}>
                                <Form.Item
                                    style={{ width: '100%', marginBottom: 0 }}
                                    label=""
                                    name={['license', ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION]}
                                >
                                    <ControlledSubstanceCertification
                                        initValue={{ licenses: licenseAndPhysicianForm.getFieldValue(['license', ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION]) }}
                                        inited={!!(licenseAndPhysicianForm.getFieldValue(['license', ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION])?.length > 0)}
                                        providerId={isOps ? providerId : getUserId()}
                                        onNullState={() => { }}
                                        isOperation={isOps}
                                        viewOnly={viewOnly}
                                    />
                                </Form.Item>
                            </div>
                            <div className={s.secEditItem}>
                                <Form.Item
                                    style={{ width: '100%', marginBottom: 0 }}
                                    label=""
                                    name={['license', ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]}
                                >
                                    <ContinuingMedicalEducationCertificates
                                        initValue={{ licenses: licenseAndPhysicianForm.getFieldValue(['license', ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]) }}
                                        inited={!!(licenseAndPhysicianForm.getFieldValue(['license', ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES])?.length > 0)}
                                        providerId={isOps ? providerId : getUserId()}
                                        onNullState={() => { }}
                                        isOperation={isOps}
                                        viewOnly={viewOnly}
                                    />
                                </Form.Item>
                            </div>
                            <div className={s.secEditItem}>
                                <Form.Item
                                    style={{ width: '100%', marginBottom: 0 }}
                                    label=""
                                    name={['license', ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]}
                                >
                                    <AdditionalLicensesNCertificates
                                        initValue={initLicenseFormValue?.PRACTICE}
                                        // inited={!!(licenseAndPhysicianForm.getFieldValue(['license', ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES])?.length > 0)}
                                        providerId={isOps ? providerId : getUserId()}
                                        // onNullState={() => { }}
                                        isOperation={isOps}
                                        viewOnly={viewOnly}
                                        refetch={refetch}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </>
            </Form>
        </div>
    );
};

export default LicenseCom;
