import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
import { EnumFields } from 'types/enumerationData';
import useGetSettingData from 'hooks/useGetSettingData';
import remove from 'lodash/remove'

interface IProps {
    approchValue?: string;
    onSelect: (value: string) => void;
}

const DescrbeList = ({
    approchValue,
    onSelect,
}: IProps) => {
    const [describeData] = useGetSettingData(EnumFields.TREATMENT_APPROACH);

    const approchValueList = useMemo(() => {
        return approchValue?.split('\n')
    }, [approchValue]);

    const includeApprochValue = useCallback((value: string) => {
        return value && approchValueList?.includes(`•${value}`)
    }, [approchValueList]);

    const handleSelect = useCallback((value: string) => {
        const included = includeApprochValue(value);

        if (!included) {
            onSelect(value);
        }
    }, [onSelect]);

    return (
        <div className={s.describeList}>
            {
                describeData?.map((describe, index) => {
                    const selected = includeApprochValue(describe.content);

                    return <div key={index} className={`${s.describe} ${selected ? s.selected : ''}`} onClick={() => handleSelect(describe.content)}>{describe.content}</div>;
                })
            }
        </div>
    );
};

export default DescrbeList;
