import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
import { Button, Checkbox, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import CCCStore from 'store/CCC';
import { CCCTypeList } from 'constants/ccc';
import ProviderStore from 'store/Provider';
import { Provider } from 'types/provider';
import { getProviderNameDisplay } from 'utils/provider';
import { closeBlackFriday2024EnhancementModal, showCCCTermsNConditionsModal } from 'utils/globalLayerControl';
import { offerCCC } from 'api/ccc';

const EnhancementModal = () => {
    const [getProvider] = ProviderStore.useStore();
    const provider = getProvider('data') as Provider;
    const [checkedOption, setCheckedOption] = React.useState<string[]>([CCCTypeList[2].skuCode]);
    const [hasCheckedTerms, setHasCheckedTerms] = React.useState<boolean>(false);
    const [getCCCStore, setCCCStore] = CCCStore.useStore();

    const cccList = getCCCStore('cccList');

    const handleCheckOption = useCallback((skuCode: string) => {
        let value = [];
        if (checkedOption.includes(skuCode)) {
            value = checkedOption.filter((item) => item !== skuCode);
        } else {
            value = [...checkedOption, skuCode];
        }
        const priority = { 'ccc-plus': 1, 'ccc-essential': 2, 'ccc-basic': 3 };

        setCheckedOption(value.sort((a, b) => priority[a] - priority[b]));
    }, [checkedOption]);

    const handleShowTermsNConditions = useCallback(() => {
        showCCCTermsNConditionsModal();
    }, []);

    const showSelectedCCCTypeText = useMemo(() => {
        if (checkedOption.length === 0) {
            return '';
        }

        const typeArray = checkedOption.map((item) => CCCTypeList.find((e) => e.skuCode === item)?.type);

        if (typeArray.length === 1) {
            return `${typeArray[0]}`;
        }

        if (typeArray.length === 2) {
            return `${typeArray[0]} and ${typeArray[1]}`;
        }

        const lastItem = typeArray.pop();
        return `${typeArray.join(', ')} and ${lastItem}`;
    }, [checkedOption]);

    const handleOffer = useCallback(async () => {
        if ((!checkedOption || checkedOption.length === 0) || !cccList || cccList.length === 0) return;

        try {
            const res = await offerCCC(checkedOption);
            if (res && !res.error) {
                setCCCStore('forceRefetchCCCList', {});
                closeBlackFriday2024EnhancementModal();
                message.success('🎉 Congratulations! You’ve successfully offered Care Commitment Card.');
            } else {
                message.error(res.error);
            }
        } catch (error) {
            console.error('error', error);
        }
    }, [checkedOption]);

    return (
        // <Modal
        //     open={getGlobalLayer('showBlackFriday2024EnhancementModal')}
        //     footer={null}
        // >
        <div className={s.wrap}>
            <div className={s.modalContent}>
                <div className={s.modalTitle}>Enhance patient loyalty with Klarity Care Commitment Cards!</div>
                <div className={s.modalDesc}>
                    <p>Offer your patients more value with Klarity Care Commitment Cards! This flexible card allows patients to pay less upfront while receiving more credit to use on any of your services in the future. </p>
                    <p>Choose the card tiers you’d like to offer and provide a convenient way for patients to commit to their care with you.</p>
                </div>
                <div className={s.pickBox}>
                    {
                        cccList?.map((item, index) => {
                            const { skuCode, price, value } = item;
                            const checked = checkedOption.includes(skuCode);
                            const type = CCCTypeList.find((e) => e.skuCode === skuCode)?.type;
                            return (
                                <div key={index} className={`${s.option} ${checked ? s.checked : ''}`} onClick={() => handleCheckOption(skuCode)}>
                                    <div className={s.checkedIcon} />
                                    <div className={`${s.plan} ${type ? s[type?.toLowerCase()] : ''}`}>
                                        <div className={s.name}>{getProviderNameDisplay(provider)}</div>
                                    </div>
                                    <div className={s.desc}>
                                        <div className={`${type === 'Basic' ? '' : s.title}`}>{type}</div>
                                        Care Commitment Card
                                    </div>
                                    <div className={s.price}>
                                        <div className={s.discoutPrice}>${price}</div> (${value} value)
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className={s.termsBox}>
                    <Checkbox checked={hasCheckedTerms} onChange={(e: CheckboxChangeEvent) => setHasCheckedTerms(e.target.checked)}>By checking this box, I have reviewed and accept the <a className={s.linkPopup} onClick={handleShowTermsNConditions}>Terms & Conditions</a>.</Checkbox>
                    <Button type="primary" disabled={!hasCheckedTerms} onClick={handleOffer}>Confirm with offering {showSelectedCCCTypeText} Care Commitment Cards</Button>
                </div>
            </div>
        </div>
        // </Modal>
    );
};

export default EnhancementModal;
