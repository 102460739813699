import React from 'react';
import { Button, Form, message, Modal } from 'antd';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import Seperator from './imgs/Seperator.svg';
// import useMyListedChannels from 'hooks/useMyListedChannels';
import FirstStep from './components/FirstStep';
import SecondStep from './components/SecondStep';
import { EProspectType, Prospect } from 'types/common';
import useMyAllProspectList from 'hooks/useMyAllProspectList';
import { FormProspects, SecondStepDataType } from './types';
import { batchCreateProspect, createProspect } from 'api/prospect';
import useMyPatientList from 'hooks/useMyPatientList';
import { isSameProspect } from 'utils/common';
import useLiveChannels from 'hooks/useLiveChannels';
import { OTHERS_CHANNEL_ID, OTHERS_CHANNEL_NAME, PF_CHANNEL_ID, PF_CHANNEL_NAME } from 'constants/common';
import Done from './imgs/Step.png';

type Props = {
    onNewDataUpdated?: (newData: Partial<Prospect>[]) => void;
    onCannel?: (needRefresh?:boolean)=>void,
    onSuccess?: ()=>void,
    show?:boolean
};

const BatchAddPatientModal = (props: Props) => {
    const { onCannel, onSuccess, show, onNewDataUpdated } = props;
    const [step, setStep] = React.useState(1);
    const [firstStepForm] = Form.useForm<FormProspects>();

    const [secondStepData, setSecondStepData] = React.useState<SecondStepDataType | undefined>(undefined);
    const [callToApiData, setCallToApiData] = React.useState<Partial<Prospect>[]>([]);
    const [data, loadingPatient, run] = useMyAllProspectList();
    const [, , runFetchMyPatient] = useMyPatientList();
    const [submiting, setSubmiting] = React.useState(false);
    const [liveChannels, liveChannelsLoading] = useLiveChannels();

    React.useEffect(() => {
        if (!show) {
            setStep(1);
            setSecondStepData(undefined);
            setCallToApiData([]);
        }
    }, [show]);

    const handleNext = async () => {
        if (step === 1) {
            try {
                const formValues = await firstStepForm.validateFields();
                const newAddPatientList:Partial<Prospect>[] = formValues.list || [];

                const newSecondData:SecondStepDataType = {
                    pendingSelectAmount: 0,
                    pendingSelectData: [],
                    pendingAddData: [],
                };

                const existEmailProspectMap:Record<string, Partial<Prospect>> = {};
                const newAddEmailProspectMap:Record<string, Partial<Prospect>[]> = {};

                data?.forEach((item:Prospect) => {
                    existEmailProspectMap[item.email] = item;
                });
                if (newAddPatientList) {
                    for (let i = 0; i < newAddPatientList.length; i++) {
                        const item = newAddPatientList[i];
                        if (item.channelId) {
                            const targetChannel = liveChannels?.find((channel) => { return `${channel.id}` === `${item.channelId}`; });
                            if (targetChannel) {
                                item.channel = targetChannel.name;
                            } else {
                                if (`${item.channelId}` === `${PF_CHANNEL_ID}`) {
                                    item.channel = PF_CHANNEL_NAME;
                                }
                                if (`${item.channelId}` === `${OTHERS_CHANNEL_ID}`) {
                                    item.channel = OTHERS_CHANNEL_NAME;
                                }
                            }
                        }
                        if (!item.email) {
                            continue;
                        }

                        if (newAddEmailProspectMap[item.email!]) {
                            const currentItems = newAddEmailProspectMap[item.email!];
                            const exist = currentItems.find((existItem) => {
                                return isSameProspect(existItem, item);
                            });
                            if (!exist) {
                                currentItems.push(item);
                            }
                        } else if (existEmailProspectMap[item.email]) {
                            //detect duplication logic
                            const existItem = existEmailProspectMap[item.email];
                            if (isSameProspect(existItem, item)) {
                                //all the same
                                existItem.type = EProspectType.PATIENT;
                                newAddEmailProspectMap[item.email] = [existItem];
                            } else {
                                //all set to PATIENT
                                item.type = EProspectType.PATIENT;
                                existItem.type = EProspectType.PATIENT;
                                newAddEmailProspectMap[item.email] = [item, existItem];
                            }
                        } else {
                            newAddEmailProspectMap[item.email] = [{ ...item, type: EProspectType.PATIENT }];
                        }
                    }
                    Object.keys(newAddEmailProspectMap).forEach((key) => {
                        const items = newAddEmailProspectMap[key];
                        if (items.length === 1) {
                            newSecondData.pendingAddData.push(items[0]);
                        } else {
                            newSecondData.pendingSelectAmount += 1;
                            newSecondData.pendingSelectData.push(items);
                        }
                    });
                }
                setSecondStepData(newSecondData);
                setStep(step + 1);
                return;
            } catch (e:any) {
                if (e.errorFields) {
                    //form error
                    return;
                }
            }
            return;
        }
        if (step === 2) {
            if (callToApiData) {
                setSubmiting(true);

                const batchAdditionData = callToApiData.map((e) => ({
                    ...e,
                    type: EProspectType.PATIENT,
                }));

                try {
                    const result = await batchCreateProspect(batchAdditionData);

                    if (result && !result.error) {
                        message.success('Add new patients success');
                        run();
                        runFetchMyPatient();
                        onSuccess?.();
                        onNewDataUpdated?.(batchAdditionData);
                    } else {
                        message.error(result.error);
                    }
                } catch (e) {
                    console.error(e);
                }
                setSubmiting(false);
            }
            setStep(1);
            setSecondStepData(undefined);
            firstStepForm?.resetFields();
        }
    };

    return (
        <Modal
            title="Add new patients"
            closable
            className={`${commonS.modalFixHeightWrap} ${commonS.modalFixSetedHeightWrap}`}
            width="1200px"
            onCancel={() => {
                setStep(1);
                setSecondStepData(undefined);
                firstStepForm?.resetFields();
                onCannel?.();
            }}
            okText="Save"
            open={show}
            footer={
                <div className={s.footer}>
                    {
                        step === 2 ?
                            <Button
                                onClick={() => {
                                    setStep(1);
                                }}
                                loading={submiting}
                            >
                                Back
                            </Button>
                            :
                            <span />
                    }
                    <Button
                        onClick={handleNext}
                        type="primary"
                        loading={submiting}
                        disabled={step === 2 && !secondStepData}
                    >
                        {step === 2 ? 'Done' : 'Next'}
                    </Button>
                </div>
            }
            destroyOnClose
        >
            <div className={s.wrap}>
                <div className={s.stepWrap}>
                    <div className={step === 1 ? `${s.stepItem} ${s.stepItemActive}` : s.stepItem}>
                        <div className={s.left}>
                            {
                                step > 1 ? <img src={Done} className={s.icon} /> : <span className={s.num}>01</span>
                            }
                        </div>
                        <div className={s.right}>
                            <div className={s.title}>
                                ADD
                            </div>
                            <div className={s.desc}>
                                Input patients information
                            </div>
                        </div>
                    </div>
                    <div className={step === 2 ? `${s.stepItem} ${s.stepItemActive}` : s.stepItem}>
                        <div className={s.spe}>
                            <img src={Seperator} />
                        </div>
                        <div className={s.left}>
                            <span className={s.num}>02</span>
                        </div>
                        <div className={s.right}>
                            <div className={s.title}>
                                CONFIRM
                            </div>
                            <div className={s.desc}>
                                Review patient added
                            </div>
                        </div>
                    </div>
                </div>
                {
                    step === 1 && <FirstStep form={firstStepForm} />
                }
                {
                    step === 2 &&
                        <SecondStep
                            data={secondStepData}
                            onUpdate={(_data) => {
                                setCallToApiData(_data || []);
                            }}
                        />
                }
            </div>
        </Modal>
    );
};

export default BatchAddPatientModal;
