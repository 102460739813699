// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--eQNun {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 1010;
  background-color: #F9FAFC;
}
.s-module__wrap--eQNun .s-module__content--ts3ML {
  flex-grow: 1;
  overflow-y: auto;
  padding: 48px 64px;
  margin: 0 auto;
  width: 100%;
}
.s-module__wrap--eQNun .s-module__content--ts3ML .s-module__inner--zbtiV {
  max-width: 1440px;
  margin: 0 auto;
}
.s-module__wrap--eQNun .s-module__header--PE6w4 {
  flex-shrink: 0;
  padding: 16px 24px;
}
.s-module__wrap--eQNun .s-module__header--PE6w4 .s-module__logo--V8M1G {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.s-module__wrap--eQNun .s-module__header--PE6w4 .s-module__logo--V8M1G svg {
  margin-right: 12px;
}
.s-module__wrap--eQNun .s-module__header--PE6w4 .s-module__logo--V8M1G .s-module__logoTitle--IhM_w {
  color: #15191E;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin-top: 4px;
}
.s-module__wrap--eQNun .s-module__header--PE6w4 .s-module__logo--V8M1G .s-module__logoDes--Eb5BA {
  font-size: 12px;
  font-weight: 400;
}
.s-module__wrap--eQNun .s-module__footer--Uo94G {
  flex-shrink: 0;
  padding: 16px 32px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: #fff;
  border-top: 1px solid #E5E7EB;
}
`, "",{"version":3,"sources":["webpack://./src/components/FullScreenPopLayout/s.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,yBAAA;AACF;AAVA;EAYI,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,WAAA;AACJ;AAjBA;EAmBM,iBAAA;EACA,cAAA;AACN;AArBA;EAyBI,cAAA;EACA,kBAAA;AADJ;AAzBA;EA6BM,aAAA;EACA,mBAAA;EACA,mBAAA;AADN;AA9BA;EAkCQ,kBAAA;AADR;AAjCA;EAsCQ,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAFR;AAxCA;EA8CQ,eAAA;EACA,gBAAA;AAHR;AA5CA;EAqDI,cAAA;EACA,kBAAA;EACA,+CAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,yBAAA;EACA,sBAAA;EACA,6BAAA;AANJ","sourcesContent":[".wrap {\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  z-index: 1010;\n  background-color: #F9FAFC;\n\n  .content {\n    flex-grow: 1;\n    overflow-y: auto;\n    padding: 48px 64px;\n    margin: 0 auto;\n    width: 100%;\n\n    .inner {\n      max-width: 1440px;\n      margin: 0 auto;\n    }\n  }\n\n  .header {\n    flex-shrink: 0;\n    padding: 16px 24px;\n\n    .logo {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n\n      svg {\n        margin-right: 12px;\n      }\n\n      .logoTitle {\n        color: #15191E;\n        font-size: 20px;\n        font-weight: 700;\n        line-height: 22px;\n        margin-top: 4px;\n      }\n\n      .logoDes {\n        font-size: 12px;\n        font-weight: 400;\n      }\n    }\n  }\n\n  .footer {\n    flex-shrink: 0;\n    padding: 16px 32px;\n    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);\n    display: flex;\n    flex-direction: row;\n    align-items: flex-end;\n    justify-content: flex-end;\n    background-color: #fff;\n    border-top: 1px solid #E5E7EB;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--eQNun`,
	"content": `s-module__content--ts3ML`,
	"inner": `s-module__inner--zbtiV`,
	"header": `s-module__header--PE6w4`,
	"logo": `s-module__logo--V8M1G`,
	"logoTitle": `s-module__logoTitle--IhM_w`,
	"logoDes": `s-module__logoDes--Eb5BA`,
	"footer": `s-module__footer--Uo94G`
};
export default ___CSS_LOADER_EXPORT___;
