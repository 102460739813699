import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Button, Input, Checkbox, DatePicker, Select, message } from 'antd';
import { EStep, StepValue } from 'pages/CreateUniProfilePage/types';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { ProviderChannelHealthcareExperience, HealthcareExperienceFieldEnum } from 'types/channelApplicationProvider';
import PhotoList from 'components/PhotoList';
import dayjs from 'dayjs';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import ExperiencePlaceholder from 'assets/common/experiencePlaceholder.svg';
import { EMPTY_PLACEHOLDER } from 'constants/common';

interface IFormData {
    providerExperienceList: Array<ProviderChannelHealthcareExperience>
}

interface IProps {
    initFormValue: Array<ProviderChannelHealthcareExperience>,
    editable?: boolean,
    showEditButtons: boolean,
    onSubmit: (value: IFormData, step: StepValue) => void,
    onNext?: (value: IFormData) => void,
    onEditableChange?: (enable: boolean, step: StepValue, callback: () => void) => void,
}

const HealthcareExperience = ({
    initFormValue,
    editable = false,
    showEditButtons = false,
    onSubmit,
    onNext,
    onEditableChange,
}: IProps) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [employmentTypeOptions] = useGetSettingData(EnumFields.EMPLOYMENT_TYPE);
    const [enable, setEnable] = useState(!!editable);
    const [healthcareExperienceForm] = Form.useForm<IFormData>();
    const [update, forceUpdate] = useState({});
    // useEffect(() => {
    //     healthcareExperienceForm.setFieldValue('providerExperienceList');
    // }, [healthcareExperienceForm]);

    useEffect(() => {
        if (initFormValue) {
            healthcareExperienceForm.setFieldsValue({
                providerExperienceList: initFormValue,
            });
        }
    }, [initFormValue, healthcareExperienceForm]);

    useEffect(() => {
        setEnable(editable);
    }, [editable]);

    const formatDate = useCallback((day: string) => dayjs(day).format('YYYY-MM'), []);

    const handleEnableStatusChange = useCallback((status: boolean) => {
        if (typeof onEditableChange === 'function') {
            onEditableChange(status, StepValue.HEALTHCARE_EXPERIENCE, () => {
                setEnable(status);
            });
        }
    }, [onEditableChange]);

    const handleSubmit = useCallback(() => {
        healthcareExperienceForm.validateFields().then((values) => {
            if (typeof onSubmit === 'function') {
                onSubmit(values, StepValue.HEALTHCARE_EXPERIENCE);
            }
        }).catch((e) => {
            console.error(e);
        });
    }, [healthcareExperienceForm, onSubmit]);

    const checkAddable = useCallback((add: (defaultValue?: any, insertIndex?: number | undefined) => void) => {
        const data = healthcareExperienceForm.getFieldsValue();
        const noEmptyEducation = !data.providerExperienceList || data.providerExperienceList.every((item) => !!item && Object.values(item).some((v) => !!v));

        if (noEmptyEducation) {
            add();
        } else {
            messageApi.error('There\'s already an empty healthcare experience form !');
        }
    }, [healthcareExperienceForm, messageApi]);

    const renderEditButtons = useMemo(() => {
        if (showEditButtons) {
            return !enable ? (
                <Button
                    onClick={() => handleEnableStatusChange(true)}
                >
                    Edit
                </Button>
            ) : (
                <div className={s.editButtons}>
                    <Button
                        onClick={() => handleEnableStatusChange(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                    >
                        Save changes
                    </Button>
                </div>
            );
        }

        return null;
    }, [enable, handleEnableStatusChange, handleSubmit, showEditButtons]);

    const renderReviewExperienceList = useMemo(() => {
        if (!initFormValue || initFormValue.length === 0) {
            return <div style={{ marginTop: 16, marginBottom: 16 }}>{EMPTY_PLACEHOLDER}</div>;
        }
        return initFormValue?.map((item, index) => {
            const {
                experienceLogo,
                currently,
                description,
                // employmentType,
                endDate,
                hospitalLocation,
                organizationName,
                startDate,
                title,
            } = item;

            const location = hospitalLocation && (
                <>
                    <span>•</span>
                    <span>{hospitalLocation}</span>
                </>
            );

            return (
                <div key={index} className={s.row}>
                    <div className={s.experience}>
                        <div className={s.logoBox}>
                            <div className={s.logo} style={{ backgroundImage: experienceLogo ? `url(${experienceLogo})` : `url(${ExperiencePlaceholder})` }} />
                        </div>
                        <div className={s.intro}>
                            <div className={s.name}>{organizationName}</div>
                            <div className={s.title}>{title}</div>
                            <div className={s.timeloc}>
                                <span>{formatDate(startDate)} - {currently ? 'Current' : formatDate(endDate)}</span>
                                {
                                    location
                                }
                            </div>
                            <div className={s.desc}>{description}</div>
                        </div>
                    </div>
                </div>
            );
        });
    }, [formatDate, initFormValue]);

    const renderExperienceList = useMemo(() => {
        return (
            <Form.List name="providerExperienceList">
                {
                    (fields, { add, remove }) => {
                        const additionalButtonText = fields.length === 0 ? '+ Add experience' : '+ Add another experience';
                        return (
                            <>
                                {
                                    fields.map(({ key, name, ...restField }, index) => {
                                        const currentWorkingOn = healthcareExperienceForm.getFieldValue(['providerExperienceList', name, HealthcareExperienceFieldEnum.IS_CURRENTLY_WORK]);
                                        const startDate = healthcareExperienceForm.getFieldValue(['providerExperienceList', name, HealthcareExperienceFieldEnum.START_DATE]);
                                        const endDate = healthcareExperienceForm.getFieldValue(['providerExperienceList', name, HealthcareExperienceFieldEnum.END_DATE]);
                                        return (
                                            <div key={key} className={s.additionalBox}>
                                                <div
                                                    id={`Experience_${index + 1}`}
                                                    style={{
                                                        position: 'relative',
                                                        top: '-120px',
                                                    }}
                                                />
                                                <div className={s.labelBox}>
                                                    <div className={`${s.label} ${s.required} ${s.third}`}>{`Experience ${index + 1}`}</div>
                                                    <Button onClick={() => remove(index)}>Remove</Button>
                                                </div>
                                                <div className={s.additionalForm}>
                                                    <div className={s.formBody}>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Organization name"
                                                                    name={[name, HealthcareExperienceFieldEnum.ORGANIZATION_NAME]}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: 'Organization name is a required field' },
                                                                    ]}
                                                                >
                                                                    <Input type="text" placeholder="Ex. Hospital or clinic or practice name" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Title"
                                                                    name={[name, HealthcareExperienceFieldEnum.TITLE]}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: 'Title is a required field' },
                                                                    ]}
                                                                >
                                                                    <Input type="text" placeholder="Ex. Nurse practitioner" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, HealthcareExperienceFieldEnum.IS_CURRENTLY_WORK]}
                                                                    valuePropName="checked"
                                                                >
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                healthcareExperienceForm.setFieldValue(['providerExperienceList', name, HealthcareExperienceFieldEnum.END_DATE], undefined);
                                                                                healthcareExperienceForm.validateFields();
                                                                            }
                                                                            forceUpdate({});
                                                                        }}
                                                                    >
                                                                        I am currently working in this role
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                                style={{
                                                                    maxWidth: '50%',
                                                                }}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    style={{ height: '85px' }}
                                                                    label="Start Date"
                                                                    // valuePropName="date"
                                                                    name={[name, HealthcareExperienceFieldEnum.START_DATE]}
                                                                    // validateFirst
                                                                    rules={[
                                                                        { required: true, message: 'Start Date is a required field' },
                                                                        {
                                                                            validator: (rules, value) => {
                                                                                const endDateValue = healthcareExperienceForm.getFieldValue('providerExperienceList')?.[index]?.[HealthcareExperienceFieldEnum.END_DATE];
                                                                                // healthcareExperienceForm.validateFields([['providerExperienceList', index, 'endDate']]);

                                                                                if (value && !endDateValue && !currentWorkingOn) {
                                                                                    return Promise.reject(
                                                                                        new Error('End date is required'),
                                                                                    );
                                                                                }

                                                                                if (endDateValue && endDateValue.isBefore(value)) {
                                                                                    return Promise.reject(
                                                                                        new Error('start date cannot be later than the end date'),
                                                                                    );
                                                                                }
                                                                                return Promise.resolve(true);
                                                                            },
                                                                        },
                                                                    ]}
                                                                >
                                                                    <DatePicker
                                                                        onChange={() => {
                                                                            forceUpdate({});
                                                                            healthcareExperienceForm.validateFields();
                                                                        }}
                                                                        allowClear
                                                                        className={s.datepicker}
                                                                        picker="month"
                                                                        disabledDate={(current) => {
                                                                            const endDateValue = healthcareExperienceForm.getFieldValue('providerExperienceList')?.[index]?.[HealthcareExperienceFieldEnum.END_DATE];
                                                                            if (!endDateValue || currentWorkingOn) {
                                                                                return false;
                                                                            }
                                                                            return current.valueOf() > endDateValue.valueOf();
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            {!currentWorkingOn &&
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    style={{ height: '85px' }}
                                                                    label="End Date"
                                                                    // valuePropName="date"
                                                                    name={[name, HealthcareExperienceFieldEnum.END_DATE]}
                                                                    // validateFirst
                                                                    rules={[
                                                                        { required: true, message: 'End Date is a required field' },
                                                                        {
                                                                            validator: (rules, value) => {
                                                                                const startDateValue = healthcareExperienceForm.getFieldValue('providerExperienceList')?.[index]?.[HealthcareExperienceFieldEnum.START_DATE];
                                                                                // healthcareExperienceForm.validateFields([['providerExperienceList', index, 'startDate']]);

                                                                                if (value && !startDateValue) {
                                                                                    return Promise.reject(
                                                                                        new Error('Start date is required'),
                                                                                    );
                                                                                }

                                                                                if (startDateValue && value && value.isBefore(startDateValue)) {
                                                                                    return Promise.reject(
                                                                                        new Error('start date cannot be later than the end date'),
                                                                                    );
                                                                                }

                                                                                return Promise.resolve(true);
                                                                            },
                                                                        },
                                                                    ]}
                                                                >
                                                                    <DatePicker
                                                                        onChange={() => {
                                                                            forceUpdate({});
                                                                            healthcareExperienceForm.validateFields();
                                                                        }}
                                                                        allowClear
                                                                        className={s.datepicker}
                                                                        picker="month"
                                                                        disabledDate={(current) => {
                                                                            const startDateValue = healthcareExperienceForm.getFieldValue('providerExperienceList')?.[index]?.[HealthcareExperienceFieldEnum.START_DATE];
                                                                            if (!startDateValue) {
                                                                                return false;
                                                                            }
                                                                            return current.valueOf() < startDateValue.valueOf();
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            }
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Employment type"
                                                                    name={[name, HealthcareExperienceFieldEnum.EMPLOYMENT_TYPE]}
                                                                    // validateFirst
                                                                    // rules={[
                                                                    //     { required: true, message: 'Employment type is a required field' },
                                                                    // ]}
                                                                >
                                                                    <Select fieldNames={{ label: 'content', value: 'dictKey' }} options={employmentTypeOptions} />
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Location"
                                                                    name={[name, 'hospitalLocation']}
                                                                    // rules={[
                                                                    //     { required: true, message: 'Location type is a required field' },
                                                                    // ]}
                                                                >
                                                                    <Input type="text" placeholder="Ex. City and state" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.formItemLabel}>Description</div>
                                                                <div className={s.des}>Write a few sentences to describe what you have done and accomplished in this role</div>
                                                                <Form.Item
                                                                    {...restField}
                                                                    // label="Description"
                                                                    name={[name, HealthcareExperienceFieldEnum.DESCRIPTION]}
                                                                >
                                                                    <Input.TextArea className={s.textarea} showCount maxLength={1000} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    label="Logo"
                                                                    name={[name, HealthcareExperienceFieldEnum.LOGO]}
                                                                    valuePropName="data"
                                                                >
                                                                    <PhotoList min={1} enable responseType="string" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={s.formFooter}>
                                                        <Button onClick={() => remove(index)}>Remove</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            // )
                                        );
                                    })
                                }
                                <Form.Item>
                                    <div className={s.additionalTitle} onClick={() => checkAddable(add)}>{additionalButtonText}</div>
                                </Form.Item>
                            </>
                        );
                    }
                }
            </Form.List>
        );
    }, [checkAddable, update, employmentTypeOptions, healthcareExperienceForm]);

    return (
        <div className={s.module}>
            {contextHolder}
            <div className={s.wrap}>
                <Form
                    id={StepValue.HEALTHCARE_EXPERIENCE}
                    form={healthcareExperienceForm}
                    name={StepValue.HEALTHCARE_EXPERIENCE}
                    className={commonS.formStyle1}
                    initialValues={{
                        providerExperienceList: initFormValue,
                    }}
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onNext}
                    scrollToFirstError
                >

                    <div className={s.module}>
                        <h3>
                            <span>Healthcare experience</span>
                            {renderEditButtons}
                        </h3>
                        {
                            !enable ? renderReviewExperienceList : renderExperienceList
                        }
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default HealthcareExperience;
