// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__popContentWrap--FlfC7 .s-module__tags--Z_6HR {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-family: CircularXX;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  gap: 18px;
}
.s-module__popContentWrap--FlfC7 .s-module__tags--Z_6HR .s-module__tag--YN3ro {
  padding: 6px;
  display: inline-block;
  background-color: #F0F0F0;
}
`, "",{"version":3,"sources":["webpack://./src/components/EarningEditor/s.module.less"],"names":[],"mappings":"AAAA;EAIQ,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,SAAA;AAFR;AAVA;EAeY,YAAA;EACA,qBAAA;EACA,yBAAA;AAFZ","sourcesContent":[".popContentWrap {\n    .body {}\n\n    .tags {\n        display: flex;\n        align-items: center;\n        flex-wrap: nowrap;\n        font-family: CircularXX;\n        font-size: 12px;\n        font-weight: 400;\n        line-height: 14.4px;\n        text-align: left;\n        gap: 18px;\n\n        .tag {\n            padding: 6px;\n            display: inline-block;\n            background-color: #F0F0F0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popContentWrap": `s-module__popContentWrap--FlfC7`,
	"tags": `s-module__tags--Z_6HR`,
	"tag": `s-module__tag--YN3ro`
};
export default ___CSS_LOADER_EXPORT___;
