// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/ehr-home/plusButton.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--RP5QT {
  padding: 16px 0;
  display: flex;
  border-bottom: 1px solid var(--gray-300);
}
.s-module__wrap--RP5QT .ant-switch {
  background: rgba(0, 0, 0, 0.25);
}
.s-module__wrap--RP5QT :where(.css-dev-only-do-not-override-1uhj8f6).ant-switch.ant-switch-checked {
  background: var(--main-green) !important;
}
.s-module__wrap--RP5QT .ant-switch.ant-switch-checked {
  background: var(--main-green) !important;
}
.s-module__wrap--RP5QT .s-module__availableSwitch--l9NDU {
  margin-top: 4px;
}
.s-module__wrap--RP5QT .s-module__dayLabel--AWz0I {
  width: 100px;
  margin-top: 2px;
  margin-left: 24px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  padding-bottom: 4px;
}
.s-module__wrap--RP5QT .s-module__times--vWuhR {
  display: flex;
  flex: 1;
}
.s-module__wrap--RP5QT .s-module__times--vWuhR .s-module__timeList--B0TzC {
  flex: 1;
}
.s-module__wrap--RP5QT .s-module__times--vWuhR .s-module__add--n_bDh {
  margin-top: 4px;
  margin-left: 48px;
  width: 24px;
  height: 24px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  background-size: contain;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/AppointmentTime/components/DaySlot/s.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,aAAA;EACA,wCAAA;AACJ;AAJA;EAMY,+BAAA;AACZ;AAPA;EASY,wCAAA;AACZ;AAVA;EAaY,wCAAA;AAAZ;AAbA;EAkBQ,eAAA;AAFR;AAhBA;EAsBQ,YAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AAHR;AA1BA;EAiCQ,aAAA;EACA,OAAA;AAJR;AA9BA;EAqCY,OAAA;AAJZ;AAjCA;EAyCY,eAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,oEAAA;EACA,wBAAA;EACA,eAAA;AALZ","sourcesContent":[".wrap {\n    padding: 16px 0;\n    display: flex;\n    border-bottom: 1px solid var(--gray-300);\n    :global {\n        .ant-switch {\n            background: rgba(0, 0, 0, 0.25);\n        }\n        :where(.css-dev-only-do-not-override-1uhj8f6).ant-switch.ant-switch-checked {\n            background: var(--main-green) !important;\n        }\n\n        .ant-switch.ant-switch-checked {\n            background: var(--main-green) !important;\n        }\n    }\n\n    .availableSwitch {\n        margin-top: 4px;\n    }\n\n    .dayLabel {\n        width: 100px;\n        margin-top: 2px;\n        margin-left: 24px;\n        margin-right: 20px;\n        font-size: 16px;\n        font-weight: 500;\n        color: #000;\n        padding-bottom: 4px;\n    }\n\n    .times {\n        display: flex;\n        flex: 1;\n\n        .timeList {\n            flex: 1;\n        }\n\n        .add {\n            margin-top: 4px;\n            margin-left: 48px;\n            width: 24px;\n            height: 24px;\n            background: url('assets/ehr-home/plusButton.svg') no-repeat center;\n            background-size: contain;\n            cursor: pointer;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--RP5QT`,
	"availableSwitch": `s-module__availableSwitch--l9NDU`,
	"dayLabel": `s-module__dayLabel--AWz0I`,
	"times": `s-module__times--vWuhR`,
	"timeList": `s-module__timeList--B0TzC`,
	"add": `s-module__add--n_bDh`
};
export default ___CSS_LOADER_EXPORT___;
