import { Request } from '../request';
import { CityState, LocalSEOInfo } from 'types/common';
import { EMetaType } from 'components/PracticeFrontFromComponents/LocalSEO/types';
import { getSuggestCityByInput } from 'api/provider';
import { opsGetSuggestCityByInput } from 'api/operation';

const getGoogleGeo = ():any => {
    //@ts-ignore
    if (!window.google) {
        return;
    }
    //@ts-ignore
    const googleApi = window.google as any;
    const geocoder = new googleApi.maps.Geocoder();
    return geocoder as any;
};

//not work
// const getPlacesService = ():any => {
//     //@ts-ignore
//     if (!window.google) {
//         return;
//     }
//     //@ts-ignore
//     const googleApi = window.google as any;
//     const placesService = new googleApi.maps.places.PlacesService();
//     return placesService as any;
// };

export const lookupLocation = (address:string, callback:(cityState: CityState[], status:string)=>void, isOps?:boolean) => {
    const fn = isOps ? opsGetSuggestCityByInput : getSuggestCityByInput;
    fn(address).then((result) => {
        if (!result.error) {
            const citys:string[] = result?.data?.data || [];
            const cityState:CityState[] = citys?.map((item) => {
                return {
                    city: item,
                    state: '',
                };
            });
            callback(cityState, 'OK');
        } else {
            callback([], 'FAIL');
        }
    }).catch((e) => {
        console.error(e);
        callback([], 'FAIL');
    });
};

//@no-used
export const getNearByCity = (address:string, callback:(results:string[], status:'OK' | 'FAIL')=>void) => {
    const geocoder = getGoogleGeo();
    if (!geocoder) Promise.resolve([undefined, undefined]);
    geocoder.geocode({ address }, (results:any, status:string) => {
        if (status === 'OK' && results[0]) {
            const { location } = results[0].geometry;
            Request.get({
                url: 'https://provider.kiwihealth.com/api/getCityFromAddress',
                params: { lat: location.lat(), lng: location.lng() },
                disableToken: true,
            }).then((res) => {
                if (!res.error) {
                    const cR = res?.data?.results;
                    if (cR && cR.length > 0) {
                        const newCitys = cR.map((item:any) => {
                            const { vicinity } = item;
                            if (vicinity) {
                                const vicinityArr = vicinity.split(',');
                                if (vicinityArr.length >= 2) {
                                    return vicinityArr[1];
                                }
                            }
                            return false;
                        })?.filter(Boolean);
                        callback(newCitys, 'OK');
                    } else {
                        callback([], 'FAIL');
                    }
                } else {
                    callback([], 'FAIL');
                }
            }).then(() => {
                callback([], 'FAIL');
            });
        }
    });
};
export const getLocalSeoInfo = (address:string, targetSeoInfo:LocalSEOInfo | any, serverSeoInfoArray: LocalSEOInfo[], callback:(results:LocalSEOInfo, status:'OK' | 'FAIL')=>void) => {
    const geocoder = getGoogleGeo();
    if (!geocoder) Promise.resolve([undefined, undefined]);
    geocoder.geocode({ address }, (results:any, status:string) => {
        if (status === 'OK' && results[0]) {
            const { location } = results[0].geometry;
            const requestAndTargetSeoInfo:any[] = [
                {
                    radius: 160000,
                    type: 'point_of_interest',
                    param: 'landmarks',
                },
                {
                    radius: 160000,
                    type: 'locality',
                    param: 'cities',
                },
                {
                    radius: 160000,
                    type: 'administrative_area_level_2',
                    param: 'counties',
                },
                {
                    radius: 160000,
                    type: 'postal_code',
                    param: 'zip',
                },
            ];

            for (let j = 0; j < requestAndTargetSeoInfo.length; j++) {
                const requestItem = requestAndTargetSeoInfo[j];
                Request.get({
                    disableToken: true,
                    url: 'https://provider.kiwihealth.com/api/getCityFromAddress',
                    params: { lat: location.lat(), lng: location.lng(), radius: requestItem.radius, type: requestItem.type },
                }).then((cityResult) => {
                    if (!cityResult.error) {
                        const cR = cityResult?.data?.results;
                        if (cR && cR.length > 0) {
                            const newItems:string[] = cR.map((item:any) => {
                                const { name } = item;
                                if (name) {
                                    return name;
                                }
                                return false;
                            })?.filter(Boolean);
                            const targetServerSeoInfo:any = serverSeoInfoArray?.find((item) => item.state === targetSeoInfo.state);
                            let newArrayData = [...targetSeoInfo![requestItem.param] as string[], ...newItems];
                            if (targetServerSeoInfo && targetServerSeoInfo?.[requestItem.param]) {
                                newArrayData = [...targetServerSeoInfo[requestItem.param]];
                            }
                            targetSeoInfo![requestItem.param] = Array.from<string>(new Set(newArrayData)).slice(0, 5);
                            callback(targetSeoInfo, 'OK');
                        }
                    }
                });
            }
        }
    });
};

export const autoComplete = (input:string, types:EMetaType, callback:(cityState: string[], status:string)=>void) => {
    let typeStr = 'geocode';
    if (types === EMetaType.COUNTIES) {
        typeStr = 'administrative_area_level_2';
    }
    if (types === EMetaType.LANDMARKS) {
        typeStr = 'point_of_interest';
    }
    Request.get({
        disableToken: true,
        url: 'https://provider.kiwihealth.com/api/getAutoComplete',
        params: { input, types: typeStr },
    }).then((result) => {
        if (!result.error) {
            const cR = result?.data?.results;
            if (cR && cR.length > 0) {
                const newItems:string[] = cR.map((item:any) => {
                    const { structured_formatting } = item;
                    if (structured_formatting?.main_text) {
                        return structured_formatting?.main_text;
                    }
                    return false;
                })?.filter(Boolean);
                callback(newItems, 'OK');
            }
        } else {
            callback([], 'FAIL');
        }
    }).then(() => {
        callback([], 'FAIL');
    });
};
