import React from 'react';
import s from './s.module.less';
import { IAddonPhysician } from 'types/ehr';
import dayjs from 'dayjs';
import { getStateDisplayName } from 'utils/common';
import { EnumFields } from 'types/enumerationData';
import useGetSettingData from 'hooks/useGetSettingData';

interface IProps {
    data: IAddonPhysician[]
}

const CollaboratePhysician = ({
    data,
}: IProps) => {
    const [stateData] = useGetSettingData(EnumFields.STATE);
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>Collaborate physician</div>
            </div>
            {
                data?.map((item) => {
                    const { id, name, tel, email, address, zip, birthdayDate, specialities, npi, licenseState, effectiveDate, expDate, url, addOnList } = item;
                    return (
                        <div key={id} className={s.body}>
                            <div className={s.row}>
                                <div className={s.item}>
                                    <div className={s.label}>Physician’s name</div>
                                    <div className={s.value}>{name}</div>
                                </div>
                                <div className={s.item}>
                                    <div className={s.label}>Physician’s phone number</div>
                                    <div className={s.value}>{tel}</div>
                                </div>
                                <div className={s.item}>
                                    <div className={s.label}>Email</div>
                                    <div className={s.value}>{email}</div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.item}>
                                    <div className={s.label}>Physician’s address</div>
                                    <div className={s.value}>{address}</div>
                                </div>
                                <div className={s.item}>
                                    <div className={s.label}>ZIP code</div>
                                    <div className={s.value}>{zip}</div>
                                </div>
                                <div className={s.item} />
                            </div>
                            <div className={s.row}>
                                <div className={s.item}>
                                    <div className={s.label}>Date of birth</div>
                                    <div className={s.value}>{birthdayDate ? dayjs(birthdayDate).format('MM-DD-YYYY') : '-'}</div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.item}>
                                    <div className={s.label}>Physician’s specialities</div>
                                    <div className={s.value}>{specialities}</div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.item}>
                                    <div className={s.label}>Physician’s NPI number</div>
                                    <div className={s.value}>{npi}</div>
                                </div>
                                <div className={s.item}>
                                    <div className={s.label}>Licensed states</div>
                                    <div className={s.value}>{getStateDisplayName(licenseState, stateData)}</div>
                                </div>
                                <div className={s.item} />
                            </div>
                            <div className={s.row}>
                                <div className={s.item}>
                                    <div className={s.label}>Effective date of the agreement</div>
                                    <div className={s.value}>{effectiveDate ? dayjs(effectiveDate).format('MM-DD-YYYY') : '-'}</div>
                                </div>
                                <div className={s.item}>
                                    <div className={s.label}>Expiration date of the agreeement</div>
                                    <div className={s.value}>{expDate ? dayjs(expDate).format('MM-DD-YYYY') : '-'}</div>
                                </div>
                                <div className={s.item} />
                            </div>
                            <div className={s.row}>
                                <div className={s.item}>
                                    <div className={s.label}>Uploaded license</div>
                                    <img className={s.picture} src={url} />
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.item}>
                                    <div className={s.label}>Selected add-on</div>
                                    <div className={s.value}>{addOnList ? addOnList?.join(',') : '-'}</div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default CollaboratePhysician;
