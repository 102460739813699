// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--vvXwm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
}
.s-module__wrap--vvXwm .s-module__content--kmaF5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 768px;
}
.s-module__wrap--vvXwm .s-module__content--kmaF5 img {
  margin-bottom: 36px;
  width: 30vw;
  max-width: 300px;
}
.s-module__wrap--vvXwm .s-module__content--kmaF5 .s-module__title--p91Uu {
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  color: var(--gray-900);
  text-align: center;
}
.s-module__wrap--vvXwm .s-module__content--kmaF5 .s-module__desc--n_eIf {
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  letter-spacing: 0.01em;
  color: var(--gray-500);
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/AccessDenied/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;AACJ;AAPA;EASQ,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACR;AAdA;EAgBY,mBAAA;EACA,WAAA;EACA,gBAAA;AACZ;AAnBA;EAsBY,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;AAAZ;AA3BA;EA8BY,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,sBAAA;EACA,kBAAA;AAAZ","sourcesContent":[".wrap {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    font-family: 'Inter';\n    font-style: normal;\n\n    .content {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        width: 768px;\n\n        img {\n            margin-bottom: 36px;\n            width: 30vw;\n            max-width: 300px;\n        }\n    \n        .title {\n            margin-bottom: 20px;\n            font-weight: 800;\n            font-size: 44px;\n            line-height: 60px;\n            color: var(--gray-900);\n            text-align: center;\n        }\n        .desc {\n            font-weight: 400;\n            font-size: 24px;\n            line-height: 37px;\n            letter-spacing: 0.01em;\n            color: var(--gray-500);\n            text-align: center;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--vvXwm`,
	"content": `s-module__content--kmaF5`,
	"title": `s-module__title--p91Uu`,
	"desc": `s-module__desc--n_eIf`
};
export default ___CSS_LOADER_EXPORT___;
