/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import s from './s.module.less';
import { TKlarityProgramItemDetail, TKlarityProgramPackageItem } from 'types/common';
import FrequencyQuestionSecion from 'components/FrequencyQuestionSecion';
import { TFaqInfo } from 'types/operation';
import icon from './icon.svg';
import { Button, Switch } from 'antd';

type IProps = {
    data?: TKlarityProgramItemDetail;
};

const FrequentlyAskedQuestions = (props: IProps) => {
    const { data } = props;
    const program = data;
    const items: TKlarityProgramPackageItem[] = [];
    const existPackageName: string[] = [];
    data?.klarityProgramPackageList?.forEach((item: TKlarityProgramPackageItem) => {
        if (!item.isKlarityDisplay || item.billingFrequencyType !== "Monthly") {
            return;
        }
        const pName = item.packageTitle;
        if (!existPackageName.includes(pName)) {
            existPackageName.push(pName);
            items.push(item);
        }
    })
    const item: TKlarityProgramPackageItem | undefined = items && items.length > 0 ? items?.[0] : undefined;

    if (!item) {
        return null;
    }

    const itemOneTime = data?.klarityProgramPackageList?.find((i) => i.packageTitle === item.packageTitle && i.billingFrequencyType === "One-Time");
    const hasOneTimeOff = !!itemOneTime && itemOneTime.totalPrice && item.totalPrice && itemOneTime.totalPrice < item.totalPrice;
    const firstPaymentPrice = item?.firstPaymentPrice - item?.price;
    const faq: TFaqInfo[] = [];
    if (!item) {
        return null;
    }
    if (item?.klarityProgramPackageExternalInfo?.faq) {
        try {
            const faqData = JSON.parse(item.klarityProgramPackageExternalInfo.faq);
            faqData?.forEach((item: any, inx: number) => {
                faq.push({
                    pageId: inx,
                    seq: inx,
                    question: item.q,
                    answer: item.a,
                });
            });
        } catch (e) {
            console.error('parse faq error', e);
        }
    }
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <span className={s.title}>Service details</span>
                <span className={s.close}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path d="M4.85156 12.6741L12.9476 4.57813M4.85156 4.57812L12.9476 12.6741" stroke="#4B5563" strokeWidth="1.34933" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
            </div>
            <div className={s.banner}>
                <img src={icon} alt="icon" />
                <div className={s.bInfo}>
                    <div className={s.iTitle}>
                        Klarity Select Programs
                    </div>
                    <div className={s.iDes}>
                        End-to-end care with guaranteed service, more value, and lower costs
                    </div>
                </div>
            </div>
            <div className={s.faqWrap}>
                <div className={s.faqTitle}>Frequently asked questions</div>
                <FrequencyQuestionSecion data={faq || []} openAll />
            </div>
            <div className={s.footer}>
                <div className={s.fLeft}>
                    <div className={s.fInfo}>
                        <span className={s.initPrice}>
                            ${firstPaymentPrice}
                        </span>
                        for initial visit +
                        <span className={s.monthlyPrice}>
                            ${item.price}/month
                        </span>
                        for medication subscription (Total: ${item.totalPrice})
                    </div>
                    {
                        hasOneTimeOff && (
                            <div className={s.payoff}>
                                Pay in full to get
                                <span className={s.high}>
                                    ${item.totalPrice - itemOneTime.totalPrice} off
                                </span>
                                <span>
                                    <Switch checked={false} />
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className={s.fRight}>
                    <Button
                        className={s.btn}
                        type='primary' size='large'>
                        Book now
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default FrequentlyAskedQuestions;
