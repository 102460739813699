import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Checkbox, Space } from 'antd';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface IProps {
    value?: string[];
    options: DefaultOptionType[];
    showCheckAll?: boolean;
    checkAllText?: string;
    onChange?: (v: CheckboxValueType[]) => void;
}

const CheckGroup = ({
    value,
    options,
    showCheckAll,
    checkAllText = 'All of the above',
    onChange,
}: IProps) => {
    const [val, setVal] = useState<string[]>([]);
    const [checkAll, setCheckAll] = useState(false);

    useEffect(() => {
        if (value) {
            setVal(value);
        }
    }, [value]);

    useEffect(() => {
        setCheckAll(val.length > 0 && val.length === options.length);
    }, [options, val]);

    const handleCheckall = useCallback((e: CheckboxChangeEvent) => {
        let v: string[] = [];
        if (e.target.checked) {
            v = options.map((option) => option.value?.toString() as string);
        }

        setVal(v);
        onChange?.(v);
    }, [onChange, options]);

    return (
        <div className={s.wrap}>
            <Space direction="vertical">
                <Checkbox.Group value={value} onChange={onChange}>
                    <Space direction="vertical">
                        {
                            options?.map((e: DefaultOptionType, i: number) => {
                                const { value: optionValue, label } = e;

                                return <Checkbox key={i} value={optionValue?.toString()}>{label}</Checkbox>;
                            })
                        }
                    </Space>
                </Checkbox.Group>
                {
                    showCheckAll && (
                        <Checkbox
                            checked={checkAll}
                            onChange={handleCheckall}
                        >{checkAllText}
                        </Checkbox>
                    )
                }
            </Space>
        </div>
    );
};

export default CheckGroup;
