import { ISubmitRecommandChannelInput, IUpdateAccessInfo } from 'types/channel';
import { Request } from '../request';

export const getChannelList = () => Request.get({ url: '/schedule-server/provider/channel/live/list' });

export const getChannelDetailById = (id: string) => Request.get({ url: `/schedule-server/provider/channel/live/detail/${id}` });

export const getIntergratingChannelList = () => Request.get({ url: '/schedule-server/live/list' });

export const updateVoteById = ({ id, voteType }: { id:number, voteType: number }) => Request.put({ url: `/schedule-server/provider/channel/vote/${id}/${voteType}` });

export const channelListingStart = (channelId: number) => Request.put({ url: `/schedule-server/provider/channel/list/start/${channelId}` });

export const channelWithdraw = (data: {
    'allowAuth'?: boolean,
    'channelId'?: number,
    'providerId'?: number,
    'step'?: string
}) => Request.put({ url: '/schedule-server/provider/channel/list/withdraw', params: data });

export const getLiveChannelList = () => Request.get({ url: '/schedule-server/provider/channel/live/list' });

export const getRecommendChannelList = () => Request.get({ url: '/schedule-server/provider/channel/recommend/list' });

export const getChannelServiceList = () => Request.get({ url: '/schedule-server/provider/channel/service/list' });

export const getUpcomingChannelList = () => Request.get({ url: '/schedule-server/provider/channel/upcoming/list' });

export const createNewChannel = (params: {
    'caseStudy'?: string,
    'channelDesc'?: string,
    'complete'?: boolean,
    'economics'?: string,
    'id'?: number,
    'intro'?: string,
    'logo'?: string,
    'name': string,
    'news'?: string,
    'tagList'?: Array<string>,
    'website'?: string
}) => Request.post({ url: '/schedule-server/channel/interest/save', params });

export const submitRecommandChannels = (data: ISubmitRecommandChannelInput) => Request.post({ url: '/schedule-server/provider/channel/submit', params: data, disableErrorNote: true });

export const getChannelMultiple = () => Request.get({ url: '/schedule-server/provider/channel/service/multiple' });

export const getMyChannels = () => Request.get({ url: '/schedule-server/provider/channel/overview' });

export const removePremiumChannel = (channelId: number) => Request.put({ url: `/schedule-server/provider/channel/remove/${channelId}` });

export const reactivatePremiumChannel = (channelId: number) => Request.put({ url: `/schedule-server/provider/channel/remove/cancel/${channelId}` });

export const deleteLicense = (id: number) => Request.delete({ url: `/schedule-server/license/del/${id}` });

export const deletePhysician = (id: number) => Request.delete({ url: `/schedule-server/physician/del/${id}` });

export const getConnectChannelList = () => Request.get({ url: '/schedule-server/provider/channel/access/collected/list' });

export const getKlarityRecommandChannels = () => Request.get({ url: '/schedule-server/provider/channel/klarity/recommend/list' });

export const updateAccessInfo = (data: IUpdateAccessInfo[]) => Request.put({ url: '/schedule-server/provider/channel/accessInfo/submit', params: data });

export const getCMDAccessInfoByProviderid = () => Request.get({ url: '/schedule-server/provider/channel/accessInfo/getByProviderId' });

export const submitNonCMDChannelSelected = (recordType = 'CHANNEL_MANAGE', value: string|number = 'NO') => Request.post({ url: '/schedule-server/common/provider/select/record/save', params: { recordType, value } });

export const getShouldShowCMDNotice = () => Request.get({ url: '/schedule-server/provider/channel/manage/check' });
