import { Request } from '../request';
import type { IPerformanceInput, IReviewInput, IReviewSummaryInput, IDisputeReviewInput, IDisputeDissionSubmitInput, IUpdateReviewtToPrivateInput } from 'types/operation';

export const getPerformanceList = (data: IPerformanceInput) => Request.post({ url: '/admin-server/provider/review/performance', params: data });
export const getReviewList = (data: IReviewInput) => Request.post({ url: '/admin-server/provider/review/page', params: data });
export const getReviewSummary = (data: IReviewSummaryInput) => Request.post({ url: '/admin-server/provider/review/summary', params: data });
export const getGoogleSheet = (type: string) => Request.get({ url: `/admin-server/provider/review/google/sheet/${type}` });
export const getDisputeReviewsList = (data: IDisputeReviewInput) => Request.post({ url: '/admin-server/provider/review/dispute/page', params: data });
export const getDisputeDetail = (providerId: number, reviewId: number) => Request.get({ url: `/admin-server/provider/review/dispute/detail/${providerId}/${reviewId}` });
export const submitDiputeDisission = (data: IDisputeDissionSubmitInput) => Request.post({ url: '/admin-server/provider/review/dispute/decision', params: data });
export const updateReviewtToPrivate = (data: IUpdateReviewtToPrivateInput) => Request.post({ url: '/admin-server/provider/review/private/remove', params: data });
