import React from 'react';
import { Modal, Form, message, Input, Button, Tag } from 'antd';
import dayjs from 'dayjs';
import commonS from 'styles/common.module.less';
import { ServerEvent, Event } from 'types/calendar';
import FormValidationHelper from 'utils/validation';
import { FormFieldEnum, FormInterface } from './types';
import { calendarCreateEvent, CreateEventType, calendarUpdateEvent, UpdateEventType, calendarDeleteEvent, DeleteEventType } from 'api/google';
import ServiceSelect from 'components/form/ServiceSelect';
import TimeRangerPicker from 'components/form/TimeRangerPicker';
import { getUserId } from 'utils/localstore';
import s from './s.module.less';
import { getTimeStrForGoogleCalandar } from 'utils/calendar';
import { ColorKey } from 'constants/calendar';
import { EventData } from 'data/calendar';
import useService from 'hooks/useService';
import useChannel from 'hooks/useChannel';
import useTimezone from 'hooks/useTimezone';
import useIntakeqForm from 'hooks/useIntakeqForm';
import { getBrowserTimezoneOffset } from 'utils/common';
import { SUPPORT_TIME_ZONE } from 'constants/common';
import useMyListedChannels from 'hooks/useMyListedChannels';
import MyChannelSelect from 'components/form/MyChannelSelect';
import type { NotificationInstance } from 'antd/es/notification/interface';
import { Prospect } from 'types/common';

type Props = {
    onCancel:()=>void
    event?:Event //for edit fun
    onSuccess?: ()=>void
    api?:NotificationInstance
    currentChannelId?: number,
    prospect?: Prospect
};

const AddAppointmentModal = (props:Props) => {
    const { onCancel, event, onSuccess, api, currentChannelId, prospect } = props;
    const [services, servicesLoading] = useService();
    const [channels, channelsLoading] = useChannel();
    const [intakeqForms, intakeqFormsLoading] = useIntakeqForm({
        isOps: false,
    });
    const [, myTimezone] = useTimezone();
    const [form] = Form.useForm<FormInterface>();
    const [submiting, setSubmiting] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const [targetServiceId, setTargetServiceId] = React.useState<string>();
    const [myChannels] = useMyListedChannels();
    const [emailChange, setEmailChange] = React.useState(false);
    const [channelId, setChannelId] = React.useState<number>();
    const [,forceUpdate] = React.useState({});
    const isEdit = !!event;
    const isLoading = servicesLoading || channelsLoading || intakeqFormsLoading;
    let showTimezone = false;
    if (myTimezone?.value) {
        const target = SUPPORT_TIME_ZONE.find((item) => item.dictKey.toLowerCase() === (myTimezone?.value as string).toLowerCase());
        if (target && target.offset !== getBrowserTimezoneOffset()) {
            showTimezone = true;
        }
    }
    const hasChannel = myChannels && myChannels.length > 0;

    //telehealth， personAddress
    const selectService = services?.find((service) => {
        return service.id === targetServiceId;
    });
    const isTelehealth = !!(selectService && selectService.telehealthType);
    const isInPerson = !!(selectService && selectService.personAddress);

    React.useEffect(() => {
        if (currentChannelId) {
            setChannelId(currentChannelId);
            form.setFieldValue(FormFieldEnum.CHANNEL_ID, currentChannelId);
        }
    }, [currentChannelId]);

    const showSuccessNotification = (evt: CreateEventType | UpdateEventType) => {
        const timeLabel = `${dayjs(new Date(evt.start)).format('hh:mm')} - ${dayjs(new Date(evt.end)).format('hh:mm A')}`;
        const title = isEdit ? 'Update appointment success' : 'Add appointment success';
        onSuccess?.();
        if (!api) {
            message.success(title);
            return;
        }
        api?.success({
            message: title,
            description: (
                <div>
                    <div className={s.notiTitle}>{evt.summary}</div>
                    <div className={s.notiTime}>{timeLabel}</div>
                </div>),
            placement: 'topRight',
            duration: 5,
        });
    };

    const showDeleteNotification = (sumary: string) => {
        onSuccess?.();
        if (!api) {
            message.success('Delete appointment success');
            return;
        }
        api?.warning({
            message: 'Delete appointment success',
            description: (
                <div>
                    <div className={s.notiTitle}>{sumary} is deleted</div>
                </div>),
            placement: 'topRight',
            duration: 5,
        });
    };

    const onOk = async () => {
        try {
            setSubmiting(true);
            setSubmiting(true);
            const formValues = await form.validateFields();
            const targetService = services.find((service) => {
                return service.id === formValues.serviceId;
            });
            const targetChannels = channels.find((channel) => {
                return channel.id === formValues.channelId;
            });
            const targetForm = intakeqForms.find((intakeqForm) => {
                return intakeqForm.id === formValues.sendForm;
            });
            const title = `[${targetService?.name}]${targetChannels ? `[${targetChannels.name}]` : ''} - Appointment with ${formValues.patientName}`;
            const des = `${title} \n [Meeting link]:${formValues.meetingLink} \n [Intake form]:${targetForm?.name}`;
            const data:CreateEventType = {
                providerId: getUserId()!,
                description: des,
                email: [formValues.patientEmail],
                location: formValues.location,
                end: getTimeStrForGoogleCalandar(formValues.time[1], myTimezone.value as string), //'2023-01-05T17:00:00-07:00'
                meetingLink: formValues.meetingLink,
                patientName: formValues.patientName,
                patientPhone: formValues.patientPhone,
                start: getTimeStrForGoogleCalandar(formValues.time[0], myTimezone.value as string),
                summary: title,
                timeZone: myTimezone?.value as string,
                //timeZone: 'Asia/Shanghai',
                colorId: ColorKey.appointment,
                serviceId: targetService?.id,
                sendForm: formValues.sendForm,
                channelId: formValues.channelId as any,
            };
            if (targetChannels) {
                data.channelId = `${targetChannels?.id}`;
            }
            let result;
            if (isEdit) {
                const updateData:UpdateEventType = {
                    eventId: event.id,
                    ...data,
                };
                result = await calendarUpdateEvent(updateData!);
            } else {
                result = await calendarCreateEvent(data!);
            }

            if (!result.error) {
                showSuccessNotification(data);
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };
    const onDelete = async () => {
        try {
            setDeleting(true);
            const data:DeleteEventType = {
                eventId: event!.id,
            };
            const result = await calendarDeleteEvent(data);
            if (!result.error) {
                showDeleteNotification(event?.summary || 'Appointment');
            }
        } catch (e:any) {
            console.error(e);
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
            onCancel();
        } finally {
            setDeleting(false);
        }
    };
    React.useEffect(() => {
        if (event) {
            const serverEvent:ServerEvent = EventData.transferGoogleEventToServerEvent(event);
            //const title = serverEvent.description;
            //change title to service and channel?
            form.setFieldsValue({
                [FormFieldEnum.CHANNEL_ID]: serverEvent.channelId,
                [FormFieldEnum.SERVICE_ID]: serverEvent.serviceId,
                [FormFieldEnum.MEETING_LINK]: serverEvent.meetingLink,
                [FormFieldEnum.LOCATION]: serverEvent.location,
                [FormFieldEnum.TIME]: [dayjs(serverEvent.start), dayjs(serverEvent.end)],
                [FormFieldEnum.PATIENT_NAME]: serverEvent.patientName,
                [FormFieldEnum.PATIENT_EMAIL]: serverEvent.email ? serverEvent.email[0] : '',
                [FormFieldEnum.PATIENT_PHONE]: serverEvent.patientPhone,
                [FormFieldEnum.SEND_FORM]: serverEvent.sendForm,
            });
            forceUpdate({});
            if (serverEvent.channelId) {
                setChannelId(parseInt(serverEvent.channelId, 10));
            }
        }
    }, [event]);

    React.useEffect(() => {
        if (prospect && !event) {
            form.setFieldsValue({
                [FormFieldEnum.CHANNEL_ID]: prospect.channelId,
                [FormFieldEnum.PATIENT_NAME]: `${prospect.firstName} ${prospect.lastName}`,
                [FormFieldEnum.PATIENT_EMAIL]: prospect.email || '',
                [FormFieldEnum.PATIENT_PHONE]: prospect.tel,
            });
            forceUpdate({});
        }
    }, [prospect, event]);

    return (
        <Modal
            title={event ? 'Edit appointment' : 'Add appointment'}
            closable
            className={commonS.modalFixHeightWrap}
            width="660px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <div>
                    {isEdit &&
                    <Button
                        onClick={onDelete}
                        type="primary"
                        loading={deleting}
                        danger
                    >
                        Delete
                    </Button>
                    }
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onOk}>Save</Button>
                </div>
            }
        >
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <div className={s.module}>
                    {hasChannel &&
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '320px' }}
                                label="Select a channel:"
                                name={[FormFieldEnum.CHANNEL_ID]}
                                validateFirst
                                rules={[
                                    { required: true, message: 'Channel is required' },
                                ]}
                            >
                                <MyChannelSelect onChange={(id) => {
                                    form.setFieldValue(FormFieldEnum.SERVICE_ID, undefined);
                                    setChannelId(id);
                                }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    }
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '320px' }}
                                label="Select a service type:"
                                name={[FormFieldEnum.SERVICE_ID]}
                                validateFirst
                                rules={[
                                    { required: true, message: 'Service type is required' },
                                ]}
                            >
                                <ServiceSelect
                                    channelId={channelId}
                                    onChange={(serviceId) => {
                                        setTargetServiceId(serviceId);
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    {isTelehealth &&
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Telehealth meeting link:"
                                name={[FormFieldEnum.MEETING_LINK]}
                                validateFirst
                                rules={[
                                    { required: false, message: 'Meeting link is required' },
                                ]}
                            >
                                <Input maxLength={200} placeholder="Virtual meeting link" />
                            </Form.Item>
                        </div>
                    </div>
                    }
                    {
                        isInPerson &&
                        <div className={s.row}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '360px' }}
                                    label="Location:"
                                    name={[FormFieldEnum.LOCATION]}
                                    validateFirst
                                    rules={[
                                        { required: false, message: 'Location is required' },
                                    ]}
                                >
                                    <Input maxLength={200} placeholder="" />
                                </Form.Item>
                            </div>
                        </div>
                    }
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '550px' }}
                                label={
                                    <div>
                                        Date and time {showTimezone && <Tag color="red">{myTimezone?.value}</Tag>}:
                                    </div>
                                }
                                name={[FormFieldEnum.TIME]}
                                rules={[{ required: true, message: 'Date and time is required' }]}
                            >
                                <TimeRangerPicker />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Patient name:"
                                name={[FormFieldEnum.PATIENT_NAME]}
                                validateFirst
                                rules={[
                                    { required: true, message: 'Patient name is required' },
                                ]}
                            >
                                <Input disabled={isEdit && !emailChange} maxLength={20} placeholder="" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Patient email:"
                                name={[FormFieldEnum.PATIENT_EMAIL]}
                                validateFirst
                                rules={[
                                    { required: true, message: 'Patient email is required' },
                                    { type: 'email', message: 'Please input correct email format' },
                                ]}
                            >
                                <Input
                                    onChange={() => {
                                        setEmailChange(true);
                                    }}
                                    maxLength={200}
                                    placeholder=""
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Patient phone number:"
                                name={[FormFieldEnum.PATIENT_PHONE]}
                                validateFirst
                                rules={[
                                    { required: true, message: 'Patient phone number is required' },
                                ]}
                            >
                                <Input maxLength={30} />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddAppointmentModal;
