import React, { useMemo } from 'react';
import s from './s.module.less';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';

interface IProps {
    showDot?: boolean;
    statusText?: string;
    status?: string;
}

const ChannelStatus = ({
    showDot,
    status,
    statusText,
}: IProps) => {
    const [channelStatus] = useGetSettingData(EnumFields.LIST_CHANNEL_STATUS);

    const content = useMemo(() => {
        let cls = '';
        switch (status) {
            case 'listed':
            case 'approved':
                cls = s.listed;
                break;
            case 'under-review':
                cls = s.inprogress;
                break;
            case 'rejected':
                cls = s.rejected;
                break;
            case 'started':
                cls = s.started;
                break;
            default:
                cls = '';
        }

        return {
            className: `${s.status} ${cls} ${showDot ? s.dot : ''}`,
            statusText: statusText || channelStatus?.find((item) => item.dictKey === status)?.content,
        };
    }, [status, showDot, statusText, channelStatus]);

    return <div className={content.className}>{content.statusText}</div>;
};

export default ChannelStatus;
