import React, { useCallback } from 'react';
import AutoLineBreakTextArea from '../AutoLineBreakTextArea/AutoLineBreakTextArea';
import DescrbeList from '../DescribeList';

interface IPros {
    value?: string;
    onChange?: (val: string) => void;
}

const TreatmentApproach = ({
    value = '',
    onChange,
}: IPros) => {
    const handleSelectTag = useCallback((v: string) => {
        const val = (value ? `${value.replace(/\n$/, '')}\n•${v}` : `•${v}`);

        if (typeof onChange === 'function') {
            onChange(val);
        }
    }, [onChange, value]);

    const handleTextAreaChange = useCallback((val: string) => {
        if (typeof onChange === 'function') {
            onChange(val);
        }
    }, [onChange]);

    return (
        <div>
            <DescrbeList approchValue={value} onSelect={handleSelectTag} />

            <AutoLineBreakTextArea
                value={value}
                onChange={handleTextAreaChange}
                placeholder="Ex. &#13;&#10;•Cognitive Behavioral (CBT)&#13;&#10;•Culturally Sensitive&#13;&#10;•Eclectic&#13;&#10;"
            />

        </div>
    );
};

export default TreatmentApproach;
