import { Request } from '../request';
import { IAddOpsMemberInput, IUpdateRoleInput, IAddRoleInput, IAddWhitelistMemberInput } from 'types/access';

// ops member
export const getOpsMemberList = () => Request.get({ url: '/admin-server/user/list' });

export const addOpsMember = (data: IAddOpsMemberInput) => Request.post({ url: '/admin-server/user/create', params: data });

export const deleteOpsMemberById = (id: string) => Request.delete({ url: `/admin-server/user/del/${id}` });

export const deleteOpsMembers = (data: Array<number>) => Request.delete({ url: '/admin-server/user/del/batch', params: data });

export const updateOpsMembers = (id: number, data: IAddOpsMemberInput) => Request.put({ url: `/admin-server/user/update/${id}`, params: data });

// role
export const getRoleList = () => Request.get({ url: '/admin-server/role/list' });

export const getRoleMain = () => Request.get({ url: '/admin-server/role/main' });

export const updateRoleById = (id: number, data: IUpdateRoleInput) => Request.put({ url: `/admin-server/role/update/${id}`, params: data });

export const addRole = (data: IAddRoleInput) => Request.post({ url: '/admin-server/role/create', params: data });

// dev whitelist
export const getDevWhitelist = () => Request.get({ url: '/admin-server/providerWhitelist/list' });

export const addDevWhitelistMember = (data: IAddWhitelistMemberInput) => Request.post({ url: '/admin-server/providerWhitelist/create', params: data });

export const deleteDevWhiteListMembers = (data: Array<number>) => Request.delete({ url: '/admin-server/providerWhitelist/del/batch', params: data });

export const updateDevWhiteLis = (id: number, data: IAddWhitelistMemberInput) => Request.put({ url: `/admin-server/providerWhitelist/update/${id}`, params: data });
