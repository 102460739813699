import React from 'react';
import s from './s.module.less';

type Props = {
    moreText?: string,
    content?: string,
    line: number,
    preLine?: boolean,
    onTextContentWidthChange?: (width: number) => void,
    autoAdjustMoreButton?: boolean,
    autoAdjustMoreButtonFollowText?: boolean, //set more button follow last line text
    isShow?: boolean,
};

const getTextWidths = (originalDiv: HTMLElement, maxLines: number) => {
    const hiddenDiv = document.createElement('div');

    // Clone the style of the original div
    const style = getComputedStyle(originalDiv);
    hiddenDiv.style.whiteSpace = style.whiteSpace;
    hiddenDiv.style.font = style.font;
    hiddenDiv.style.lineHeight = style.lineHeight;
    hiddenDiv.style.padding = style.padding;
    hiddenDiv.style.visibility = 'hidden';
    hiddenDiv.style.position = 'absolute';
    hiddenDiv.style.top = '-9999px';
    hiddenDiv.style.left = '-9999px';
    hiddenDiv.style.width = 'auto';  // Ensure the width is not restricted

    // Set the same text content
    hiddenDiv.textContent = originalDiv.textContent;

    // Append the hidden div to the body
    document.body.appendChild(hiddenDiv);

    // Get the total width of the text
    const textWidth = hiddenDiv.scrollWidth;

    // Get the width of the last line
    const lines = hiddenDiv?.textContent?.split('\n');
    const lastLine = (lines && lines.length >= maxLines) ? lines[maxLines - 1] : null;
    hiddenDiv.textContent = lastLine;
    const lastLineWidth = hiddenDiv.scrollWidth;
    // Remove the hidden div from the body
    document.body.removeChild(hiddenDiv);
    return lastLineWidth;
}

//not same ui with antd
const AjustText = (props: Props) => {
    const { moreText = 'More', content = '', line, preLine, onTextContentWidthChange, autoAdjustMoreButton, autoAdjustMoreButtonFollowText, isShow = true } = props;
    const [showAll, setShowAll] = React.useState(false);
    const [isEllipsized, setIsEllipsized] = React.useState(false);
    const [totalWidth, setTotalWidth] = React.useState<number>(0);
    const [textWidth, setTextWidth] = React.useState<number>(0);
    const [lastLineWidth, setLastLineWidth] = React.useState<number>(0);

    const textRef = React.useRef(null);

    React.useEffect(() => {
        const getTextWidth = (element: HTMLElement) => {
            // Create a temporary element
            const tempElement = document.createElement('div');

            // Copy the text content
            tempElement.textContent = element.textContent;

            // Copy the relevant styles
            const style = window.getComputedStyle(element);
            tempElement.style.whiteSpace = style.whiteSpace;
            tempElement.style.font = style.font;
            tempElement.style.fontSize = style.fontSize;
            tempElement.style.fontFamily = style.fontFamily;
            tempElement.style.lineHeight = style.lineHeight;
            tempElement.style.padding = style.padding;
            tempElement.style.visibility = 'hidden';
            tempElement.style.position = 'absolute';
            tempElement.style.whiteSpace = 'pre-line';

            // Append the temporary element to the body
            document.body.appendChild(tempElement);

            // Get the width of the temporary element
            const width = tempElement.getBoundingClientRect().width;

            // Remove the temporary element from the DOM
            document.body.removeChild(tempElement);

            return width;
        }

        const checkEllipsis = () => {
            const element: any = textRef.current;
            if (element) {
                const lineHeight = parseInt(window.getComputedStyle(element).lineHeight, 10);
                const maxHeight = lineHeight * line; // Assuming -webkit-line-clamp is 2
                const isEllipsized = element.scrollHeight > maxHeight;
                setIsEllipsized(isEllipsized);
            }
        };

        const checkWidth = () => {
            const element: HTMLElement = textRef.current!;
            if (element) {
                const maxWidth = element?.clientWidth;
                const width = getTextWidth(element);
                let textWidth = width || maxWidth;
                if (width > maxWidth) {
                    textWidth = maxWidth;
                }
                setTotalWidth(maxWidth);
                setTextWidth(textWidth);
                if (onTextContentWidthChange) {
                    onTextContentWidthChange(textWidth);
                }

                const lastLineWidth = getTextWidths(element, line);
                if (lastLineWidth > maxWidth) {
                    setLastLineWidth(maxWidth);
                } else {
                    setLastLineWidth(lastLineWidth)
                }
            }
        };

        checkEllipsis();
        checkWidth();
    }, [content, isShow]);

    //can set to more button for better display
    let moreRightPosition = 8;
    let style: React.CSSProperties | undefined;
    if (autoAdjustMoreButton) {
        moreRightPosition = totalWidth - textWidth;
        if (moreRightPosition < 8) {
            moreRightPosition = 8;
        }
        style = { right: moreRightPosition };
    } else if (autoAdjustMoreButtonFollowText) {
        let moreLeft = lastLineWidth - 24;
        if (moreLeft > (totalWidth - 48)) {
            moreLeft = totalWidth - 48;
        }
        style = { left: moreLeft, right: 'auto' };
    }
    return (
        <div
            ref={textRef}
            className={`${s.wrap} ${isEllipsized ? s.wrapElli : ''} ${showAll ? s.wrapAll : ''} ${preLine ? s.wrapPreLine : ''}`}
            style={{
                'lineClamp': line,
                'WebkitLineClamp': line,
            }}>
            {content}
            {
                moreText !== null &&
                <span
                    onClick={() => {
                        setShowAll(true)
                    }}
                    style={style}
                    className={s.more}>{moreText}
                </span>
            }
        </div>
    );
};

export default AjustText;
