// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--KAcbA .ant-table-cell {
  border-right: 0 !important;
  padding: 8px 16px !important;
  vertical-align: middle;
}
.s-module__wrap--KAcbA .ant-table-bordered .ant-table-cell {
  border-right: 1px solid #f0f0f0 !important;
}
.s-module__wrap--KAcbA .ant-table-cell:last-child {
  border-right: 1px solid rgba(5, 5, 5, 0.06) !important;
  padding: 8px 16px !important;
}
.s-module__wrap--KAcbA .ant-table-thead .ant-table-cell {
  color: var(--gray-500);
  font-weight: bold;
  color: var(--gray-800);
  font-size: 12px !important;
  font-weight: 500 !important;
}
.s-module__wrap--KAcbA .ant-table-row .ant-table-cell {
  color: var(--gray-500);
  font-size: 14px !important;
  font-weight: 400 !important;
  vertical-align: middle !important;
}
.s-module__wrap--KAcbA .ant-table-row .ant-table-cell:first-child {
  color: var(--gray-700);
  font-weight: 500 !important;
}
.s-module__wrap--KAcbA .ant-tag {
  border-radius: 2px;
  min-width: 40px;
  height: 24px;
  vertical-align: middle;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/CommonTable/s.module.less"],"names":[],"mappings":"AAAA;EAGM,0BAAA;EACA,4BAAA;EACA,sBAAA;AADN;AAJA;EAUQ,0CAAA;AAHR;AAPA;EAeM,sDAAA;EACA,4BAAA;AALN;AAXA;EAoBM,sBAAA;EAEA,iBAAA;EAIA,sBAAA;EACA,0BAAA;EACA,2BAAA;AAZN;AAhBA;EAgCM,sBAAA;EACA,0BAAA;EACA,2BAAA;EACA,iCAAA;AARN;AA3BA;EAuCM,sBAAA;EACA,2BAAA;AATN;AA/BA;EA4CM,kBAAA;EACA,eAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;AAVN","sourcesContent":[".wrap {\n  :global {\n    .ant-table-cell {\n      border-right: 0 !important;\n      padding: 8px 16px !important;\n      vertical-align: middle;\n    }\n\n    .ant-table-bordered {\n      .ant-table-cell {\n        border-right: 1px solid #f0f0f0 !important;\n      }\n    }\n\n    .ant-table-cell:last-child {\n      border-right: 1px solid rgba(5, 5, 5, 0.06) !important;\n      padding: 8px 16px !important;\n    }\n\n    .ant-table-thead .ant-table-cell {\n      color: var(--gray-500);\n      font-size: 13px !important;\n      font-weight: bold;\n    }\n\n    .ant-table-thead .ant-table-cell {\n      color: var(--gray-800);\n      font-size: 12px !important;\n      font-weight: 500 !important;\n    }\n\n    .ant-table-row .ant-table-cell {\n      color: var(--gray-500);\n      font-size: 14px !important;\n      font-weight: 400 !important;\n      vertical-align: middle !important;\n    }\n\n    .ant-table-row .ant-table-cell:first-child {\n      color: var(--gray-700);\n      font-weight: 500 !important;\n    }\n\n    .ant-tag {\n      border-radius: 2px;\n      min-width: 40px;\n      height: 24px;\n      vertical-align: middle;\n      text-align: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--KAcbA`
};
export default ___CSS_LOADER_EXPORT___;
