import React from 'react';
import s from './s.module.less';
import { GOOGLE_CLIENT_ID } from 'constants/google';
import { message, Spin } from 'antd';
import { login, opsLogin } from 'api/google';
import { ProviderBasicInfo } from 'types/provider';
import ProviderStore from 'store/Provider';
import { setUserId, setUserToken } from 'utils/localstore';
import useSyncAccess from 'hooks/useSyncAccess';

type Props = {
    isOps?: boolean;
    onSuccess: ()=> void;
};

export default function GoogleLogin(props: Props) {
    const { onSuccess, isOps } = props;
    const [loading, setLoading] = React.useState(false);
    const [, set] = ProviderStore.useStore();
    const [saveAccess, saveRole] = useSyncAccess();

    (window as any).handleOpCredentialResponse = async (result: any) => {
        if (result && result.credential) {
            setLoading(true);
            let res;
            if (isOps) {
                res = await opsLogin(result.credential);
                const privList = res?.data?.data?.privList || [];
                const role = res?.data?.data?.roles || [];

                saveAccess(privList);
                saveRole(role);
            } else {
                res = await login(result.credential);
            }
            setLoading(false);

            if (!(res?.data?.data?.authPaylod)) {
                //should we show this toast? looks we do it in Request function
                message.error("We couldn't find the email you entered in Kiwi Health. Please double-check and try again.");
                return;
            }
            if (!res?.error) {
                const info:ProviderBasicInfo = res.data?.data?.authPaylod;
                const { token } = res.data.data;
                set('data', {
                    ...info,
                });
                setUserId(info.id);
                // if (isOps) {
                //     setOpsToken(token);
                // } else {
                //     setUserToken(token);
                // }
                setUserToken(token);
                if (onSuccess) {
                    onSuccess();
                }
            }
        }
    };
    const width = window.screen.availWidth * 0.9 > 400 ? 400 : window.screen.availWidth * 0.9;
    return (
        <div className={s.wrap}>
            <Spin spinning={loading}>
                <div
                    id="g_id_onload"
                    data-client_id={GOOGLE_CLIENT_ID}
                    data-context="signin"
                    data-ux_mode="popup"
                    data-callback="handleOpCredentialResponse"
                    data-nonce=""
                    data-auto_select="false"
                    data-itp_support="true"
                />
                <div
                    className="g_id_signin"
                    data-type="standard"
                    data-shape="rectangular"
                    data-theme="outline"
                    data-text="continue_with"
                    data-size="large"
                    data-logo_alignment="center"
                    data-width={width}
                />
            </Spin>
        </div>
    );
}
