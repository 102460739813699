import React, { useMemo } from 'react';
import s from './s.module.less';

interface IProps {
    status?: string;
}

const StatusTag = ({
    status,
}: IProps) => {
    const statusClass = useMemo(() => {
        let cls = '';
        switch (status?.toLowerCase()) {
            case 'active':
                cls = s.approved;
                break;
            case 'pending':
                cls = s.pending;
                break;
            default: cls = '';
        }

        return `${s.status} ${cls}`;
    }, [status]);

    return <div className={statusClass}>{status}</div>;
};

export default StatusTag;
