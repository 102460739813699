import { Button, Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import React from 'react';
import { ChatController } from './chat-controller';
import { TextActionRequest, TextActionResponse } from './chat-types';

import s from './s.module.less';

const { TextArea } = Input;

export function TextInput({
    chatController,
    actionRequest,
    waitingAnswer,
}: {
    chatController: ChatController;
    actionRequest: TextActionRequest;
    waitingAnswer: boolean;
}): React.ReactElement {
    const chatCtl = chatController;
    const [value, setValue] = React.useState('');

    const setResponse = React.useCallback((): void => {
        if (value) {
            const res: TextActionResponse = { type: 'text', value };
            chatCtl.setActionResponse(actionRequest, res);
            setValue('');
        }
    }, [actionRequest, chatCtl, value]);

    const canSend = value && !waitingAnswer;

    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
            if (e.nativeEvent.isComposing) {
                return;
            }
            if (!canSend) {
                return;
            }

            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                setResponse();
            }
        },
        [setResponse],
    );

    return (
        <div className={s.textInputWrapper}>
            <TextArea
                placeholder="type a message...."
                value={value}
                onChange={(e): void => setValue(e.target.value)}
                onPressEnter={handleKeyDown}
                autoSize={{ minRows: 1, maxRows: 3 }}
                style={{ resize: 'none' }}
                className={s.input}
            />
            <Button
                type="primary"
                onClick={setResponse}
                disabled={!canSend}
                loading={waitingAnswer}
                icon={<SendOutlined />}
                className={s.send}
            />
        </div>
    );
}
