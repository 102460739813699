/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import s from './s.module.less';
import { TKlarityProgramItemDetail, TKlarityProgramPackageItem } from 'types/common';
import icon from './icon.svg';
import { Button, Switch } from 'antd';

type IProps = {
    data?: TKlarityProgramItemDetail;
};

const DisclaimerPolicy = (props: IProps) => {
    const { data } = props;
    const program = data;
    const items: TKlarityProgramPackageItem[] = [];
    const existPackageName: string[] = [];
    data?.klarityProgramPackageList?.forEach((item: TKlarityProgramPackageItem) => {
        if (!item.isKlarityDisplay || item.billingFrequencyType !== "Monthly") {
            return;
        }
        const pName = item.packageTitle;
        if (!existPackageName.includes(pName)) {
            existPackageName.push(pName);
            items.push(item);
        }
    })

    const item: TKlarityProgramPackageItem | undefined = items && items.length > 0 ? items?.[0] : undefined;

    if (!item) {
        return null;
    }

    const itemOneTime = data?.klarityProgramPackageList?.find((i) => i.packageTitle === item.packageTitle && i.billingFrequencyType === "One-Time");
    const hasOneTimeOff = !!itemOneTime && itemOneTime.totalPrice && item.totalPrice && itemOneTime.totalPrice < item.totalPrice;
    const firstPaymentPrice = item?.firstPaymentPrice - item?.price;

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <span className={s.title}>Service details</span>
                <span className={s.close}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path d="M4.85156 12.6741L12.9476 4.57813M4.85156 4.57812L12.9476 12.6741" stroke="#4B5563" strokeWidth="1.34933" strokeLinecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </div>
            <div className={s.banner}>
                <img src={icon} alt="icon" />
                <div className={s.bInfo}>
                    <div className={s.iTitle}>
                        Klarity Select Programs
                    </div>
                    <div className={s.iDes}>
                        End-to-end care with guaranteed service, more value, and lower costs
                    </div>
                </div>
            </div>
            <div className={s.content}>
                <div className={s.subTitle}>
                    Disclaimer & policy
                </div>
                <p>The Klarity Select Weight Loss Program is designed to support individuals in their weight management journey through personalized medical care and the use of Compounded Semaglutide where appropriate. However, this program is not suitable for all patients, and participation is subject to a thorough medical evaluation by a licensed healthcare provider.</p>
                <ul>
                    <li>Medications will only be prescribed to patients who meet the medical criteria for treatment, as determined by your healthcare provider. </li>
                    <li>Results from the weight loss program may vary based on individual factors, including medical history, lifestyle changes, and adherence to the prescribed treatment plan. </li>
                    <li>This program is not intended to replace traditional medical care or address underlying medical conditions unrelated to weight loss. </li>
                    <li>Participation in the program does not guarantee weight loss or any specific outcome. Potential risks and side effects associated with the use of Compounded Semaglutide will be discussed during your consultation, and it is your responsibility to report any adverse effects to your healthcare provider immediately. </li>
                    <li>The Klarity Select Weight Loss Program does not accept insurance, and all costs associated with consultations, medications, and follow-up care will be borne by the patient.</li>
                </ul>
                <p>By participating in this program, you acknowledge and accept the terms outlined above. Please consult your healthcare provider with any questions or concerns.</p>
            </div>
            <div className={s.footer}>
                <div className={s.fLeft}>
                    <div className={s.fInfo}>
                        <span className={s.initPrice}>
                            ${firstPaymentPrice}
                        </span>
                        for initial visit +
                        <span className={s.monthlyPrice}>
                            ${item.price}/month
                        </span>
                        for medication subscription (Total: ${item.totalPrice})
                    </div>
                    {
                        hasOneTimeOff && (
                            <div className={s.payoff}>
                                Pay in full to get
                                <span className={s.high}>
                                    ${item.totalPrice - itemOneTime.totalPrice} off
                                </span>
                                <span>
                                    <Switch checked={false} />
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className={s.fRight}>
                    <Button
                        className={s.btn}
                        type='primary' size='large'>
                        Book now
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DisclaimerPolicy;
