// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__descItem--_jMDS {
  color: var(--gray-500, #6B7280);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  display: flex;
  align-items: center;
}
.s-module__Pending--Q6GTh {
  border-radius: 20px;
  color: #92400e;
  display: flex;
  align-items: center;
  padding: 2px 10px;
  justify-content: center;
  box-sizing: border-box;
  margin-left: 4px;
}
.s-module__Pending--Q6GTh::before {
  content: '';
  display: block;
  background-color: #fbbf24;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 8px;
}
.s-module__Active--HvDav {
  border-radius: 20px;
  color: #065f46;
  display: flex;
  align-items: center;
  padding: 2px 10px;
  justify-content: center;
  box-sizing: border-box;
  margin-left: 4px;
}
.s-module__Active--HvDav::before {
  content: '';
  display: block;
  background-color: #34d399;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/EhrActiveStatus/s.module.less"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAa;EAAb,aAAA;EACA,mBAAA;AAEF;AAEA;EACE,mBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;EACA,sBAAA;EACA,gBAAA;AAAF;AACE;EACE,WAAA;EACA,cAAA;EACA,yBAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;AACJ;AAGA;EACE,mBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;EACA,sBAAA;EACA,gBAAA;AADF;AAEE;EACE,WAAA;EACA,cAAA;EACA,yBAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;AAAJ","sourcesContent":[".descItem {\n  color: var(--gray-500, #6B7280);\n  text-align: center;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px; /* 142.857% */\n  display: flex;\n  align-items: center;\n}\n\n\n.Pending {\n  border-radius: 20px;\n  color: rgba(146, 64, 14, 1);\n  display: flex;\n  align-items: center;\n  padding: 2px 10px;\n  justify-content: center;\n  box-sizing: border-box;\n  margin-left: 4px;\n  &::before {\n    content: '';\n    display: block;\n    background-color: rgba(251, 191, 36, 1);\n    width: 8px;\n    height: 8px;\n    border-radius: 8px;\n    margin-right: 8px;\n  }\n}\n\n.Active {\n  border-radius: 20px;\n  color: rgba(6, 95, 70, 1);\n  display: flex;\n  align-items: center;\n  padding: 2px 10px;\n  justify-content: center;\n  box-sizing: border-box;\n  margin-left: 4px;\n  &::before {\n    content: '';\n    display: block;\n    background-color: rgba(52, 211, 153, 1);\n    width: 8px;\n    height: 8px;\n    border-radius: 8px;\n    margin-right: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descItem": `s-module__descItem--_jMDS`,
	"Pending": `s-module__Pending--Q6GTh`,
	"Active": `s-module__Active--HvDav`
};
export default ___CSS_LOADER_EXPORT___;
