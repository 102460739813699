import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Popconfirm, Select, Tooltip } from 'antd';
// import NumberSelector from 'components/NumberSelector';
import s from './s.module.less';
import StateSelect from 'components/form/StateSelect';
import { EBookingLinkModalMode } from 'types/helloKlarity';
import Selector from 'components/Selector';
import { IEditBookingLink } from 'pages/operation/CustomizeHelloKlarityPPP/type';

type TSelectOption = {
    content: string;
    dictKey: string;
};

interface IProps {
    isError?: boolean;
    index?: number;
    watchField: string;
    linkOptions: TSelectOption[];
    serviceTypeOptions: TSelectOption[];
    specialtyOptions: TSelectOption[];
    bookingLinkNameField: string | (string | number)[];
    serviceTypeField: string | (string | number)[];
    stateField: string | (string | number)[];
    specialtyField: string | (string | number)[];
    conditionsField: string | (string | number)[];
    allConditionsList?: Record<string, TSelectOption[]>;
    fullPrefillField: string | (string | number)[];
    idField: string | (string | number)[];
    uuidField: string | (string | number)[];
    directBookingLinkField: string | (string | number)[];
    allConditionsField: string | (string | number)[];
    watchFieldAllConditionsField: string;
    onRemove?:() => void;
    getInfoFromLinkName: (linkName: string) => ({
        state: string,
        specialty: string,
        serviceType: string,
        bookingLinkName: string,
    });
    mode?: EBookingLinkModalMode;
    onCopyLink?: () => void;
}

const FormItem = ({
    isError,
    index,
    watchField,
    watchFieldAllConditionsField,
    linkOptions,
    allConditionsList,
    serviceTypeOptions,
    specialtyOptions,
    bookingLinkNameField,
    serviceTypeField,
    stateField,
    idField,
    uuidField,
    specialtyField,
    conditionsField,
    directBookingLinkField,
    allConditionsField,
    fullPrefillField, // setFieldValue需要完整的路劲的前缀，比如form list需要带上bookingList这个key + index，再加上 具体的那个fieldName
    onRemove,
    getInfoFromLinkName,
    mode,
    onCopyLink,
}: IProps) => {
    const formInstance = Form.useFormInstance();

    const [conditionsOptions, setConditionsOptions] = useState<TSelectOption[]>([]);
    const watchedFieldValue = Form.useWatch(watchField, formInstance);
    const watchAllConditionsValue = Form.useWatch(watchFieldAllConditionsField, formInstance);
    const [specialtyValue, setSpecialtyValue] = useState<string>();
    const [showOtherFields, setShowOtherFields] = useState(false);
    const [filteredLinkOptions, setFilteredLinkOptions] = useState<TSelectOption[]>([]);

    useEffect(() => {
        if (typeof index !== 'undefined' && watchedFieldValue) {
            const res = linkOptions.map((option) => {
                if (!option?.disabled) {
                    const isExsistd = watchedFieldValue.find((v) => v?.bookingLinkName === option.dictKey);

                    return {
                        ...option,
                        disabled: !!isExsistd,
                    };
                }

                return option;
            });

            setFilteredLinkOptions(res);
        } else {
            setFilteredLinkOptions(linkOptions);
        }
    }, [index, linkOptions, watchedFieldValue]);

    useEffect(() => {
        if (typeof index !== 'undefined' && watchedFieldValue) {
            setSpecialtyValue(watchedFieldValue[index].specialty);
            setShowOtherFields(!!(watchedFieldValue[index].bookingLinkName));
        } else if (!index) {
            setSpecialtyValue(watchedFieldValue);
        }
    }, [index, watchedFieldValue]);

    useEffect(() => {
        if (typeof index !== 'undefined' && watchAllConditionsValue) {
            if (watchAllConditionsValue[index]?.allCondition) {
                formInstance.setFieldValue([...(fullPrefillField as string[]), 'conditions'], conditionsOptions.map((e) => e.dictKey));
            }
        } else if (!index && watchAllConditionsValue) {
            formInstance.setFieldValue('conditions', conditionsOptions.map((e) => e.dictKey));
        }
    }, [conditionsOptions, formInstance, fullPrefillField, index, watchAllConditionsValue, watchedFieldValue]);

    useEffect(() => {
        if (allConditionsList && specialtyValue) {
            const conditions = allConditionsList[specialtyValue];

            setConditionsOptions(conditions);
        }
    }, [specialtyValue, allConditionsList]);

    const handleBookingNameChange = useCallback((e: string) => {
        const info = getInfoFromLinkName(e);

        if (mode === EBookingLinkModalMode.LINK_EDIT) {
            formInstance.setFieldsValue({
                ...info,
                conditions: [],
                directBookingLink: linkOptions.find((item) => item.dictKey === e)?.directBookingLink,
            });
        } else {
            formInstance.setFieldValue(fullPrefillField, {
                ...info,
                directBookingLink: linkOptions.find((item) => item.dictKey === e)?.directBookingLink,
            });
        }
    }, [formInstance, fullPrefillField, getInfoFromLinkName, linkOptions, mode]);

    const handleSpecialtyChange = useCallback((e: string) => {
        setSpecialtyValue(e);
        if (fullPrefillField instanceof Array) {
            formInstance.setFieldValue([...fullPrefillField, 'conditions'], []);
        } else {
            formInstance.setFieldValue('conditions', []);
        }
    }, [formInstance, fullPrefillField]);

    const handleConditionChange = useCallback(() => {
        if (fullPrefillField instanceof Array) {
            formInstance.setFieldValue([...fullPrefillField, 'allCondition'], false);
        } else {
            formInstance.setFieldValue('allCondition', false);
        }
    } ,[])

    return (
        <div className={s.linkItem}>
            <div className={s.header}>
                <div className={s.title}>Booking link {typeof index === 'number' ? index + 1 : ''}</div>
                <div className={s.btns}>
                    {
                        mode === EBookingLinkModalMode.LINK_EDIT ?
                            <Popconfirm title="Are you sure delete this data?" okText="Yes" cancelText="No" onConfirm={onRemove}>
                                <Button>Remove</Button>
                            </Popconfirm> :
                            <Button onClick={onRemove}>Remove</Button>
                    }
                    <Button onClick={onCopyLink}>Copy link</Button>
                </div>
            </div>
            <div className={`${s.body} ${isError ? s.error : ''}`}>
                <Form.Item name={idField} className={s.hidden}>
                    <Input type="hidden" />
                </Form.Item>

                <Form.Item name={uuidField} className={s.hidden}>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name={directBookingLinkField} className={s.hidden}>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name={allConditionsField} className={s.hidden}>
                    <Input type="hidden" />
                </Form.Item>
                <div className={s.formItem}>
                    <Form.Item
                        label="Booking link"
                        name={bookingLinkNameField}
                        rules={[
                            { required: true, message: 'Booking link is a required field' },
                        ]}
                    >
                        <Select
                            fieldNames={{
                                label: 'content',
                                value: 'dictKey',
                            }}
                            options={filteredLinkOptions}
                            onChange={handleBookingNameChange}
                        />
                        {/* <NumberSelector
                            options={linkOptions}
                        /> */}
                    </Form.Item>
                </div>
                {
                    (showOtherFields || mode === EBookingLinkModalMode.LINK_EDIT) && (
                        <>
                            <div className={s.formItem}>
                                <Form.Item
                                    label="Service type"
                                    name={serviceTypeField}
                                    rules={[
                                        { required: true, message: 'Service type is a required field' },
                                    ]}
                                >
                                    <Select
                                        fieldNames={{
                                            label: 'content',
                                            value: 'dictKey',
                                        }}
                                        options={serviceTypeOptions}
                                    />
                                </Form.Item>
                            </div>
                            <div className={s.formItem}>
                                <Form.Item
                                    label={
                                        <div className={s.formLabel}>State
                                            <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '12px' }} color="#000" title={<div className={s.tipText}>Only licensed states are available for selection</div>}>
                                                <div className={s.questionMarkIcon} />
                                            </Tooltip>
                                        </div>
                                    }
                                    name={stateField}
                                    rules={[
                                        { required: true, message: 'State is a required field' },
                                    ]}
                                >
                                    {/* <Select /> */}
                                    <StateSelect />
                                </Form.Item>
                            </div>
                            <div className={s.formItem}>
                                <Form.Item
                                    label="Specialty"
                                    name={specialtyField}
                                    rules={[
                                        { required: true, message: 'Specialty is a required field' },
                                    ]}
                                >
                                    <Select
                                        fieldNames={{
                                            label: 'content',
                                            value: 'dictKey',
                                        }}
                                        options={specialtyOptions}
                                        onChange={handleSpecialtyChange}
                                    />
                                </Form.Item>
                            </div>
                            <div className={s.formItem}>
                                <Form.Item
                                    label="Conditions"
                                    name={conditionsField}
                                    rules={[
                                        { required: true, message: 'Conditions is a required field' },
                                    ]}
                                >

                                    <Selector
                                        fieldNames={{
                                            label: 'content',
                                            value: 'dictKey',
                                        }}
                                        options={conditionsOptions}
                                        onChange={handleConditionChange}
                                    />
                                </Form.Item>
                            </div>
                        </>
                    )
                }
            </div>
            {isError && <div className={s.errorMessage}>Please enter all information to proceed</div>}
        </div>
    );
};

export default FormItem;
