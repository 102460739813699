import React from 'react';
import { Transition } from 'react-transition-group';

const duration = 500;

const defaultStyle = {
    transition: 'opacity',
    opacity: 0,
    transitionDuration: `${duration}ms`,
    transitionTimingFunction: 'ease-in-out',
};

const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};

const GrowIn = ({ children, inProp }: { children: React.ReactNode, inProp?: boolean }) => (
    <Transition in={inProp} timeout={duration} unmountOnExit>
        {(state) => (
            <div style={{
                ...defaultStyle,
                ...transitionStyles[state as (keyof typeof transitionStyles)],
            }}
            >
                {children}
            </div>
        )}
    </Transition>
);

export default GrowIn;
