import React from 'react';
import AccessDeniedImg from 'assets/accessManagement/securityBg.svg';
import s from './s.module.less';

type Props = {
    type?: 'info' | 'warning' | 'error' | 'success',
    text: string,
};
//not same ui with antd
const Alert = (props: Props) => {
    const { type, text } = props;
    return (
        <div className={s.wrap}>
            <span className={s.img}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6ZM9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11V14C9 14.5523 9.44772 15 10 15H11C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13V10C11 9.44772 10.5523 9 10 9H9Z" fill="#60A5FA" />
                </svg>
            </span>
            <div className={s.title}>{text}</div>
        </div>
    );
};

export default Alert;
