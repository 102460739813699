import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { AdditionalPracticeFormFieldEnum, PracticeFormInterface, PracitceAddressForm } from 'pages/CreateUniProfilePage/types';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { Button, Checkbox, Form, Input, Radio, message, Tooltip, Select, Modal, Spin, Space } from 'antd';
import StateSelect from 'components/form/StateSelect';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import { IServiceAddressUpdateInput, PracticeAddressUpdateType } from 'types/applicationForm';
import { getUserId } from 'utils/localstore';
import { IService } from 'types/practiceFront';
import { updateProviderServiceAddress, getAllServiceByPracticeAddressId } from 'api/applicationForm';
import { getAllServiceByPracticeAddressId as getOpsAllServiceByPracticeAddressId, updateProviderServiceAddress as updateOpsProviderServiceAddress } from 'api/operation';
import PracticeAddressRemovement from 'components/PracticeAddressRemovement';
import { useParams } from 'react-router-dom';
import { addressPublicType } from 'utils/address';
import { practiceAddressTypeMapping } from 'constants/uniprofile';

type IFormData = {
    addressList: Array<PracitceAddressForm>
};

interface IProps {
    isOps?: boolean;
    formName: string;
    showEditButtons?: boolean;
    initFormValue: Partial<PracticeFormInterface>,
    enable: boolean;
    onEditStatusChange: (v: boolean, formName: string) => void;
    onUpdateService: (formName: string, formValue: {
        providerPractice: Partial<PracticeFormInterface>
    }) => void;
}

const PracticeAddress = forwardRef(({
    isOps,
    initFormValue,
    formName,
    showEditButtons = true,
    enable,
    onUpdateService,
    onEditStatusChange,
}: IProps, ref) => {
    const { pid } = useParams();
    const providerId = pid ? parseInt(pid, 10) : 0;
    const [setting] = useSettings();
    const addressTypeSetting = setting[EnumFields.ADDRESS_PUBLIC_TYPE];

    const addressTypeOptions = useMemo(() => {
        if (addressTypeSetting) {
            return addressTypeSetting.map((e) => {
                const { value, label } = e;

                const typeMapping = practiceAddressTypeMapping[value as keyof typeof practiceAddressTypeMapping];

                if (typeMapping) {
                    return {
                        value,
                        label: typeMapping
                    }
                }

                return e;
            });
        }
        return [];
    }, [addressTypeSetting]);
    const [practiceAddressForm] = Form.useForm<IFormData>();
    const originFormValues = useRef<Partial<PracticeFormInterface>>();
    const [addressEditType, setAddressEditType] = useState<PracticeAddressUpdateType>(PracticeAddressUpdateType.UPDATE);

    const [showAddressUpdateModal, setShowAddressUpdateModal] = useState(false);
    const [fetchServiceLoading, setFetchServiceLoading] = useState<boolean>(false);
    const [serviceListData, setServiceListData] = useState<Array<IService>>([]);
    const [updateServiceAddressData, setUpdateServiceAddressData] = useState<Array<IServiceAddressUpdateInput>>([]);
    // 用来处理 address 删除的逻辑，review里的删除，需要调用form validation，步骤里的不需要，因为是所有address一起保存的。
    const [isSingleFormSubmit, setIsSingleFormSubmit] = useState(false);

    const formListRemoveFn = useRef<null | Function>(null);

    const [updatedAddressIdList, setUpdatedAddressIdList] = useState<Array<number>>([]);

    useEffect(() => {
        if (initFormValue) {
            originFormValues.current = {
                addressList: initFormValue.addressList ? [...initFormValue.addressList] : [],
            };
            practiceAddressForm.setFieldsValue(initFormValue);
        }
    }, [initFormValue, practiceAddressForm]);

    const fetchServiceList = useCallback(async (providerID: number, addressId: number) => {
        setFetchServiceLoading(true);
        let res = {
            error: '',
        };
        try {
            if (isOps) {
                res = await getOpsAllServiceByPracticeAddressId(providerID, addressId);
            } else {
                res = await getAllServiceByPracticeAddressId(providerID, addressId);
            }
        } catch (e) {
            res.error = e;
        }
        setFetchServiceLoading(false);
        return res;
    }, [isOps]);

    const getFormValues = useCallback(async () => {
        let validRes = {};
        try {
            validRes = await practiceAddressForm.validateFields();
        } catch (e) {
            validRes = e;
            console.error(e);
        }

        return validRes;
    }, [practiceAddressForm]);

    useImperativeHandle(ref, () => ({
        getFromValues: getFormValues,
    }));

    const handleFormFinish = useCallback(async () => {
        practiceAddressForm.validateFields().then((values: IFormData) => {
            if (typeof onUpdateService === 'function') {
                let addressListData = values.addressList;
                if (addressListData.length === 1) {
                    addressListData = [
                        {
                            ...addressListData[0],
                            [AdditionalPracticeFormFieldEnum.IS_DEFAULT]: true,
                        },
                    ];
                }
                onUpdateService(formName, {
                    providerPractice: {
                        ...initFormValue,
                        addressList: addressListData,
                    },
                });
            }
        }).catch((e) => {
            console.error(e);
        });
    }, [formName, initFormValue, onUpdateService, practiceAddressForm]);

    const handleFormFieldChange = useCallback((changedFields) => {
        if (changedFields.length > 0) {
            if (changedFields[0].name[2] === AdditionalPracticeFormFieldEnum.IS_DEFAULT) {
                const checkboxIndex = changedFields[0].name[1];
                const isChecked = changedFields[0].value;

                if (isChecked) {
                    const formValues = practiceAddressForm.getFieldsValue();
                    practiceAddressForm.setFieldValue('addressList', formValues.addressList.map((address: PracitceAddressForm, index) => ({
                        ...address,
                        isDefault: index === checkboxIndex,
                    })));
                }
            }
        }
    }, [practiceAddressForm]);

    const checkAddable = useCallback((add: (defaultValue?: any, insertIndex?: number | undefined) => void) => {
        const data = practiceAddressForm.getFieldsValue();
        const noEmptyEducation = !data.addressList || data.addressList.every((item) => !!item && Object.values(item).some((v) => !!v));

        if (noEmptyEducation) {
            add();
        } else {
            message.error('There\'s already an empty practice address form !');
        }
    }, [practiceAddressForm]);

    const handleBeforeRemove = useCallback(async (addressId: number, removeCallback: () => void) => {
        const userId = isOps ? providerId : getUserId();

        if (userId) {
            const res = await fetchServiceList(userId, addressId);

            if (!res.error) {
                if (res?.data?.data?.length > 0) {
                    setServiceListData(res.data.data);
                    setAddressEditType(PracticeAddressUpdateType.REMOVE);
                    setShowAddressUpdateModal(true);
                    formListRemoveFn.current = removeCallback;
                } else {
                    removeCallback();
                }
            } else {
                message.error(res.error);
            }
        }
    }, [fetchServiceList, isOps, providerId]);

    const handleRemove = useCallback((index: number, remove: (index: number | number[]) => void) => {
        const values = practiceAddressForm.getFieldsValue();

        const removedItem = values.addressList?.[index];

        if (removedItem) {
            if (removedItem.isDefault) {
                if (!removedItem.id) {
                    practiceAddressForm.setFieldsValue(originFormValues.current);
                } else {
                    handleBeforeRemove(removedItem.id, () => {
                        remove(index);
                        const fieldsValues = practiceAddressForm.getFieldsValue();

                        practiceAddressForm.setFieldsValue({
                            addressList: fieldsValues.addressList?.map((e, i: number) => (i === 0 ? { ...e, isDefault: true } : e)),
                        });
                    });
                }
            } else if (removedItem.id) {
                handleBeforeRemove(removedItem.id, () => {
                    remove(index);
                });
            } else { // 这个else 和下面else的区别是，这个else是新增的address，输入了一些字段，但是因为新增未保存，所以无id，所以也是直接删
                remove(index);
            }
        } else {
            remove(index);
        }
    }, [handleBeforeRemove, practiceAddressForm]);

    const handleServiceAddressChange = useCallback(async (data: Array<IServiceAddressUpdateInput>) => {
        setUpdateServiceAddressData(data);
    }, []);

    const getAllServicesByChangeAddressIds = useCallback(async () => {
        const userId = isOps ? providerId : getUserId();

        if (userId) {
            if (updatedAddressIdList.length > 0) {
                try {
                    const resps = await Promise.all(updatedAddressIdList.map((addressId: number) => fetchServiceList(userId, addressId)));

                    const errors = resps.find((res) => !!res.error);

                    if (!errors) {
                        const servicesList = resps.reduce((list, res) => {
                            if (res?.data?.data?.length > 0) {
                                const services = res.data.data;
                                return list.concat(services);
                            }

                            return list;
                        }, []);

                        if (servicesList.length > 0) {
                            setServiceListData(servicesList);
                            setAddressEditType(PracticeAddressUpdateType.UPDATE);
                            setShowAddressUpdateModal(true);
                        } else {
                            handleFormFinish();
                        }
                    } else {
                        message.error(errors.error);
                    }
                } catch (e) {
                    console.error(e);
                }
            } else {
                handleFormFinish();
            }
        }
    }, [fetchServiceList, handleFormFinish, isOps, providerId, updatedAddressIdList]);

    const handleAddressChange = useCallback((index: number) => {
        const values = practiceAddressForm.getFieldsValue();

        const editItem = values.addressList?.[index];

        if (editItem && editItem.id) {
            const addressId = editItem.id;

            if (!updatedAddressIdList.includes(addressId)) {
                updatedAddressIdList.push(addressId);

                setUpdatedAddressIdList([...updatedAddressIdList]);
            }
        }
    }, [practiceAddressForm, updatedAddressIdList]);

    const handleConfirm = useCallback(async () => {
        if (typeof formListRemoveFn.current === 'function') {
            (formListRemoveFn.current)();
        }
        if (isSingleFormSubmit) {
            handleFormFinish();
        }

        let res;

        if (isOps) {
            res = await updateOpsProviderServiceAddress(updateServiceAddressData);
        } else {
            res = await updateProviderServiceAddress(updateServiceAddressData);
        }

        if (res && !res.error) {
            setUpdatedAddressIdList([]);
            setShowAddressUpdateModal(false);
        } else {
            message.error(res.error);
        }
    }, [handleFormFinish, isOps, isSingleFormSubmit, updateServiceAddressData]);

    const resetAddressVars = useCallback(() => {
        setUpdateServiceAddressData([]);
        formListRemoveFn.current = null;
    }, []);

    const handleHideAddressUpdateModal = useCallback(() => {
        setShowAddressUpdateModal(false);
        resetAddressVars();
    }, [resetAddressVars]);

    const renderEditButtons = useMemo(() => {
        if (showEditButtons) {
            return !enable ? (
                <div key={`${formName}_disable`} className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            setIsSingleFormSubmit(true);
                            onEditStatusChange(true, formName);
                        }}
                    >
                        Edit
                    </Button>
                </div>
            ) : (
                <div key={`${formName}_enable`} className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            resetAddressVars();
                            setIsSingleFormSubmit(false);
                            onEditStatusChange(false, formName);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        // size="small"
                        type="primary"
                        onClick={getAllServicesByChangeAddressIds}
                    >
                        Save changes
                    </Button>
                </div>
            );
        }
        return null;
    }, [showEditButtons, enable, formName, getAllServicesByChangeAddressIds, onEditStatusChange, resetAddressVars]);

    const editFormList = useMemo(() => {
        return (
            <Form.List name="addressList">
                {
                    (fields, { add, remove }) => {
                        const additionalButtonText = '+ Add another practice address';
                        return (
                            <>
                                {
                                    fields.map(({ key, name, id, ...restField }, index) => {
                                        return (
                                            <div key={id | key} className={s.additionalBox}>
                                                <div className={s.labelBox}>
                                                    <div className={`${s.label} ${index === 0 && s.required} ${s.third}`}>{`Practice address ${index + 1}`}</div>
                                                    { fields.length > 1 && <Button onClick={() => handleRemove(index, remove)}>Remove</Button>}
                                                </div>
                                                <div className={s.additionalForm}>
                                                    <div className={s.subModule}>
                                                    <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label={
                                                                        <div className={s.formLabel}>Address type</div>
                                                                    }
                                                                    name={[name, AdditionalPracticeFormFieldEnum.ADDRESS_PUBLIC_TYPE]}
                                                                    rules={[
                                                                        { required: true, message: 'Address type is a required field' },
                                                                    ]}
                                                                >
                                                                    {/* <Select options={addressTypeOptions} onChange={() => handleAddressChange(index)} /> */}
                                                                    <Radio.Group onChange={() => handleAddressChange(index)}>
                                                                        <Space direction="vertical">
                                                                            {
                                                                                addressTypeOptions?.map((e) => {
                                                                                    return (
                                                                                        <Radio value={e.value}>{e.label}</Radio>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Space>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Address"
                                                                    name={[name, AdditionalPracticeFormFieldEnum.ADDRESS]}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: 'Address is a required field' },
                                                                    ]}
                                                                >
                                                                    <Input type="text" onChange={() => handleAddressChange(index)} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="City"
                                                                    name={[name, AdditionalPracticeFormFieldEnum.CITY]}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: 'City is a required field' },
                                                                    ]}
                                                                >
                                                                    <Input type="text" onChange={() => handleAddressChange(index)} />
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="State"
                                                                    name={[name, AdditionalPracticeFormFieldEnum.STATE]}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: 'State is a required field' },
                                                                    ]}
                                                                >
                                                                    <StateSelect onChange={() => handleAddressChange(index)} />
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="ZIP Code"
                                                                    name={[name, AdditionalPracticeFormFieldEnum.ZIP_CODE]}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: 'Zip code is a required field' },
                                                                    ]}
                                                                >
                                                                    <Input type="text" onChange={() => handleAddressChange(index)} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                                style={{ marginBottom: '-24px' }}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, AdditionalPracticeFormFieldEnum.IS_DEFAULT]}
                                                                    valuePropName="checked"
                                                                >
                                                                    <Radio>
                                                                        <span className={s.textWithQ}>
                                                                            Make this my default practice address
                                                                            <Tooltip color="#fff" overlayInnerStyle={{ color: '#333', padding: 16 }} title="Default practice address will be used as your default address in Practice Front, Service and Channels.">
                                                                                <span className={s.questionMarkIcon} />
                                                                            </Tooltip>
                                                                        </span>
                                                                    </Radio>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row} style={{ paddingTop: 16 }}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, AdditionalPracticeFormFieldEnum.AS_MAILING]}
                                                                    valuePropName="checked"
                                                                >
                                                                    <Checkbox>Set as a mailing address</Checkbox>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        {/* <div className={s.row} style={{ marginTop: 0 }}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, AdditionalPracticeFormFieldEnum.NOT_PUBLISH]}
                                                                    valuePropName="checked"
                                                                >
                                                                    <Checkbox>
                                                                        <span className={s.textWithQ}>Don’t publish this address publicly on my profiles
                                                                            <Tooltip color="#fff" overlayInnerStyle={{ color: '#333', padding: 16 }} title="We will hide your address and only show states and city for SEO purpose.">
                                                                                <span className={s.questionMarkIcon} />
                                                                            </Tooltip>
                                                                        </span>
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className={s.formFooter}>
                                                        { fields.length > 1 && <Button onClick={() => handleRemove(index, remove)}>Remove</Button> }
                                                    </div>
                                                </div>
                                            </div>
                                            // )
                                        );
                                    })
                                }
                                <Form.Item>
                                    <div className={s.additionalTitle} onClick={() => checkAddable(add)}>{additionalButtonText}</div>
                                </Form.Item>
                            </>
                        );
                    }
                }
            </Form.List>
        );
    }, [addressTypeOptions, checkAddable, handleAddressChange, handleRemove]);

    const renderViewList = useMemo(() => {
        return initFormValue?.addressList?.map((item, index) => {
            const {
                practiceCity,
                practiceZip,
                practiceState,
                practiceAddress,
                isDefault,
                asMailing,
                publicType,
            } = item;

            const addressValue = practiceAddress || '';
            const cityValue = practiceCity ? `,${practiceCity}` : '';
            const stateValue = practiceState ? `,${practiceState}` : '';
            const zipCodeValue = practiceZip ? `,${practiceZip}` : '';

            const finalAddress = `${addressValue}${cityValue}${stateValue} ${zipCodeValue}`;

            return (
                <div key={index} className={s.row}>
                    <div className={s.addressBox}>
                        <div className={s.labelBox}>
                            <div className={s.label}>Practice address {index + 1}</div>
                            { isDefault && <div className={s.defaultTag}>Default</div> }
                            { asMailing && <div className={s.mailingTag}>Mailing address</div> }
                            { publicType && <div className={s.publicTypeTag}>{addressPublicType(publicType)}</div> }
                        </div>
                        <div className={s.intro}>
                            {finalAddress || ''}
                        </div>
                    </div>
                </div>
            );
        });
    }, [initFormValue]);

    return (
        <div className={s.wrap}>
            <div
                id="practiceAddress"
                style={{
                    position: 'relative',
                    top: '-120px',
                }}
            />
            <Spin spinning={fetchServiceLoading}>
                <Modal
                    title={`${addressEditType === PracticeAddressUpdateType.REMOVE ? 'Remove the address' : 'Update the address'}`}
                    centered
                    open={showAddressUpdateModal}
                    okText={`${addressEditType === PracticeAddressUpdateType.REMOVE ? 'Remove' : 'Update'}`}
                    onOk={handleConfirm}
                    onCancel={(handleHideAddressUpdateModal)}
                    width="50%"
                >
                    <div className={s.ServiceModalContent}>
                        <PracticeAddressRemovement
                            isOps={isOps}
                            providerId={providerId}
                            serviceList={serviceListData}
                            onChange={handleServiceAddressChange}
                            type={addressEditType}
                        />
                    </div>
                </Modal>
                <h4>
                    Practice address
                    {renderEditButtons}
                </h4>
                <div className={s.content}>
                    {
                        !enable ?
                            renderViewList :
                            <Form
                                scrollToFirstError
                                form={practiceAddressForm}
                                name={formName}
                                className={commonS.formStyle1}
                                initialValues={initFormValue}
                                autoComplete="off"
                                layout="vertical"
                                onFieldsChange={handleFormFieldChange}
                            >
                                {editFormList}
                            </Form>
                    }
                </div>
            </Spin>
        </div>
    );
});

export default PracticeAddress;
