import React from 'react';
import s from './s.module.less';
import { IAssistant } from 'types/ehr';

interface IProps {
    data: IAssistant[]
}

const Assistant = ({
    data,
}: IProps) => {
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>Assistant</div>
            </div>
            <div className={s.body}>
                {
                    data?.map((item) => {
                        const { id, assistantName, assistantEmail, addOnList } = item;
                        return (
                            <div key={id} className={s.listItem}>
                                <div className={s.row}>
                                    <div className={s.item}>
                                        <div className={s.label}>Assistant’s name</div>
                                        <div className={s.value}>{assistantName}</div>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div className={s.item}>
                                        <div className={s.label}>Email</div>
                                        <div className={s.value}>{assistantEmail}</div>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div className={s.item}>
                                        <div className={s.label}>Selected add-on</div>
                                        <div className={s.value}>{addOnList ? addOnList?.join(',') : '-'}</div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Assistant;
